import React, {useState, useMemo, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import gql from 'graphql-tag';
import {useQuery} from 'react-apollo';
import {Card, Icon, Label, Search} from 'semantic-ui-react';

const _ = require('lodash');


const SEARCH_CONTACTS = gql`
    query searchContacts($searchText: String!) {
        searchContacts(searchText: $searchText) {
            nodes{
                id
                fullname
                title
                phone
                active
                drymode
                teamByTeamid {
                    id
                    name
                    leagueByLeagueid {
                        id
                        name
                    }
                }
            }
        }
    }
`;

const resultRenderer = ({ id, fullname, phone, title, active, drymode, teamByTeamid }) => {
    return (
        <div id={id}>
            <Card color={"orange"} style={{padding:8}} raised centered>
                <Card.Header>{fullname}</Card.Header>
                <Card.Meta>{title}</Card.Meta>
                <Card.Description>
                    <div>Phone: {phone}</div>
                    <div style={{fontWeight: 700}}>
                        <div>Team: {teamByTeamid.name}</div>
                        <div>League: {teamByTeamid.leagueByLeagueid.name}</div>
                    </div>
                    <div>
                        ACTIVE: { active ? <Icon name='check' color='green'/> : <Icon name='x' color='red'/> }
                        DRYMODE: { drymode ? <Icon name='check' color='green'/> : <Icon name='x' color='red'/> }
                    </div>
                </Card.Description>
            </Card>
        </div>)
}

const  SearchContact  = () =>  {
    const history = useHistory();
    const [value, setValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const {loading, error, data} = useQuery(SEARCH_CONTACTS, {variables: {searchText: searchValue}});

    const handleResultSelect = (result) => {
        history.push(`/contacts/${result.id}`);
    }
    const handleSearchChange = async (value) => {
        setSearchValue(value);
    }

    const debouncedResults = useMemo((v) => {
        setValue(v);
        return _.debounce(handleSearchChange, 700);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <Label>
                Search Contacts:
            <Search as='span'
                aligned={'right'}
                loading={loading}
                onResultSelect={(e, {result}) => handleResultSelect(result)}
                onSearchChange={(e, {value}) => { debouncedResults(value)}}
                results={(data &&  data.searchContacts && data.searchContacts.nodes) || []}
                value={value}
                resultRenderer={resultRenderer || <div/>}
            />
        </Label>
    );


}

export default SearchContact;
