import {Component} from "react";
import {
    Button,
    Icon,
    List, Message, Image
} from "semantic-ui-react";

import React from "react";
import gql from "graphql-tag";

import { Mutation } from "react-apollo";
import image1 from "../../images/christian.jpg";
import image2 from "../../images/helen.jpg";
import image3 from "../../images/daniel.jpg";

const _ = require('lodash');

const getContactImage = (index) => {
    if(index % 3 === 0) {
        return image1;
    } else if(index % 3 === 1) {
        return image2;
    }
    return image3;
};


const ACTIVATE_ALTERNATIVE_MUTATION = gql`
    mutation activateAlternativeContact($input: ActivateAlternativeContactInput!) {
        activateAlternativeContact(input: $input){
            alternativeContact{
                id
                timestamp
            }
        }
    }
`;

class AlternativeContactsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            displaySuccess: false,
        };
        this.handleRemoveAlternative = this.handleRemoveAlternative.bind(this);
        this.renderAlternativeContacts = this.renderAlternativeContacts.bind(this);
    };

    handleRemoveAlternative = async (alternativeId, active) => {
        this.props.activateAlternativeContact({
                variables: {
                    input: {
                        contactId: alternativeId,
                        activeInput: active
                    }
                }, refetchQueries: [{
                query: this.props.refetchQuery, variables: {id: this.props.contactPersonId}
            }]
        }).then(() => {
            this.setState({displaySuccess: true});
            setTimeout(() => {
                this.setState({displaySuccess: false});
            }, 3000);
        }).catch((err) => {
                console.error(err);
                this.setState({error: err.toString()});
                setTimeout(() => {
                    this.setState({error: null});
                }, 5000);
            }
        );
    };

    renderAlternativeContacts = () => {
        const {alternativeContacts} = this.props;
        return _.map(alternativeContacts, (alternativeContact, index) => {
            return (
                <List.Item key={alternativeContact.id}>
                    <Image avatar src={getContactImage(index)} />
                    <List.Content>
                        <List.Header>{alternativeContact.fullname}</List.Header>
                        Title: {alternativeContact.title} Active: { alternativeContact.active ? <Icon name='check' color='green'/> : <Icon name='x' color='red'/> }
                    </List.Content>
                    <Button color='blue' floated='right' content={alternativeContact.active ? 'Inactivate' : 'activate'} onClick={() => this.handleRemoveAlternative(alternativeContact.id, !alternativeContact.active)}/>
                </List.Item>);
        })
    };

    render() {
        return (
            <List celled>
                {
                    this.state.displaySuccess ?
                        <Message
                            success
                            header='Alternative contact activation/inactivation successful!'
                        />
                        :''
                }
                {
                    this.state.error ?
                        <Message
                            warning
                            header='Error activating/inactivating alternative contact'
                            content={this.state.error}
                        /> : ''
                }
                {this.renderAlternativeContacts()}
            </List>
        );
    }
}


const AlternativeContacts= ({contactPersonId, alternativeContacts, refetchQuery}) => ( <Mutation mutation={ACTIVATE_ALTERNATIVE_MUTATION}>
    {(activateAlternativeContact) => (
        <AlternativeContactsContainer activateAlternativeContact={activateAlternativeContact} contactPersonId={contactPersonId} alternativeContacts={alternativeContacts} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default AlternativeContacts;

