import React, { useState, useEffect } from 'react';
import {
    Icon,
} from 'semantic-ui-react';
import _ from 'lodash'
import isArticleLocked from '../utils/isArticleLocked'

export default function LocksByArticle(props) {
    const { article } = props
    const [now, setNow] = useState(new Date());
    useEffect(() => {
        const id = setInterval(() => {
            setNow(new Date());
        }, 1000);
        return () => clearInterval(id);
    }, []);

    if (!_.get(article, 'locksByArticle.nodes.length')) {
        return null
    }

    const currentAdmin = JSON.parse(localStorage.getItem('current-admin'));
    const lastLock = _.get(article, 'locksByArticle.nodes[0]')
    if (!lastLock) {
        return null
    }

    const isLocked = isArticleLocked(lastLock.timestamp, now);
    if (!isLocked) {
        return null
    }

    return (
        <div style={{ display: 'flex', marginTop: 5 }}>
            <span style={{ color: isLocked ? '#db2828' : 'black' }}>
                <Icon name="pencil alternate" />
            </span>

            <div>
                by {' '}
                <b>{lastLock.adminByAdmin.id === currentAdmin.id ? 'me' : lastLock.adminByAdmin.fullname}</b> {' '}
            </div>
        </div>
    )
}
