import React from 'react'
import gql from 'graphql-tag';
import {Header, Container, Segment, Grid, Label, Card, Icon, List} from 'semantic-ui-react'
import Info from '.././Info';
import Messages from "../messages/Messages";
import SendNewMessage from "./SendNewMessage";
import ContactPersonSettings from "./ContactPersonSettings";
import AddAlternativeContact from "./AddAlternativeContact";
import SearchContact from '../SearchContact';
import { Query } from "react-apollo";
import AlternativeContacts from "./AlternativeContacts";
import {Link} from "react-router-dom";
import ContactNotifications from "../notification/ContactNotifications";
const _ = require('lodash');

const displayLeagues = (teamLeaguesByTeamId) => {
    return _.map(_.filter(teamLeaguesByTeamId?.nodes, (ln) => ln.leagueByLeagueId?.active), (ln) => {
        return (<List.Item>{`${ln.leagueByLeagueId.id} - ${ln.leagueByLeagueId.name}`}</List.Item>);
    })
}

const moment = require('moment');

const ContactPersonContainer = ({admins, contactPerson, error}) => {
    if (contactPerson && contactPerson.fullname ) {
        return (
            <div>
                <Header as='h1' textAlign='center' color='blue'>
                    {`Contact Person: ${contactPerson.title} ${contactPerson.fullname}`}
                </Header>

                <Grid>
                    <Grid.Column floated='right' width={5}>
                        <SearchContact />
                    </Grid.Column>
                </Grid>
                <Container style={{marginTop: 20}}>
                    <Grid columns={2}>
                        <Grid.Column width={10}>
                            <ContactNotifications contactId={contactPerson.id}/>
                            <SendNewMessage contactPerson={contactPerson} refetchQuery={contactPersonPageQuery}/>
                            <Segment color='yellow' style={{textAlign: 'left'}}>
                                <Label as='h2' size='big' color='blue' ribbon style={{marginBottom: 15}}>Latest 50 Messages</Label>
                                <Messages admins={admins.nodes} messages={contactPerson && contactPerson.messagesByContactpersonid.nodes} contactPersonName={contactPerson && contactPerson.fullname}/>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Card fluid color={'grey'} >
                                <Card.Content>
                                    <Card.Header>{contactPerson.title + ' '+ contactPerson.fullname}</Card.Header>
                                    <Card.Meta>
                                        <span className='date'>Joined in {moment(contactPerson.registrationdate).format('MMMM Do, YYYY')}</span>
                                    </Card.Meta>
                                    <Card.Description>
                                        {contactPerson.fullname} is contact person for <strong><Link as={'a'} to={'/team/'+contactPerson.teamByTeamid.id}>{contactPerson.teamByTeamid.name}</Link></strong> team.
                                        <div><strong>Phone Number:</strong> {contactPerson.phone}</div>
                                        <div><b>Leagues</b><List>{displayLeagues(contactPerson.teamByTeamid.teamLeaguesByTeamId)}</List></div>
                                        <div>
                                            Active: { contactPerson.active ? <Icon name='check' color='green'/> : <Icon name='x' color='red'/> }
                                            Dry mode: { contactPerson.drymode ? <Icon name='check' color='green'/> : <Icon name='x' color='red'/> }
                                            Send Matchday Reminder: { contactPerson.sendMatchDay ? <Icon name='check' color='green'/> : <Icon name='x' color='red'/> }
                                        </div>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra >
                                    <a href={`http://inrapportering.everysport.se/intra-new/contactList.html?teamId=${contactPerson.teamByTeamid.id}&clean`} target="_blank" rel="noopener noreferrer"><Icon color='red' name='linkify' /> <Label>Edit in inrapportering.everysport.se </Label></a>
                                </Card.Content>
                            </Card>
                            <ContactPersonSettings contactPerson={contactPerson} refetchQuery={contactPersonPageQuery}/>
                            <Segment color='blue' textAlign='left'>
                                <Header as='h3' color='red'>Alternative Contacts</Header>
                                <AlternativeContacts refetchQuery={contactPersonPageQuery} contactPersonId={contactPerson.id} alternativeContacts={contactPerson.alternativeContactsByContactpersonId.nodes}/>
                                <AddAlternativeContact contactPersonId={contactPerson.id} refetchQuery={contactPersonPageQuery} />
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Container>
            </div>
        );
    } else {
        return <Info icon='warning' message={error || 'Sorry, we could not find the contact person you requested!'} title='Error'
                     loading={false}/>;
    }


};

const contactPersonPageQuery = gql`
    query ContactPersonQuery($id: Int!) {
        contactPerson: contactpersonById(id: $id) {
            id
            fullname
            title
            phone
            active
            drymode
            level
            qnaManaged
            registrationdate
            sendMatchDay
            remindToAnswer
            sendApproved
            teamByTeamid {
                id
                name
                teamLeaguesByTeamId{
                    nodes{
                        leagueByLeagueId{
                            id
                            name
                            sportname
                            teamclass
                            active
                        }
                    }
                }
                leagueByLeagueid {
                    id
                    name
                    sportname
                }
            }
            alternativeContactsByContactpersonId {
                nodes {
                    id
                    fullname
                    title
                    timestamp
                    active
                }
            }
            messagesByContactpersonid(first: 50, orderBy: [TIMESTAMP_DESC]) {
                nodes{
                    id
                    type
                    timestamp
                    about
                    questionid
                    text
                    sender
                    questionByQuestionid{
                        id
                        question
                        type
                        articleByArticleid {
                            id
                            title
                        }
                    }
                }
            }
        }
        allAdmins {
            nodes {
                id
                fullname
                avatarByAvatar {
                    id
                    url
                }
            }
        }
    }
`;

const ContactPerson = (props) => ( <Query query={contactPersonPageQuery} variables={{ id: parseInt(props.match.params.contactPersonId)}}>
    {({ data, loading, error }) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if(data && data.contactPerson) {
            return <ContactPersonContainer admins={data.allAdmins} contactPerson={data.contactPerson}/>
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, we could not find the contact person you requested!'} title='Error' loading={false}/>;
    }}
</Query>);

export default ContactPerson;
