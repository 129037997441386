import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import React, {Component} from "react";
import {Icon, Form, Message} from "semantic-ui-react";

const ADD_ALTERNATIVE_MUTATION = gql`
    mutation addAlternativeContact($input: AddAlternativeContactInput!) {
        addAlternativeContact(input: $input) {
            alternativeContact {
                id
                timestamp
            }
        }
    }
`;

class AddAlternativeContactContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alternativeName: '',
            alternativeTitle: '',
            displaySuccess: false,
            error: '',
        };
        this.handleAddAlternativeContact = this.handleAddAlternativeContact.bind(this);
    };

    handleAddAlternativeContact = () => {
        this.props.addAlternativeContact({
            variables: {
                input: {
                    fullnameIn: this.state.alternativeName,
                    titleIn: this.state.alternativeTitle,
                    contactpersonIdIn: this.props.contactPersonId
                }
            }, refetchQueries: [{
                query: this.props.refetchQuery, variables: {id: this.props.contactPersonId}
            }]
        }).then(() => {
            this.setState({displaySuccess: true, alternativeName: '', alternativeTitle: ''});
            setTimeout(() => {
                this.setState({displaySuccess: false});
            }, 3000);
        }).catch((err) => {
            console.error(err);
            this.setState({error: err.toString()});
            setTimeout(() => {
                this.setState({error: ''});
            }, 5000);
        });

    };

    render() {
        return (
            <Form>
                {
                    this.state.displaySuccess ?
                        <Message
                            success
                            header='Settings changed successfully!'
                        />
                        :''
                }
                {
                    this.state.error ?
                        <Message
                            warning
                            header='Error changing contact settings'
                            content={this.state.error}
                        /> : ''
                }
                <Form.Group>
                    <Form.Input label='Full Name'
                                value={this.state.alternativeName}
                                onChange={(event) => this.setState({alternativeName: event.target.value})}
                                placeholder='Name Surname'/>
                    <Form.Input label='Title'
                                value={this.state.alternativeTitle}
                                onChange={(event) => this.setState({alternativeTitle: event.target.value})}
                                placeholder='Add a note'/>
                </Form.Group>
                <Form.Button disabled={!this.state.alternativeTitle || !this.state.alternativeName}  onClick={this.handleAddAlternativeContact} color='green'><Icon name='add'/> Add Alternative Contact</Form.Button>
            </Form>
        );

    }
};

const AddAlternativeContact = ({contactPersonId, refetchQuery}) => ( <Mutation mutation={ADD_ALTERNATIVE_MUTATION}>
    {(addAlternativeContact) => (
        <AddAlternativeContactContainer addAlternativeContact={addAlternativeContact} contactPersonId={contactPersonId} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default AddAlternativeContact;
