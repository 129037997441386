import { Component } from 'react'
import {
    Button,
    Divider,
    Dropdown,
    Icon,
    Input,
    Label,
    Segment,
    Form,
    Message,
} from 'semantic-ui-react'

import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

const _ = require('lodash')

const SEND_SMS_MUTATION = gql`
    mutation sendSms($input: SendSmsInput!) {
        sendSms(input: $input) {
            message {
                timestamp
            }
        }
    }
`

class SendNewMessageContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            topic: null,
            displayNewTopic: false,
            isQuestion: false,
            questionId: -1,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSendSms = this.handleSendSms.bind(this)
    }

    handleChange = (e, { value }) => {
        const valueObj = JSON.parse(value)
        if (valueObj.about === 'new') {
            this.setState({
                topic: '',
                displayNewTopic: true,
                isQuestion: false,
                questionId: -1,
            })
        } else {
            this.setState({
                topic: valueObj.about,
                displayNewTopic: false,
                isQuestion: valueObj.isQuestion,
                questionId: valueObj.questionId,
                displaySuccess: false,
                error: '',
            })
        }
    }

    handleNewTopic = (e, { value }) => {
        this.setState({ topic: value })
    }

    handleMessageChange = event => {
        this.setState({ message: event.target.value })
    }

    handleSendSms = () => {
        console.log(
            'Another message sent',
            this.state.message,
            this.state.topic,
            this.state.isQuestion,
            this.state.questionId
        )
        console.log('handleSendSms', this.props.contactPerson.id)

        this.props
            .sendSms({
                variables: {
                    input: {
                        contactpersonid: this.props.contactPerson.id,
                        text: this.state.message,
                        questionid: this.state.questionId,
                        about: this.state.isQuestion ? '' : this.state.topic,
                    },
                },
                refetchQueries: [
                    {
                        query: this.props.refetchQuery,
                        variables: { id: this.props.contactPerson.id },
                    },
                ],
            })
            .then(() => {
                this.setState({ displaySuccess: true, message: '' })
                setTimeout(() => {
                    this.setState({ displaySuccess: false })
                }, 3000)
            })
            .catch(err => {
                console.error(err)
                this.setState({ error: err.toString() })
                setTimeout(() => {
                    this.setState({ error: '' })
                }, 5000)
            })
    }

    render() {
        const { contactPerson } = this.props

        if (contactPerson && contactPerson.fullname) {
            const messageTopics = _.map(
                _.uniqBy(
                    contactPerson.messagesByContactpersonid.nodes,
                    message => message.questionid || message.about
                ),
                message => {
                    if (message.questionid) {
                        return {
                            key: message.questionid,
                            value: JSON.stringify({
                                questionId: message.questionid,
                                isQuestion: true,
                                about:
                                    message.questionByQuestionid
                                        .articleByArticleid.title,
                            }),
                            text:
                                message.questionByQuestionid.articleByArticleid
                                    .title,
                        }
                    } else {
                        return {
                            key: message.about,
                            value: JSON.stringify({
                                questionId: -1,
                                isQuestion: false,
                                about: message.about,
                            }),
                            text: message.about,
                        }
                    }
                }
            )
            messageTopics.push({
                key: 'new',
                value: JSON.stringify({ isQuestion: false, about: 'new' }),
                text: 'New Topic',
            })

            return (
                <Segment color="yellow" style={{ textAlign: 'left' }}>
                    <Label
                        as="h2"
                        size="big"
                        color="blue"
                        ribbon
                        style={{ marginBottom: 15 }}
                    >
                        Send a new message
                    </Label>
                    {this.state.displaySuccess ? (
                        <Message success header="SMS sent successfully!" />
                    ) : (
                        ''
                    )}
                    {this.state.error ? (
                        <Message
                            warning
                            header="Error sending SMS"
                            content={this.state.error}
                        />
                    ) : (
                        ''
                    )}
                    <Form reply>
                        <Form.TextArea
                            value={this.state.message}
                            autoHeight
                            placeholder="Send new sms to contact person"
                            onChange={this.handleMessageChange}
                        />

                        <Dropdown
                            name="Topic"
                            text="Topic"
                            icon="tags"
                            floating
                            labeled
                            button
                            className="icon"
                            onChange={this.handleChange}
                            options={messageTopics}
                        />

                        <Button
                            content="Send"
                            attached="right"
                            labelPosition="left"
                            icon={<Icon name={'send'} />}
                            primary
                            disabled={!this.state.topic || !this.state.message}
                            onClick={this.handleSendSms}
                        />

                        {this.state.displayNewTopic ? (
                            <div>
                                <Divider />
                                <Input
                                    placeholder="Add new topic..."
                                    value={this.state.topic}
                                    onChange={this.handleNewTopic}
                                />
                            </div>
                        ) : null}

                        {this.state.topic ? (
                            <div>
                                <Divider />
                                <Label as="p" color="blue" tag>
                                    {this.state.topic}
                                </Label>
                            </div>
                        ) : null}
                    </Form>
                </Segment>
            )
        }
    }
}

const SendNewMessage = ({ contactPerson, refetchQuery }) => (
    <Mutation mutation={SEND_SMS_MUTATION}>
        {sendSms => (
            <SendNewMessageContainer
                sendSms={sendSms}
                contactPerson={contactPerson}
                refetchQuery={refetchQuery}
            />
        )}
    </Mutation>
)

export default SendNewMessage
