import React, { Component } from 'react';
import './App.css';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import QnaAppBar from './components/QnaAppBar';
import Main from './containers/Main';
import { BrowserRouter } from 'react-router-dom'

const httpLink = createHttpLink({uri: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3030/graphql'});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them

    if(token) {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            }
        }
    } else {
        if(headers) {
            headers.delete('authorization');
        }
        return headers;
    }

});

const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(err => {
            if(err.message ===  'invalid token' ) {
                localStorage.removeItem('token');
            }
        })

    }
    if (networkError && (networkError.statusCode === 401 || networkError.statusCode === 403)) {
        localStorage.removeItem('token');
    }
});

const link = ApolloLink.from([
    errorLink,
    authLink.concat(httpLink)
]);

const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});
class App extends Component {
    render() {
        return (
            <ApolloProvider client={client}>
                <div className="App">
                    <BrowserRouter basename={process.env.REACT_APP_URL_PREFIX }/>
                    <QnaAppBar />

                    <Main />
                </div>
            </ApolloProvider>
        );
    }
}

export default App;
