import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import React, {Component} from "react";
import {Icon, Form} from "semantic-ui-react";
import Info from "../Info";
import SearchTeams from "./SearchTeams";
import SeasonPicker from "./SeasonPicker";
const _ = require('lodash');

const EDIT_EXTERNAL_CONTACT_MUTATION = gql`
    mutation editExternalContact($input: EditExternalContact3Input!) {
        editeExternalContact: editExternalContact3(input: $input) {
            externalContact {
                id
                fullname
            }
        }
    }
`;

class EditExternalContactContainer extends Component {
    constructor(props) {
        super(props);
        const {externalContact: {
            id,
            fullname,
            phone,
            note,
            receiver,
            teamId,
            title,
            seasonId,
            teamByTeamId
        }} = this.props;
        this.state = {
            id,
            title,
            fullname,
            phone,
            note,
            receiver: receiver ? receiver : '-1',
            teamId,
            sportId: teamByTeamId?.leagueByLeagueid?.sportid,
            seasonId,
            error: '',
            displaySuccess: false,
        };
        this.handleEditExternalContact = this.handleEditExternalContact.bind(this);
    };

    handleEditExternalContact = async () => {

        let formatted;
        let phoneStr = this.state.phone.replace(/(?!^[+])[^0-9]/g,'');
        if(phoneStr.startsWith('00')) {
            formatted = '+' + phoneStr.substring(2);
        } else if(phoneStr.startsWith('0')) {
            formatted = '+46' + phoneStr.substring(1);
        } else {
            formatted = phoneStr;
        }

        this.setState({phone: formatted});


        const input =  {
            contactId: this.state.id,
            name: this.state.fullname,
            noteIn: this.state.note || '',
            phoneIn: formatted,
            titleIn: this.state.title,
            season: this.state.seasonId
        };

        if(this.state.teamId) {
            input.teamIn = this.state.teamId
        }
        if(this.state.receiver && this.state.receiver !== '-1') {
            input.receiverIn = this.state.receiver
        }

        try {
            const result = await this.props.editExternalContact({
                variables: {
                    input
                }, refetchQueries: [{
                    query: this.props.refetchQuery,
                    variables: {
                        id: this.props.externalContact.id,
                        adminId: JSON.parse(localStorage.getItem('current-admin')).id
                    }
                }]
            });
            console.log(result);
            this.setState({displaySuccess: true});
            console.log(result, this.state);
            setTimeout(() => {
                this.setState({displaySuccess: false});
            }, 3000);
        } catch(err) {

            const errMsg = err.message.indexOf('external_contact_team_id_fk') > 0 ? 'Team Id is not found in the system, please leave empty for now.': err.message;
            this.setState({error: errMsg});
            setTimeout(() => {
                this.setState({error: ''});
            }, 5000);
        }

    };


    render() {
        const {receivers, sports, externalContact} = this.props;
        const receiverOptions = _.map(_.orderBy(receivers.nodes, 'name'), r => ({ key:r.key, value: r.id, text: `${r.name} ( ${r.key} )`}));
        receiverOptions.splice(0, 0, { key: '-1', value: '-1', text: 'NONE'});

        const sportOptions = _.map(_.orderBy(sports, 'name'), r => ({ key:r.sportid, value: r.sportid, text: `${r.sportname}`}));
        sportOptions.splice(0, 0, { key: '-1', value: null, text: 'ALL'});
        return (
            <Form>
                {
                    this.state.displaySuccess ?
                        <Info icon='check' title='External Contact updated successfully!'
                              loading={false}/>

                        :''
                }
                {
                    this.state.error ?
                        <Info icon='warning' message={this.state.error} title='Error editing external contact'
                              loading={false}/>
                        : ''
                }
                <Form.Group widths={'equal'}>
                    <Form.Input label='Title *'
                                value={this.state.title}
                                onChange={(event) => this.setState({title: event.target.value})}
                                placeholder='Title'/>
                    <Form.Input label='Full Name *'
                                value={this.state.fullname}
                                onChange={(event) => this.setState({fullname: event.target.value})}
                                placeholder='Name Surname'/>
                    <Form.Input label='Phone *'
                                value={this.state.phone}
                                onChange={(event) => this.setState({phone: event.target.value})}
                                placeholder='Set phone mumber'/>
                </Form.Group>
                <Form.Group widths={'equal'}>
                    <Form.Select label='Sport' value={this.state.sportId} placeholder='ALL' options={sportOptions}
                                 onChange={(e, {value})=> this.setState({sportId: value})}/>
                    <SeasonPicker selectedSeason={this.state.seasonId} sportId={this.state.sportId} onSeasonSelected={ (seasonId) => this.setState({seasonId})}/>
                    <Form.Select label='Receiver' placeholder='Select a receiver' options={receiverOptions} value={this.state.receiver} onChange={(event,{value}) => this.setState({receiver: value})}/>
                </Form.Group>
                <Form.Group widths={'16'}>
                    <Form.Input label='Team Id (Read only)'
                                value={this.state.teamId}
                                readonly
                                type={'text'}
                                placeholder='Set team id'
                                width={"6"}
                    />
                    <Form.Field width={"10"}>
                        <label>Team Search</label>
                        <SearchTeams team={this.props.externalContact.teamByTeamId} onTeamSelected={(teamId) => this.setState({teamId})}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths={'equal'}>
                    <Form.Input label='Note'
                                value={this.state.note}
                                onChange={(event) => this.setState({note: event.target.value})}
                                placeholder='Add a note'/>
                </Form.Group>
                <Form.Button disabled={externalContact.fullname === this.state.fullname
                    && externalContact.note === this.state.note
                    && externalContact.phone === this.state.phone
                    && externalContact.title === this.state.title
                    && externalContact.teamId === this.state.teamId
                    && externalContact.seasonId === this.state.seasonId
                    && externalContact.receiver === this.state.receiver}
                             onClick={this.handleEditExternalContact} color='green'><Icon name='edit'/> Save Changes</Form.Button>
            </Form>
        );

    }
};

const EditExternalContact = ({externalContact, receivers, sports, refetchQuery}) => ( <Mutation mutation={EDIT_EXTERNAL_CONTACT_MUTATION}>
    {(editExternalContact) => (
        <EditExternalContactContainer editExternalContact={editExternalContact} sports={sports} receivers={receivers} externalContact={externalContact} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default EditExternalContact;
