import React, { useState} from 'react';
import gql from "graphql-tag";
import {
    Header,
    Button,
    Segment,
    Form
} from 'semantic-ui-react';
import {useMutation, useQuery} from "react-apollo";
import Info from '../Info';
import _ from "lodash";

const allAdminsQuery = gql`
    query AllAdminsQuery{
        admins:allAdmins{
            nodes {
                id
                fullname
                level
                adminRegionsByAdminId {
                    nodes {
                        adminId
                        regionId
                    }
                }
            }
        }
    }
`;

const addAdminToRegionMutation = gql`
    mutation AddAdminToRegionMutation($admin: Int!, $region: Int!){
        addAdminRegion(input: { adminIn: $admin, regionIn: $region} ) {
            boolean
        }
    }
`;

const AddAdminToRegion = ({region, refetchQuery}) => {
    const [addAdminRegion] = useMutation(addAdminToRegionMutation);
    const {loading, error, data} = useQuery(allAdminsQuery);
    const [selectedAdmin, setSelectedAdmin] = useState(null);

    if(loading) {
        return <Info icon="circle notched" message="Loading admins..." loading={true} />;
    }
    if (error) {
        return <Info icon="warning" message={error.message} title="Error loading admins" loading={false} />;
    }

    const {admins} = data;
    const adminOptions = _.map(_.filter(admins.nodes, (a) => a.level <= 3 && !_.find(a.adminRegionsByAdminId?.nodes, (r) => r.regionId === region )), (a) =>({ key: a.id, value: a.id, text: a.fullname}))

    const addAdmin = async() => {
        await addAdminRegion({
            variables: {
                admin: selectedAdmin,
                region,
            }, refetchQueries: [
                {
                    query: refetchQuery,
                    variables:{region}
                },{query: allAdminsQuery}
            ]
        });
    }
    return (
        <Segment raised >
            <Header as='h4' textAlign='center' color='blue'>
                Add admin to this region
            </Header>
            <Form>
                <Form.Group inline >
                    <Form.Select width={8} options={adminOptions} label={'Select Admin'} onChange={(event, data) => {setSelectedAdmin(data.value)}} value={selectedAdmin}/>
                    <Button onClick={addAdmin} color={"green"} label={'Add admin to region'} icon={'plus'} />
                </Form.Group>
            </Form>

        </Segment>
    );

};

export default AddAdminToRegion;
