import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Table, Header, Icon, Pagination } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Info from '../components/Info';
const PAGE_SIZE = 15;

const ReceiverListContainer = props => {
    const {
        data: { loading, error, allReceivers },
        match: {
            params: { page },
        },
        history,
    } = props;

    if (error) {
        return <Info icon="warning" message={error.message} title="Error" loading={false} />;
    }

    if (loading) {
        return <Info icon="circle notched" message="Loading..." loading={true} />;
    }

    const pageNo = page ? parseInt(page, 10) : 1;
    const navigate = url => {
        history.push(`/receivers/list/${url}`);
    };
    const pagination = (
        <Table.Row>
            <Table.HeaderCell colSpan="7" style={{ textAlign: 'center' }}>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={pageNo}
                    ellipsisItem={null}
                    lastItem={null}
                    siblingRange={1}
                    prevItem={undefined}
                    nextItem={undefined}
                    totalPages={
                        _.get(allReceivers, 'nodes.length', 0) < PAGE_SIZE
                            ? pageNo
                            : pageNo + 1
                    }
                    onPageChange={(e, data) => {
                        navigate(data.activePage)
                    }}
                />
            </Table.HeaderCell>
        </Table.Row>
    );

    return (
        <div style={{ padding: 10 }}>
            <Header as="h1" color="blue">
                ARTICLE RECEIVERS
            </Header>
            <Table celled selectable color="blue">
                <Table.Header>
                    {pagination}
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>
                            Key <Icon name="sort ascending" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>Display Name</Table.HeaderCell>
                        <Table.HeaderCell>Note</Table.HeaderCell>
                        <Table.HeaderCell>Teams</Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {allReceivers.nodes.map(node => {
                        return (
                            <Table.Row key={node.id}>
                                <Table.Cell>{node.id}</Table.Cell>
                                <Table.Cell>{node.name}</Table.Cell>
                                <Table.Cell>{node.key}</Table.Cell>
                                <Table.Cell>{node.displayName}</Table.Cell>
                                <Table.Cell>{node.note}</Table.Cell>
                                <Table.Cell>{node.teamReceiversByReceiverId.totalCount}</Table.Cell>
                                <Table.Cell>
                                    <Link to={`/receivers/${node.id}`}>See more</Link>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
                <Table.Footer>{pagination}</Table.Footer>
            </Table>
        </div>
    );
};

const receiverListQuery = gql`
    query ReceiverListQuery($limit: Int, $offset: Int, $region: Int) {
        allReceivers: filterReceivers(regionIn: $region, limitNo: $limit, offsetNo: $offset){
            nodes {
                id
                key
                name
                displayName
                note
                teamReceiversByReceiverId {
                    totalCount
                }
            }
        }
    }
`;

const ReceiverList = graphql(receiverListQuery, {
    options: ownProps => ({
        fetchPolicy: 'network-only',
        variables: {
            offset: (ownProps.match.params.page ? parseInt(ownProps.match.params.page, 10) - 1 : 0) * PAGE_SIZE,
            limit: PAGE_SIZE,
            region: parseInt(localStorage.getItem('selected-region')|| '1')
        },
    }),
})(ReceiverListContainer);
export default ReceiverList;
