import React from "react";

import {Container, Grid, Header, Segment} from "semantic-ui-react";
import WhiteListedContactStats from "../components/stats/WhiteListedContactStats";
import MonthlyArticleStats from "../components/stats/MonthlyArticleStats";
import MonthlyArticleChangeStats from "../components/stats/MonthlyArticleChangeStats";
import DailyArticleStats from "../components/stats/DailyArticleStats";


const KpiStats = (props) => {

    return (
        <Segment raised style={{textAlign: 'left'}}>
            <Header as='h1' textAlign='center' color='blue'>
                KPI STATS
            </Header>

            <Container fluid={true}>

                <Grid columns={16}>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <WhiteListedContactStats region={null}/>
                        </Grid.Column>
                        <Grid.Column width={8}>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid columns={16}>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header as={'h2'} color={'red'}>Monthly Article Stats For Last 12 Months</Header>
                            <MonthlyArticleStats />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header as={'h2'} color={'red'}>Monthly Moderation Stats For Last 12 Months</Header>
                            <MonthlyArticleChangeStats />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Grid columns={16}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <DailyArticleStats />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Container>
        </Segment>
    )

};
export default KpiStats;
