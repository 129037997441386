import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ContactListPage from './ContactListPage';
import EventList from './EventList';
import ArticleList from './ArticleList';
import MessageList from './MessageList';
import Article from '../components/article/Article';
import Event from '../components/Event';
import ContactPerson from '../components/contact-person/ContactPerson';
import ExternalContactList from "./ExternalContactList";
import ExternalContact from "./ExternalContact";
import ReceiverList from "./ReceiverList";
import Stats from "./Stats";
import Receiver from "../components/Receiver";
import UnidentifiedMessageList from "./UnidentifiedMessageList";
import Admin from "./Admin";
import NotificationCenter from "./NotificationCenter";
import ExternalMessages from "./ExternalMessages";
import StatsTabs from "./StatsTabs";
import Team from "./Team";
import TeamList from "./TeamList";
import FocusedTeamList from "./FocusedTeamList";
import Administration from "./Administration";
import CustomerStats from "./CustomerStats";
import KpiStats from "./KpiStats";
import Region from "../components/admin/Region";

const Main = (props) => (
    <main style={{paddingTop: 15}}>
        <Switch>
            <Route {...props} exact path='/' component={ArticleList}/>
            <Route {...props} exact path='/contacts/filter/:filter/:order/:page' component={ContactListPage}/>
            <Route {...props} path='/contacts/:contactPersonId' component={ContactPerson}/>
            <Route {...props} path='/contacts' component={ContactListPage}/>
            <Route {...props} path='/event/:eventId' component={Event}/>
            <Route {...props} path='/events/:filter/:dateFilter/:page' component={EventList}/>
            <Route {...props} path='/events/:filter/:dateFilter' component={EventList}/>
            <Route {...props} path='/events/:filter' component={EventList}/>
            <Route {...props} path='/events' component={EventList}/>
            <Route {...props} path='/article/:articleId' component={Article}/>
            <Route {...props} path='/articles/:status/:page' component={ArticleList}/>
            <Route {...props} path='/articles/:status/' component={ArticleList}/>
            <Route {...props} path='/articles' component={ArticleList}/>
            <Route {...props} path='/messages/:direction/:page' component={MessageList}/>
            <Route {...props} path='/messages/:direction' component={MessageList}/>
            <Route {...props} path='/messages' component={MessageList}/>
            <Route {...props} path='/receivers/list/:page?' component={ReceiverList}/>
            <Route {...props} path='/receivers/:receiverId' component={Receiver}/>
            <Route {...props} path='/external-contacts/:page' component={ExternalContactList}/>
            <Route {...props} path='/external-contacts' component={ExternalContactList}/>
            <Route {...props} path='/external-messages/:page' component={ExternalMessages}/>
            <Route {...props} path='/external-messages' component={ExternalMessages}/>
            <Route {...props} path='/external-contact/:externalContactId' component={ExternalContact}/>
            <Route {...props} path='/stats' component={Stats}/>
            <Route {...props} path='/trash-bin/list/:page?' component={UnidentifiedMessageList}/>
            <Route {...props} path='/admin/:adminId' component={Admin}/>
            <Route {...props} path='/notification-center' component={NotificationCenter}/>
            <Route {...props} path='/league-stats' component={StatsTabs}/>
            <Route {...props} path='/all-stats/:tab/:sort/:page' component={StatsTabs}/>
            <Route {...props} path='/all-stats/:tab/:page' component={StatsTabs}/>
            <Route {...props} path='/all-stats/:tab' component={StatsTabs}/>
            <Route {...props} path='/all-stats' component={StatsTabs}/>
            <Route {...props} path='/team/:teamId' component={Team}/>
            <Route {...props} path='/teams/:page' component={TeamList}/>
            <Route {...props} path='/teams' component={TeamList}/>
            <Route {...props} path='/focused-teams/:page' component={FocusedTeamList}/>
            <Route {...props} path='/focused-teams' component={FocusedTeamList}/>
            <Route {...props} path='/region/:regionId' component={Region}/>
            <Route {...props} path='/administration' component={Administration}/>
            <Route {...props} path='/customer-stats' component={CustomerStats}/>
            <Route {...props} path='/kpi' component={KpiStats}/>
        </Switch>
    </main>
);

export default Main;
