import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Statistic} from 'semantic-ui-react'
import Info from '.././Info';
import {Query} from "react-apollo";
import { Link } from 'react-router-dom';

const ContactDetailStatsContainer = (props) => {
    const {answereds, unanswereds, contact} = props;
    const { id, fullname, title, teamByTeamid } = contact;
    return (
        <Segment inverted raised={true} >
            <Header as={'h3'} color={"black"}><Link to={'/contacts/'+id}>{`${title} ${fullname}`}</Link> </Header>
            <Header as={'h4'} color={"black"}><Link to={'/team/'+teamByTeamid?.id}>{`${teamByTeamid?.name}`}</Link></Header>
            <Statistic.Group horizontal inverted color={'orange'} size={'small'}>
                <Statistic inverted>
                    <Statistic.Value>{answereds.totalCount}</Statistic.Value>
                    <Statistic.Label>Answers</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>{unanswereds.totalCount}</Statistic.Value>
                    <Statistic.Label>Unanswered</Statistic.Label>
                </Statistic>
            </Statistic.Group>

        </Segment>
    );



};

const contactDetailStatsQuery = gql`
    query ContactAnswers($contactId: Int!, $season: Boolean) {
        answereds: contactAnswers(contactId: $contactId, answered: true, season: $season) {
            totalCount
            nodes {
                id
                answer
                question
                articleByArticleid {
                    title
                }
            }
        }
        unanswereds: contactAnswers(contactId: $contactId, answered: false, season: $season) {
            totalCount
            nodes {
                id
                answer
                question
                articleByArticleid {
                    title
                }
            }
        }
    }
`;

const ContactDetailStats = ({ contact, forSeason }) => (<Query query={contactDetailStatsQuery} variables={{contactId: contact.id, season: forSeason}}>
    {({data, loading, error}) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if (data) {
            return <ContactDetailStatsContainer answereds={data.answereds} contact={contact}
                                                unanswereds={data.unanswereds}>

            </ContactDetailStatsContainer>
        }
        return <Info icon='warning'
                     message={(error && error.message) || 'Sorry, we could not find any contact answers'}
                     title='Error' loading={false}/>;
    }}
</Query>);

export default ContactDetailStats;
