import React from 'react';
import {graphql} from "react-apollo";
import gql from "graphql-tag";
import {
    Table,
    Header,
    Segment,
    Icon,
    Menu,
    Pagination,
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import _ from 'lodash'
import moment from 'moment'
import Info from '../components/Info';
import ArticleStatusIcon from '../components/ArticleStatusIcon';

const getArticles = (articleNodes) => (
    articleNodes.map((node) => {
        const hide = node.teamByFocusedteam && node.teamByFocusedteam.contactpeopleByTeamid && node.teamByFocusedteam.contactpeopleByTeamid.totalCount === 0;
        const style = hide ? { textDecoration: 'line-through', color: 'silver'} : {};
        return (<div key={node.id}><ArticleStatusIcon hide={hide} status={node.status}/><Link style={style} to={`/article/${node.id}`}>{node.title}</Link><br/></div>);
    })
);
const PAGE_SIZE = 20;

const EventListContainer = (props) => {
    const {
        data: {loading, error, filterEvents},
        match: { params: { page, filter, dateFilter }},
        history,
    } = props;

    const pageNo = page ? parseInt(page, 10) : 1;

    const filterMenu = () => {
        const today = moment().format('YYYY-MM-DD');
        const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
        const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
        const in2days = moment().add(2, 'day').format('YYYY-MM-DD');
        const in2daysName = moment().add(2, 'day').format('dddd');
        const in3days = moment().add(3, 'day').format('YYYY-MM-DD');
        const in3daysName = moment().add(3, 'day').format('dddd');
        return (
            <Menu stackable color='blue' secondary>
                <Menu.Item as={Link} to="/events" name='contact-person-assigned' active={!props.match.params.filter || (props.match.params.filter === 'contact' && !props.match.params.dateFilter)}>
                    <Icon name='adn'/> Events with a Contact Person
                </Menu.Item>
                <Menu.Item as={Link} to={'/events/contact/'+yesterday} name='contact-person-assigned' active={props.match.params.filter === 'contact' && props.match.params.dateFilter === yesterday}>
                    <Icon name='calendar alternate outline'/> Yesterday's Events
                </Menu.Item>
                <Menu.Item as={Link} to={'/events/contact/'+today} name='contact-person-assigned' active={props.match.params.filter === 'contact' && props.match.params.dateFilter === today}>
                    <Icon name='calendar alternate outline'/> Today's Events
                </Menu.Item>
                <Menu.Item as={Link} to={'/events/contact/'+tomorrow} name='contact-person-assigned' active={props.match.params.filter === 'contact' && props.match.params.dateFilter === tomorrow}>
                    <Icon name='calendar alternate outline'/> Tomorrow's Events
                </Menu.Item>
                <Menu.Item as={Link} to={'/events/contact/'+in2days} name='contact-person-assigned' active={props.match.params.filter === 'contact' && props.match.params.dateFilter === in2days}>
                    <Icon name='calendar alternate outline'/> {in2daysName}'s Events
                </Menu.Item>
                <Menu.Item as={Link} to={'/events/contact/'+in3days} name='contact-person-assigned' active={props.match.params.filter === 'contact' && props.match.params.dateFilter === in3days}>
                    <Icon name='calendar alternate outline'/> {in3daysName}'s Events
                </Menu.Item>
                <Menu.Item as={Link} to="/events/all" name='all' active={props.match.params.filter === 'all'}>
                    <Icon name='wait'/> All Events
                </Menu.Item>
            </Menu>
        );
    };

    const navigate = url => {
        history.push(`/events/${(filter || 'contact')}/${(dateFilter || 'all')}/${url}`)
    }
    const pagination = (
        <Table.Row>
            <Table.HeaderCell colSpan="9" style={{ textAlign: 'center' }}>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={pageNo}
                    ellipsisItem={null}
                    lastItem={null}
                    siblingRange={1}
                    prevItem={undefined}
                    nextItem={undefined}
                    totalPages={
                        _.get(filterEvents, 'nodes.length', 0) < PAGE_SIZE
                            ? pageNo
                            : pageNo + 1
                    }
                    onPageChange={(e, data) => {
                        navigate(data.activePage)
                    }}
                />
            </Table.HeaderCell>
        </Table.Row>
    )

    if (error) {
        return <Info icon="warning" message={error.message} title="Error" loading={false} />;
    }

    if (loading) {
        return <Info icon="circle notched" message="Loading..." loading={true} />;
    }

    return (
        <div style={{ padding: 10 }}>
            <Header as="h1" color='blue'>EVENTS</Header>
            {filterMenu()}
            <Segment basic ><b>Article Statuses:</b> <ArticleStatusIcon status='article-received' displayLabel={true}/> { ' ' }
                <ArticleStatusIcon status='comment-received' displayLabel={true}/>{ ' ' }
                <ArticleStatusIcon status='approved' displayLabel={true}/>{ ' ' }
                <ArticleStatusIcon status='disapproved' displayLabel={true}/>{ ' ' }
                <ArticleStatusIcon status='snoozed' displayLabel={true}/>{ ' ' }
                <ArticleStatusIcon status='blocked' displayLabel={true}/>
            </Segment>
            <Table celled selectable color='blue'>
                <Table.Header>
                    {pagination}
                    <Table.Row>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Home Team</Table.HeaderCell>
                        <Table.HeaderCell>Away Team</Table.HeaderCell>
                        <Table.HeaderCell>League</Table.HeaderCell>
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>Articles</Table.HeaderCell>
                        <Table.HeaderCell>Edit Event</Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filterEvents.nodes.map((node, index) => {
                        return (
                            <Table.Row key={node.id} warning={node.suspended}>
                                <Table.Cell>{1 + index + PAGE_SIZE * (pageNo ? pageNo - 1 : 0)}</Table.Cell>
                                <Table.Cell>{node.id}</Table.Cell>
                                <Table.Cell>{node.teamByHometeamid.name}</Table.Cell>
                                <Table.Cell>{node.teamByAwayteamid.name}</Table.Cell>
                                <Table.Cell>{node.leagueByLeagueid.name}</Table.Cell>
                                <Table.Cell>{moment(node.startdate).format('MMMM Do, h:mm a')}</Table.Cell>
                                <Table.Cell>{getArticles(node.articlesByEventid.nodes)}</Table.Cell>
                                <Table.Cell><a href={`http://inrapportering.everysport.se/Intra/Controller?service=se.Everysport.Intra.Services.ReportMatch&action=show&match_id=${node.id}&target=/index.jsp?category=report&content=report_match`} target="_blank" rel="noopener noreferrer">Edit</a></Table.Cell>
                                <Table.Cell><Link to={`/event/${node.id}`}>See more</Link></Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
                <Table.Footer>
                    {pagination}
                </Table.Footer>
            </Table>
        </div>
    );
};

const eventListQuery = gql`
    query EventsListQuery($limit: Int,$offset: Int, $filter: String, $dateFilter: String, $region: Int) {
        filterEvents: filterEventsDateByPage2(eventFilter: $filter, dateFilter:$dateFilter, limitNo: $limit, offsetNo: $offset, regionIn: $region) {
            nodes {
                id
                startdate
                suspended
                teamByHometeamid{
                    id
                    name
                }
                teamByAwayteamid{
                    id
                    name
                }
                leagueByLeagueid {
                    id
                    name
                }
                articlesByEventid {
                    nodes{
                        id
                        title
                        status
                        teamByFocusedteam {
                            id
                            contactpeopleByTeamid(condition: {active: true}) {
                                totalCount
                            }
                        }
                    }
                }
            }
        }
    }
`;

const EventList = graphql(eventListQuery,{
    options: (ownProps) => ({
        fetchPolicy: 'network-only',
        variables: {
            offset: (ownProps.match.params.page ? parseInt(ownProps.match.params.page, 10) - 1 : 0) * PAGE_SIZE,
            limit:  PAGE_SIZE,
            filter: ownProps.match.params.filter || 'contact',
            region: parseInt(localStorage.getItem('selected-region')|| '1'),
            dateFilter: ownProps.match.params.dateFilter === 'all' ? null : ownProps.match.params.dateFilter}, pollInterval: 480000
    }),
})(EventListContainer);
export default EventList;
