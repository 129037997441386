import React, {Component} from 'react'
import {Header, Comment, Icon, Segment, Form, Button, Message, Checkbox, Popup, Label, Dropdown, Grid} from 'semantic-ui-react';
import Messages from "../messages/Messages";
import {
    ARTICLE_STATUS_AUTO_APPROVE,
    ARTICLE_STATUS_APPROVED,
    ARTICLE_STATUS_COMMENT_RECEIVED,
    ARTICLE_STATUS_IGNORED,
    ARTICLE_STATUS_BLOCKED,
    ARTICLE_STATUS_DISAPPROVED,
    ARTICLE_STATUS_ADMIN_DONE,
    ARTICLE_STATUS_SNOOZED
} from "../../utils/QnaConstants";
const XRegExp = require('xregexp');
const moment = require('moment');
const _ = require('lodash');

const findStr = ['iår','div1','60min','m6','iaf','mv','e','MB','60minuter','medans','bla','ej','div','&','1:a','1a','2:a','2a','3p','4:a','90min','bi','bytta','ca','dom','domarn','Dvs','endå','ett-ett','ett-fem','ett-fyra','ett-nio','ett-noll','ett-sex','ett-sju','ett-tre','ett-två','ett-åtta','fem-ett','fem-fem','fem-fyra','fem-noll','fem-tre','fem-två','focus','fyra-ett','fyra-fem','fyra-fyra','fyra-noll','fyra-tre','fyra-två','ggr','insatts','kämpar match','kämparinsats','kämparmatch','me','minutrarna','mkt','nio-noll','noll-1','noll-fem','noll-fyra','noll-nio','noll-noll','noll-sex','noll-sju','noll-tre','noll-två','noll-åtta','nåt','nått','o','ok','osv','oxå','pga','PP','prefekt','sek','sex-ett','sex-noll','sex-tre','sex-två','sju-noll','spm','st','sån','tillbaks','tre-ett','tre-fem','tre-fyra','tre-noll','tre-tre','tre-två','trotts','två-ett','två-fem','två-fyra','två-noll','två-sex','två-tre','två-två','va','varann','vart','våran','vårat','X','åtta-noll','tre:är']

const replaceStr = [
    'i år',
    'division ett',
    '60 minuter',
    'mittsexa',
    'i alla fall',
    'målvakt',
    'är',
    'mittback',
    '60 minuter',
    'medan',
    'bland annat',
    'inte',
    'division',
    'och',
    'första',
    'första',
    'andra',
    'andra',
    'tre poäng',
    'fyra',
    '90 minuter',
    'vi',
    'byta',
    'cirka',
    'de',
    'domaren',
    'Det vill säga',
    'ändå',
    '1-1',
    '1-5',
    '1-4',
    '1-9',
    '1-0',
    '1-6',
    '1-7',
    '1-3',
    '1-2',
    '1-8',
    '5-1',
    '5-5',
    '5-4',
    '5-0',
    '5-3',
    '5-2',
    'fokus',
    '4-1',
    '4-5',
    '4-4',
    '4-0',
    '4-3',
    '4-2',
    'gånger',
    'insats',
    'kämpamatch',
    'kämpainsats',
    'kämpamatch',
    'med',
    'minuterna',
    'mycket',
    '9-0',
    '0-1',
    '0-5',
    '0-4',
    '0-9',
    '0-0',
    '0-6',
    '0-7',
    '0-3',
    '0-2',
    '0-8',
    'något',
    'något',
    'och',
    'okej',
    'och så vidare',
    'också',
    'på grund av',
    'power play',
    'perfekt',
    'sekunder',
    '6-1',
    '6-0',
    '6-3',
    '6-2',
    '7-0',
    'som',
    'stycken',
    'sådan',
    'tillbaka',
    '3-1',
    '3-5',
    '3-4',
    '3-0',
    '3-3',
    '3-2',
    'trots',
    '2-1',
    '2-5',
    '2-4',
    '2-0',
    '2-6',
    '2-3',
    '2-2',
    'var',
    'varandra',
    'var',
    'vår',
    'vårt',
    'kryss',
    '8-0',
    'tredje'];

const replaceAll = (str) => {
    const re = new XRegExp(_.map(findStr, s => '(?:^|\\s)'+ s + '[ _.,!"\'/$]{1,1}').join("|"),"g");
    //console.log('reg exp', re, findStr.length, replaceStr.length);
    return str.replace(re, function(matched){
        const prefix = matched.slice(0,1);
        const postfix = matched.slice(-1);
        const actualMatched = matched.slice(1,matched.length -1);
        const index = findStr.indexOf(actualMatched);
       // console.log(index, matched);
        return prefix + replaceStr[index] + postfix;
    });
}

class Question extends Component {

    constructor(props) {
        super(props);
        const {node: {alternativeContact, answeredBy, messagesByQuestionid}, contactPersonList} = this.props;
        const firstContact = contactPersonList && contactPersonList.length > 0 ? -(contactPersonList[0].id) : null;

        const messagedContact = _.find(messagesByQuestionid.nodes, (node) => {
            if(node.type === 'received') {
                return _.find(contactPersonList, (c) => {
                    return c.id === _.get(node, 'contactpersonByContactpersonid.id');
                })
            }
            return false;
        });
        //
        // console.log('messagedContact', messagedContact)
        // console.log('answeredBy', answeredBy)
        // console.log('alternativeContact ? alternativeContact : (answeredBy ? -answeredBy : (messagedContact ? -(messagedContact.id) : firstContact))',alternativeContact ? alternativeContact : (answeredBy ? -answeredBy : (messagedContact ? '-'+(messagedContact.id) : firstContact)))

        this.state = {
            answer: this.props.node.answer || '',
            approveSent : false,
            ignoreSent : false,
            snoozeSent: false,
            adminCompleteSent: false,
            sendModerationSent: false,
            disapproveSent : false,
            approveFailed : false,
            blockSent: false,
            blockFailed: false,
            showBlockButton: false,
            alertNewspaper : this.props.node.important,
            reply: '',
            moreFeedback: '',
            moreFeedbackSentFailed: false,
            feedback: '',
            editStartTime: null,
            extraInfo: this.props.node.extraInfo || '',
            selectedContactForApproval: alternativeContact ? alternativeContact : (answeredBy ? -answeredBy : (messagedContact ? -messagedContact.contactpersonByContactpersonid.id : firstContact)),
            selectedContactForMessaging: contactPersonList && contactPersonList.length > 0 && contactPersonList[0].id
        };
        this.handleAppendMessages = this.handleAppendMessages.bind(this);
        this.handleReply = this.handleReply.bind(this);
        this.logTimeSpentEditing = this.logTimeSpentEditing.bind(this);
    }

    logTimeSpentEditing() {
        const elapsedTime = Math.floor((new Date() - this.state.editStartTime)/1000);

        if(elapsedTime > 15) {
             this.props.logTimeFunction({
                variables: {
                    input: {
                        articleId: this.props.articleId,
                        eventType: 'EDITING',
                        duration: elapsedTime
                    }
                }});
        }
    }

    handleAppendMessages = (event) => {
        const region = parseInt(localStorage.getItem('selected-region')|| '1');
        let allMessages = _.map(_.remove(_.sortBy(this.props.node.messagesByQuestionid.nodes, ['timestamp']), ['type', 'received']), 'text').join(' ')
        allMessages = allMessages.replace(/^(hejsan|hej|tack|hallå)[^a-zA-Z]*/gi,'')
        allMessages = allMessages.replace(/\s*(tackar)(\W)*$/gi,'')
        let mvhindex= allMessages.search(/( mvh | Med vänlig hälsning | Häsningar )/gi);
        mvhindex = mvhindex > 0 ? mvhindex : allMessages.search(' /');
        allMessages = mvhindex > 0 ? allMessages.slice(0, mvhindex).trim() : allMessages;
        if(region === 1) {
            allMessages = replaceAll(allMessages);
        }
        if(this.props.homeTeam){
            allMessages = allMessages.replace(new XRegExp(this.props.homeTeam,'gi'), this.props.homeTeam);
        }
        if(this.props.awayTeam){
            allMessages = allMessages.replace(new XRegExp(this.props.awayTeam,'gi'), this.props.awayTeam);
        }
        allMessages = allMessages.replace(/\s\s+/g, ' ');
        allMessages = allMessages.replace(/\s*(,)/g, ',');
        allMessages = allMessages.replace(/\s*(\.)/g, '.');
        this.setState({answer: allMessages, editStartTime: this.state.editStartTime || new Date()});
    };

    approveAnswer = (event) => {
        if(this.state.answer && (this.state.answer !== this.props.node.answer || this.state.extraInfo !== this.props.node.extraInfo)){
            this.logTimeSpentEditing();
            const answerText = this.state.answer.trim().replace(/[ ]{2,}/g, ' ');

            let alternativeContactId = null;
            let answeredContact = null;
            if(this.state.selectedContactForApproval > 0) {
                alternativeContactId = this.state.selectedContactForApproval
            }
            if(this.state.selectedContactForApproval < 0) {
                answeredContact = -this.state.selectedContactForApproval;
            }

            this.props.approveAnswerFunction({
                variables: {
                    input: {
                        approvedAnswer: answerText,
                        questionid: this.props.node.id,
                        sendFeedback: this.props.node.approvedat ? false : true,
                        alert: this.state.alertNewspaper,
                        alternativeContactId,
                        answeredContact,
                        approvedExtraInfo: this.state.extraInfo,
                    }
                }}).then(() => {
                this.setState({approveSent: true, answer: answerText, showBlockButton: false});
                setTimeout(() => {
                    this.setState({approveSent: false});
                }, 3000);
            }).catch((err) => console.error(err)
            );
        } else {
            this.setState({approveFailed: true});
            setTimeout(() => {
                this.setState({approveFailed: false});
            }, 3000);
        }
    };

    disapproveAnswer = (event) => {

        if(this.state.answer || this.props.articleStatus === ARTICLE_STATUS_BLOCKED){
            this.logTimeSpentEditing();
            this.props.disapproveAnswerFunction({
                variables: {
                    input: {
                        questionId: this.props.node.id
                    }
                }}).then(() => {
                this.setState({disapproveSent: true, answer: '', selectedContactForApproval : null});
                setTimeout(() => {
                    this.setState({disapproveSent: false});
                    }, 3000);
            }).catch((err) => console.error(err)
            );
        }
    };

    ignoreAnswer = (event) => {

        if(this.props.articleStatus !== ARTICLE_STATUS_APPROVED){
            this.logTimeSpentEditing();
            this.props.ignoreAnswerFunction({
                variables: {
                    input: {
                        questionId: this.props.node.id
                    }
                }}).then(() => {
                this.setState({ignoreSent: true, answer: '', selectedContactForApproval : null});
                setTimeout(() => {
                    this.setState({ignoreSent: false});
                }, 3000);
            }).catch((err) => console.error(err)
            );
        }
    };

    snoozeModeration = (event) => {

        console.log('snoozed')

        if(this.props.articleStatus === ARTICLE_STATUS_COMMENT_RECEIVED || this.props.articleStatus === ARTICLE_STATUS_DISAPPROVED){
            this.logTimeSpentEditing();
            console.log('snoozed calling')
            this.props.snoozeModerationFunction({
                variables: {
                    input: {
                        questionId: this.props.node.id
                    }
                }}).then(() => {
                console.log('snoozed called')
                this.setState({snoozeSent: true, answer: '', selectedContactForApproval : null});
                setTimeout(() => {
                    this.setState({snoozeSent: false});
                }, 3000);
            }).catch((err) => console.error(err)
            );
        }
    };

    adminstrationDone = (event) => {
        console.log('administration complete')
        if(this.props.articleStatus === ARTICLE_STATUS_SNOOZED){
            this.logTimeSpentEditing();
            console.log('snoozed calling')
            this.props.adminstrationComplete({
                variables: {
                    input: {
                        questionId: this.props.node.id
                    }
                }}).then(() => {
                console.log('administration complete called')
                this.setState({adminCompleteSent: true, answer: '', selectedContactForApproval : null});
                setTimeout(() => {
                    this.setState({adminCompleteSent: false});
                }, 3000);
            }).catch((err) => console.error(err)
            );
        }
    };

    sendModeration = (event) => {
        console.log('send moderation')
        if(this.props.articleStatus === ARTICLE_STATUS_ADMIN_DONE || this.props.articleStatus === ARTICLE_STATUS_SNOOZED){
            this.logTimeSpentEditing();
            console.log('send moderation calling')
            this.props.sendModeration({
                variables: {
                    input: {
                        questionId: this.props.node.id
                    }
                }}).then(() => {
                console.log('send moderation called')
                this.setState({sendModerationSent: true, answer: '', selectedContactForApproval : null});
                setTimeout(() => {
                    this.setState({sendModerationSent: false});
                }, 3000);
            }).catch((err) => console.error(err)
            );
        }
    };

    blockAnswer = (event) => {
        this.logTimeSpentEditing();
        this.props.blockAnswerFunction({
            variables: {
                input: {
                    questionId: this.props.node.id,
                    reason: this.state.feedback
                }
            }}).then(() => {
            this.setState({showBlockButton: false, blockSent: true, answer: ''});
            setTimeout(() => {
                this.setState({blockSent: false});
            }, 3000);
        }).catch((err) => {
                console.error(err)
                this.setState({blockFailed: true});
                setTimeout(() => {
                    this.setState({blockFailed: false});
                }, 3000);
            }
        );
    };

    handleAnswerChange = (event) => {
        this.setState({answer: event.target.value, editStartTime: this.state.editStartTime || new Date()});
    };

    handleExtraInfoChange = (event) => {
        this.setState({extraInfo: event.target.value});
    };

    handleReplyChange = (event) => {
        this.setState({reply: event.target.value});
    };

    handleFeedbackChange = (event) => {
        this.setState({feedback: event.target.value});
    };

    handleMoreFeedbackChange = (event) => {
        this.setState({moreFeedback: event.target.value});
    };

    addNote = (event) => {
        if(this.state.moreFeedback.length > 0) {
            this.props.addNoteFunction({
                variables: {
                    input: {
                        questionId: this.props.node.id,
                        feedback: this.state.moreFeedback
                    }
                }}).then(() => {
                this.setState({ moreFeedback: ''});
            }).catch((err) => {
                    console.error(err)
                    this.setState({moreFeedbackSentFailed: true});
                    setTimeout(() => {
                        this.setState({moreFeedbackSentFailed: false});
                    }, 3000);
                }
            );
        }
    };

    removeNote = (noteId) => {
        this.props.removeNoteFunction({
            variables: {
                input: {
                    noteId: noteId
                }
            }}).then(console.log('success'))
        .catch((err) => {
                console.error(err);
            }
        );
    };

    handleReply = (event) => {
        if(this.state.reply){
            this.props.sendSmsFunction({
                variables: {
                    input: {
                        contactpersonid: this.state.selectedContactForMessaging,
                        text: this.state.reply,
                        questionid: this.props.node.id,
                        about: ''
                    }
                }}).then(() => {
                this.setState({reply: ''});
            }).catch((err) => console.error(err)
            );
        }

    };

    toggle = () => this.setState({ alertNewspaper: !this.state.alertNewspaper });

    toggleShowBlock = () => this.setState({ showBlockButton: !this.state.showBlockButton });

    renderApproved = () => {
        if(this.state.approveSent) {
            return (<Message
                success
                header='Answer approved successfully!'
                content='You may now see approved answer in the article.'
            />);
        }
        return null;
    };

    renderApproveFailed = () => {
        if(this.state.approveFailed) {
            return (<Message
                warning
                header='Answer could not be approved!'
                content='Please check that answer is not empty and do not try to approve same answer again.'
            />);
        }
        return null;
    };

    renderDisapproved = () => {
        if(this.state.disapproveSent) {
            return (<Message
                success
                header='Answer disapproved!'
                content='You may now approve new answer.'
            />);
        }
        return null;
    };

    renderIgnored = () => {
        if(this.state.ignoreSent) {
            return (<Message
                success
                header='Answer ignored!'
                content='This article will not include any answer from contacts.'
            />);
        }
        return null;
    };

    renderSnoozed = () => {
        if(this.state.snoozeSent) {
            return (<Message
                success
                header='Article is sent to administration!'
                content='This article will remain in administration until it is marked as done.'
            />);
        }
        return null;
    };

    renderAdminComplete = () => {
        if(this.state.adminCompleteSent) {
            return (<Message
                success
                header='Article is marked as Administration Done!'
                content='You can see this article under Administration Done in Articles Page'
            />);
        }
        return null;
    };

    renderModerationSent = () => {
        if(this.state.sendModerationSent) {
            return (<Message
                success
                header='Article is sent back to moderation!'
                content='You can see this article under pending articles'
            />);
        }
        return null;
    };

    alternativeContacts = (contactPerson) => {
        if(contactPerson && contactPerson.alternativeContactsByContactpersonId && contactPerson.alternativeContactsByContactpersonId.nodes ) {
            const alternatives = _.map(contactPerson.alternativeContactsByContactpersonId.nodes, (alternativeContact) => (
                {   key : alternativeContact.id,
                    value: alternativeContact.id,
                    text: alternativeContact.title + ' '+ alternativeContact.fullname + (!alternativeContact.active ? ' (inactive)': ''),
                    disabled: !alternativeContact.active
                }
                ));
            alternatives.unshift({key :contactPerson.id, value: -1, text: contactPerson.title + ' '+ contactPerson.fullname});
            return alternatives;
        }
        return [];
    };

    allContacts = (contactPersonList) => {
        let allContacts = [];

        _.forEach(contactPersonList, (contactPerson) => {
            if(contactPerson && contactPerson.alternativeContactsByContactpersonId && contactPerson.alternativeContactsByContactpersonId.nodes ) {
                const alternatives = _.map(contactPerson.alternativeContactsByContactpersonId.nodes, (alternativeContact) => (
                    {   key : alternativeContact.id,
                        value: alternativeContact.id,
                        text: alternativeContact.title + ' '+ alternativeContact.fullname + ' (alternative of: '+ contactPerson.fullname +') ' + (!alternativeContact.active ? ' (inactive)': ''),
                        disabled: !alternativeContact.active
                    }
                ));
                if(alternatives && alternatives.length > 0 ) {
                    allContacts = allContacts.concat(alternatives);
                }
            }
        });

        _.forEach(contactPersonList, (contactPerson) => {
            allContacts.unshift({key : contactPerson.id, value: -contactPerson.id, text: contactPerson.title + ' '+ contactPerson.fullname + ' (main)'})
        });

        // console.log('allContacts', allContacts)

        return allContacts;
    };

    contactsWithMultipleTeams = (contactPersonList) => {
        if(_.find(contactPersonList, (c) => c.phoneCount > 1)) {
            return <div style={{marginTop: 10}}><Label as='a' color={'red'}>Multiple teams are associated with same phone number. Please check the answer if it is for this article or not.</Label></div>;
        }
        return '';
    };

    mainContacts = (contactPersonList) => {
        let mainContacts = [];
        _.forEach(contactPersonList, (contactPerson) => {
            mainContacts.push({key : contactPerson.id, value: contactPerson.id, text: contactPerson.title + ' '+ contactPerson.fullname})
        });

        // console.log('main', mainContacts)

        return mainContacts;
    };

    render() {
        const {node, contactPersonList, admins, receivers} = this.props;
        const importantStyle = { color: 'black', fontWeight: 'bolder'};

        const extraInfoReceiver = _.find(receivers, (receiver) => {
            return receiver.receiverByReceiverId.key.search(/stampen-qna-at|stampen-at/gi) >= 0;
        });

        let extraInfolabel = '';
        let displayExtraInfo = { display: 'none'};
        if(extraInfoReceiver && extraInfoReceiver.receiverByReceiverId) {
            extraInfolabel = 'Player Stats: ';
            displayExtraInfo =  { };
        }

        const notes = _.map(_.sortBy(_.filter(node.notesByQuestionId.nodes, 'visible'), ['datetime']), (note) => {
            return (
                <Comment as="div" key={note.id}>
                    <Comment.Content>
                        <Comment.Author
                            as="i">{note.admin}</Comment.Author>
                        <Comment.Metadata>
                            <div>
                                <Popup
                                    trigger={ <span>{moment(note.datetime).fromNow()} </span>}
                                    content={moment(note.datetime).format('YYYY-MM-DD HH:mm')}
                                    size='large'
                                />
                            </div>
                        </Comment.Metadata>
                        <Comment.Text>
                            <p style={importantStyle}>{note.feedback} <Button size={'mini'} circular icon='remove' onClick={() => this.removeNote(note.id)} /></p>
                        </Comment.Text>
                    </Comment.Content>
                </Comment>);
        });

        return (
            <Segment color='yellow' inverted>
                <Segment>
                    <div style={{marginBottom: 10}}>
                        { node.changedat > node.approvedat ?
                            <Popup
                            trigger={<Icon color='red' name='warning circle' />}
                            content="Question might have changed! Please check related messages for the question asked to the user and current question in system below. Approve if the answer is ok for the current question in system!"
                            basic
                            /> :
                            <span/>}
                        <b>Question</b></div>
                    <p>{node.question}</p>
                </Segment>
                <Segment>
                    <Header as='h5' dividing><Icon name='sticky note outline'/> Admin Notes</Header>
                    <Comment.Group>
                        {notes}
                    </Comment.Group>
                    {
                        this.state.moreFeedbackSentFailed ?
                            <Message
                                warning
                                header='Feedback could not be added!'
                                content='Please check your internet connection.'
                            />
                            :
                            ''
                    }

                    <Form widths='equal'>
                        <Form.TextArea rows="3"  onChange={this.handleMoreFeedbackChange} value={this.state.moreFeedback} placeholder="This will NOT be send to contact person, it is just for other admins to see."/>
                        <Button content='Add feedback' labelPosition='left' icon='edit' primary onClick={this.addNote} />
                    </Form>
                </Segment>
                <Segment>
                    {this.renderApproved()}
                    {this.renderDisapproved()}
                    {this.renderApproveFailed()}
                    {this.renderIgnored()}
                    {this.renderSnoozed()}
                    {this.renderAdminComplete()}
                    {this.renderModerationSent()}
                    <Form>
                        <Form.TextArea value={this.state.answer}
                                       label="Answer"
                                       placeholder="You can use 'Append Received Messages' button or edit this text area to submit answer..."
                                       style={{ minHeight: 150 }}
                                       onChange={this.handleAnswerChange}
                        />
                        <Form.TextArea value={this.state.extraInfo}
                                       style={displayExtraInfo}
                                       label={extraInfolabel}
                                       placeholder="Player stats for Alingsås Tidning"
                                       onChange={this.handleExtraInfoChange}
                        />
                        <Form.Group>
                            {/*<Checkbox fluid toggle label='Alert newspaper' onChange={this.toggle} checked={this.state.alertNewspaper} />*/}
                            {/*<span>Select alternative contact:</span>*/}
                            {/*<Select fluid placeholder='Select alternative contact' options={[{ key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' }]} />*/}
                            <Grid verticalAlign='middle' textAlign={"left"} container relaxed stretched columns={2}>
                                <Grid.Column stretched={false} textAlign={"right"} width={8}>
                                    <Label as={'div'}>Answered by:</Label>
                                    {/*<Checkbox fluid='true' toggle label='Alert newspaper' onChange={this.toggle} checked={this.state.alertNewspaper} />*/}
                                </Grid.Column>
                                <Grid.Column stretched width={8}>
                                    <Dropdown
                                        search
                                        fluid={true}
                                        selection value={this.state.selectedContactForApproval} onChange={(event,data) => this.setState({selectedContactForApproval: data.value})} options={this.allContacts(contactPersonList)} />
                                </Grid.Column>
                            </Grid>
                        </Form.Group>

                        <Form.Group inline widths='equal'>
                            {(this.props.articleStatus !== ARTICLE_STATUS_SNOOZED && this.props.articleStatus !== ARTICLE_STATUS_ADMIN_DONE) ? <Form.Button style={{width: '100%'}} color='yellow' onClick={this.handleAppendMessages}><Icon name="arrow up"/>Append Messages</Form.Button> : <div/>}
                            {(this.props.articleStatus !== ARTICLE_STATUS_SNOOZED && this.props.articleStatus !== ARTICLE_STATUS_ADMIN_DONE) ? <Form.Button style={{width: '100%'}} color="green" onClick={this.approveAnswer}>{ this.props.node.approvedat ? 'Change Answer' : 'Approve'}</Form.Button> : <div/>}
                            { (this.props.articleStatus === ARTICLE_STATUS_BLOCKED || this.props.articleStatus === ARTICLE_STATUS_APPROVED) ?  <Form.Button style={{width: '100%'}}  color="red" onClick={this.disapproveAnswer}>Disapprove</Form.Button> : <div/> }
                            {this.props.articleStatus !== ARTICLE_STATUS_APPROVED &&  this.props.articleStatus !== ARTICLE_STATUS_IGNORED  ? <Form.Button style={{width: '100%'}}  color="purple" onClick={this.ignoreAnswer}>Ignore</Form.Button> : <div/>}
                            {(this.props.articleStatus === ARTICLE_STATUS_COMMENT_RECEIVED ||  this.props.articleStatus === ARTICLE_STATUS_DISAPPROVED) ? <Form.Button style={{width: '100%'}}  color="brown" onClick={this.snoozeModeration}>Send to Administration</Form.Button> : <div/>}
                            {(this.props.articleStatus === ARTICLE_STATUS_SNOOZED || this.props.articleStatus === ARTICLE_STATUS_ADMIN_DONE) ? <Form.Button style={{width: '100%'}}  color="yellow" onClick={this.sendModeration}>Send to Moderation</Form.Button> : <div/>}
                            {(this.props.articleStatus === ARTICLE_STATUS_SNOOZED) ? <Form.Button style={{width: '100%'}}  color="green" onClick={this.adminstrationDone}>Administration Done</Form.Button> : <div/>}
                        </Form.Group>
                        { node.adminByModeratedby ?
                            <Form.Group inline>
                                <Label><Icon size={'small'} circular inverted color={"red"} name='info'/> Last moderated by {node.adminByModeratedby.fullname} at {moment(node.changedat).format('YYYY-MM-DD HH:mm')}</Label>
                            </Form.Group> :
                            ''}
                        { !this.props.node.approvedat && this.props.articleStatus !== 'blocked'  ?  <Checkbox toggle label='This is breaking our rules' onChange={this.toggleShowBlock} checked={this.state.showBlockButton} /> : <div/> }
                        { this.state.showBlockButton ?
                            <Form.Group inline widths='equal' style={{marginTop: 15}}>
                                <Form.TextArea placeholder="Please write why it is blocking our rules. This will NOT be send to contact person, it is just for other admins to see." rows="4"  onChange={this.handleFeedbackChange} value={this.state.feedback} />
                                <Form.Button  color="red" onClick={this.blockAnswer}>{ 'Block Answer'}</Form.Button>
                            </Form.Group> : ''}

                        {this.contactsWithMultipleTeams(contactPersonList)}

                    </Form>
                    { this.props.articleStatus === ARTICLE_STATUS_AUTO_APPROVE ? <Message
                        success
                        header='Moderation is not needed for this article. Comment will be auto-approved soon!'
                    /> : '' }

                </Segment>
                <Segment>
                    <Comment.Group>
                        <Header as='h4' dividing><Icon name='comments'/> Related messages</Header>

                        <Messages admins={admins} messages={_.sortBy(node.messagesByQuestionid.nodes, ['timestamp'])}
                                          contactPersonList={contactPersonList}/>
                        <Form reply>
                            <Form.TextArea onChange={this.handleReplyChange} value={this.state.reply} />
                            <Grid container stretched width='16'>
                                <Grid.Column width='8'>
                                <Dropdown
                                    search
                                    fluid={true}
                                    selection value={this.state.selectedContactForMessaging} onChange={(event,data) => this.setState({selectedContactForMessaging: data.value})} options={this.mainContacts(contactPersonList)} />
                                </Grid.Column>
                                <Grid.Column width='8'>
                                <Button  content='Reply or Ask Related Question' labelPosition='left' icon='edit' primary onClick={this.handleReply} />
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </Comment.Group>
                </Segment>
            </Segment>
        );
    }
}

export default Question;
