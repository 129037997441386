import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Icon } from "semantic-ui-react";

export default function CopyTextToClipboard(props) {
    const { text, styles = {} } = props;

    const [copied, setCopied] = useState(false);
    useEffect(() => {
        let timeout;
        if (copied) {
            timeout = setTimeout(() => {
                setCopied(false);
            }, 2000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [copied]);
    return (
        <div style={styles}>
            <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
                <Button toggle active={copied}>
                    <Icon name="clipboard" />
                    {!copied ? "Copy" : "Copied!"}
                </Button>
            </CopyToClipboard>
        </div>
    );
}
