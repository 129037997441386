import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Statistic} from 'semantic-ui-react'
import Info from '.././Info';
import {Query} from "react-apollo";

const FocusedTeamContactStatsContainer = (props) => {
    const {focusedTeamCount, focusedTeamWithContactCount} = props;
    return (
        <Segment inverted raised={true} >
            <Header as={'h3'} color={"black"}>Focused Team Stats</Header>
            <Statistic.Group horizontal inverted color={'orange'} size={'small'}>
                <Statistic inverted>
                    <Statistic.Value>{((focusedTeamWithContactCount || 0) / (focusedTeamCount || 100) * 100).toFixed(0) + '%'}</Statistic.Value>
                    <Statistic.Label>Percentage</Statistic.Label>
                </Statistic>
                <Statistic inverted>
                    <Statistic.Value>{focusedTeamWithContactCount}</Statistic.Value>
                    <Statistic.Label>Focused Teams With Contact</Statistic.Label>
                </Statistic>
                <Statistic inverted>
                    <Statistic.Value>{focusedTeamCount}</Statistic.Value>
                    <Statistic.Label>Total Focused Teams</Statistic.Label>
                </Statistic>
            </Statistic.Group>

        </Segment>
    );
};

const QUERY_FOCUSED_TEAMS_COUNT = gql`
    query focusedTeamStats ($sport: Int, $fromDate: String, $toDate: String){
        focusedTeamCount:focusedTeamCount(sportIdIn: $sport)
        focusedTeamWithContactCount:focusedTeamWithContactCount(sportIdIn: $sport, fromDate: $fromDate, toDate: $toDate)
    }
`;

const FocusedTeamContactStats = ({sport, fromDate, toDate}) => (<Query query={QUERY_FOCUSED_TEAMS_COUNT} variables={{sport: sport || null, fromDate, toDate}}>
    {({data, loading, error}) => {

        console.log('sport', sport)
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if (data) {
            return <FocusedTeamContactStatsContainer focusedTeamCount={data.focusedTeamCount} focusedTeamWithContactCount={data.focusedTeamWithContactCount} />
        }
        return <Info icon='warning'
            message={(error && error.message) || 'Sorry, we could not fetch focused team stats '}
            title='Error' loading={false}/>;
    }}
</Query>);

export default FocusedTeamContactStats;
