import React, { useState, useEffect, useRef } from 'react';
import { Button, Icon, Form, Message, Modal, Header, Segment } from 'semantic-ui-react';

import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import CopySignature, { parseSignature } from '../CopySignature';
import Info from '../Info';

function SendNewBulkMessageComponent(props) {
    const { contactPersons = [], allSignatures, sendSms, removeContact, refetchQueries } = props;

    const timeout = useRef(null);
    const [state, setState] = useState({
        message: '',
        topic: '',
        displaySuccess: '',
        error: '',
        selectedContactId: null,
    });
    useEffect(() => {
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);
    if (!contactPersons.length) {
        return null;
    }

    const handleTopicChange = (e, { value }) => {
        setState({ ...state, topic: value });
    };

    const handleMessageChange = event => {
        setState({ ...state, message: event.target.value });
    };

    const handleSendSms = async () => {
        const { contactPersons } = props;
        if (state.displaySuccess) {
            return;
        }

        try {
            await Promise.all(
                contactPersons.map(contactPerson => {
                    return sendSms({
                        variables: {
                            input: {
                                contactpersonid: contactPerson.id,
                                text: parseSignature({
                                    contact: contactPerson,
                                    message: state.message,
                                }),
                                about: state.topic,
                                questionid: -1,
                            },
                        },
                        refetchQueries: refetchQueries || [],
                    });
                }),
            );

            setState({ ...state, displaySuccess: true, message: '' });
            timeout.current = setTimeout(() => {
                setState({ ...state, displaySuccess: false });
                props.onSuccess();
            }, 3000);
        } catch (err) {
            setState({ ...state, error: err.toString() });
            timeout.current = setTimeout(() => {
                props.onFail();
                setState({ ...state, error: '' });
            }, 5000);
        }
    };

    const messagesWithWarnings = contactPersons
        .filter(contact => {
            if (!state.message) {
                return false;
            }
            const m = parseSignature({
                contact,
                message: state.message,
            });
            return m.includes('WARNING');
        })
        .map(c => c.id);

    const hasWarning = messagesWithWarnings.length > 0;
    const warningStyle = (() => {
        if (!state.message) {
            return { display: 'none' };
        }

        return hasWarning ? { display: 'block' } : { display: 'none' };
    })();

    const selectedContact = contactPersons.find(c => c.id === state.selectedContactId);

    return (
        <div>
            {selectedContact && (
                <Modal
                    onClose={() => {
                        setState({
                            ...state,
                            selectedContactId: null,
                        });
                    }}
                    open={Boolean(selectedContact)}
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                >
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content>
                        <Header>
                            <Icon name="user" />
                            {selectedContact.fullname}
                        </Header>
                        <Segment raised style={{ position: 'relative' }}>
                            <pre style={{ whiteSpace: 'pre-wrap' }}>
                                {parseSignature({
                                    contact: selectedContact,
                                    message: state.message,
                                }) || 'Empty Message'}
                            </pre>
                            <Icon
                                name="envelope outline"
                                size="large"
                                style={{ position: 'absolute', right: 5, bottom: 5 }}
                            />
                        </Segment>
                    </Modal.Content>
                </Modal>
            )}
            <Message>
                <Message.Header>
                    Send message to <b>({contactPersons.length})</b> contacts
                </Message.Header>
                <div style={{ padding: '5px 0' }}>
                    {contactPersons.map(c => (
                        <Button.Group key={`contact-name-${c.id}`} style={{ margin: '2px 2px' }}>
                            <Button
                                color="grey"
                                icon="delete"
                                size="mini"
                                style={{ fontSize: 11 }}
                                onClick={() => {
                                    removeContact(c.id);
                                }}
                            />
                            <Button
                                size="mini"
                                style={{ fontSize: 11 }}
                                onClick={() => {
                                    setState({
                                        ...state,
                                        selectedContactId: c.id,
                                    });
                                }}
                            >
                                {!state.displaySuccess && (!state.message || messagesWithWarnings.includes(c.id)) ? (
                                    <Icon color="red" name="thumbs down" />
                                ) : (
                                    <Icon color="teal" name="thumbs up" />
                                )}
                                {c.fullname}
                            </Button>
                        </Button.Group>
                    ))}{' '}
                </div>
            </Message>
            {state.displaySuccess ? <Message success header="SMSs sent successfully!" /> : ''}
            {state.error ? <Message warning header="Error sending SMSs" content={state.error} /> : ''}
            <Form reply>
                <Form.Input
                    label="Topic"
                    value={state.topic}
                    onChange={handleTopicChange}
                    disabled={Boolean(state.displaySuccess)}
                />
                <Form.TextArea
                    value={state.message}
                    autoheight
                    onChange={handleMessageChange}
                    disabled={Boolean(state.displaySuccess)}
                    label="Message"
                    type="textarea"
                />

                <span style={warningStyle}>
                    <Icon name="warning circle" color="red" />
                    Message has warning for some contacts! Usually <b>$receiver_name</b> is missing from the contact.
                    Try to remove it.
                </span>
            </Form>

            <CopySignature
                signatures={allSignatures}
                onClick={value => {
                    setState({ ...state, message: value });
                }}
            />

            <div style={{ textAlign: 'right' }}>
                <Button
                    content="Send"
                    attached="right"
                    labelPosition="left"
                    icon={<Icon name={'send'} />}
                    primary
                    disabled={!state.topic || !state.message || hasWarning}
                    onClick={handleSendSms}
                />

                <Button
                    content="Close"
                    attached="right"
                    labelPosition="left"
                    icon={<Icon name={'cancel'} />}
                    onClick={props.onSuccess}
                    style={{ marginLeft: 10 }}
                />
            </div>
        </div>
    );
}

export default function SendNewBulkMessages(props) {
    const ALL_SIGNATURES = gql`
        query ALL_SIGNATURES($adminId: Int) {
            allSignatures(orderBy: [ID_DESC], condition: { group: "group_messages", admin: $adminId }) {
                nodes {
                    id
                    text
                    title
                }
            }
        }
    `;
    const SEND_SMS_MUTATION = gql`
        mutation sendSms($input: SendSmsInput!) {
            sendSms(input: $input) {
                message {
                    timestamp
                }
            }
        }
    `;
    const admin = JSON.parse(localStorage.getItem('current-admin'));
    return (
        <Query query={ALL_SIGNATURES} variables={{ adminId: admin.id }}>
            {({ loading, error, data }) => {
                if (error) {
                    return <Info icon="warning" message={error.message} title="Error" loading={false} />;
                }

                if (loading) {
                    return <Info icon="circle notched" message="Loading..." loading={true} />;
                }
                return (
                    <Mutation mutation={SEND_SMS_MUTATION}>
                        {sendSms => (
                            <SendNewBulkMessageComponent
                                {...props}
                                sendSms={sendSms}
                                allSignatures={data.allSignatures.nodes}
                            />
                        )}
                    </Mutation>
                );
            }}
        </Query>
    );
}
