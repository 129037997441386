import React from 'react'

import {Icon} from 'semantic-ui-react'

const SportIcon = (props) => {

    const {sportName, hide} = props;

    if(hide) {
        return '';
    }

    switch (sportName) {
        case 'Fotboll': return <Icon size={'large'} name='futbol' color='green'/>;
        case 'Innebandy': return <Icon size={'large'} name='life ring outline' color='black'/> ;
        case 'Futsal': return <Icon size={'large'} name='futbol outline' color='purple'/>;
        case 'Volleyboll': return <Icon size={'large'} name='volleyball ball' color='purple'/>;
        case 'Ishockey': return <Icon size={'large'} name='hockey puck' color='black'/>;
        case 'Badminton': return <Icon size={'large'} name='utensil spoon' color='grey'/>;
        case 'Bowling': return <Icon size={'large'} name='bowling ball' color='red'/>;
        case 'Bandy': return <Icon size={'large'} name='snowflake' color='purple'/>;
        case 'Bordtennis': return <Icon size={'large'} name='table tennis' color='purple'/>;
        case 'Basket': return <Icon size={'large'} name='basketball ball' color='orange'/>;
        case 'Speedway': return <Icon size={'large'} name='motorcycle' color='purple'/>;
        case 'Amerikansk Fotboll': return <Icon size={'large'} name='football ball' color='orange'/>;
        case 'Baseboll': return <Icon size={'large'} name='baseball ball' color='grey'/>;
        case 'Softboll': return <Icon size={'large'} name='baseball ball' color='grey'/>;
        case 'Brottning': return <Icon size={'large'} name='handshake' color='yellow'/>;
        case 'Rugby': return <Icon size={'large'} name='football ball' color='black'/>;
        case 'Tennis': return <Icon size={'large'} name='table tennis' color='red'/>;
        default: return '';
    }

};

export default SportIcon;
