import React from 'react'
import {
    ARTICLE_STATUS_AUTO_APPROVE, ARTICLE_STATUS_APPROVED, ARTICLE_STATUS_COMMENT_RECEIVED,
    ARTICLE_STATUS_IGNORED, ARTICLE_STATUS_BLOCKED, ARTICLE_STATUS_SNOOZED,ARTICLE_STATUS_DISAPPROVED,
    ARTICLE_STATUS_ARTICLE_RECEIVED, ARTICLE_STATUS_ADMIN_DONE
} from "../utils/QnaConstants";
import {Icon} from 'semantic-ui-react'

const ArticleStatusIcon = (props) => {

    const {status, displayLabel, hide} = props;

    if(hide) {
        return '';
    }

    switch (status) {
        case ARTICLE_STATUS_COMMENT_RECEIVED: return <span><Icon name='wait' color='yellow'/> {displayLabel ? 'Pending Moderation' : ''}</span>;
        case ARTICLE_STATUS_AUTO_APPROVE: return <span><Icon name='wait' color='blue'/> {displayLabel ? 'Awaiting Auto-Approval' : ''}</span>;
        case ARTICLE_STATUS_DISAPPROVED: return <span><Icon name='x' color='red'/>{displayLabel ? 'Disapproved' : ''}</span> ;
        case ARTICLE_STATUS_APPROVED: return <span><Icon name='check' color='green'/>{displayLabel ? 'Approved' : ''}</span>;
        case ARTICLE_STATUS_BLOCKED: return <span><Icon name='exclamation' color='orange'/>{displayLabel ? 'Blocked' : ''}</span>;
        case ARTICLE_STATUS_IGNORED: return <span><Icon name='time' color='purple'/>{displayLabel ? 'Ignored' : ''}</span>;
        case ARTICLE_STATUS_ARTICLE_RECEIVED: return <span><Icon name='comment alternate' color='grey'/>{displayLabel ? 'Pending Comment' : ''}</span>;
        case ARTICLE_STATUS_SNOOZED: return <span><Icon name='user circle' color='brown'/>{displayLabel ? 'Administration' : ''}</span>;
        case ARTICLE_STATUS_ADMIN_DONE: return <span><Icon name='check circle outline' color='green'/>{displayLabel ? 'Administration Done' : ''}</span>;
        default: return '';
    }

};

export default ArticleStatusIcon;
