import React from 'react'
import gql from 'graphql-tag';
import {  Table } from 'semantic-ui-react'
import Info from '.././Info';
import { Query } from "react-apollo";
import _ from "lodash";
const moment = require('moment');


const MonthlyArticleChangeStatsContainer = (props) => {
    const {changedArticles, unchangedArticles} = props;
    if (changedArticles.nodes && unchangedArticles.nodes) {

        const changedArticlesData = _.map(changedArticles.nodes, (node) => ({
            month: moment(node.timePeriod).format('YYYY-MM'),
            changedCount: parseInt(node.numberOfArticles, 10)
        }));


        const unchangedArticlesData = _.map(unchangedArticles.nodes, (node) => ({
            month: moment(node.timePeriod).format('YYYY-MM'),
            unchangedCount: parseInt(node.numberOfArticles, 10)
        }));

        const data = _.map(changedArticlesData, (t) => {
            const unchangedCount = _.get(_.findLast(unchangedArticlesData, (aa) => {return aa.month === t.month}), 'unchangedCount') || 0;
            return {
                month: t.month,
                changedCount: t.changedCount,
                unchangedCount,
                percentage: (unchangedCount/(t.changedCount + unchangedCount) * 100).toFixed(1)
            }
        })


        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Month</Table.HeaderCell>
                        <Table.HeaderCell>Directly Approved Comment Count</Table.HeaderCell>
                        <Table.HeaderCell>Moderated Comments Count</Table.HeaderCell>
                        <Table.HeaderCell>Percentage</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        _.map(data, (d) => {
                            return (
                                <Table.Row key={d.month}>
                                    <Table.Cell>{d.month}</Table.Cell>
                                    <Table.Cell>{d.unchangedCount}</Table.Cell>
                                    <Table.Cell>{d.changedCount}</Table.Cell>
                                    <Table.Cell>{d.percentage+'%'}</Table.Cell>
                                </Table.Row>)
                        })
                    }

                </Table.Body>
            </Table>
        );
    }
    return <Info icon='warning' message={'Sorry, failed to fetch data'} title='Error' loading={false}/>;
};


const monthlyArticleStatsQuery = gql`
    query ArticlesPerMonthQuery {
        changedArticles: monthlyApprovedArticles(changed: true) {
            nodes {
                numberOfArticles
                timePeriod
            }
        }
        unchangedArticles: monthlyApprovedArticles(changed: false)  {
            nodes {
                numberOfArticles
                timePeriod
            }
        }
    }
`;

const MonthlyArticleChangeStats = () => ( <Query query={monthlyArticleStatsQuery}>
    {({ data, loading, error }) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if(data && data.changedArticles && data.unchangedArticles) {
            return <MonthlyArticleChangeStatsContainer changedArticles={data.changedArticles} unchangedArticles={data.unchangedArticles}/>
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, you must be a super admin to see'} title='Error' loading={false}/>;
    }}
</Query>);

export default MonthlyArticleChangeStats;
