import React from 'react'
import gql from 'graphql-tag';
import {  Table } from 'semantic-ui-react'
import Info from '.././Info';
import { Query } from "react-apollo";
import _ from "lodash";
const moment = require('moment');


const MonthlyArticleStatsContainer = (props) => {
    const {approvedArticles, allArticles} = props;
    if (approvedArticles.nodes && allArticles.nodes) {

        const approvedArticlesData = _.map(approvedArticles.nodes, (node) => ({
            month: moment(node.timePeriod).format('YYYY-MM'),
            approvedCount: parseInt(node.numberOfArticles, 10)
        }));


        const totalArticlesData = _.map(allArticles.nodes, (node) => ({
            month: moment(node.timePeriod).format('YYYY-MM'),
            totalCount: parseInt(node.numberOfArticles, 10)
        }));

        const data = _.map(totalArticlesData, (t) => {
            const approvedCount = _.get(_.findLast(approvedArticlesData, (aa) => {return aa.month === t.month}), 'approvedCount') || 0;
            return {
                month: t.month,
                totalCount: t.totalCount,
                approvedCount,
                percentage: (approvedCount/t.totalCount * 100).toFixed(1)
            }
        })


        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Month</Table.HeaderCell>
                        <Table.HeaderCell>Articles With Comment</Table.HeaderCell>
                        <Table.HeaderCell>Total Articles</Table.HeaderCell>
                        <Table.HeaderCell>Percentage</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        _.map(data, (d) => {
                            return (
                                <Table.Row key={d.month}>
                                    <Table.Cell>{d.month}</Table.Cell>
                                    <Table.Cell>{d.approvedCount}</Table.Cell>
                                    <Table.Cell>{d.totalCount}</Table.Cell>
                                    <Table.Cell>{d.percentage+'%'}</Table.Cell>
                                </Table.Row>)
                        })
                    }

                </Table.Body>
            </Table>
        );
    }
    return <Info icon='warning' message={'Sorry, failed to fetch data'} title='Error' loading={false}/>;
};


const monthlyArticleStatsQuery = gql`
    query ArticlesPerMonthQuery {
        approvedArticles: monthlyArticles(statusIn: "approved") {
            nodes {
               numberOfArticles
                timePeriod
            }
        }
        allArticles: monthlyArticles {
            nodes {
                numberOfArticles
                timePeriod
            }
        }
    }
`;

const MonthlyArticleStats = () => ( <Query query={monthlyArticleStatsQuery}>
    {({ data, loading, error }) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if(data && data.approvedArticles && data.allArticles) {
            return <MonthlyArticleStatsContainer approvedArticles={data.approvedArticles} allArticles={data.allArticles}/>
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, you must be a super admin to see'} title='Error' loading={false}/>;
    }}
</Query>);

export default MonthlyArticleStats;
