import {Button, Header, Icon, List, Modal, Statistic, Image} from "semantic-ui-react";
import SportIcon from "../SportIcon";
import React from "react";
import _ from "lodash";
import {Link} from "react-router-dom";

const teams = (league) => {
    return _.map(league?.teamLeaguesByLeagueId?.nodes, ({teamByTeamId}) => (<List.Item key={teamByTeamId.id}>
        <Image avatar src={teamByTeamId.logo}/>
        <List.Content verticalAlign={"middle"}>
            <List.Header as={Link} to={'/team/'+teamByTeamId.id}>{teamByTeamId.name}</List.Header>
        </List.Content>
    </List.Item>))
}
const StatsTeamsModal = ({league, teamOpen, setTeamOpen}) => {
    const { teamLeaguesByLeagueId : {totalCount}} = league;
    return(
        <Modal
            onClose={() => setTeamOpen(false)}
            onOpen={() => setTeamOpen(true)}
            open={teamOpen}
            size='small'
            trigger={<Statistic>
                <Statistic.Value>{totalCount}</Statistic.Value>
                <Statistic.Label>Teams </Statistic.Label>
                <Icon name={'external square alternate'} style={{marginLeft:10}}/>
            </Statistic>}
        >
            <Header icon>
                <SportIcon sportName={league.sportname}/>
                {'Teams in ' + league.name + ' ' + league.sportname + ' '+ league.teamclass}
            </Header>
            <Modal.Content>
                <List divided relaxed>
                    {teams(league)}
                </List>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' inverted onClick={() => setTeamOpen(false)}>
                    <Icon name='checkmark' /> Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default StatsTeamsModal;
