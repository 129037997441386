import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import {
    Header,
    Icon,
    Button,
    Dimmer,
    Message,
    Sticky,
} from 'semantic-ui-react'
import isArticleLocked, { getDuration } from '../../utils/isArticleLocked';

const getLockMeta = props => {
    const { article, now = new Date() } = props;
    const currentAdmin = JSON.parse(localStorage.getItem('current-admin'));
    const lastLock = _.get(article, 'locksByArticle.nodes[0]');
    const isLocked = isArticleLocked(_.get(lastLock, 'timestamp'), now);
    const countdown = getDuration(_.get(lastLock, 'timestamp'), now);
    const isCountdownActive = countdown && countdown < 60;
    const canEdit =
        isLocked &&
        (currentAdmin &&
            currentAdmin.id === _.get(lastLock, 'adminByAdmin.id'));

    return {
        lastLock,
        currentAdmin,
        isLocked,
        countdown,
        isCountdownActive,
        canEdit
    };
};

export default function LockControlers(props) {
    const {
        article,
        history,
        contextRef,
        children,
    } = props;
    const [now, setNow] = useState(new Date());
    const [hasAutoLocked, setHasAutoLocked] = useState((() => {
        const { isLocked } = getLockMeta({
            article,
        });

        return isLocked
    })());
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 1000);

        const unlisten = history.listen(() => {
            const { isLocked, canEdit } = getLockMeta({
                article,
                now,
            });
            if (isLocked && canEdit) {
                unlockArticle();
            }
        });

        return () => {
            clearInterval(interval);
            unlisten();
        };
    }, []);

    const {
        lastLock,
        isLocked,
        countdown,
        isCountdownActive,
        canEdit
    } = getLockMeta({
        article,
        now,
    });

    const lockArticle = async () => {
        const {
            data: {
                lockArticle: { lock }
            },
        } = await props.lockArticle({
            variables: {
                input: {
                    articleId: article.id,
                }
            }})
        if (!lock) {
            console.log('Failed to lock this article')
        }
        setHasAutoLocked(true)
    }

    const unlockArticle = () => {
        return props.unlockArticle({
            variables: {
                input: {
                    articleId: article.id,
                }
            }})
    }

    if (!isLocked && !canEdit && !hasAutoLocked) {
        console.log('auto lock article', hasAutoLocked);
        lockArticle();
    }

    // if (isLocked && !canEdit && countdown <= 0) {
    //     window.location.reload();
    // }

    return (
        <div>
            <Sticky context={contextRef.current}>
                <div
                    style={{
                        padding: 20,
                        backgroundColor: '#F3F4F5'
                    }}
                >
                    <Header as='h2' color='blue'>
                        {article.title}
                    </Header>
                    {!canEdit && !isLocked ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{ textAlign: 'left' }}>
                                <Button
                                    color={isLocked ? 'red': 'green'}
                                    onClick={() => {
                                        lockArticle()
                                    }}
                                >
                                    <Icon name="pencil alternate" />
                                    Start editing the article
                                </Button>
                            </div>
                            <div style={{ flex: '1 1 auto' }} />
                        </div>
                    ) : null}
                    <Dimmer.Dimmable dimmed={!canEdit}>
                        {canEdit ? (
                            <div>
                                {!isCountdownActive && isLocked && countdown ? (
                                    <Message
                                        success
                                        icon="warning"
                                        header={(
                                            <div style={{ fontSize: 18 }}>
                                                You can edit this article until {' '}
                                                <b>{moment(_.get(lastLock, 'timestamp')).format('MMMM Do, h:mm a')}</b>
                                            </div>
                                        )}
                                    />
                                ) : null}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0 20px',
                                    }}
                                >
                                    <div style={{ textAlign: 'left' }}>
                                        {isCountdownActive ? (
                                            <div style={{ marginTop: 5 }}>
                                                <div>
                                                    <Button
                                                        icon
                                                        color="green"
                                                        onClick={() => {
                                                            lockArticle()
                                                        }}
                                                    >
                                                        <Icon name="hourglass start" />
                                                        {' '}I need more time
                                                    </Button>
                                                    <Button
                                                        icon
                                                        color="red"
                                                        onClick={() => {
                                                            unlockArticle()
                                                        }}
                                                    >
                                                        <Icon name="cancel" />
                                                        {' '}Stop editing this article
                                                    </Button>
                                                </div>

                                                <Message
                                                    warning
                                                    icon
                                                    size="mini"
                                                >
                                                    <Icon name="warning sign" size="mini" style={{ fontSize: 15 }} />
                                                    <Message.Content>
                                                        <div style={{ fontSize: 12 }}>
                                                            You will stop editing in {countdown}"
                                                        </div>
                                                    </Message.Content>
                                                </Message>
                                            </div>
                                        ) : (
                                            <Button
                                                color={isLocked ? 'red': 'yellow'}
                                                onClick={() => {
                                                    if (isLocked) {
                                                        unlockArticle()
                                                    } else {
                                                        lockArticle()
                                                    }
                                                }}
                                            >
                                                <Icon name="pencil alternate" />
                                                {isLocked ? 'Stop editing this article': 'Start editing this article'}
                                            </Button>
                                        )}
                                    </div>
                                    <div style={{ flex: '1 1 auto' }} />
                                </div>
                            </div>
                        ) : (
                            <div>
                                {isLocked && (
                                    <div style={{ padding: 20, textAlign: 'left' }}>
                                        <Message
                                            warning
                                            icon="warning sign"
                                            header={(
                                                <div style={{ fontSize: 18 }}>
                                                    You can't edit.{' '}
                                                    <b>{_.get(lastLock, 'adminByAdmin.fullname')}</b>{' '}
                                                    is editing{' '}

                                                    {!isCountdownActive && (
                                                        <span>
                                                            until <b>{moment(_.get(lastLock, 'timestamp')).format('MMMM Do, h:mm a')}</b>
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                            content={(
                                                <div>
                                                    {isCountdownActive && (
                                                        <div style={{ marginTop: 10 }}>
                                                            <b>{_.get(lastLock, 'adminByAdmin.fullname')}</b>{' '}
                                                            will stop editing in {countdown}"
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </Dimmer.Dimmable>
                </div>
            </Sticky>
            {children({
                canEdit,
                isLocked,
            })}
        </div>
    );
}
