import React, {Component} from 'react';
import gql from 'graphql-tag';
import {
    Header,
    Container,
    Segment,
    Grid,
    Label,
    Card,
    Icon, Image
} from 'semantic-ui-react'
import Info from '../components/Info';
import {Query} from "react-apollo";
import {Link} from "react-router-dom";
import SendNewExternalSms from "../components/external-contact/SendNewExternalSms";
import ExternalMessages from "../components/external-contact/ExternalMessages";
import EditExternalContact from "../components/external-contact/EditExternalContact";
import ExternalContactStatus from "../components/external-contact/ExternalContactStatus";
import _ from "lodash";
import AssignExternalContact from "../components/external-contact/AssignExternalContact";
import ContactPersonCard from "../components/contact-person/ContactPersonCard";

const moment = require('moment');

class ExternalContactContainer extends Component {

    render() {
        const {admins, contactPerson, signatures, receivers, sports, error} = this.props;

        if (contactPerson && contactPerson.fullname) {
            const contacts = _.map(_.get(contactPerson, 'teamByTeamId.contactpeopleByTeamid.nodes'), (c) => (<ContactPersonCard contactPersonId={c.id} key={c.id} />))
            return (
                <div>
                    <Header as='h1' textAlign='center' color='blue'>
                        {`External Contact: ${contactPerson.fullname}`}
                    </Header>

                    <Container style={{marginTop: 20}}>
                        <Grid columns={2}>
                            <Grid.Row>

                                <Grid.Column width={6}>
                                    <Card fluid color={'grey'}>
                                        <Card.Content>
                                            <Card.Header>{contactPerson.fullname}</Card.Header>
                                            <Card.Meta>
                                                <span
                                                    className='date'>Joined in {moment(contactPerson.registrationdate).format('MMMM Do, YYYY')}</span>
                                            </Card.Meta>
                                            <Card.Description>
                                                {contactPerson.fullname} is an external contact person
                                                for <Link to={'/team/'+ contactPerson.teamId}>{contactPerson.teamByTeamId && contactPerson.teamByTeamId.name}</Link> team
                                                in <strong>{contactPerson.teamByTeamId && contactPerson.teamByTeamId.leagueByLeagueid.name}</strong> competition.
                                                <div style={{textAlign: 'left'}}><strong>Phone
                                                    Number:</strong> {contactPerson.phone}</div>
                                                <div style={{textAlign: 'left'}}>
                                                    <strong>Title:</strong> {contactPerson.title}</div>
                                                <div style={{textAlign: 'left'}}>
                                                    <strong>Note:</strong> {contactPerson.note}</div>
                                                <div style={{textAlign: 'left'}}>
                                                    <strong>Auto-Onboard:</strong> {contactPerson.autoOnboard ? 'yes' : 'no' }</div>
                                                <div style={{textAlign: 'left'}}><strong>Moderated By: </strong><Image
                                                    avatar
                                                    size='mini'
                                                    inline
                                                    src={_.get(contactPerson, 'adminByCreatedBy.avatarByAvatar.url')}
                                                    alt={_.get(contactPerson, 'adminByCreatedBy.fullname')}
                                                /> {_.get(contactPerson, 'adminByCreatedBy.fullname')}</div>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <AssignExternalContact externalContact={contactPerson} refetchQuery={externalContactPageQuery} />
                                            {
                                                contactPerson.teamId ?
                                                    <a href={`http://inrapportering.everysport.se/intra-new/contactList.html?teamId=${contactPerson.teamId}&clean`}
                                                        target="_blank" rel="noopener noreferrer"><Icon color='red'
                                                            name='linkify'/>
                                                        <Label>Add in
                                                            inrapportering.everysport.se </Label></a> :
                                                    <p>Please add a team id to view link to ES inrapportering.</p>

                                            }
                                            {
                                                contactPerson.sendEverysport === 1 ?
                                                    <p style={{marginTop: 10}}>Adding contact to everysport intra, please wait a couple of
                                                        minutes..</p> : ''
                                            }
                                            {
                                                contactPerson.sendEverysport === 2 ?
                                                    <p style={{marginTop: 10}}>This contact was added to everysport db, it should soon show up as a
                                                        regular contact.</p> : ''
                                            }
                                            {
                                                contactPerson.sendEverysport === 3 ? <p style={{marginTop: 10}}>This contact could not be added to everysport db, please add it manually in intra.</p> : ''
                                            }

                                        </Card.Content>
                                        <Card.Content extra>
                                            <div style={{marginTop: 10}}>
                                                <Header as='h4' textAlign='left' color='red'><Icon color='black' name='address book outline' />Active Contacts for the team: </Header>
                                                {
                                                    contacts && contacts.length > 0 ?
                                                        <Card.Group itemsPerRow={2}>{contacts}</Card.Group>
                                                        :
                                                        <div>No contact</div>
                                                }
                                            </div>
                                        </Card.Content>
                                    </Card>

                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <EditExternalContact externalContact={contactPerson}
                                        receivers={receivers}
                                        refetchQuery={externalContactPageQuery} sports={sports.nodes}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>

                                    <Segment color='yellow' style={{textAlign: 'left'}}>
                                        <Label as='h2' size='big' color='blue' ribbon style={{marginBottom: 15}}>Latest
                                            50
                                            Messages</Label>
                                        <ExternalMessages admins={admins.nodes}
                                            externalSms={contactPerson && contactPerson.externalSmsByContact.nodes}/>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <ExternalContactStatus externalContact={contactPerson}
                                        refetchQuery={externalContactPageQuery}/>
                                    <SendNewExternalSms externalContact={contactPerson} signatures={signatures.nodes}
                                        refetchQuery={externalContactPageQuery}/>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Container>
                </div>
            );
        } else {
            return <Info icon='warning' message={error || 'Sorry, we could not find the contact person you requested!'}
                title='Error'
                loading={false}/>;
        }
    }
};

const externalContactPageQuery = gql`
    query ExternalContactQuery($id: Int!, $adminId: Int!) {
        contactPerson: externalContactById( id : $id) {
            id
            fullname
            phone
            createdAt
            note
            teamId
            title
            sendEverysport
            seasonId
            autoOnboard
            teamByTeamId {
                id
                name
                leagueByLeagueid {
                    id
                    name
                    sportid
                }
                contactpeopleByTeamid(condition: {active: true}) {
                    totalCount
                    nodes {
                        id
                        fullname
                        title
                    }
                }
            }
            adminByCreatedBy {
                id
                fullname
                avatarByAvatar {
                    id
                    url
                }
            }
            deleted
            onboarded
            lastMessageTime
            receiver
            receiverByReceiver {
                id
                name
                displayName
                key
            }
            externalSmsByContact(orderBy: [CREATED_AT_DESC, SENT_AT_DESC]) {
                nodes {
                    id
                    text
                    type
                    sender
                    sentAt
                    createdAt
                    failreason
                }
            }
            signatureByMessageTemplate {
                id
                title
                text
                receiverByReceiver {
                    id
                    key
                    name
                    displayName
                }
            }
        }
        allReceivers {
            nodes {
                id
                displayName
                name
                key
            }
        }
        allAdmins {
            nodes {
                id
                fullname
                avatarByAvatar {
                    id
                    url
                }
            }
        }
        allSignatures(orderBy:[ID_DESC],condition: { group: "external_contact", admin: $adminId}) {
            nodes {
                id
                text
                title
            }
        }
        allSports{
            nodes {
                endDate
                startDate
                id
                sportid
                sportname
            }
        }
    }
`;

const ExternalContact = (props) => (
    <Query query={externalContactPageQuery} variables={{
        id: parseInt(props.match.params.externalContactId),
        adminId: JSON.parse(localStorage.getItem('current-admin')).id
    }}>
        {({data, loading, error}) => {
            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true}/>;
            }
            if (data && data.contactPerson) {
                return <ExternalContactContainer admins={data.allAdmins} receivers={data.allReceivers} signatures={data.allSignatures} contactPerson={data.contactPerson} sports={data.allSports}/>
            }
            return <Info icon='warning' message={(error && error.message) || 'Sorry, we could not find the contact person you requested!'} title='Error' loading={false}/>;
        }}
    </Query>);

export default ExternalContact;
