import {
    Header, Segment, Statistic, Icon
} from 'semantic-ui-react';

import React from 'react';
import gql from 'graphql-tag';
import { Query} from 'react-apollo';
import Info from "../Info";



const receiverTeamsQuery = gql`
    query ReceiverQuery($receiver: Int!, $region: Int) {
        contacts: filterContacts7(onlyActive: true, receiverIn: $receiver, regionIn: $region, limitNo: 1000) {
            totalCount
        }
        deletedExternalContacts: filterExternalContacts3(receiverId: $receiver, regionIn: $region, isDeleted: true){
            totalCount
        }
        onboardedExternalContacts: filterExternalContacts3(receiverId: $receiver, regionIn: $region, isDeleted: false, isOnboarded: true){
            totalCount
        }
        activeExternalContacts: filterExternalContacts3(receiverId: $receiver, regionIn: $region, isDeleted: false, isOnboarded: false){
            totalCount
        }
    }
`;

const ReceiverStatsContainer = ({contacts, deletedExternalContacts, onboardedExternalContacts, activeExternalContacts}) => {
    return (
        <Segment color={'yellow'}>
            <Header as={'h3'} color={'red'} textAlign={'center'}>Receiver Stats</Header>
            <Statistic.Group size={"small"}>
                <Statistic>
                    <Statistic.Value><Icon name='comment alternate' />{contacts?.totalCount}</Statistic.Value>
                    <Statistic.Label>Number of active contacts</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value><Icon name='check circle' />{onboardedExternalContacts?.totalCount}</Statistic.Value>
                    <Statistic.Label>Number of contacts onboarded</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>
                        <Icon name='comments outline' />{activeExternalContacts?.totalCount}
                    </Statistic.Value>
                    <Statistic.Label>Number of contacts in progress to onboard</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>
                        <Icon name='ban' />{deletedExternalContacts?.totalCount}
                    </Statistic.Value>
                    <Statistic.Label>Number of contacts declined</Statistic.Label>
                </Statistic>
            </Statistic.Group>
        </Segment>
    )
}

const ReceiverStats = ({ receiverId, region }) => (
    <Query query={receiverTeamsQuery} variables={{ receiver: receiverId, region }}>
        {({ loading, error, data}) => {
            if (error) {
                return <Info icon="warning" message={error.message} title="Error" loading={false} />;
            }

            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true} />;
            }
            return (
                <ReceiverStatsContainer contacts={data.contacts}
                                        deletedExternalContacts={data.condeletedExternalContactstacts}
                                        onboardedExternalContacts={data.onboardedExternalContacts}
                                        activeExternalContacts={data.activeExternalContacts} />
            );
        }}
    </Query>
);

export default ReceiverStats;
