import React, { useState } from 'react'
import { Header } from 'semantic-ui-react'
import ContactList from './ContactList'

export default function ContactListPage(props) {
    const { match } = props;
    if (!match.params.filter) {
        match.params.filter = ',,,,,'
    }
    if (!match.params.order) {
        match.params.order = 'fullname,asc'
    }
    const { params } = match;
    const { page = 1 } = params;
    const [sportId, teamClass, teamId, leagueId, onlyActive, levelIn, receiverId] = params.filter.split(',');

    const filter = {
        sportId: parseInt(sportId, 10) || null,
        teamClass: teamClass || null,
        teamId: teamId || null,
        leagueId: leagueId || null,
        onlyActive: Boolean(onlyActive) || null,
        levelIn: parseInt(levelIn, 10) || null,
        receiverId: parseInt(receiverId, 10) || null
    };
    const [orderBy, orderByDirection] = params.order.split(',');
    const limitNo = 100;
    const offsetNo = (() => {
        const result = (page - 1) * limitNo;
        if (result < 0) {
            return 0
        }
        return result
    })();
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [openSendSmsModal, setOpenSendSmsModal] = useState(false);
    return (
        <div>
            <Header as="h1" textAlign="center" color="blue">
                CONTACTS
            </Header>
            <ContactList
                {...props}
                match={match}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                filter={filter}
                offsetNo={offsetNo}
                limitNo={limitNo}
                page={page ? parseInt(page, 10) : 1}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                openSendSmsModal={openSendSmsModal}
                setOpenSendSmsModal={setOpenSendSmsModal}
            />
        </div>
    )
}
