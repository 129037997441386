import {
    Card,
} from 'semantic-ui-react';

import React from 'react';
import gql from 'graphql-tag';
import { Query} from 'react-apollo';
import Info from "../Info";
import {ExternalContactCardContainer} from "./ExternalContactCard";
import _ from "lodash";


const contactPersonQuery = gql`
    query ContactPersonQuery($phone: String!, $region: Int) {
        externalContacts: searchExternalContacts(
                searchText: $phone,
                limitNo: 5,
                regionIn: $region
                ) {
            nodes {
                id
                fullname
                note
                teamByTeamId {
                    id
                    name
                    logo
                    leagueByLeagueid {
                        name
                        id
                    }
                }
                adminByCreatedBy {
                    avatarByAvatar {
                        id
                        url
                    }
                    id
                    fullname
                }
                phone
                receiverByReceiver {
                    id
                    displayName
                    name
                    key
                }
            }
        }
    }
`;

const ExternalContactCards = ({contacts}) => {
    return (<Card.Group itemsPerRow={3}>
        {_.map(_.get(contacts,'nodes'), (cp, index) => <ExternalContactCardContainer key={cp.id} contactPerson={cp} />)}
    </Card.Group>);
}

const ExternalContactsByPhone = ({ phone, region }) => (
    <Query query={contactPersonQuery} variables={{ phone, region }}>
        {({ loading, error, data}) => {
            if (error) {
                return <Info icon="warning" message={error.message} title="Error" loading={false} />;
            }

            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true} />;
            }
            return (
                <ExternalContactCards contacts={data.externalContacts} />
            );
        }}
    </Query>
);

export default ExternalContactsByPhone;
