import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Statistic} from 'semantic-ui-react'
import Info from '.././Info';
import {Query} from "react-apollo";

const ReceiverContactStatsContainer = (props) => {
    const {activeCount, inActiveCount, inprogressCount, notOnboardedCount, onboardedCount, receiver} = props;

    const {teamReceiversByReceiverId :{
        totalCount
    }} = receiver;
    return (
        <Segment inverted raised={true} >
            <Header as={'h3'} color={"black"}>{receiver.displayName || receiver.name} - {receiver.key}</Header>
            <Statistic.Group horizontal inverted color={'red'} size={'small'}>
                <Statistic>
                    <Statistic.Value>{totalCount}</Statistic.Value>
                    <Statistic.Label>Total Teams</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>{activeCount} - {(activeCount/(activeCount+inprogressCount) *100).toFixed(0)+"%"}</Statistic.Value>
                    <Statistic.Label>Active Contacts</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>
                        {inActiveCount}
                    </Statistic.Value>
                    <Statistic.Label>Inactive Contacts</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>
                        {inprogressCount} - {(inprogressCount/(activeCount+inprogressCount) *100).toFixed(0)+"%"}
                    </Statistic.Value>
                    <Statistic.Label>In progress</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>
                        {onboardedCount}
                    </Statistic.Value>
                    <Statistic.Label>Onboarded</Statistic.Label>
                </Statistic>
                <Statistic>
                    <Statistic.Value>
                        {notOnboardedCount}
                    </Statistic.Value>
                    <Statistic.Label>Not Onboarded</Statistic.Label>
                </Statistic>
            </Statistic.Group>
            <div style={{marginTop: 25}}><i>All data displayed here are based on contact creation time.</i></div>
        </Segment>
    );



};

const receiverContactStatsQuery = gql`
    query ReceiverContacts($receiverId: Int!, $date: String, $toDate: String) {
        activeContactCount: receiverContactCount(receiverIdIn: $receiverId, fromDate: $date, toDate: $toDate, contactTypeIn: "active")
        inactiveContactCount: receiverContactCount(receiverIdIn: $receiverId, fromDate: $date, toDate: $toDate, contactTypeIn: "inactive")
        inprogressContactCount: receiverContactCount(receiverIdIn: $receiverId, fromDate: $date, toDate: $toDate, contactTypeIn: "in_progress")
        onboardedContactCount: receiverContactCount(receiverIdIn: $receiverId, fromDate: $date, toDate: $toDate, contactTypeIn: "onboarded")
        notonboardedContactCount: receiverContactCount(receiverIdIn: $receiverId, fromDate: $date, toDate: $toDate, contactTypeIn: "not_onboarded")
    }
`;

const ReceiverContactStats = ({ receiver, date, dateTo }) => (<Query query={receiverContactStatsQuery} variables={{receiverId: receiver.id, date: date, toDate: dateTo}}>
    {({data, loading, error}) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if (data) {
            return <ReceiverContactStatsContainer activeCount={data.activeContactCount}
                inActiveCount={data.inactiveContactCount}
                inprogressCount={data.inprogressContactCount}
                onboardedCount={data.onboardedContactCount}
                notOnboardedCount={data.notonboardedContactCount}
                receiver={receiver}>

            </ReceiverContactStatsContainer>
        }
        return <Info icon='warning'
                 message={(error && error.message) || 'Sorry, we could not find any receiver contacts'}
                 title='Error' loading={false}/>;
    }}
</Query>);

export default ReceiverContactStats;
