import React, { Component } from 'react';
import {withApollo, graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {Menu, Modal, Header, Button, Form, Image} from 'semantic-ui-react'
import {Link} from "react-router-dom";
import _ from 'lodash';
import compose from "lodash.flowright";
import SelectRegionModal from "./SelectRegionModal";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loginError: ''
        };

        this.logout = () => {
            localStorage.removeItem('token');
            localStorage.removeItem('current-admin');
            props.client.resetStore();
        };

        this.handleUsernameChange = (event, data) => {
            this.setState({email: data.value});
        };

        this.handlePasswordChange = (event, data) => {
            this.setState({password: data.value});
        };

        this.login = () => {
            props.authenticate({
                variables: {
                    input: {email: this.state && this.state.email, password:  this.state && this.state.password }
                }}).then((res) => {
                if(res.data.authenticate.jwtToken && res.data.authenticate.jwtToken !== 'null') {
                    localStorage.setItem('token', res.data.authenticate.jwtToken);
                    window.location.reload();
                }
            }).catch((err) => console.error(err));
        }
    }



    render() {
        const {loading, currentAdmin, error, menuClick} = this.props;

        if (loading && !error) {
            return (
                <Menu.Menu position='right'>
                    <Menu.Item name='loading'>Loading...</Menu.Item>
                </Menu.Menu>
            );
        }
        if (currentAdmin) {
            localStorage.setItem('current-admin', JSON.stringify(currentAdmin));
            return (
                <Menu.Menu position='right'>
                    <Menu.Item style={{maxHeight:50}} as={Link} to={'/admin/'+currentAdmin.id} name='admin' onClick={() => menuClick(null, { name: 'notification-icon'})}>
                        {_.get(currentAdmin, 'avatarByAvatar.url') && (
                            <Image
                                avatar
                                size='mini'
                                inline
                                src={_.get(currentAdmin, 'avatarByAvatar.url')}
                                alt={currentAdmin.fullname}
                            />
                        )}{currentAdmin.fullname}
                    </Menu.Item>
                    <Menu.Item icon='log out' name='Logout' onClick={this.logout}/>
                    <SelectRegionModal regions={currentAdmin?.adminRegionsByAdminId?.nodes}/>
                </Menu.Menu>
            );
        }
        return (
            <Menu.Menu position='right'>
                <Modal style={{marginTop: 'auto', marginLeft: 'auto', marginRight: 'auto'}}  trigger={<Menu.Item name='Login' />} size='tiny'>
                    <Header icon='user circle' content='Login' />
                    <Modal.Content>
                        <p>Please enter your mail and password</p>
                        <Form>
                            <Form.Input fluid label='Email' placeholder='email' onChange={this.handleUsernameChange} />
                            <Form.Input fluid type='password' label='Password' placeholder='password' onChange={this.handlePasswordChange} />
                        </Form>
                        {this.state && this.state.loginError ? <div>this.state.loginError</div> : <div></div>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={this.login}>
                             Login
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Menu.Menu>

        );
    }
}

const PROFILE_QUERY = gql`
    query CurrentUserForLayout {
        currentAdmin {
            id
            fullname
            createdAt
            level
            adminRegionsByAdminId{
                nodes{
                    regionId
                }
            }
            avatarByAvatar {
                id
                url
            }
            adminRegionsByAdminId{
                nodes{
                    regionByRegionId{
                        id
                        name
                    }
                }
            }
        }
    }
`;

const AUTHENTICATE_QUERY = gql`
    mutation authenticate($input: AuthenticateInput!) {
        authenticate(input: $input) {
            jwtToken
        }
    }
`;

export default withApollo(
    compose(
        graphql(PROFILE_QUERY, {
            options: {fetchPolicy: 'network-only', errorPolicy: 'all'},
            props: ({data: {loading, currentAdmin, error}}) => ({
                loading, currentAdmin, error
            })
        }),
        graphql(AUTHENTICATE_QUERY, {name : 'authenticate'})
    )(Profile)
);
