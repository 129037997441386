import React from 'react';
import _ from 'lodash';
import {
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    LineChart,
    styler,
    ValueAxis
} from "react-timeseries-charts";
import { Collection, TimeSeries, TimeEvent/* , IndexedEvent, TimeRange, Index */ } from 'pondjs';

const moment = require('moment');

const style = styler([
    { key: "answerLength", color: "#CA4040", width: 2 },
    { key: "articleCount", color: "#A5C8E1", selected: "#2CB1CF", width: 2 },
    { key: "duration", color: "steelBlue", width: 2 },
]);

class StatsChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tracker: null,
            timeRange: null
        };
    }
    handleTimeRangeChange = timerange => {
        console.log(timerange);
        this.setState({ timerange });
    };

    render() {
        const {operatorStatsNodes, eventType, title} = this.props;

        const data = _.map(_.filter(operatorStatsNodes, ['eventType', eventType]), (node) => {
            return new TimeEvent(moment(node.day).toDate(), {
                answerLength: +node.avgAnswerLength,
                articleCount: +node.numberOfArticles,
                duration: +node.avgDuration
            });
        });

        const collection = new Collection(data);
        const sortedCollection = collection.sortByTime();
        const series = new TimeSeries({
            name: title,
            columns: ['time', 'answerLength', 'articleCount', 'duration'],
            collection: sortedCollection
        });

        const timeRange = this.state.timeRange || series.timerange();

        let durationValue = "--";
        let answerLengthValue = "--";
        let articleCountValue = "--";
        if (this.state.tracker) {
            const approx =
                (+this.state.tracker - +timeRange.begin()) /
                (+timeRange.end() - +timeRange.begin());
            const ii = Math.floor(approx * series.size());
            const i = series.bisect(new Date(this.state.tracker), ii);

            if(i < series.size() ) {
                const seriesPos = series.at(i);
                const vd = seriesPos.get('duration');
                const va = seriesPos.get('answerLength');
                const vac = seriesPos.get('articleCount');
                if (vd) {
                    durationValue = parseFloat(vd).toFixed(1);
                }
                if(va) {
                    answerLengthValue = parseFloat(va).toFixed(1);
                }
                if(va) {
                    articleCountValue = parseInt(vac, 10);
                }
            }
        }

        return (
            <div>
                <ChartContainer showGrid={true}
                                showGridPosition="under"
                                minDuration={86400000}
                                width={window.innerWidth -100}
                                timeRange={timeRange}
                                trackerPosition={this.state.tracker}
                                trackerTimeFormat="%X"
                                enablePanZoom={false}
                                onTrackerChanged={tracker => this.setState({ tracker })}
                                onTimeRangeChanged={this.handleTimeRangeChange}
                                padding={5}
                >
                    <ChartRow height="200">
                        <ValueAxis
                            id={`${eventType}_duration_valueaxis`}
                            value={durationValue}
                            detail={'secs'}
                            width={80}
                            min={0}
                            max={series.max('duration')}
                        />
                        <YAxis id={`${eventType}-axis1`} label="Time spent" min={0}
                               max={series.max('duration')} type="linear" format=",.1f"
                               style={style.axisStyle("duration")}
                        />

                        <Charts>
                            <LineChart label={'Time spent'} axis={`${eventType}-axis1`} series={series}
                                       columns={["duration"]}
                                       style={style}/>
                        </Charts>
                    </ChartRow>
                    <ChartRow height="200">
                        <ValueAxis
                            id={`${eventType}_answerLength_valueaxis`}
                            value={answerLengthValue}
                            detail={'chars'}
                            width={80}
                            min={0}
                            max={series.max('answerLength')}
                        />
                        <YAxis id={`${eventType}-axis2`}  label="Answer Length" min={0}
                               max={series.max('answerLength')}
                               type="linear"
                               format=",.1f"
                               style={style.axisStyle("answerLength")}
                        />
                        <Charts>
                            <LineChart label={'Number of chars in answer'} axis={`${eventType}-axis2`}  series={series}
                                       columns={["answerLength"]}

                                       style={style}/>
                        </Charts>
                    </ChartRow>
                    <ChartRow height="100">
                        <ValueAxis
                            id={`articleCount_valueaxis`}
                            value={articleCountValue}
                            detail={'articles'}
                            width={80}
                            min={0}
                            max={series.max('articleCount')}
                        />
                        <YAxis id={`${eventType}-axis3`}  label="Number of Articles" min={0}
                               max={series.max('articleCount')}
                               type="linear"
                               style={style.axisStyle("articleCount")}
                        />
                        <Charts>
                            <LineChart label={'Number of approved articles'} axis={`${eventType}-axis3`}  series={series}
                                       columns={["articleCount"]}
                                       style={style}/>
                        </Charts>
                    </ChartRow>
                </ChartContainer>
            </div>
        );
    }
}

export default StatsChart;
