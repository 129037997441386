import React from 'react';
import {Icon, Comment, Divider, Popup} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import MessageStatusIcon from '../MessageStatusIcon';

const _ = require('lodash');
const moment = require('moment');

const Messages = ( {admins,  messages, contactPersonName, contactPersonList }) => {

    const allMessages = _.map(_.orderBy(messages, ['timestamp'], ['desc']),
        (message) => {
            let avatarUrl = message.type === 'received' ?'/img/trainer.png' : '/img/admin.jpg';
            if(message.sender === contactPersonName || (contactPersonList && _.find(contactPersonList, (cp) => cp.fullname === message.sender))) {
                avatarUrl = '/img/trainer.png';
            } else {
                if(message.sender === 'Q&A' || message.sender === 'Q/A') {
                    avatarUrl = '/img/qa.png';
                } else {
                    const admin = admins && _.find(admins,  (admin) => admin.fullname === message.sender);

                    avatarUrl = (admin && admin.avatarByAvatar) ? admin.avatarByAvatar.url : avatarUrl;
                }
            }
            const alertStyle = { color: 'red', fontWeight: 'bolder'};
            return (
                <Comment as="div" key={message.id}>
                    <Comment.Avatar
                        src={avatarUrl}/>
                    <Comment.Content>
                        <Comment.Author
                            as="i">{message.sender}</Comment.Author>
                        <Comment.Metadata>
                            <MessageStatusIcon type={message.type} displayLabel={false} failReason={message.failreason}/>
                            <Popup
                                trigger={ <div>{moment(message.timestamp).fromNow()} </div>}
                                content={moment(message.timestamp).format('YYYY-MM-DD HH:mm')}
                                size='large'
                            />
                            <div>
                                <Icon name='info circle'/>
                                {message.questionid ? <Link
                                    to={`/article/${message.questionByQuestionid.articleByArticleid.id}`}>{message.questionByQuestionid.articleByArticleid.title}</Link> : message.about}
                            </div>
                        </Comment.Metadata>
                        <Comment.Text>
                            <p style={message.type === 'received' && message.text &&  message.text.search(/\bNEJ|\bANNAN COACH/gi) >=0 ? alertStyle : {}}>{message.text || message.type}</p>
                        </Comment.Text>
                    </Comment.Content>
                </Comment>)
        });

    return (
        <Comment.Group size='large' style={{textAlign: 'left'}}>
            {allMessages}
            <Divider/>
        </Comment.Group>
    );
};

export default Messages;
