import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Table, Header, Icon, Pagination } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import Info from '../components/Info';
const PAGE_SIZE = 10;

const UnidentifiedMessagesContainer = props => {
    const {
        data: { loading, error, allUnidentifiedSms },
        match: {
            params: { page },
        },
        history,
    } = props;

    if (error) {
        return <Info icon="warning" message={error.message} title="Error" loading={false} />;
    }

    if (loading) {
        return <Info icon="circle notched" message="Loading..." loading={true} />;
    }

    const pageNo = page ? parseInt(page, 10) : 1;
    const navigate = url => {
        history.push(`/trash-bin/list/${url}`);
    };
    const pagination = (
        <Table.Row>
            <Table.HeaderCell colSpan="3" style={{ textAlign: 'center' }}>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={pageNo}
                    ellipsisItem={null}
                    lastItem={null}
                    siblingRange={1}
                    prevItem={undefined}
                    nextItem={undefined}
                    totalPages={_.get(allUnidentifiedSms, 'nodes.length', 0) < PAGE_SIZE ? pageNo : pageNo + 1}
                    onPageChange={(e, data) => {
                        navigate(data.activePage);
                    }}
                />
            </Table.HeaderCell>
        </Table.Row>
    );

    return (
        <div>
            <Header as="h1" color="blue">
                Trash Bin
            </Header>
            <Table celled selectable color="blue">
                <Table.Header>
                    {pagination}
                    <Table.Row>
                        <Table.HeaderCell>Phone</Table.HeaderCell>
                        <Table.HeaderCell>Text</Table.HeaderCell>
                        <Table.HeaderCell>
                            Time <Icon name="sort descending" />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {allUnidentifiedSms.nodes.map(node => {
                        return (
                            <Table.Row key={node.id}>
                                <Table.Cell>{node.phone}</Table.Cell>
                                <Table.Cell>{node.text}</Table.Cell>
                                <Table.Cell>{moment(node.timestamp).format('YYYY-MM-DD HH:mm')}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
                <Table.Footer>{pagination}</Table.Footer>
            </Table>
        </div>
    );
};

const unidentifiedListQuery = gql`
    query UnidentifiedListQuery($limit: Int, $offset: Int, $region: Int) {
        allUnidentifiedSms(orderBy: [TIMESTAMP_DESC], first: $limit, offset: $offset, condition: {regionId: $region}) {
            pageInfo {
                endCursor
                startCursor
                hasNextPage
                hasPreviousPage
            }
            nodes {
                id
                timestamp
                text
                phone
            }
        }
    }
`;

const UnidentifiedMessageList = graphql(unidentifiedListQuery, {
    options: ownProps => ({
        fetchPolicy: 'network-only',
        variables: {
            offset: (ownProps.match.params.page ? parseInt(ownProps.match.params.page, 10) - 1 : 0) * PAGE_SIZE,
            limit: PAGE_SIZE,
            region: parseInt(localStorage.getItem('selected-region')|| '1', 10)
        },
    }),
})(UnidentifiedMessagesContainer);
export default UnidentifiedMessageList;
