import {Component} from "react";
import {
    Button,
    Form, Message,
    Segment
} from "semantic-ui-react";

import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import RemoveSignature from "./RemoveSignature";

const groupOptions = [
    { key: 'external_contact', value: 'external_contact', text: 'External Contact' },
    { key: 'group_messages', value: 'group_messages', text: 'Group Message' },
    { key: 'auto_onboard', value: 'auto_onboard', text: 'Opt-Out External Contact' },
];

const EDIT_SIGNATURE_MUTATION = gql`
    mutation updateSignatureById($input: EditSignatureInput!) {
        editSignature(input: $input){
            signature{
                id
                text
                title
                group
            }
        }
    }
`;

class EditSignatureContainer extends Component {
    constructor(props) {
        super(props);
        const {signature} =  props;
        this.state = {
            id: (signature && signature.id) || null,
            title: (signature && signature.title) || null,
            text: (signature && signature.text) || null,
            group: (signature && signature.group) || null,
            success: null,
            error: null
        };
    };
    handleChange(e, { name, value }) {
        this.setState({ [name]: value })
    };

    componentDidUpdate(prevProps) {
        const {signature} = this.props;
        if(prevProps.signature && prevProps.signature.id !== signature.id) {
            this.setState({
                id: (signature && signature.id) || null,
                title: (signature && signature.title) || null,
                text: (signature && signature.text) || null,
                group: (signature && signature.group) || null,
                success: null,
                error: null
            });
        }
    };
    async handleSignatureSubmit() {
        const {text, group, title} = this.state;
        this.props.updateSignature({
            variables: {
                input: {
                    signatureId: this.props.signature.id,
                    textInput: text,
                    groupInput: group,
                    titleInput: title
                }
            }
        }).then(() => {
            this.props.refetchQuery(false);
            this.setState({success: true});
            setTimeout(() => {
                this.setState({success: false});
            }, 3000);
        }).catch((err) => {
            this.setState({error: err.toString()});
            setTimeout(() => {
                this.setState({error: null});
            }, 5000);
        });
    };

    render() {
        const {signature,refetchQuery, adminId} = this.props;
        const { title, text, group, error, success } = this.state;

        return (
            <Segment>
                <Message
                    error
                    hidden={!error}
                    header='Signature could not be updated'
                    content={error}
                />
                <Message
                    success
                    hidden={!success}
                    header='Signature updated successfully!'
                    content='Signature updated'
                />
                <Form >
                    <Form.Group widths='equal'>
                        <Form.Input
                            name='title'
                            label='Title'
                            value={title}
                            placeholder='Title'
                            onChange={this.handleChange.bind(this)}
                        />
                        <Form.Select
                            name='group'
                            label='Group'
                            value={group}
                            onChange={this.handleChange.bind(this)}
                            options={groupOptions}
                        />
                    </Form.Group>
                    <Form.TextArea
                        name='text'
                        label='Text'
                        value={text}
                        rows={7}
                        onChange={this.handleChange.bind(this)}
                        placeholder='Text to be sent as sms...'
                    />
                    <Form.Group widths='equal'>
                        <span style={{marginTop: 'auto', marginLeft: 10, marginRight:10}}>You may use following keywords:</span>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$first_name'})}}>
                            $first_name
                        </Button>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$last_name'})}}>
                            $last_name
                        </Button>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$full_name'})}}>
                            $full_name
                        </Button>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$admin_name'})}} >
                            $admin_name
                        </Button>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$receiver_name'})}}>
                            $receiver_name
                        </Button>
                    </Form.Group>
                    <Form.Button content='Save' onClick={this.handleSignatureSubmit.bind(this)}/>
                    <RemoveSignature signature={signature} refetchQuery={refetchQuery} adminId={adminId}/>
                </Form>
            </Segment>
        );
    }
}


const EditSignature= ({signature, refetchQuery, adminId}) => ( <Mutation mutation={EDIT_SIGNATURE_MUTATION}>
    {(editSignature) => (
        <EditSignatureContainer adminId={adminId} signature={signature} updateSignature={editSignature} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default EditSignature;

