import gql from "graphql-tag";
import {useMutation, useQuery} from "react-apollo";
import React from "react";
import {Button, Icon, Label, List, Segment} from "semantic-ui-react";
import Info from "../Info";
import moment from 'moment'
import _ from 'lodash'

const MARK_ALL_READ_MUTATION = gql`
    mutation markAllAsRead($input: MarkMultipleNotificationsReadInput!) {
        markAsRead: markMultipleNotificationsRead(input: $input) {
            boolean
        }
    }
`;

const QUERY_ARTICLE_NOTIFICATIONS = gql`
    query notifications ($articleId: String!){
       allNotifications(condition: {articleId: $articleId, read: false }){
           nodes {
               id
               level
               read
               text
               timestamp
               type
           }
       }
    }
`;


const ArticleNotifications = ({articleId}) => {
    const [markAsRead] = useMutation(MARK_ALL_READ_MUTATION);
    const {loading, error, data} = useQuery(QUERY_ARTICLE_NOTIFICATIONS, { variables: {articleId}});

    if(loading) {
        return <Info icon="circle notched" message="Loading notifications..." loading={true} />;
    }
    if (error) {
        return <Info icon="warning" message={error.message} title="Error loading notifications" loading={false} />;
    }

    const notifications = _.map(data?.allNotifications?.nodes, (node) => {
        return (
            <List.Item as='a' key={node.id} active={!node.read}>
                <Icon name={node.level === 'WARNING' ? 'warning' : 'info '} color={node.level === 'WARNING' ? 'orange' : 'green'}/>
                <List.Content>
                    <List.Header>{moment(node.timestamp).format('YYYY-MM-DD HH:mm')}</List.Header>
                    <List.Description>
                        {node.text}
                    </List.Description>
                </List.Content>
            </List.Item>
        )
    })

    return (
        <Segment raised style={{textAlign: 'left'}}>
            <Label as='a' color='blue' ribbon style={{marginBottom: 15}}>Related Notifications</Label>

            <List>
                {notifications && notifications.length > 0  ? notifications : 'No notifications'}
            </List>
            {notifications && notifications.length > 0 ?
                <Button color='orange' content='Mark all as read' onClick={() => markAsRead({
                    variables: {
                        input: {inLevel: null, inArticle: articleId}
                    },
                    refetchQueries: [
                        {
                            query: QUERY_ARTICLE_NOTIFICATIONS,
                            variables:{articleId}
                        }
                    ]
                })}/>
            : ''}
        </Segment>
    )

};

export default ArticleNotifications;
