import gql from "graphql-tag";
import { useQuery} from "react-apollo";
import React, {useState} from "react";
import { Container, Grid, Header, Radio, Segment, Select} from "semantic-ui-react";
import Info from "../components/Info";
import ContactStats from "../components/stats/ContactStats";
import ExternalContactStats from "../components/stats/ExternalContactStats";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import FocusedTeamContactStats from "../components/stats/FocusedTeamContactStats";

const QUERY_CONSTANTS = gql`
    query constants ($region: Int){
        admins: filterAdmins(regionIn: $region) {
            nodes {
                id
                fullname
            }
        }
        sports: allSports {
            nodes {
                sportid
                sportname
            }
        }
    }
`;



const GenericStats = (props) => {
    const [activeNow, setActiveNow] = useState(false);
    const [sport, setSport] = useState(-1);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [admin, setAdmin] = useState(-1);
    const [deleted, setDeleted] = useState(false);
    const [onboarded, setOnboarded] = useState(true);

    const lr = localStorage.getItem('selected-region');
    const selectedRegion = lr ? parseInt(lr, 10) : null;


    const {loading, error, data} = useQuery(QUERY_CONSTANTS, { variables: {region: selectedRegion}});
    if(loading) {
        return <Info icon="circle notched" message="Loading data..." loading={true} />;
    }
    if (error) {
        return <Info icon="warning" message={error.message} title="Error loading data" loading={false} />;
    }

    const {sports, admins} = data;

    const sportsList = [
        {
            key: 'sport',
            value: -1,
            text: 'All sports',
        },
        ...sports.nodes.map(( node) => ({
            key: node.sportid,
            value: node.sportid,
            text: node.sportname,
        })),
    ];

    const adminList = [
        {
            key: 'admin',
            value: -1,
            text: 'All admins',
        },
        ...admins.nodes.map(( node) => ({
            key: node.id,
            value: node.id,
            text: node.fullname,
        })),
    ];

    return (
        <Segment raised style={{textAlign: 'left'}}>
            <Header as='h1' textAlign='center' color='blue'>
                GENERAL STATS
            </Header>

            <Segment fluid={true}>
                <Grid columns={16}>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            Select Sport: <Select label='SPORT' labeled={true} placeholder='Select a sport' options={sportsList}
                                value={sport || '-1'}
                                onChange={(event, {value}) => setSport(value)}/>
                            <span style={{marginLeft: 15}}/>
                            <SemanticDatepicker label={'Start Date: '} onChange={(event, data) => setFromDate(data.value)} />
                            <span style={{marginLeft: 15}}/>
                            <SemanticDatepicker label={'End Date: '} onChange={(event, data) => setToDate(data.value)} />

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>

                        </Grid.Column>
                        <Grid.Column width={8}>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Container fluid={true} >
                <Grid columns={16} padded={true} celled={true}>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Radio label={'Active Contacts Only'} value={activeNow} toggle onChange={()=> setActiveNow(!activeNow)} />
                            <ContactStats activeNow={activeNow} fromDate={fromDate} sport={sport >0 ? sport : null} toDate={toDate}/>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            Select Admin: <Select label='Admin' placeholder='Select an admin' options={adminList}
                                                  value={admin || '-1'}
                                                  onChange={(event, {value}) => setAdmin(value)}/>
                            <div style={{marginBottom:25, width:'100%'}}/>
                            <Radio label={'Onboarded Only'} value={onboarded} toggle onChange={()=> setOnboarded(!onboarded)} />
                            <Radio style={{marginLeft: 15}} label={'Deleted Only'} value={deleted} toggle onChange={()=> setDeleted(!deleted)} />
                            <ExternalContactStats onboarded={onboarded} deleted={deleted} admin={admin > 0 ? admin : null} fromDate={fromDate} sport={sport >0 ? sport : null} toDate={toDate}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <FocusedTeamContactStats fromDate={fromDate} sport={sport >0 ? sport : null} toDate={toDate}/>
                        </Grid.Column>
                        <Grid.Column width={8}>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>


            </Container>
        </Segment>
    )

};
export default GenericStats;
