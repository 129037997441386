import {Link} from "react-router-dom";
import ArticleStatusIcon from "../ArticleStatusIcon";
import {Button, Feed, Icon} from "semantic-ui-react";
import moment from "moment";
import React from "react";

const NotificationFeed = ({allNotifications, markRead, type}) => {

    const feedNotifications = allNotifications?.nodes?.filter((node => (type === 'all' || node.type === type))).map((node) => {
        let articleLink = null;
        if (node.articleByArticleId) {
            articleLink = <Link
                to={`/article/${node.articleByArticleId.id}`}> <ArticleStatusIcon
                    status={node.articleByArticleId.status}
                    displayLabel={false}/> {node.articleByArticleId.title}</Link>;
        }

        let contactPersonLink = null;

        if (node.contactpersonByContactId) {
            contactPersonLink =
                <Link to={`/contacts/${node.contactpersonByContactId.id}`}>
                    {node.contactpersonByContactId.title + ' ' + node.contactpersonByContactId.fullname}
                </Link>
        }

        let receiverLink = null;
        if (node.receiverByReceiverId) {
            receiverLink =
                <Link to={`/receivers/${node.receiverByReceiverId.id}`}>
                    {node.receiverByReceiverId.name}
                </Link>
        }
        return (
            <Feed.Event key={node.id}>
                <Feed.Label> <Icon name={node.level === 'WARNING' ? 'warning ' : 'info '}
                    color={node.level === 'WARNING' ? 'orange' : 'green'}/></Feed.Label>
                <Feed.Content>
                    <Feed.Date>{moment(node.timestamp).format('YYYY-MM-DD HH:mm')}</Feed.Date>
                    <Feed.Summary>
                        {contactPersonLink ? <span>Contact </span> : <span/>}
                        {contactPersonLink}
                        {articleLink ? <span> <br/>Article </span> : <span/>}
                        {articleLink}
                        {receiverLink ? <span> <br/>Receiver </span> : <span/>}
                        {receiverLink}
                    </Feed.Summary>
                    <Feed.Extra text>
                        {node.text}
                    </Feed.Extra>
                    <Feed.Meta>
                        <Feed.Like>
                            <Button style={{display: node.read ? 'none' : 'block'}} icon onClick={() => {
                                if (!node.read) {
                                    markRead({
                                        variables: {
                                            input: {notificationId: node.id}
                                        }
                                    });
                                }
                            }}><Icon name={'eye'}/>Mark as read</Button>
                        </Feed.Like>
                    </Feed.Meta>
                </Feed.Content>
            </Feed.Event>
        );
    });

    return <Feed size='small'>
        {feedNotifications}
    </Feed>;
}

export default NotificationFeed;
