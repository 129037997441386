import {
    Card,
} from 'semantic-ui-react';

import React from 'react';
import gql from 'graphql-tag';
import { Query} from 'react-apollo';
import Info from "./Info";
import {ExternalContactCardContainer} from "./external-contact/ExternalContactCard";
import _ from "lodash";
import ContactPersonCardContainer from "./contact-person/ContactPersonCardContainer";



const contactPersonQuery = gql`
    query ContactPersonQuery($phone: String!, $region: Int) {
        contacts: searchContactsByPhone( regionIn: $region, phoneIn: $phone) {
            nodes {
                id
                fullname
                title
                phone
                active
                drymode
                teamByTeamid {
                    id
                    name
                    leagueByLeagueid {
                        id
                        name
                    }
                }
            }
        }
        externalContacts: searchExternalContacts(
            searchText: $phone,
            limitNo: 3,
            regionIn: $region
        ) {
            nodes {
                id
                fullname
                note
                teamByTeamId {
                    id
                    name
                    logo
                    leagueByLeagueid {
                        name
                        id
                    }
                }
                adminByCreatedBy {
                    avatarByAvatar {
                        id
                        url
                    }
                    id
                    fullname
                }
                phone
                receiverByReceiver {
                    id
                    displayName
                    name
                    key
                }
            }
        }
    }
`;

const AllContactsByPhoneContainer = ({contacts, externalContacts}) => {
    return (<Card.Group itemsPerRow={3}>
        {_.map(_.get(contacts,'nodes'), (cp, index) => <ContactPersonCardContainer key={cp.id} contactPerson={cp} />)}
        {_.map(_.get(externalContacts,'nodes'), (cp, index) => <ExternalContactCardContainer key={cp.id} contactPerson={cp} />)}
    </Card.Group>);
}

const AllContactsByPhone = ({ phone, region }) => (
    <Query query={contactPersonQuery} variables={{ phone, region }}>
        {({ loading, error, data}) => {
            if (error) {
                return <Info icon="warning" message={error.message} title="Error" loading={false} />;
            }

            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true} />;
            }
            if(phone){
                return (
                    <AllContactsByPhoneContainer externalContacts={data.externalContacts} contacts={data.contacts}/>
                );
            }
            return (
                <div/>
            );
        }}
    </Query>
);

export default AllContactsByPhone;
