import { Component } from 'react'
import {
    Icon,
    Segment,
    Form,
    Message,
    Button
} from 'semantic-ui-react'

import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import CopySignature from "../CopySignature";
const _ = require('lodash');



const SEND_EXTERNAL_SMS_MUTATION = gql`
    mutation sendExternalSms($input: SendExternalSmsInput!) {
        sendExternalSms(input : $input) {
            externalSm {
                id
            }
        }
    }
`;

const templateToDraft = (template, fullName, adminName, receiver) => {
    let messageDraft = template;
    messageDraft =  _.replace(messageDraft, /\$full_name/g, fullName);
    messageDraft =  _.replace(messageDraft, /\$admin_name/g, adminName);
    messageDraft =  _.replace(messageDraft, /\$receiver_name/g, (receiver && receiver.displayName) || 'WARNING');

    const name = fullName;
    const lastSpace= name.lastIndexOf(' ');
    const firstSpace= name.indexOf(' ');
    const firstName = name.substring( 0, firstSpace > 0 ? firstSpace : name.length);
    const lastName = name.substring( lastSpace> 0 ? lastSpace : 0);
    messageDraft =  _.replace(messageDraft, /\$first_name/g, firstName);
    messageDraft =  _.replace(messageDraft, /\$last_name/g, lastName);
    return messageDraft;
};

class SendNewExternalSmsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageDraft: null,
            newMessage: null,
            sendExternalSmsFailed: false
        };
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleSendSms = this.handleSendSms.bind(this);
        this.handleSignatureChange = this.handleSignatureChange.bind(this);
        this.handleReceiverTemplate = this.handleReceiverTemplate.bind(this);
        this.copySignature = this.copySignature.bind(this);
    }

    handleMessageChange = event => {
        this.setState({ newMessage: event.target.value })
    };
    copySignature = ()=> {
        this.setState({ newMessage: this.state.messageDraft })
    };

    handleSendSms = async () => {
        const { sendExternalSms, externalContact, refetchQuery } = this.props;
        try {
            await sendExternalSms({
                variables: {
                    input: {message: this.state.newMessage, contact: externalContact.id}
                },
                refetchQueries: [
                    {
                        query: refetchQuery,
                        variables: { id: externalContact.id, adminId: JSON.parse(localStorage.getItem('current-admin')).id },
                    },
                ],
            });
            this.setState({newMessage: ''});
        } catch (err) {
            console.error(err);
            this.setState({sendExternalSmsFailed: true});
            setTimeout(() => {
                this.setState({sendExternalSmsFailed: false});
            }, 3000);
        }
    };

    handleSignatureChange = (value) => {
        const {externalContact, signatures} = this.props;
        const admin = JSON.parse(localStorage.getItem('current-admin'));
        let template = _.get(_.find(signatures, (s) =>  (s.id === value)), 'text');
        const messageDraft = templateToDraft(template, externalContact.fullname, admin.fullname, externalContact.receiverByReceiver);
        this.setState({messageDraft });
    };

    handleReceiverTemplate = () => {
        const {externalContact} = this.props;
        const admin = JSON.parse(localStorage.getItem('current-admin'));
        let template = externalContact.signatureByMessageTemplate;

        const messageDraft = templateToDraft(template.text, externalContact.fullname, admin.fullname, externalContact?.signatureByMessageTemplate?.receiverByReceiver);
        this.setState({newMessage: messageDraft });
    };



    render() {
        const { signatures, externalContact } = this.props;
        const warningStyle =  (this.state.newMessage && this.state.newMessage.indexOf('WARNING') > 0) ?  {float:'left'} : {display: 'none'};

        if (externalContact && externalContact.fullname) {
            const receiverTemplate = externalContact.signatureByMessageTemplate;

            return (
                <Segment color="yellow" style={{ textAlign: 'left' }}>
                    {
                        this.state.sendExternalSmsFailed ? <Message
                            warning
                            header='Message could not be sent.'
                            content='Please try again!'
                        /> : ''
                    }
                    <Form>
                        <Form.TextArea rows={10} label='New Message' placeholder={'Send a new message to ' + externalContact.fullname} value={this.state.newMessage} onChange={this.handleMessageChange}/>
                        <Segment basic style={{textAlign:'right'}}>
                            {<span style={warningStyle}><Icon name='warning circle' color='red'/>Message has warning!</span>}
                            <Form.Button color={'green'} onClick={this.handleSendSms}><Icon name='send'/>Send</Form.Button>
                        </Segment>
                    </Form>

                    {
                        receiverTemplate ? <Button icon={'star'} color={'green'} onClick={this.handleReceiverTemplate} label={'Click here to use receiver requested message'}/> : ''
                    }

                    <CopySignature
                        signatures={signatures}
                        onChangeSignature={this.handleSignatureChange}
                        onClick={this.copySignature}
                        selectedContact={externalContact}
                    />
                </Segment>
            )
        }
    }
}

const SendNewExternalSms = ({ externalContact, signatures, refetchQuery }) => (
    <Mutation mutation={SEND_EXTERNAL_SMS_MUTATION}>
        {sendExternalSms => (
            <SendNewExternalSmsContainer
                signatures={signatures}
                sendExternalSms={sendExternalSms}
                externalContact={externalContact}
                refetchQuery={refetchQuery}
            />
        )}
    </Mutation>
);

export default SendNewExternalSms;
