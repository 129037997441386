import React from 'react';
import {Menu, Icon, Header, Image, Dropdown} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import Profile from './profile/Profile';
import NotificationIcon from './NotificationIcon';
import moment from "moment";

class QnaAppBar extends React.Component {
    state = {activeItem: ''};

    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name !== 'undefined' ? name :  ' '});
    };

    render() {
        const {activeItem} = this.state;
        const currentAdmin = JSON.parse(localStorage.getItem('current-admin'));
        const today= moment().format('YYYY-MM-DD');
        return (
            <div style={{ paddingTop: 15,  backgroundColor : '#1e272e'}}>
                <Header as='h1' style={{color:'#4df775'}}>
                    <Image style={{width: 160, marginLeft: 30, marginBottom: 0, marginTop: 0, padding: 0}} floated='left' circular src='/ur-logo.png' />
                    Questions & Answers Service
                </Header>
                <Menu  inverted color='green' pointing secondary stackable size='large'>
                    <Menu.Item as={Link} to="/articles" name='articles' active={activeItem === 'articles'} onClick={this.handleItemClick}>
                        <Icon name='adn'/> Articles
                    </Menu.Item>
                    <Menu.Item as={Link} to={'/events/contact/'+today} name='events' active={activeItem === 'events'} onClick={this.handleItemClick}>
                        <Icon name='soccer'/> Events
                    </Menu.Item>
                    <Menu.Item as={Link} to="/contacts" name='contacts' active={activeItem === 'contacts'} onClick={this.handleItemClick}>
                        <Icon name='address book outline'/> Contacts
                    </Menu.Item>
                    <Menu.Item as={Link} to="/messages" name='messages' active={activeItem === 'messages'} onClick={this.handleItemClick}>
                        <Icon name='comments outline'/> Messages
                    </Menu.Item>
                    <Menu.Item as={Link} to="/external-contacts" name='external-contacts' active={activeItem === 'external-contacts'} onClick={this.handleItemClick}>
                        <Icon name='address book'/> External Contacts
                    </Menu.Item>
                    <Menu.Item as={Link} to="/external-messages" name='external-messages' active={activeItem === 'external-messages'} onClick={this.handleItemClick}>
                        <Icon name='comment alternate outline'/> External Messages
                    </Menu.Item>
                    <Dropdown item text='More' labeled={false} pointing={"top"}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/receivers/list" onClick={this.handleItemClick} icon='newspaper outline' text='Article Receivers' />
                            <Dropdown.Item as={Link} to="/teams/" onClick={this.handleItemClick} icon='world' text='Teams' />
                            <Dropdown.Item as={Link} to="/focused-teams/" onClick={this.handleItemClick} icon='bullseye' text='Focused Teams' />
                            <Dropdown.Item as={Link} to="/all-stats/" onClick={this.handleItemClick} icon='chart line' text='Stats' />
                            <Dropdown.Item as={Link} to="/trash-bin/list" onClick={this.handleItemClick} icon='trash alternate outline' text='Trash Bin'/>
                            {
                                currentAdmin && currentAdmin.level >= 3 ?
                                    <Dropdown.Item as={Link} to="/administration" onClick={this.handleItemClick}
                                                   icon='settings' text='Site Settings'/> : ''
                            }
                            {
                                currentAdmin && currentAdmin.level >= 3 ?
                                    <Dropdown.Item as={Link} to="/customer-stats" onClick={this.handleItemClick}
                                                   icon='chart pie' text='Customer Stats'/> : ''
                            }
                            {
                                currentAdmin && currentAdmin.level >= 3 ?
                                    <Dropdown.Item as={Link} to="/kpi" onClick={this.handleItemClick}
                                                   icon='tasks' text='KPI Stats'/> : ''
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <Profile menuClick={this.handleItemClick}/>
                    <NotificationIcon menuClick={this.handleItemClick}/>
                </Menu>

            </div>
        );
    }
}

export default QnaAppBar;
