import React from 'react';
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import './fonts/FiraSans-Regular.ttf'
import './fonts/FiraSans-Black.ttf'
import './fonts/FiraSans-Bold.ttf'
import './fonts/FiraSans-BoldItalic.ttf'
import './fonts/FiraSans-ExtraBold.ttf'
import './fonts/FiraSans-ExtraBoldItalic.ttf'
import './fonts/FiraSans-ExtraLight.ttf'
import './fonts/FiraSans-ExtraLightItalic.ttf'
import './fonts/FiraSans-Italic.ttf'
import './fonts/FiraSans-Light.ttf'
import './fonts/FiraSans-LightItalic.ttf'
import './fonts/FiraSans-Medium.ttf'
import './fonts/FiraSans-MediumItalic.ttf'
import './fonts/FiraSans-SemiBold.ttf'
import './fonts/FiraSans-SemiBoldItalic.ttf'
import './fonts/FiraSans-Thin.ttf'
import './fonts/FiraSans-ThinItalic.ttf'

import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import {unregister} from './registerServiceWorker';


render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('root'));
unregister();
