import React, {Component} from 'react';
import {graphql, withApollo} from "react-apollo";
import gql from "graphql-tag";
import {
    Table,
    Header,
    Icon,
    Segment,
    Button,
    Grid,
    Form,
    Select,
    Checkbox,
    Pagination, Modal,
    Input
} from 'semantic-ui-react'
import Info from '../components/Info';
import AddExternalContact from '../components/external-contact/AddExternalContact';
import compose from "lodash.flowright";
import {Link} from "react-router-dom";
import SendNewBulkMessages from "../components/external-contact/SendNewBulkMessages";
import AllContactsByPhone from "../components/AllContactsByPhone";

const moment = require('moment');
const _ = require('lodash');


const PAGE_SIZE = 50;

class ExternalContactListContainer extends Component {
    constructor(props) {
        super(props);
        const settings = JSON.parse(localStorage.getItem('external-contact-settings')) || {};
        this.state = {
            fetchingMessages: false,
            externalSms: [],
            signatures: [],
            addOpen: false,
            settings,
            searchText: settings.searchText,
            selectedContacts: [],
            openSendSmsModal: false,
            phone: ''
        };
    }

    toggleSettings(refetch, filter, value) {

        this.setState({selectedContacts: []});
        this.setState({openSendSmsModal: false});

        const {settings, searchText} = this.state;

        if (filter === 'all') {
            settings.isDeleted = null;
            settings.isOnboarded = null;
        } else if (filter === 'active') {
            settings.isDeleted = false;
            settings.isOnboarded = false;
        } else if (filter === 'deleted') {
            settings.isDeleted = true;
            settings.isOnboarded = false;
        } else if (filter === 'onboarded') {
            settings.isDeleted = false;
            settings.isOnboarded = true;
        } else if (filter === 'mine') {
            settings.isMine = value
        } else if (filter === 'search') {
            settings.searchText = searchText && searchText.length > 0 ? searchText : null;
        } else if (filter === 'receiver') {
            settings.receiverId = value && value > 0 ? value : null;
        }
        localStorage.setItem('external-contact-settings', JSON.stringify(settings));


        if (this.props.match.params.page !== 0) {
            this.props.history.push('/external-contacts');
        }

        refetch(getExternalContactListQueryVariables(this.props));
    };

    render() {
        const {
            data: {
                loading,
                error,
                allExternalContacts,
                allReceivers,
                allSports,
                allSignatures,
                refetch
            },
            match: {params: {page}},
            history,
        } = this.props;
        const {
            settings,
            selectedContacts,
            openSendSmsModal
        } = this.state;
        const pageNo = page ? parseInt(page, 10) : 1;
        const navigate = url => {
            history.push(`/external-contacts/${url}`)
        };

        if (error) {
            return <Info icon="warning" message={error.message} title="Error" loading={false}/>;
        }

        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }

        const receiverOptions = _.map(_.orderBy(allReceivers.nodes, 'name'), r => ({ key:r.key, value: r.id, text: `${r.name} ( ${r.key} )`}));

        // const receiverOptions = _.map(allReceivers.nodes, r => ({
        //     key: r.key,
        //     value: r.id,
        //     text: (r.displayName && r.displayName.length > 0) ? r.displayName : r.name
        // }));
        receiverOptions.splice(0, 0, {key: '-1', value: '-1', text: 'ALL'});

        const pagination = (
            <Table.Row>
                <Table.HeaderCell colSpan="14" style={{textAlign: 'center'}}>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={pageNo}
                        ellipsisItem={null}
                        lastItem={null}
                        siblingRange={1}
                        prevItem={undefined}
                        nextItem={undefined}
                        totalPages={
                            _.get(allExternalContacts, 'nodes.length', 0) < PAGE_SIZE
                                ? pageNo
                                : pageNo + 1
                        }
                        onPageChange={(e, data) => {
                            navigate(data.activePage)
                        }}
                    />
                </Table.HeaderCell>
            </Table.Row>
        )

        const selectedContactsUIHandler = selectedContacts.length > 0 && (
            <Table.Row>
                <Table.HeaderCell colSpan="14" style={{ textAlign: 'left' }}>
                    selected {selectedContacts.length} contacts
                    <Modal
                        onClose={() => {
                            this.setState({ openSendSmsModal: false});
                        }}
                        open={openSendSmsModal}
                        closeOnEscape={true}
                        closeOnDimmerClick={false}
                        trigger={
                            <Button
                                onClick={() => {
                                    this.setState({ openSendSmsModal: true});
                                }}
                                style={{
                                    marginLeft: 10,
                                }}
                                color="green"
                            >
                                <Icon name="send" />
                                Send Message
                            </Button>
                        }
                    >
                        <Modal.Header>Send message</Modal.Header>
                        <Modal.Content>
                            {selectedContacts.length && (
                                <SendNewBulkMessages
                                    contactPersonIds={selectedContacts}
                                    contactPersons={allExternalContacts.nodes
                                        .filter(c => selectedContacts.includes(c.id))
                                    }
                                    removeContact={(contactId) => {
                                        this.setState({ selectedContacts: selectedContacts.filter(cId => cId !== contactId)})
                                    }}
                                    onSuccess={() => {
                                        this.setState({ openSendSmsModal: false});
                                        this.setState({ selectedContacts: []})
                                    }}
                                    onFail={() => {}}
                                />
                            )}
                        </Modal.Content>
                    </Modal>
                </Table.HeaderCell>
            </Table.Row>
        );
        return (
            <Grid width={16} stackable centered padded>
                <Grid.Row stretched>
                    <Segment basic={true} style={{width: '90%'}}>
                        <Segment basic={true}>
                            <Input action={{
                                color: 'teal',
                                labelPosition: 'left',
                                icon: 'search',
                                content: 'Search all contacts by phone:',
                            }} defaultValue={this.state.phone} actionPosition={'left'} placeholder='Phone number...' onChange={(e, data) => {this.setState({phone:data.value && data.value.replace(/[^\d\\+]/g, '').replace(/^0+(?!$)/, '')})}}/>
                        </Segment>
                        {
                            this.state.phone.length > 5 ?<AllContactsByPhone phone={this.state.phone } region={parseInt(localStorage.getItem('selected-region')|| '1')}/> :<br/>
                        }
                    </Segment>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={12}>
                    </Grid.Column>
                    <Grid.Column width={4} stretched={false}>
                        <AddExternalContact
                            onClose={() => {
                                this.setState({addOpen: false});
                            }}
                            onOpen={() => {
                                this.setState({addOpen: true});
                            }}
                            open={this.state.addOpen}
                            receivers={allReceivers.nodes}
                            sports={allSports.nodes}
                            signatures={allSignatures.nodes}
                            onSubmit={(input) => {
                                let formatted;
                                let phoneStr = input.phone.replace(/(?!^[+])[^0-9]/g, '');
                                if (phoneStr.startsWith('00')) {
                                    formatted = '+' + phoneStr.substring(2);
                                } else if (phoneStr.startsWith('0')) {
                                    formatted = '+46' + phoneStr.substring(1);
                                } else {
                                    formatted = phoneStr;
                                }
                                input.phone = formatted;

                                return this.props.addExternalContact({
                                    variables: {
                                        input,
                                    },
                                });
                            }}
                        />
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row stretched>
                    <Segment color='blue' style={{width: '90%'}}>
                        <Header as='h1' textAlign='center' color='blue'>
                            EXTERNAL CONTACTS
                        </Header>
                        <div style={{width: '90%'}}>
                            <Segment style={{display: 'flex', alignItems: 'center'}} basic floated='left'>
                                <Button.Group>
                                    <Button style={{margin: 5}} icon='circle' label='All'
                                        color={settings && settings.isDeleted === null && settings.isOnboarded === null ? 'blue' : 'grey'}
                                        onClick={() => {
                                            this.toggleSettings(refetch, 'all')
                                        }}/>
                                    <Button style={{margin: 5}} icon='plus' label='Active'
                                        color={settings && settings.isDeleted === false && settings.isOnboarded === false ? 'yellow' : 'grey'}
                                        onClick={() => {
                                            this.toggleSettings(refetch, 'active')
                                        }}/>
                                    <Button style={{margin: 5}} icon='x' label='Deleted'
                                        color={settings && settings.isDeleted === true && settings.isOnboarded === false ? 'red' : 'grey'}
                                        onClick={() => {
                                            this.toggleSettings(refetch, 'deleted')
                                        }}/>
                                    <Button style={{margin: 5}} icon='check' label='Onboarded'
                                        color={settings && settings.isDeleted === false && settings.isOnboarded === true ? 'green' : 'grey'}
                                        onClick={() => {
                                            this.toggleSettings(refetch, 'onboarded')
                                        }}/>
                                </Button.Group>

                                <span style={{marginLeft: 5, marginRight: 10}}> <b>|</b> </span>
                                <Select label='Receiver' placeholder='Select a receiver' options={receiverOptions}
                                    value={settings.receiverId || '-1'}
                                    onChange={(event, {value}) => this.toggleSettings(refetch, 'receiver', value)}/>
                                <span style={{marginLeft: 5, marginRight: 10}}> <b>|</b> </span>
                                <Form onSubmit={() => {
                                    this.toggleSettings(refetch, 'search')
                                }}>
                                    <Form.Input
                                        onChange={(e) => {
                                            this.setState({searchText: e.target.value})
                                        }}
                                        placeholder='Search...'
                                        color={settings && settings.searchText && settings.searchText.length > 0 ? 'green' : 'grey'}
                                        value={this.state.searchText || ''}
                                    />
                                </Form>
                                <Button as='button'
                                    onClick={() => {
                                        this.toggleSettings(refetch, 'search')
                                    }}
                                    content='Search'/>

                            </Segment>
                            <Segment basic floated='right'>
                                <Checkbox style={{margin: 5}} toggle label='Just display my conversations' onChange={() => {
                                    this.toggleSettings(refetch, 'mine', !(settings && settings.isMine))
                                }} checked={settings && settings.isMine}/>
                            </Segment>
                        </div>
                        <Table style={{width: '100%'}} celled collapsing striped selectable stackable>
                            <Table.Header>
                                {pagination}
                                {selectedContactsUIHandler}
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <Checkbox
                                            onChange={() => {
                                                if (selectedContacts.length === allExternalContacts.nodes.length) {
                                                    this.setState({selectedContacts: []});
                                                } else {
                                                    this.setState({selectedContacts: allExternalContacts.nodes.map((node) => node.id)});
                                                }
                                            }}
                                            checked={selectedContacts.length === allExternalContacts.nodes.length}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Title</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Phone</Table.HeaderCell>
                                    <Table.HeaderCell>Note</Table.HeaderCell>
                                    <Table.HeaderCell>Receiver</Table.HeaderCell>
                                    <Table.HeaderCell>Team</Table.HeaderCell>
                                    <Table.HeaderCell>Moderation</Table.HeaderCell>
                                    <Table.HeaderCell>Last Message</Table.HeaderCell>
                                    <Table.HeaderCell>Total SMS</Table.HeaderCell>
                                    <Table.HeaderCell/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {allExternalContacts.nodes.map((node) => {
                                    return (
                                        <Table.Row key={node.id}>
                                            <Table.Cell>
                                                <Checkbox
                                                    onChange={() => {
                                                        if (selectedContacts.includes(node.id)) {
                                                            this.setState({selectedContacts: selectedContacts.filter(cId => cId !== node.id)});
                                                        } else {
                                                            this.setState({selectedContacts: [...selectedContacts, node.id]});
                                                        }
                                                    }}
                                                    checked={selectedContacts.includes(node.id)}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>{node.title}</Table.Cell>
                                            <Table.Cell><Link
                                                to={'/external-contact/' + node.id}> {node.fullname}</Link></Table.Cell>
                                            <Table.Cell>{node.phone}</Table.Cell>
                                            <Table.Cell>{node.note}</Table.Cell>
                                            <Table.Cell>{node.receiverByReceiver && (node.receiverByReceiver.displayName || node.receiverByReceiver.name)}</Table.Cell>
                                            <Table.Cell>{node.teamByTeamId && node.teamByTeamId.name}</Table.Cell>
                                            <Table.Cell>Created by {node.adminByCreatedBy.fullname}
                                                <br/> on {moment(node.createdAt).format('MMM Do, h:mm a')}</Table.Cell>
                                            <Table.Cell>{node.lastMessageTime && moment(node.lastMessageTime).format('MMM Do, h:mm a')}</Table.Cell>
                                            <Table.Cell>{node.externalSmsByContact.totalCount}</Table.Cell>
                                            <Table.Cell><Link to={'/external-contact/' + node.id}> <Icon
                                                name='angle double right'/></Link> </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            <Table.Footer>
                                {pagination}
                                {selectedContactsUIHandler}
                            </Table.Footer>
                        </Table>
                    </Segment>
                </Grid.Row>
            </Grid>
        );
    }
}

const externalContactListQuery = gql`
    query ExternalContacts(
        $isDeleted: Boolean,
        $isOnboarded: Boolean,
        $admin: Int,
        $searchText: String,
        $receiverId: Int,
        $limitNo: Int,
        $offsetNo: Int
        $region: Int
    ) {
        allExternalContacts: filterExternalContacts3(
            isDeleted: $isDeleted,
            isOnboarded: $isOnboarded,
            admin: $admin,
            searchText: $searchText,
            receiverId: $receiverId,
            limitNo: $limitNo,
            offsetNo: $offsetNo
            regionIn: $region
        ) {
            totalCount
            nodes {
                id
                fullname
                phone
                note
                createdBy
                receiver
                title
                seasonId
                receiverByReceiver {
                    id
                    name
                    displayName
                }
                createdAt
                deleted
                onboarded
                lastMessageTime
                teamId
                teamByTeamId {
                    id
                    name
                    leagueByLeagueid {
                        id
                        sportid
                    }
                }
                adminByCreatedBy {
                    id
                    fullname
                    avatarByAvatar {
                        id
                        url
                    }
                }
                externalSmsByContact {
                    totalCount
                }
            }
        }
        allReceivers (condition: { visible: true}){
            nodes {
                id
                displayName
                name
                key
            }
        }
        allExternalSms(first: 50, condition: { type: "received"}, orderBy: [SENT_AT_DESC, CREATED_AT_DESC]) {
            nodes {
                id
                text
                type
                sentAt
                sender
                numberoftries
                failreason
                externalContactByContact {
                    id
                }
            }
        }
        allAdmins{
            nodes {
                id
                fullname
                avatarByAvatar {
                    id
                    url
                }
            }
        }
        allSports{
            nodes {
                endDate
                startDate
                id
                sportid
                sportname
            }
        }
        allSignatures(condition: {group: "auto_onboard"}){
            nodes {
                id
                title
            }
        }
    }
`;

const ADD_EXTERNAL_CONTACT_MUTATION = gql`
    mutation addExternalContact($input: AddExternalContact7Input!) {
        addExternalContact: addExternalContact7(input : $input) {
            externalContact {
                fullname
                id
                note
                phone
                createdAt
                title
            }
        }
    }
`;

function getExternalContactListQueryVariables(ownProps) {
    const settings = JSON.parse(localStorage.getItem('external-contact-settings')) || {};
    const admin = JSON.parse(localStorage.getItem('current-admin'));
    const region = parseInt(localStorage.getItem('selected-region')|| '1')
    return {
        offsetNo: (ownProps.match.params.page ? parseInt(ownProps.match.params.page, 10) - 1 : 0) * PAGE_SIZE,
        limitNo: PAGE_SIZE,
        isDeleted: (settings && settings.isDeleted),
        isOnboarded: (settings && settings.isOnboarded),
        admin: (settings && settings.isMine && admin && admin.id) || null,
        searchText: (settings && settings.searchText) || null,
        receiverId: (settings && settings.receiverId && parseInt(settings.receiverId, 10)) || null,
        region
    };
}

const ExternalContactList = withApollo(
    compose(
        graphql(externalContactListQuery, {
            options: (ownProps) => ({
                variables: getExternalContactListQueryVariables(ownProps),
                fetchPolicy: 'network-only',
                pollInterval: 480000,
            }),
        }),
        graphql(ADD_EXTERNAL_CONTACT_MUTATION, {
            name: 'addExternalContact',
            options: (ownProps) => ({
                refetchQueries: [{
                    query: externalContactListQuery,
                    variables: getExternalContactListQueryVariables(ownProps)
                }],
            })
        }),
    )(ExternalContactListContainer)
);


export default ExternalContactList;
