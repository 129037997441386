import React, {createRef} from 'react';
import {graphql, Query} from "react-apollo";
import gql from "graphql-tag";
import {Button, Label, Checkbox, Ref, Sticky, Grid, Header, Segment, Select, Table} from 'semantic-ui-react'
import Info from '../components/Info';
import * as _ from "lodash";

import ContactDetailStats from "../components/stats/ContactDetailStats";

class ContactStatsContainer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedSport: null,
            selectedClass: null,
            onlyActiveContact: true,
            onlyActiveNow: false,
            selectedContact: null,
            seasonOnly: true
        };
        this.contextRef = createRef()
    }

    search() {
        const {refetch} = this.props;
        const {selectedSport, selectedClass, onlyActiveContact, onlyActiveNow} = this.state;
        refetch({
            sport: selectedSport,
            tclass: selectedClass,
            activeContact: onlyActiveContact,
            activeNow: onlyActiveNow
        });

    }


    render() {
        const {isLoading, error, sports, contacts, refetch} = this.props;
        const {selectedContact, selectedSport, selectedClass, onlyActiveContact, onlyActiveNow, seasonOnly} = this.state;
        const sportOptions = _.map(_.orderBy(sports?.nodes, 'name'), r => ({ key:r.sportid, value: r.sportid, text: `${r.sportname}`}));
        sportOptions.splice(0, 0, { key: '-1', value: null, text: 'ALL'});

        const classOptions = [{key: 'all', value: null, text: 'ALL'}, {key: 'herr', value: 'herr', text: 'Men'},{key: 'dam', value: 'dam', text: 'Women'},{key: 'mix', value: 'mix', text: 'Mix'}]

        if (error) {
            return <Info icon="warning" message={error.message} title="Error" loading={false}/>;
        }

        if (isLoading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }

        return (
            <div>
                <Ref innerRef={this.contextRef}>
                    <Grid width={16} stackable centered padded>
                        <Grid.Row stretched>
                            <Header as='h1' textAlign='center' color='blue'>
                                CONTACT STATS
                            </Header>
                            <div style={{width: '90%'}}>
                                <Segment style={{display: 'flex', alignItems: 'center'}} basic floated='left'>
                                    <Select label='Sport' value={selectedSport} placeholder='ALL' options={sportOptions}
                                            onChange={(e, {value})=> this.setState({selectedSport: value})}/>

                                    <Select label='Class' value={selectedClass } placeholder='ALL' options={classOptions}
                                            onChange={(e, {value})=> this.setState({selectedClass: value})}/>

                                    <Checkbox toggle style={{margin: 5}} label='Active Contact'
                                              checked={onlyActiveContact}
                                              color={onlyActiveContact ? 'green' : 'grey'}
                                              onClick={() => {
                                                  this.setState({onlyActiveContact: !onlyActiveContact})
                                              }}/>
                                    <Checkbox toggle style={{margin: 5}} label='Active League'
                                              checked={onlyActiveNow}
                                              color={onlyActiveNow ? 'green' : 'grey'}
                                              onClick={() => {
                                                  this.setState({onlyActiveNow: !onlyActiveNow})
                                              }}/>

                                    <Button as='button'
                                            onClick={() => {
                                                refetch({
                                                    sport: selectedSport,
                                                    tclass: selectedClass,
                                                    activeContact: onlyActiveContact,
                                                    activeNow: onlyActiveNow
                                                });
                                            }}
                                            content='Search'/>
                                </Segment>
                            </div>

                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                <Table style={{width: '100%'}} celled collapsing striped selectable stackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>#</Table.HeaderCell>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Phone</Table.HeaderCell>
                                            <Table.HeaderCell>Whitelisted</Table.HeaderCell>
                                            <Table.HeaderCell>Team</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {contacts?.nodes.map((node, index) => {
                                            return (
                                                <Table.Row key={node.id} onClick={() => this.setState({selectedContact : node})} positive={selectedContact?.id == node.id}>
                                                    <Table.Cell>{index+1}</Table.Cell>
                                                    <Table.Cell>{node.id}</Table.Cell>
                                                    <Table.Cell>{`${node.title} ${node.fullname}`}</Table.Cell>
                                                    <Table.Cell>{node.phone}</Table.Cell>
                                                    <Table.Cell>{node.level > 0 ? 'Y' : 'N'}</Table.Cell>
                                                    <Table.Cell>{node.teamByTeamid?.name + '-' + node.teamByTeamid?.leagueByLeagueid?.name + '-'+ node.teamByTeamid?.leagueByLeagueid?.teamclass +'-'+node.teamByTeamid?.leagueByLeagueid?.sportname}</Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column width={4}>

                                <Sticky context={this.contextRef}>
                                    <Grid>
                                        <Grid.Row >
                                            <Grid.Column width={4}>
                                                <Label color={"red"} pointing={'right'} size={"large"}>Period: </Label>
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                                <Checkbox
                                                    toggle
                                                    label={seasonOnly ? 'This season' : 'Last month'}
                                                    checked={seasonOnly}
                                                    onChange={(e, {value}) => this.setState({seasonOnly:!seasonOnly})}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    {
                                        selectedContact ?
                                            <ContactDetailStats forSeason={seasonOnly} contact={selectedContact}/>
                                            :
                                            <Segment>Please select a contact</Segment>
                                    }
                                </Sticky>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Ref>
            </div>
        );
    }
};

const contactStatsQuery = gql`
    query ContactStatsQuery($sport: Int, $tclass: String, $activeContact: Boolean, $activeNow: Boolean, $region: Int) {
        allSports{
            nodes {
                id
                sportid
                sportname
            }
        }
        contacts: filterContactsForStats(sportId: $sport, teamClass: $tclass, activeContact: $activeContact, activeNow: $activeNow, regionIn: $region) {
            nodes {
                id
                active
                drymode
                fullname
                level
                phone
                title
                registrationdate
                teamByTeamid{
                    id
                    name
                    leagueByLeagueid{
                        id
                        name
                        sportname
                        teamclass
                    }
                }
            }
        }
    }
`;


const ContactStats = () => ( <Query query={contactStatsQuery} variables={{activeContact: true, region: parseInt(localStorage.getItem('selected-region')|| '1')}}>
    {({ data, refetch, loading, error }) => {
        return <ContactStatsContainer isLoading={loading} refetch={refetch} error={error} sports={data?.allSports} contacts={data?.contacts}/>
    }}
</Query>);


export default ContactStats;
