import React, {useState} from "react";

import {Button, Radio, Flag, Header, Icon, Form, Modal} from "semantic-ui-react";

import _ from "lodash";

const SelectRegionModal = ( {regions}) => {
    const [open, setOpen] = useState(false)
    const [selectedRegionId, setSelectedRegionId] = useState(parseInt(localStorage.getItem('selected-region')|| '1'))
    const selectRegionName = _.result(_.find(regions, (r) => r?.regionByRegionId?.id === selectedRegionId), 'regionByRegionId.name');
    const handleChange = (e, { value }) => setSelectedRegionId(value);


    const regionOptions = _.map(regions, (r) => (<Form.Field key={r.regionByRegionId.id}>
        <Flag name={r.regionByRegionId.name.toLowerCase()}/>
        <Radio
            label={r.regionByRegionId.name}
            name='radioGroup'
            value={r.regionByRegionId.id}
            checked={selectedRegionId === r.regionByRegionId.id}
            onChange={handleChange}
        />
    </Form.Field>));

    return(
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
            trigger={<Button color='green' onClick={() => setOpen(true)}>
                <Flag name={selectRegionName && selectRegionName.toLowerCase()} /> {selectRegionName}
            </Button>}
        >
            <Header icon>
                <Flag name={selectRegionName && selectRegionName.toLowerCase()} /> Change region
            </Header>
            <Modal.Content scrolling={false}>
                <Form>
                    <Form.Field>
                        Select region
                    </Form.Field>
                    {regionOptions}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' inverted onClick={() => {localStorage.setItem('selected-region', selectedRegionId); setOpen(false); window.location.reload(false);}}>
                    <Icon name='checkmark' /> Select Region
                </Button>
                <Button color='red' inverted onClick={() => setOpen(false)}>
                    <Icon name='close' /> Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default SelectRegionModal;
