import React, {Component, createRef}from 'react'
import IdleTimer from 'react-idle-timer'
import gql from 'graphql-tag';
import {
    Label,
    Segment,
    Grid,
    Dimmer,
    Ref,
    Card, Image
} from 'semantic-ui-react'
import {withApollo, graphql} from 'react-apollo';
import {Link, withRouter, Prompt} from 'react-router-dom';
import moment from 'moment'
import _ from 'lodash'

import Question from './Question';
import Messages from '../messages/Messages';
import ArticleStatusIcon from '../ArticleStatusIcon';
import LocksByArticle from '../LocksByArticle';
import LockControlers from './LockControlers';
import compose from "lodash.flowright";
import ContactPersonCard from "../contact-person/ContactPersonCard";
import ArticleNotifications from "../notification/ArticleNotifications";

class ArticleContainer extends Component{

    constructor(props){
        super(props);
        this.state = {
            activeTime: 0,
        };
        this.idleTimer = null;
        this.onActive = this.onActive.bind(this);
        this.onIdle = this.onIdle.bind(this);
        this.contextRef = createRef()
    }

    componentDidMount() {
        this.setState({startTime: new Date()});
    }

    async componentWillUnmount() {
        const elapsedTime = Math.floor((new Date() - this.state.startTime)/1000);
        if(elapsedTime > 15) {
            await this.props.logTime({
                variables: {
                    input: {
                        articleId: this.props.data.article.id,
                        eventType: 'PAGE_VIEW',
                        duration: elapsedTime
                    }
                }});
        }

        console.log('activeTime', this.state.activeTime);
        if(this.state.activeTime > 25) {
            await this.props.logTime({
                variables: {
                    input: {
                        articleId: this.props.data.article.id,
                        eventType: 'USER_ACTIVE',
                        duration: this.state.activeTime
                    }
                }});
        }

        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    onActive() {
        console.log('user is active');
        if(this.idleTimer) {
            this.setState( { activeTime:  Math.floor(this.idleTimer.getElapsedTime()/1000)});
        }
    }
    async onIdle(e) {
        this.setState( { activeTime:  Math.floor(this.idleTimer.getElapsedTime()/1000)});
    }
    renderLatestMessages(contactPerson) {
        const admins = this && this.props && this.props.data && this.props.data.allAdmins &&  this.props.data.allAdmins.nodes;

        return (<Segment key={contactPerson.id+'messages'} raised style={{textAlign: 'left'}}>
            <Label as='a' color='blue' ribbon style={{marginBottom: 15}}>Latest 30 messages
                with {contactPerson && contactPerson.fullname}</Label>
            <Messages admins={admins} messages={contactPerson && contactPerson.messagesByContactpersonid.nodes}
                      contactPersonName={contactPerson && contactPerson.fullname}/>
        </Segment>);
    }

    render() {
        const {
            data: {
                loading,
                error,
                article,
                allAdmins
            },
            lockArticle,
            unlockArticle,
            sendSms,
            approveAnswer,
            ignoreAnswer,
            snoozeModeration,
            administrationComplete,
            sendModeration,
            disapproveAnswer,
            blockAnswer,
            addNote,
            removeNote,
            logTime,
            history,
        } = this.props;

        if (loading) {
            return <p>Loading ...</p>;
        }
        if (error) {
            return <p>{error.message}</p>;
        }

        const contactPerson =  _.find(article.teamByFocusedteam.contactpeopleByTeamid.nodes,{ id: _.get(article, 'questionsByArticleid.nodes[0].answeredBy')});
        const contactPersonList = _.filter(article.teamByFocusedteam.contactpeopleByTeamid.nodes, {'active': true});

        let articleText = article.article;

        if (article.status === 'approved') {
            let alternativeContact;
            if (article.questionsByArticleid && article.questionsByArticleid.nodes && article.questionsByArticleid.nodes[0]) {
                const firstQuestion = article.questionsByArticleid.nodes[0];
                const firstAnswer = firstQuestion.answer;
                alternativeContact = firstQuestion.alternativeContactByAlternativeContact || {};
                articleText = articleText.replace('$answer_text', firstAnswer);
            }
            if (contactPerson) {
                articleText = articleText.replace('$answer_person', (alternativeContact.title ||  contactPerson.title) + ' ' + (alternativeContact.fullname || contactPerson.fullname));
            }

            articleText = articleText.replace(/ *,( *,)*/g, `,`);
            articleText = articleText.replace(/[!;.] *, */g, ', ');
            articleText = articleText.replace(/[,.]*\.[,.]*/g, '.');
            articleText = articleText.replace(/[ ]{2,}/g, ' ');
        }

        const articleLines = [];

        _.forEach(_.split(articleText, '<br/>'), (line, i) => {
            articleLines.push(<p key={i}>{line}</p>);
        });

        const receivers = _.get(article, 'teamByFocusedteam.teamReceiversByTeamId.nodes');
        return (
            <article style={{backgroundColor: '#f3f4f5', paddingTop: 20}}>
                <Ref innerRef={this.contextRef}>
                    <div>
                        <LockControlers
                            article={article}
                            lockArticle={lockArticle}
                            unlockArticle={unlockArticle}
                            history={history}
                            contextRef={this.contextRef}
                        >
                            {({ canEdit, isLocked }) => (
                                <div>
                                    <Prompt
                                        when={isLocked && canEdit}
                                        message="You have locked the article. If you leave this page it will be unlocked. Do you want to leave?"
                                    />
                                    <div style={{padding: 20}}>
                                        <div style={{
                                            padding: '0 20px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flex: '1 1 auto',
                                            flexDirection: 'row'
                                        }}>
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <b style={{ marginRight: 5 }}>Status: </b>
                                                <div style={{ marginRight: 5 }}>
                                                    <ArticleStatusIcon status={article.status} displayLabel={true}/>
                                                </div>
                                                <LocksByArticle article={article} />
                                            </div>
                                        </div>
                                            <div>{moment(article.createdat).fromNow()}</div>
                                        </div>
                                        <Segment raised color='blue' size='huge' padded='very' as='div' textAlign='left'>
                                            {articleLines}
                                        </Segment>
                                    </div>
                                    <div style={{backgroundColor: '#f3f4f5', padding: 20}}>
                                        <Grid stackable columns={3}>
                                            <Grid.Column width={4}>
                                                <Segment raised style={{textAlign: 'left'}}>
                                                    <Label as='a' color='blue' ribbon style={{marginBottom: 15}}>Related Event
                                                        Information</Label>
                                                    <div key="event-info">
                                                        <div><Link
                                                            to={`/event/${article.eventByEventid.id}`}>{`${article.eventByEventid.teamByHometeamid.name} vs ${article.eventByEventid.teamByAwayteamid.name}`}</Link>
                                                        </div>
                                                        <div>{`${article.eventByEventid.leagueByLeagueid && article.eventByEventid.leagueByLeagueid.sportname}
                                                        - ${article.eventByEventid.leagueByLeagueid && article.eventByEventid.leagueByLeagueid.teamclass}
                                                        - ${article.eventByEventid.leagueByLeagueid && article.eventByEventid.leagueByLeagueid.name}`}
                                                        </div>
                                                        <div><b>Start
                                                            Date:</b> {moment(article.eventByEventid.startdate).format('YYYY-MM-DD h:mm a')}
                                                        </div>
                                                        <div><b>Event id: </b>{`${article.eventByEventid.id} `}</div>
                                                        <div><b>Event status: </b>{article.eventByEventid.suspended ? 'Suspended/Inactive': 'Active'}</div>
                                                        <div><a
                                                            href={`http://inrapportering.everysport.se/Intra/Controller?service=se.Everysport.Intra.Services.ReportMatch&action=show&match_id=${article.eventByEventid.id}&target=/index.jsp?category=report&content=report_match`}
                                                            target="_blank" rel="noopener noreferrer">Edit Event in ES intraweb</a></div>
                                                    </div>
                                                </Segment>
                                            </Grid.Column>

                                            <Grid.Column width={4}>
                                                <Segment style={{textAlign: 'left'}}>
                                                    <Label as='a' color='blue' ribbon style={{marginBottom: 15}}>Focused Team</Label>
                                                    <div key="focused-team">
                                                        {
                                                            article.teamByFocusedteam.logo ? <Image src={article.teamByFocusedteam.logo} size='tiny' rounded={true} circular={true} bordered floated={"left"} /> : ''
                                                        }
                                                        <div><b><Link as={'a'} to={'/team/'+article.teamByFocusedteam.id}> {article.teamByFocusedteam.name}</Link></b></div>
                                                        <div><b>Team Id: </b>{article.teamByFocusedteam.id}</div>
                                                        <div><b>Receivers:</b>
                                                            {
                                                                _.map(receivers, node =>
                                                                { return <Label key={node.receiverByReceiverId.id + 'receiver'} as={Link} to={`/receivers/${node.receiverByReceiverId.id}`}>{`${node.receiverByReceiverId.displayName || node.receiverByReceiverId.name}`}</Label>})
                                                            }
                                                        </div>
                                                        <div><a
                                                            href={`http://inrapportering.everysport.se/intra-new/contactList.html?teamId=${article.teamByFocusedteam.id}&clean`}
                                                            target="_blank" rel="noopener noreferrer">Edit Team in ES intraweb</a></div>
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Segment raised style={{textAlign: 'left'}}>
                                                    <Label as='a' color='blue' ribbon style={{marginBottom: 15}}>Team Contacts</Label>
                                                    <Card.Group itemsPerRow={2}>
                                                        {_.map(contactPersonList, (cp, index) => <ContactPersonCard key={cp.id} contactPersonId={cp.id} index={index+1} articleDate={article.eventByEventid.startdate}  />)}
                                                    </Card.Group>
                                                </Segment>

                                            </Grid.Column>
                                        </Grid>
                                    </div>
                                    <Dimmer.Dimmable dimmed={!canEdit}>
                                        <div style={{backgroundColor: '#f3f4f5', padding: 20}}>
                                            <Grid stackable columns={2}>
                                                <Grid.Column>
                                                    <Segment style={{textAlign: 'left'}}>
                                                        <Label as='a' color='blue' ribbon style={{marginBottom: 15}}>Questions</Label>

                                                        {article.questionsByArticleid.nodes.map((node) => {
                                                            return (
                                                                <Question admins={allAdmins && allAdmins.nodes} articleStatus={article.status} contactPersonList={contactPersonList} articleId={article.id} key={node.id} node={node}
                                                                          sendSmsFunction={sendSms} approveAnswerFunction={approveAnswer}
                                                                          ignoreAnswerFunction={ignoreAnswer} snoozeModerationFunction={snoozeModeration} adminstrationComplete={administrationComplete}
                                                                          disapproveAnswerFunction={disapproveAnswer} sendModeration={sendModeration}
                                                                          blockAnswerFunction={blockAnswer} addNoteFunction={addNote}
                                                                          removeNoteFunction={removeNote} logTimeFunction={logTime} receivers={receivers}
                                                                          homeTeam={article?.eventByEventid?.teamByHometeamid?.name} awayTeam={article?.eventByEventid?.teamByAwayteamid?.name}/>
                                                            );
                                                        })}
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <ArticleNotifications articleId={article.id}/>
                                                    {_.map(contactPersonList,this.renderLatestMessages.bind(this))}
                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                    </Dimmer.Dimmable>
                                </div>
                            )}
                        </LockControlers>
                    </div>
                </Ref>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    onAction={this.onActive}
                    debounce={250}
                    timeout={1000 * 10} />
            </article>
        );
    }
};

const articleQuery = gql`
    query ArticleQuery($id: String!) {
        article: articleById(id: $id) {
            id
            title
            article
            status
            createdat
            eventByEventid {
                id
                suspended
                leagueByLeagueid {
                    id
                    name
                    sportname
                    teamclass
                }
                teamByHometeamid {
                    id
                    name
                }
                teamByAwayteamid {
                    id
                    name
                }
                startdate
            }
            questionsByArticleid{
                nodes {
                    id
                    question
                    answer
                    extraInfo
                    approvedat
                    important
                    changedat
                    answeredBy
                    contactpersonByAnsweredBy {
                        id
                        title
                        fullname
                    }
                    adminByModeratedby {
                        id
                        fullname
                    }
                    alternativeContact
                    alternativeContactByAlternativeContact {
                        id
                        fullname
                        title
                    }
                    messagesByQuestionid {
                        nodes{
                            id
                            about
                            text
                            timestamp
                            type
                            sender
                            failreason
                            numberoftries
                            contactpersonByContactpersonid {
                                id
                                fullname
                                active
                            }
                        }
                    }
                    notesByQuestionId {
                        nodes {
                            id
                            admin
                            datetime
                            feedback
                            visible
                        }
                    }
                }
            }
            teamByFocusedteam{
                id
                logo
                name
                contactpeopleByTeamid{
                    nodes {
                        id
                        title
                        fullname
                        active
                        registrationdate
                        phoneCount
                        alternativeContactsByContactpersonId {
                            nodes{
                                id
                                active
                                fullname
                                title
                            }
                        }
                        messagesByContactpersonid(first: 30, orderBy: [TIMESTAMP_DESC]) {
                            nodes{
                                id
                                type
                                timestamp
                                about
                                questionid
                                text
                                sender
                                failreason
                                numberoftries
                                questionByQuestionid{
                                    id
                                    question
                                    type
                                    articleByArticleid {
                                        id
                                        title
                                    }
                                }
                            }
                        }
                    }
                }
                teamReceiversByTeamId {
                    nodes {
                        receiverByReceiverId {
                            id
                            key
                            name
                            displayName
                        }
                    }
                }
            }
            locksByArticle(first: 5, orderBy: [TIMESTAMP_DESC]) {
                nodes {
                    id
                    timestamp
                    adminByAdmin {
                        fullname
                        id
                    }
                }
            }
        }
        allAdmins {
            nodes {
                id
                fullname
                avatarByAvatar {
                    id
                    url
                }
            }
        }
    }
`;

const LOCK_ARTICLE_MUTATION = gql`
    mutation lockArticle($input: LockArticleInput!) {
        lockArticle(input: $input) {
            lock {
                id
                timestamp
                admin
            }
        }
    }
`;

const UNLOCK_ARTICLE_MUTATION = gql`
    mutation unlockArticle($input: UnlockArticleInput!) {
        unlockArticle(input: $input) {
            boolean
        }
    }
`;

const SEND_SMS_MUTATION = gql`
    mutation sendSms($input: SendSmsInput!) {
        sendSms(input: $input) {
            message {
                timestamp
            }
        }
    }
`;

const APPROVE_ANSWER_MUTATION = gql`
    mutation approveAnswer($input: ApproveAnswer3Input!) {
        approveAnswer: approveAnswer3(input: $input) {
            question {
                approvedat
            }
        }
    }
`;

const IGNORE_ANSWER_MUTATION = gql`
    mutation ignoreAnswer($input: IgnoreAnswerInput!) {
        ignoreAnswer: ignoreAnswer(input: $input) {
            question {
                answer
            }
        }
    }
`;

const SNOOZE_MODERATION_MUTATION = gql`
    mutation snoozeModeration($input: SnoozeModerationInput!) {
        snoozeModeration: snoozeModeration(input: $input) {
            question {
                answer
            }
        }
    }
`;

const ADMINISTRATION_COMPLETE_MUTATION = gql`
    mutation adminstrationComplete($input: AdministrationCompleteInput!) {
        administrationComplete: administrationComplete(input: $input) {
            question {
                answer
            }
        }
    }
`;

const SEND_MODERATION_MUTATION = gql`
    mutation adminstrationComplete($input: SendModerationInput!) {
        sendModeration: sendModeration(input: $input) {
            question {
                answer
            }
        }
    }
`;

const DISAPPROVE_ANSWER_MUTATION = gql`
    mutation disapproveAnswer($input: DisapproveAnswerInput!) {
        disapproveAnswer(input: $input) {
            question {
                answer
            }
        }
    }
`;

const BLOCK_ANSWER_MUTATION = gql`
    mutation blockAnswer($input: BlockAnswerInput!) {
        blockAnswer(input: $input) {
            question {
                id
            }
        }
    }
`;

const ADD_NOTE_MUTATION = gql`
    mutation addNote($input: AddNoteInput!) {
        addNote(input: $input) {
            note {
                feedback
            }
        }
    }
`;

const REMOVE_NOTE_MUTATION = gql`
    mutation removeNote($input: RemoveNoteInput!) {
        removeNote(input: $input) {
            note {
                visible
            }
        }
    }
`;

const LOG_TIME_MUTATION = gql`
    mutation logTime($input: LogTimeInput!) {
        logTime(input: $input) {
            timelog{
                id
                timestamp
            }
        }
    }
`;

const Article = withApollo(
    compose(
        graphql(articleQuery, {
            options: (ownProps) => ({variables: {id: ownProps.match.params.articleId}, pollInterval: 120000}),
        }),
        graphql(LOCK_ARTICLE_MUTATION, {
            name : 'lockArticle',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(UNLOCK_ARTICLE_MUTATION, {
            name : 'unlockArticle',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(SEND_SMS_MUTATION, {
            name : 'sendSms',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(APPROVE_ANSWER_MUTATION, {
            name : 'approveAnswer',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(IGNORE_ANSWER_MUTATION, {
            name : 'ignoreAnswer',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(SNOOZE_MODERATION_MUTATION, {
            name : 'snoozeModeration',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(ADMINISTRATION_COMPLETE_MUTATION, {
            name : 'administrationComplete',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(SEND_MODERATION_MUTATION, {
            name : 'sendModeration',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(DISAPPROVE_ANSWER_MUTATION, {
            name : 'disapproveAnswer',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(BLOCK_ANSWER_MUTATION, {
            name : 'blockAnswer',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(ADD_NOTE_MUTATION, {
            name : 'addNote',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(REMOVE_NOTE_MUTATION, {
            name : 'removeNote',
            options: ownProps => ({
                refetchQueries : [{
                    query: articleQuery, variables: {id: ownProps.match.params.articleId}
                }],
            })
        }),
        graphql(LOG_TIME_MUTATION, {
            name : 'logTime'
        }),
    )(withRouter(ArticleContainer))
);

export default Article;
