import React, {createRef, useState} from "react";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import {Label, Ref, Sticky, Grid, Header, Segment, Table, Input, Pagination} from 'semantic-ui-react'
import Info from '../components/Info';
import { useHistory } from "react-router-dom";


import ReceiverContactStats from "../components/stats/ReceiverContactStats";
import _ from "lodash";

const PAGE_SIZE = 15;

const ReceiverStatsContainer = ({isLoading, error, receivers, page}) => {
        //const {selectedReceiver, selectedDate, selectedToDate} = this.state;
        const contextRef = createRef();
        const [selectedReceiver, setSelectedReceiver] = useState();
        const [selectedDate, setSelectedDate] = useState('');
        const [selectedToDate, setSelectedToDate] = useState('');
        const history = useHistory();


        const pageNo = page ? parseInt(page, 10) : 1;
        const navigate = url => {
            history.push(`/all-stats/receiver/${url}`);
        };

        const pagination = (
            <Table.Row>
                <Table.HeaderCell colSpan="7" style={{textAlign: 'center'}}>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={pageNo}
                        ellipsisItem={null}
                        lastItem={null}
                        siblingRange={1}
                        prevItem={undefined}
                        nextItem={undefined}
                        totalPages={
                            _.get(receivers, 'nodes.length', 0) < PAGE_SIZE
                                ? pageNo
                                : pageNo + 1
                        }
                        onPageChange={(e, data) => {
                            navigate(data.activePage)
                        }}
                    />
                </Table.HeaderCell>
            </Table.Row>
        );


        if (error) {
            return <Info icon="warning" message={error.message} title="Error" loading={false}/>;
        }

        if (isLoading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }

        return (
            <div>
                <Ref innerRef={contextRef}>
                    <Grid width={16} stackable centered padded>
                        <Grid.Row stretched>
                            <Header as='h1' textAlign='center' color='blue'>
                                RECEIVER STATS
                            </Header>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={12}>
                                <Table style={{width: '100%'}} celled collapsing striped selectable stackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>#</Table.HeaderCell>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>Name *</Table.HeaderCell>
                                            <Table.HeaderCell>Key</Table.HeaderCell>
                                            <Table.HeaderCell>Display Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {pagination}
                                        {receivers?.nodes.map((node, index) => {
                                            return (
                                                <Table.Row key={node.id}
                                                           onClick={() => setSelectedReceiver(node)}
                                                           positive={selectedReceiver?.id === node.id}>
                                                    <Table.Cell>{index + 1}</Table.Cell>
                                                    <Table.Cell>{node.id}</Table.Cell>
                                                    <Table.Cell>{node.name}</Table.Cell>
                                                    <Table.Cell>{node.key}</Table.Cell>
                                                    <Table.Cell>{node.displayName}</Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column width={4}>

                                <Sticky context={contextRef}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={4}>
                                                <Label color={"red"} pointing={'right'} size={"large"}>From: </Label>
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                                <Input
                                                    label={{icon: 'calendar'}}
                                                    labelPosition='left'
                                                    placeholder='YYYY-MM-DD'
                                                    value={selectedDate}
                                                    onChange={(e, {value}) => setSelectedDate(value)}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={4}>
                                                <Label color={"red"} pointing={'right'} size={"large"}>To: </Label>
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                                <Input
                                                    label={{icon: 'calendar'}}
                                                    labelPosition='left'
                                                    placeholder='YYYY-MM-DD'
                                                    value={selectedToDate}
                                                    onChange={(e, {value}) => setSelectedToDate(value)}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    {
                                        selectedReceiver ?
                                            <ReceiverContactStats
                                                date={selectedDate.length === 10 ? selectedDate : null}
                                                dateTo={selectedToDate.length === 10 ? selectedToDate : null}
                                                receiver={selectedReceiver}/>
                                            :
                                            <Segment>Please select a receiver</Segment>
                                    }
                                </Sticky>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Ref>
            </div>
        );
};

const receiverStatsQuery = gql`
    query LeagueStatsQuery($limit: Int, $offset: Int, $region: Int){
        allReceivers : filterReceivers(regionIn: $region, limitNo: $limit, offsetNo: $offset){
            nodes {
                id
                key
                name
                displayName
                note
                teamReceiversByReceiverId {
                    totalCount
                }
            }
        }
    }
`;


const ReceiverStats = (props) => ( <Query query={receiverStatsQuery}
                                          variables={
                                              {
                                                  offset: (props.page ? parseInt(props.page, 10) - 1 : 0) * PAGE_SIZE,
                                                  limit: PAGE_SIZE,
                                                  region: parseInt(localStorage.getItem('selected-region')|| '1')
                                              }}>
    {({ data, refetch, loading, error }) => {
        return <ReceiverStatsContainer isLoading={loading} refetch={refetch} error={error} page={props.page || '1'} receivers={data?.allReceivers}/>
    }}
</Query>);


export default ReceiverStats;
