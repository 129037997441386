import {Component} from "react";
import {
    Button
} from "semantic-ui-react";

import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

const REMOVE_SIGNATURE_MUTATION = gql`
    mutation removeSignature($input: RemoveSignatureInput!) {
        removeSignature(input: $input){
            boolean
        }
    }
`;

class RemoveSignatureContainer extends Component {
    async handleRemoveSignature() {
        this.props.removeSignature({
            variables: {
                input: {
                    signatureId: this.props.signatureId,
                }
            }
        }).then(() => {
            this.props.refetchQuery(true);
        }).catch((err) => {
            console.log(err)
            this.props.refetchQuery(true);
        });
    };

    render() {
        return (
            <Button color='red' onClick={this.handleRemoveSignature.bind(this)}>Delete</Button>
        );
    }
}


const RemoveSignature= ({signature, refetchQuery, adminId}) => ( <Mutation mutation={REMOVE_SIGNATURE_MUTATION}>
    {(removeSignature) => (
        <RemoveSignatureContainer adminId={adminId} signatureId={signature.id} removeSignature={removeSignature} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default RemoveSignature;

