import {Query} from "react-apollo";
import gql from "graphql-tag";
import React, { Component } from 'react'
import _ from 'lodash'
import { Search, Grid, Label } from 'semantic-ui-react'
import SportIcon from "../SportIcon";

const resultRenderer = ({  id, leaguename, name, sportname, teamclass }) =>

        <Grid columns={2} stackable textAlign='center' style={{overflowY: "auto", whiteSpace: "nowrap"}}>
            <Grid.Row verticalAlign='middle'>
                <Grid.Column width={2}>
                    <SportIcon sportName={sportname}/>
                </Grid.Column>

                <Grid.Column width={14}>
                    <Label as='a' size={'medium'} basic>
                        {name}
                        <Label.Detail>{leaguename + ' - ' + teamclass}</Label.Detail>
                    </Label>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    ;


class SearchTeamsContainer extends Component {
    constructor(params) {
        super(params);
        this.state = {value: (params.team && params.team.name) || ''};
    }
    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     return this.props.team.id !== nextProps.team.id;
    // }

    handleResultSelect = (e, { result }) => {
        this.props.onTeamSelected(result.id);
        this.setState({ value: result.name });
    }

    handleSearchChange = (e, { value }) => {
        const {sportId, regionId} = this.props;
        this.setState({ value });
        if(value) {
            this.props.refetch({searchText: value, sportId, regionIn: regionId});
        }
    };

    render() {
        const { isLoading, teams } = this.props;
        teams && _.forEach(teams, t=> t.title = t.name + t.leaguename);
        const { value } = this.state;

        return (
            <Search icon={'search'}
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                    leading: true,
                })}
                results={teams}
                value={value}
                resultRenderer={resultRenderer}
                minCharacters={2}
                fluid
            />
        )
    }
}


const SEARCH_TEAMS_QUERY = gql`
    query searchTeam($searchText: String!, $sportId: Int, $regionIn: Int) {
        searchTeams: searchTeamsInSport2(searchText : $searchText, sport: $sportId, regionIn: $regionIn) {
            nodes {
                id
                leagueid
                leaguename
                name
                sportname
                sportid
                teamclass
            }
        }
    }
`;

const SearchTeams = ({team, sportId, regionId, onTeamSelected}) => ( <Query query={SEARCH_TEAMS_QUERY} variables={{ sportId: sportId || null, regionIn: regionId || 1, searchText: (team && team.name) || ''}}>
    {({ data, refetch, loading, error }) => {
        return <SearchTeamsContainer isLoading={loading} refetch={refetch} onTeamSelected={onTeamSelected} team={team} sportId={sportId || null} regionId={regionId || 1} teams={data && data.searchTeams && data.searchTeams.nodes}/>
    }}
</Query>);

export default SearchTeams;
