import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Statistic} from 'semantic-ui-react'
import Info from '.././Info';
import {Query} from "react-apollo";

const ContactStatsContainer = (props) => {
    const {whiteListedCount, allCount} = props;
    return (
        <Segment inverted raised={true} >
            <Header as={'h3'} color={"black"}>Contact Stats</Header>
            <Statistic.Group horizontal inverted color={'orange'} size={'small'}>
                <Statistic inverted>
                    <Statistic.Value>{allCount}</Statistic.Value>
                    <Statistic.Label>Total Active Contacts</Statistic.Label>
                </Statistic>
                <Statistic inverted>
                    <Statistic.Value>{whiteListedCount}</Statistic.Value>
                    <Statistic.Label>Whitelisted Contacts</Statistic.Label>
                </Statistic>
                <Statistic inverted>
                    <Statistic.Value>{(whiteListedCount / allCount).toFixed(2) + '%'}</Statistic.Value>
                    <Statistic.Label>Percentage of Whitelisted Contacts</Statistic.Label>
                </Statistic>
            </Statistic.Group>

        </Segment>
    );
};

const QUERY_CONTACTS_COUNT = gql`
    query contactStats ( $region: Int){
        whiteListedCount:contactCount3(levelIn: 1, regionIn: $region)
        allCount:contactCount3(regionIn: $region)
    }
`;

const WhiteListedContactStats = ({region}) => (<Query query={QUERY_CONTACTS_COUNT} variables={{region}}>
    {({data, loading, error}) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if (data) {
            return <ContactStatsContainer whiteListedCount={data.whiteListedCount} allCount={data.allCount} />
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, we could not fetch contact stats '} title='Error' loading={false}/>;
    }}
</Query>);

export default WhiteListedContactStats;
