import React from 'react'
import { Tab } from 'semantic-ui-react'
import LeagueStats from "./LeagueStats";
import ReceiverStats from "./ReceiverStats";
import ReceiverFocusedStats from "./ReceiverFocusedStats";
import ContactStats from "./ContactStats";
import GenericStats from "./GenericStats";
import ReceiverUnFocusedStats from "./ReceiverUnFocusedStats";

const getPanes = (page, sort) => {
    console.log('page', page);
    console.log('sort', sort);

   return [
       {
           menuItem: 'League Stats',
           render: () => <Tab.Pane><LeagueStats/></Tab.Pane>,
       },
       { menuItem: 'Receiver Stats', render: () => <Tab.Pane><ReceiverStats page={page}/></Tab.Pane> },
       { menuItem: 'Contact Stats', render: () => <Tab.Pane><ContactStats/></Tab.Pane> },
       { menuItem: 'General Stats', render: () => <Tab.Pane><GenericStats/></Tab.Pane> },
       { menuItem: 'Receiver Focused Teams Stats', render: () => <Tab.Pane><ReceiverFocusedStats page={page} sort={sort} /></Tab.Pane> },
       { menuItem: 'Receiver UnFocused Teams Stats', render: () => <Tab.Pane><ReceiverUnFocusedStats page={page} sort={sort} /></Tab.Pane> },
   ];
}
const activeIndexF = (tabName) => {

    console.log('tabname', tabName)
    switch (tabName) {
        case 'receiver':
            return 1;
        case 'contact':
            return 2;
        case 'league':
            return 0;
        case 'receiver-focused':
            return 4;
        case 'unfocused':
            return 5;
        case 'generic':
            return 3;
        default:
            return 3;
    }
}

const indexToUrl = (index) => {
    switch (index) {
        case 1:
            return '/all-stats/receiver';
        case 2:
            return '/all-stats/contact';
        case 3:
            return '/all-stats/generic';
        case 4:
            return '/all-stats/receiver-focused/nameasc/1';
        case 5:
            return '/all-stats/unfocused/nameasc/1';
        case 0:
            return '/all-stats/league';
        default:
            return '/all-stats/generic';
    }
}

const StatsTabs = (props) => <Tab panes={getPanes(props?.match?.params?.page, props?.match?.params?.sort)} activeIndex={activeIndexF(props?.match?.params?.tab)}
                                  onTabChange={(event, {activeIndex}) => props.history.push(indexToUrl(activeIndex))} />

export default StatsTabs;
