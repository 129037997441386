import {Button, Container, Icon } from "semantic-ui-react";
import React from "react";
import {Mutation} from "react-apollo";
import gql from "graphql-tag";
import Info from "../Info";
import _ from "lodash";

const markExternal = (markExternalContactFunc, id, deletedInput, onboardedInput, send, refetchQuery) => {
    markExternalContactFunc({
        variables: {
            input: { externalContactId: id,
                deletedInput,
                onboardedInput,
                send}
        }, refetchQueries: [{
            query: refetchQuery,
            variables: {
                id: id,
                adminId: JSON.parse(localStorage.getItem('current-admin')).id
            }
        }]
    })
};

const ExternalContactStatusComponent = ({externalContact, markExternalContact, refetchQuery, error}) => {
    if (externalContact) {
        const { teamId, title, onboarded, deleted, seasonId, sendEverysport} = externalContact;
        const canBeSendToEverysport = sendEverysport === 0 && !_.isEmpty(title) && seasonId !== null && teamId !== null && onboarded !== true;
        return (
            <Container style={{marginTop: 10}}>
                <span style={{marginRight: 20}}><b>Status: </b> {externalContact.onboarded ? 'Onboarded' :  (externalContact.deleted ? 'Deleted' : 'Active')}</span>
                <Button style={externalContact.deleted ? {display: 'none'} : {}}  color={'red'}  onClick={() => markExternal(markExternalContact, externalContact.id, true, false, null, refetchQuery) }><Icon name='x'/>Delete</Button>
                <Button style={externalContact.onboarded ? {display: 'none'} : {}} color={'olive'}  onClick={() => markExternal(markExternalContact, externalContact.id, false, true, null, refetchQuery) }><Icon name='check'/>Onboard</Button>
                <Button style={!canBeSendToEverysport ? {display: 'none'} : {}} color={'green'}  onClick={() => markExternal(markExternalContact, externalContact.id, false, true, 1, refetchQuery) }><Icon name='plus'/>Onboard & Add to Intra</Button>
                <Button style={!externalContact.onboarded && !externalContact.deleted ? {display: 'none'} : {}} color={'yellow'}  onClick={() => markExternal(markExternalContact, externalContact.id, false, false, 0, refetchQuery) }><Icon name='history'/>Activate</Button>
            </Container>
        );
    } else {
        return <Info icon='warning' message={error || 'Sorry, we could not find the contact person you requested!'} title='Error'
                     loading={false}/>;
    }
};


const MARK_EXTERNAL_CONTACT_MUTATION = gql`
    mutation markExternalContact($input: MarkExternalContact2Input!) {
       markExternalContact: markExternalContact2(input : $input) {
            externalContact{
                id
                fullname
            }
        }
    }
`;

const ExternalContactStatus= ({externalContact, refetchQuery}) => ( <Mutation mutation={MARK_EXTERNAL_CONTACT_MUTATION}>
    {(markExternalContact) => (
        <ExternalContactStatusComponent externalContact={externalContact} markExternalContact={markExternalContact} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default ExternalContactStatus;

