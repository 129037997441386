import React, {Component} from 'react';
import {Query} from "react-apollo";
import gql from "graphql-tag";
import {
    Table,
    Header,
    Segment,
    Button,
    Grid,
    Form,
    Select,
    Pagination, Item, List
} from 'semantic-ui-react'
import Info from '../components/Info';
import {Link} from "react-router-dom";
import SportIcon from "../components/SportIcon";
import * as moment from 'moment';
import AddTeamNote from "../components/team/AddTeamNote";

const _ = require('lodash');


const PAGE_SIZE = 50;

const displayLeagues = (teamLeaguesByTeamId) => {
    return _.map(_.filter(teamLeaguesByTeamId?.nodes, (ln) => ln.leagueByLeagueId?.active), (ln) => {
        return (<List.Item >{`${ln.leagueByLeagueId.id} - ${ln.leagueByLeagueId.name}`}</List.Item>);
    })
}

class TeamListContainer extends Component {
    constructor(props) {
        super(props);
        const settings = JSON.parse(localStorage.getItem('team-list-settings')) || {};
        this.state = {
            settings,
            searchText: settings.searchText,
        };
    }

    toggleSettings(refetch, filter, value) {
        const {settings, searchText} = this.state;

        if (filter === 'sport') {
            settings.sportId = value && value !== '-1' ? value : null;
        } else if (filter === 'league') {
            settings.leagueIdStr = value && value !== '-1' ? value : null;
        } else if (filter === 'teamClass') {
            settings.teamClass = value !== 'all' ? value : null;
        } else if (filter === 'search') {
            settings.searchText = searchText && searchText.length > 0 ? searchText : null;
        } else if (filter === 'receiver') {
            settings.receiverId = value && value !== '-1' ? value : null;
        }
        localStorage.setItem('team-list-settings', JSON.stringify(settings));

        this.setState({settings})


        // if (this.props.match.params.page !== 0) {
        //     this.props.history.push('/teams');
        // }

        refetch(getTeamListQueryVariables(this.props));
    };

    renderContact(contact) {
        return (<p><Link to={'/contacts/'+contact.id}>{contact.title + ' '+ contact.fullname}</Link> registered on {moment(contact.registrationdate).format('DD-MM-YYYY')}
            {contact.questionsByAnsweredBy?.nodes?.length > 0 ? ' and has latest approved article on ' + moment(contact.questionsByAnsweredBy.nodes[0].approvedat).format('DD-MM-YYYY') : ''}
        </p>)
    }


    render() {
        const {
            settings,
        } = this.state;

        const {
            refetch,
            sports,
            leagues,
            receivers,
            teams,
            page,
            history
        } = this.props;


        const receiverOptions = _.map(_.orderBy(receivers.nodes, 'name'), r => ({ key:r.key, value: r.id, text: `${r.name} ( ${r.key} )`}));
        receiverOptions.splice(0, 0, {key: '-1', value: '-1', text: 'All Receivers'});

        const sportOptions = _.map(_.orderBy(sports?.nodes, 'name'), r => ({ key:r.sportid, value: r.sportid, text: `${r.sportname}`}));
        sportOptions.splice(0, 0, { key: '-1', value: '-1', text: 'All Sports'});


        const leagueOptions = _.map(_.orderBy(leagues?.nodes, 'name'), r => ({ key:r.id, value: r.id, text: `${r.id} - ${r.name}`}));
        leagueOptions.splice(0, 0, { key: '-1', value: '-1', text: 'All Leagues'});

        const classOptions = [{key: 'all', value: 'all', text: 'All Classes'}, {key: 'herr', value: 'herr', text: 'Men'},{key: 'dam', value: 'dam', text: 'Women'},{key: 'mix', value: 'mix', text: 'Mix'}]

        const pageNo = page ? parseInt(page, 10) : 1;

        const navigate = url => {
            history.push(`/teams/${url}`)
        };

        const pagination = (
            <Table.Row>
                <Table.HeaderCell colSpan="10" style={{textAlign: 'center'}}>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={pageNo}
                        ellipsisItem={null}
                        lastItem={null}
                        siblingRange={1}
                        prevItem={undefined}
                        nextItem={undefined}
                        totalPages={
                            _.get(teams, 'nodes.length', 0) < PAGE_SIZE
                                ? pageNo
                                : pageNo + 1
                        }
                        onPageChange={(e, data) => {
                            navigate(data.activePage)
                        }}
                    />
                </Table.HeaderCell>
            </Table.Row>
        )
        return (
            <Grid width={16} stackable centered padded>
                <Grid.Row stretched>
                    <Segment color='blue' style={{width: '90%'}}>
                        <Header size={"large"} textAlign='center' color='red'>
                            Teams
                        </Header>
                        <div style={{width: '90%'}}>
                            <Segment style={{display: 'flex', alignItems: 'center'}} basic floated='left'>
                                <Select label='Sport' value={settings.sportId || '-1'} placeholder='ALL' options={sportOptions}
                                        onChange={(event, {value}) => this.toggleSettings(refetch, 'sport', value)}/>

                                <Select label='Class' value={settings.teamClass || 'all'} placeholder='ALL' options={classOptions}
                                        onChange={(event, {value}) => this.toggleSettings(refetch, 'teamClass', value)}/>

                                <Select label='League' value={settings.leagueIdStr || '-1' } placeholder='ALL' options={leagueOptions}
                                        onChange={(event, {value}) => this.toggleSettings(refetch, 'league', value)}/>

                                <Select label='Receiver' placeholder='Select a receiver' options={receiverOptions}
                                        value={settings.receiverId || '-1'}
                                        onChange={(event, {value}) => this.toggleSettings(refetch, 'receiver', value)}/>
                                <span style={{marginLeft: 5, marginRight: 10}}> <b>|</b> </span>
                                <Form onSubmit={() => {
                                    this.toggleSettings(refetch, 'search')
                                }}>
                                    <Form.Input
                                        onChange={(e) => {
                                            this.setState({searchText: e.target.value})
                                        }}
                                        placeholder='Search...'
                                        color={settings && settings.searchText && settings.searchText.length > 0 ? 'green' : 'grey'}
                                        value={this.state.searchText || ''}
                                    />
                                </Form>
                                <Button as='button'
                                        onClick={() => {
                                            this.toggleSettings(refetch, 'search')
                                        }}
                                        content='Search'/>

                            </Segment>
                        </div>
                        <Table style={{width: '100%'}} celled collapsing striped selectable stackable>
                            <Table.Header>
                                {pagination}
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Logo</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Leagues</Table.HeaderCell>
                                    <Table.HeaderCell>Class</Table.HeaderCell>
                                    <Table.HeaderCell>Sport</Table.HeaderCell>
                                    <Table.HeaderCell>Receivers</Table.HeaderCell>
                                    <Table.HeaderCell>Approved Articles</Table.HeaderCell>
                                    <Table.HeaderCell>Active Contacts</Table.HeaderCell>
                                    <Table.HeaderCell>Note</Table.HeaderCell>
                                    <Table.HeaderCell> Link </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {teams.nodes.map((node) => {
                                    return (
                                        <Table.Row key={node.id}>
                                            <Table.Cell>{node.id}</Table.Cell>
                                            <Table.Cell>{   node.logo ?
                                                <Item.Image src={node.logo} size='mini' rounded={true} circular={true} bordered fluid floated={"left"} />
                                                : ''
                                            }</Table.Cell>
                                            <Table.Cell><Link
                                                to={'/team/' + node.id}> {node.name}</Link></Table.Cell>
                                            <Table.Cell><List>{displayLeagues(node.teamLeaguesByTeamId)}</List></Table.Cell>
                                            <Table.Cell>{node.leagueByLeagueid.teamclass}</Table.Cell>
                                            <Table.Cell><SportIcon sportName={node.leagueByLeagueid.sportname} />{node.leagueByLeagueid.sportname}</Table.Cell>
                                            <Table.Cell>{node.teamReceiversByTeamId && node.teamReceiversByTeamId.totalCount}</Table.Cell>
                                            <Table.Cell>{node.articlesByFocusedteam && node.articlesByFocusedteam.totalCount}</Table.Cell>
                                            <Table.Cell>{node.contactpeopleByTeamid && _.map(node.contactpeopleByTeamid.nodes, (c)=>this.renderContact(c))}</Table.Cell>
                                            <Table.Cell><AddTeamNote teamId={node.id} note={node.note} refetchQuery={teamListQuery} variables={getTeamListQueryVariables(this.props)} /></Table.Cell>
                                            <Table.Cell><Link
                                                to={'/team/' + node.id}>{'>>'}</Link></Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                            <Table.Footer>
                                {pagination}
                            </Table.Footer>
                        </Table>
                    </Segment>
                </Grid.Row>
            </Grid>
        );
    }
}

const teamListQuery = gql`
    query TeamList(
        $leagueIn: String,
        $teamClass: String,
        $sportIn: Int,
        $searchText: String,
        $receiverIn: Int,
        $regionIn: Int,
        $limitNo: Int,
        $offsetNo: Int
    ) {
        teams: filterTeams3(
            leagueIn: $leagueIn,
            teamClass: $teamClass,
            sportIn: $sportIn,
            searchText: $searchText,
            receiverIn: $receiverIn,
            regionIn: $regionIn,
            limitNo: $limitNo,
            offsetNo: $offsetNo
        ) {
            totalCount
            nodes {
                id
                logo
                name
                note
                leagueByLeagueid{
                    id
                    name
                    sportname
                    teamclass
                }
                contactpeopleByTeamid(condition: {active: true}) {
                    nodes {
                        id
                        title
                        fullname
                        registrationdate
                        questionsByAnsweredBy(orderBy: [APPROVEDAT_DESC], first: 1) {
                            nodes {
                                id
                                approvedat
                                articleid
                            }
                        }
                    }
                }
                teamLeaguesByTeamId{
                    nodes{
                        leagueByLeagueId {
                            id
                            name
                            sportname
                            teamclass
                            active
                        }
                    }
                }
                teamReceiversByTeamId {
                    totalCount
                }
                articlesByFocusedteam(condition: {status: "approved"}){
                    totalCount
                }
            }
        }
        allReceivers {
            nodes {
                id
                displayName
                name
                key
            }
        }
        leagues: filterLeagues3(sportId: $sportIn, teamClass: $teamClass, regionIn: $regionIn) {
            nodes {
                id
                name
                sportid
                teamclass
            }
        }
        allSports{
            nodes {
                endDate
                startDate
                id
                sportid
                sportname
            }
        }
    }
`;


const  getTeamListQueryVariables = (ownProps) => {
    const settings = JSON.parse(localStorage.getItem('team-list-settings')) || {};
    return {
        leagueIn: (settings && settings.leagueIdStr) || null,
        teamClass: (settings && settings.teamClass) || null,
        sportIn: (settings && settings.sportId && parseInt(settings.sportId, 10)) || null,
        receiverIn: (settings && settings.receiverId && parseInt(settings.receiverId, 10)) || null,
        regionIn: parseInt(localStorage.getItem('selected-region')|| '1'),
        offsetNo: (ownProps.page ? parseInt(ownProps.page, 10) - 1 : 0) * PAGE_SIZE,
        limitNo: PAGE_SIZE,
        searchText: (settings && settings.searchText) || null,
    };
}

const TeamList = (props) => ( <Query query={teamListQuery} variables={getTeamListQueryVariables({page: props.match.params.page})}>
    {({ data, refetch, loading, error }) => {
        const {
            match: {
                params: {
                    page
                }
            },
            history
        } = props;

        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if(data && data.teams) {
            return <TeamListContainer teams={data.teams} sports={data.allSports} receivers={data.allReceivers} leagues={data.leagues} refetch={refetch} page={page} history={history}/>
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, we could not fetch teams!'} title='Error' loading={false}/>;
    }}
</Query>);

export default TeamList;

