import gql from "graphql-tag";
import { useMutation} from "react-apollo";
import React, {useState} from "react";
import {Icon, Form, Message, Segment, Header} from "semantic-ui-react";

const ADD_ADMIN_MUTATION = gql`
    mutation addAdmin($input: AddAdminInput!) {
        addAdmin(input: $input) {
           admin{
               id
           }
        }
    }
`;

const ADD_ADMIN_TO_REGION = gql`
    mutation AddAdminToRegionMutation($admin: Int!, $region: Int!){
        addAdminRegion(input: { adminIn: $admin, regionIn: $region} ) {
            boolean
        }
    }
`;

const smsProviderOptions = [
    { key: '46elks_se', value: '46elks_se', text: '46Elks Sweden'},
    { key: '46elks_nl', value: '46elks_nl', text: '46Elks Netherlands'},
    { key: '46elks_de', value: '46elks_de', text: '46Elks Germany'},
    { key: '46elks_fin', value: '46elks_fin', text: '46Elks Finland'}];

const AddAdmin = ({refetchQuery, region}) => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedSmsProvider, setSelectedSmsProvider] = useState('46elks_se');
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [error, setError] = useState();
    const [addAdmin] = useMutation(ADD_ADMIN_MUTATION);
    const [addAdminRegion] = useMutation(ADD_ADMIN_TO_REGION);


    const refetchQueries = [];

    if(refetchQuery) {
        refetchQueries.push({
            query: refetchQuery,
            variables:{region: region > 0 ? region : null}
        });
    }
    const handleAddAdmin = async () => {

        try {
            const result = await addAdmin({
                variables: {
                    input: {
                        fullnameIn: fullName,
                        emailIn: email,
                        phoneIn: phone,
                        smsProviderIn: selectedSmsProvider
                    }
                }, refetchQueries
            });

            if(region > 0) {
                await addAdminRegion({
                    variables: {
                        admin: result?.data?.addAdmin?.admin?.id,
                        region,
                    }, refetchQueries
                });
            }

            setDisplaySuccess(true);
            setFullName('');
            setEmail('');
            setPhone('');
            setSelectedSmsProvider('46elks_se');
            setTimeout(() => {
                setDisplaySuccess(false);
            }, 3000);


        } catch (err) {
            console.error(err);
            setError(err);
            setTimeout(() => {
                setError('');
            }, 30000);
        }
    };

    return (
        <Segment color={'yellow'} inverted textAlign={"center"}>
            <Header as='h3'>Add a new admin</Header>
            <Form>
                {
                    displaySuccess ?
                        <Message
                            success
                            header='New admin added successfully!'
                        />
                        :''
                }
                {
                    error ?
                        <Message
                            warning
                            header='Error adding new admin!'
                            content={error}
                        /> : ''
                }
                <Form.Group widths={16}>
                    <Form.Input width={8} label='Full Name'
                        value={fullName}
                        onChange={(event) => setFullName(event.target.value)}
                        placeholder='Name Surname'/>
                    <Form.Input width={8} label='Email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder='admin@x.com'/>
                </Form.Group >
                <Form.Group widths={16}>
                    <Form.Input width={8} label='Phone'
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        placeholder='Format +46xxx, +358xxx'/>
                    <Form.Select width={8} options={smsProviderOptions} label={'SMS Provider'} onChange={(event, data) => {setSelectedSmsProvider(data.value)}} value={selectedSmsProvider}/>
                </Form.Group>
                <Form.Button disabled={!fullName || !phone|| !email}  onClick={handleAddAdmin} color='green'><Icon name='add'/> Add Admin</Form.Button>
            </Form>
        </Segment>
    );
};

export default AddAdmin;
