import React, {Component} from 'react';
import {withApollo, graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {Menu, Segment, Header, Button, Container, Grid, Tab} from 'semantic-ui-react'
import { withRouter} from "react-router-dom";
import compose from "lodash.flowright";
import EventStats from "../components/stats/EventStats";
import ReceiverStats from "../components/stats/ReceiverStats";
import NotificationFeed from "../components/notification/NotificationFeed";
import NewExternalContacts from "../components/external-contact/NewExternalContacts";

class NotificationCenter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            loginError: ''
        };

        this.logout = () => {
            localStorage.removeItem('token');
            localStorage.removeItem('current-admin');
            props.client.resetStore();
        };

        this.handleUsernameChange = (event, data) => {
            this.setState({email: data.value});
        };

        this.handlePasswordChange = (event, data) => {
            this.setState({password: data.value});
        };
    }

    render() {
        const {loading, markRead, markAllRead, allNotifications, error} = this.props;

        const panes = [
            {
                menuItem: 'Administration (' + allNotifications?.nodes?.filter((node => (node.type === 'snoozed'))).length + ')',
                render: () => <NotificationFeed allNotifications={allNotifications} markRead={markRead} type={'snoozed'} />,
            },
            {
                menuItem: 'Auto-moderation (' + allNotifications?.nodes?.filter((node => (node.type === 'moderation_required'))).length + ')',
                render: () => <NotificationFeed allNotifications={allNotifications} markRead={markRead} type={'moderation_required'} />,
            },
            {
                menuItem: 'Contact Info (' + allNotifications?.nodes?.filter((node => (node.type === 'contact_info_changes'))).length + ')',
                render: () => <NotificationFeed allNotifications={allNotifications} markRead={markRead} type={'contact_info_changes'} />,
            },
            {
                menuItem: 'Unresponding Contacts (' + allNotifications?.nodes?.filter((node => (node.type === 'unresponding_contact'))).length + ')',
                render: () => <NotificationFeed allNotifications={allNotifications} markRead={markRead} type={'unresponding_contact'} />,
            },
            {
                menuItem: 'New subscriptions (' + allNotifications?.nodes?.filter((node => (node.type === 'receiver_subscription'))).length + ')',
                render: () => <NotificationFeed allNotifications={allNotifications} markRead={markRead} type={'receiver_subscription'} />,
            },
            {
                menuItem: 'All (' + allNotifications?.nodes?.length + ')',
                render: () => <NotificationFeed allNotifications={allNotifications} markRead={markRead} type={'all'} />,
            },
        ]

        if (loading && !error) {
            return (
                <Menu.Menu position='right'>
                    <Menu.Item name='loading'>Loading...</Menu.Item>
                </Menu.Menu>
            );
        }

        return (
            <div>

                <Header as='h1' textAlign='center' color='blue'>
                    NOTIFICATION CENTER
                </Header>

                <EventStats/>
                <Container >
                    <Grid columns={2} stackable textAlign='center'>
                        <Grid.Row verticalAlign={"top"}>
                            <Grid.Column width={'16'} >
                                <Segment compact={false} basic>
                                    <Segment vertical color={"white"} raised padded>
                                        <Button color='green' content='Mark all info messages as read' onClick={() => markAllRead({
                                            variables: {
                                                input: {inLevel: 'INFO'}
                                            }
                                        })}/>
                                        <Button color='orange' content='Mark all warning messages as read' onClick={() => markAllRead({
                                            variables: {
                                                input: {inLevel: 'WARNING'}
                                            }
                                        })}/>
                                    </Segment>

                                    <Tab style={{marginTop: 20}} menu={{ color: 'blue', inverted: true, attached: false, tabular: false }} panes={panes} />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row verticalAlign={"top"}>
                            <Grid.Column width={'8'} >
                                <Segment compact={true} basic>
                                    <ReceiverStats />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={'8'} stretched={true} >
                                <Segment compact={true} basic>
                                    <NewExternalContacts />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>


            </div>

        );
    }
}

const NOTIFICATIONS_QUERY = gql`
    query allNotifications {
        allNotifications( first: 500, orderBy: [TIMESTAMP_DESC], condition: {read: false}) {
            nodes {
                id
                articleId
                articleByArticleId {
                    id
                    title
                    status
                }
                contactId
                contactpersonByContactId {
                    id
                    fullname
                    title
                }
                receiverByReceiverId {
                    id
                    name
                    key
                }
                level
                read
                text
                timestamp
                type
            }
        }
    }
`;

const MARK_READ_MUTATION = gql`
    mutation markAsRead($input: MarkNotificationReadInput!) {
        markNotificationRead(input: $input) {
            notification {
                id
            }
        }
    }
`;


const MARK_ALL_READ_MUTATION = gql`
    mutation markAllAsRead($input: MarkAllNotificationsReadInput!) {
        markAllNotificationsRead(input: $input) {
            boolean
        }
    }
`;

export default withApollo(
    compose(
        graphql(NOTIFICATIONS_QUERY, {
            options: {fetchPolicy: 'network-only', errorPolicy: 'all'},
            props: ({data: {loading, allNotifications, error}}) => ({
                loading, allNotifications, error
            })
        }),
        graphql(MARK_READ_MUTATION, {
            options: ownProps => ({
                refetchQueries: [{
                    query: NOTIFICATIONS_QUERY
                }],
            }), name: 'markRead'
        }),
        graphql(MARK_ALL_READ_MUTATION, {
            options: ownProps => ({
                refetchQueries: [{
                    query: NOTIFICATIONS_QUERY
                }],
            }), name: 'markAllRead'
        })
    )(withRouter(NotificationCenter))
);
