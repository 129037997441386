import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Statistic} from 'semantic-ui-react'
import Info from '.././Info';
import {Query} from "react-apollo";

const ContactStatsContainer = (props) => {
    const { externalContactsCount} = props;
    return (
        <Segment inverted raised={true} >
            <Header as={'h3'} color={"black"}>External Contact Stats</Header>
            <Statistic.Group horizontal inverted color={'orange'} size={'small'}>
                <Statistic>
                    <Statistic.Value>{externalContactsCount}</Statistic.Value>
                    <Statistic.Label>External Contacts</Statistic.Label>
                </Statistic>
            </Statistic.Group>

        </Segment>
    );



};

const QUERY_CONTACTS_COUNT = gql`
    query contactStats ( $sport: Int, $fromDate: String, $toDate: String, $admin: Int, $deleted: Boolean, $onboarded: Boolean, $region: Int){
        externalContactsCount: externalContactsCount2(sport: $sport, toDate: $toDate, fromDate: $fromDate, admin: $admin, isDeleted: $deleted, isOnboarded: $onboarded, regionIn: $region)
    }
`;

const ExternalContactStats = ({sport, fromDate, toDate, deleted, onboarded, admin}) => (<Query query={QUERY_CONTACTS_COUNT} variables={{sport, fromDate, toDate, deleted, onboarded, admin, region: parseInt(localStorage.getItem('selected-region')|| '1')}}>
    {({data, loading, error}) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if (data) {
            return <ContactStatsContainer externalContactsCount={data.externalContactsCount}>

            </ContactStatsContainer>
        }
        return <Info icon='warning'
                     message={(error && error.message) || 'Sorry, we could not fetch external contact stats '}
                     title='Error' loading={false}/>;
    }}
</Query>);

export default ExternalContactStats;
