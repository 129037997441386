import {Component} from "react";
import {
    Form, Message,
    Segment, Button
} from "semantic-ui-react";

import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

const groupOptions = [
    { key: 'external_contact', value: 'external_contact', text: 'External Contact' },
    { key: 'group_messages', value: 'group_messages', text: 'Group Message' },
    { key: 'auto_onboard', value: 'auto_onboard', text: 'Opt-Out External Contact' },
];

const NEW_SIGNATURE_MUTATION = gql`
    mutation createSignature($input: AddSignatureInput!) {
        addSignature(input: $input){
            signature{
                id
                text
                title
                group
            }
        }
    }
`;

class NewSignatureContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: undefined,
            text: undefined,
            group: undefined,
            success: undefined,
            error: undefined
        };
    };
    handleChange(e, { name, value }) {
        this.setState({ [name]: value })
    };
    async handleSignatureSubmit() {
        const {text, group, title} = this.state;
        this.props.createSignature({
            variables: {
                input:{
                    textInput: text,
                    groupInput: group,
                    titleInput: title
                }
            }
        }).then(() => {
            this.props.refetchQuery(true);
            this.setState({success: true,
                title: undefined,
                text: undefined,
                group: undefined});
            setTimeout(() => {
                this.setState({success: false});
            }, 3000);
        }).catch((err) => {
            this.setState({error: err.toString()});
            setTimeout(() => {
                this.setState({error: null});
            }, 5000);
        });
    };

    render(){
        const { title, text, group, error, success } = this.state;
        return (
            <Segment>
                <Message
                    error
                    hidden={!error}
                    header='Signature could not be added'
                    content={error}
                />
                <Message
                    success
                    hidden={!success}
                    header='Signature added successfully!'
                    content='Signature aded'
                />
                <Form >
                    <Form.Group widths='equal'>
                        <Form.Input
                            name='title'
                            label='Title'
                            value={title}
                            placeholder='Title'
                            onChange={this.handleChange.bind(this)}
                        />
                        <Form.Select
                            name='group'
                            label='Group'
                            value={group}
                            onChange={this.handleChange.bind(this)}
                            options={groupOptions}
                        />
                    </Form.Group>
                    <Form.TextArea
                        name='text'
                        label='Text'
                        value={text}
                        rows={7}
                        onChange={this.handleChange.bind(this)}
                        placeholder='Text to be sent as sms...'
                    />
                    <Form.Group widths='equal'>
                        <span style={{marginTop: 'auto', marginLeft: 10, marginRight:10}}>You may use following keywords:</span>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$first_name'})}}>
                            $first_name
                        </Button>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$last_name'})}}>
                            $last_name
                        </Button>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$full_name'})}}>
                            $full_name
                        </Button>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$admin_name'})}} >
                            $admin_name
                        </Button>
                        <Button color='olive' horizontal onClick={() => { this.setState({text: (this.state.text || '') + '$receiver_name'})}}>
                            $receiver_name
                        </Button>
                    </Form.Group>
                    <Form.Button color='green'  content='Save' onClick={this.handleSignatureSubmit.bind(this)}/>
                </Form>
            </Segment>
        );
    }
}


const NewSignature= ({ adminId, refetchQuery}) => ( <Mutation mutation={NEW_SIGNATURE_MUTATION}>
    {(addSignature) => (
        <NewSignatureContainer adminId={adminId} createSignature={addSignature} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default NewSignature;

