import React, {Component} from 'react';
import gql from 'graphql-tag';
import {
    Header,
    Segment,
    Icon, Comment, Popup, Pagination, Button, Checkbox
} from 'semantic-ui-react'
import Info from '../components/Info';
import {Query} from "react-apollo";
import {Link} from "react-router-dom";
import CopyTextToClipboard from "../components/CopyTextToClipboard";
import MarkExternalSmsRead from "../components/external-contact/MarkExternalSmsRead";

const PAGE_SIZE = 50;
const moment = require('moment');
const _ = require('lodash');

class ExternalMessagesContainer extends Component {
    constructor(props) {
        super(props);
        const settings = JSON.parse(localStorage.getItem('external-messages-settings')) || {};
        console.log(settings);
        this.state = {
            fetchingMessages: false,
            externalSms: [],
            signatures: [],
            selectedContact: null,
            removeConfirmOpen: false,
            addOpen: false,
            settings,
            searchText: settings.searchText
        };

    }

    toggleSettings = (refetch, filter, value) => {
        const {settings} = this.state;

        if (filter === 'direction') {
            settings.direction = value;
            if(value === 'sent') {
                settings.unReadOnly = false;
            }
        } else if (filter === 'unReadOnly') {
            settings.unReadOnly = value;
        } else if (filter === 'mine') {
            settings.isMine = value
        }
        localStorage.setItem('external-messages-settings', JSON.stringify(settings));

        console.log(this.props.match.params.page || 0);

        if (this.props.match.params.page !== 0) {
            this.props.history.push('/external-messages');
        }

        refetch(getExternalMessagesQueryVariables(this.props));
    };


    render() {
        const {
            allExternalSms,
            allAdmins,
            match: {params: {page}},
            history,
            refetch,
        } = this.props;

        const {settings} = this.state;
        const pageNo = page ? parseInt(page, 10) : 1;
        const navigate = url => {
            history.push(`/external-messages/${url}`)
        };

        const pagination = (
            <Pagination
                boundaryRange={0}
                defaultActivePage={pageNo}
                ellipsisItem={null}
                lastItem={null}
                siblingRange={1}
                prevItem={undefined}
                nextItem={undefined}
                totalPages={
                    _.get(allExternalSms, 'nodes.length', 0) < PAGE_SIZE
                        ? pageNo
                        : pageNo + 1
                }
                onPageChange={(e, data) => {
                    navigate(data.activePage)
                }}
            />
        );


        return (
            <div style={{padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Header as='h1' textAlign='center' color='blue'>
                    External Messages
                </Header>

                    <Segment style={{display: 'flex', alignItems: 'center'}} basic >
                        <Button.Group>
                            <Button style={{margin: 5}} icon='long arrow alternate left' label='Received'
                                    color={settings && (!settings.direction || settings.direction === 'received') ? 'green' : 'grey'}
                                    onClick={() => {
                                        this.toggleSettings(refetch, 'direction', 'received')
                                    }}/>
                            <Button style={{margin: 5}} icon='long arrow alternate right' label='Sent'
                                    color={settings && settings.direction === 'sent' ? 'green' : 'grey'}
                                    onClick={() => {
                                        this.toggleSettings(refetch, 'direction', 'sent')
                                    }}/>
                            <Button style={{margin: 5}} icon='arrows alternate horizontal' label='All'
                                    color={settings && settings.direction === 'all' ? 'green' : 'grey'}
                                    onClick={() => {
                                        this.toggleSettings(refetch, 'direction', 'all')
                                    }}/>
                        </Button.Group>
                        <Checkbox style={{margin: 5}} toggle label='Unread messages only' onChange={() => {
                            this.toggleSettings(refetch, 'unReadOnly', !(settings && settings.unReadOnly))
                        }} checked={settings && settings.unReadOnly}/>

                        <Checkbox style={{margin: 5}} toggle label='Just display my conversations' onChange={() => {
                            this.toggleSettings(refetch, 'mine', !(settings && settings.isMine))
                        }} checked={settings && settings.isMine}/>

                    </Segment>

                <Segment padded style={{width: '80%'}}>
                <Comment.Group size='large' style={{textAlign: 'left', maxWidth: '100%'}}>

                    {_.map(allExternalSms.nodes, (sms) => {
                            let statusIcon;
                            if (sms.type === 'pending') {
                                statusIcon = <Icon color='purple' name='wait'/>;
                            } else if (sms.type === 'sent') {
                                statusIcon = <Icon color='green' name='long arrow alternate right'/>
                            } else if (sms.type === 'received') {
                                statusIcon = <Icon color='green' name='long arrow alternate left'/>
                            } else {
                                statusIcon = <Popup
                                    trigger={<Icon color='red' name='x'/>}
                                    content={sms.failreason}
                                    size='large'/>
                            }

                            let avatarUrl = '/img/admin.jpg';
                            if (sms.type === 'received') {
                                avatarUrl = '/img/trainer.png';
                            } else {
                                if (sms.sender === 'Q&A' || sms.sender === 'Q/A') {
                                    avatarUrl = '/img/qa.png';
                                } else {
                                    const admin = allAdmins && allAdmins.nodes && _.find(allAdmins.nodes, (admin) => admin.fullname === sms.sender);

                                    avatarUrl = (admin && admin.avatarByAvatar) ? admin.avatarByAvatar.url : avatarUrl;
                                }
                            }
                            return (
                                <Comment as="div" style={{width: '100%'}} key={sms.id}>
                                    <Comment.Avatar
                                        src={avatarUrl}/>
                                    <Comment.Content>
                                        <Comment.Author
                                            as="i"><Link
                                            to={'/external-contact/' + sms.contact}>{sms.sender}</Link>
                                            {statusIcon}
                                            {sms.type === 'sent' ? <Link to={'/external-contact/' + sms.contact}>{sms.externalContactByContact.fullname}</Link>
                                            : ''}</Comment.Author>
                                        <Comment.Metadata>

                                            {sms.sentAt &&
                                            (<Popup
                                                trigger={<div>{moment(sms.sentAt).fromNow()} </div>}
                                                content={moment(sms.sentAt).format('YYYY-MM-DD HH:mm')}
                                                size='large'/>)
                                            }

                                        </Comment.Metadata>
                                        <Comment.Text>
                                        <pre
                                            style={{whiteSpace: 'pre-wrap'}}
                                        >
                                            {sms.text}
                                        </pre>
                                        </Comment.Text>
                                        <div style={{display: 'flex', justifyContent:'flex-end'}}>
                                            { sms.type === 'received' ? <MarkExternalSmsRead id={sms.id} isRead={sms.read} /> : ''}
                                        <CopyTextToClipboard
                                            text={sms.text}
                                            styles={{textAlign: 'right'}}
                                        />
                                        </div>
                                    </Comment.Content>
                                </Comment>

                            )
                        }
                    )}
                </Comment.Group>
                {pagination}
                </Segment>
            </div>
        );

    }
}

const externalSmsQuery = gql`
    query ExternalSmsQuery(
        $direction: String,
        $unreadOnly: Boolean,
        $admin: Int,
        $limitNo: Int,
        $offsetNo: Int
        $region: Int
    ) {
        externalSms: filterExternalSms2(adminId: $admin, direction: $direction, unreadOnly: $unreadOnly, limitNo: $limitNo, offsetNo: $offsetNo, regionIn: $region) {
            nodes {
                id
                text
                type
                sentAt
                sender
                numberoftries
                failreason
                contact
                read
                externalContactByContact {
                    id
                    fullname
                }
            }
        }
        allAdmins{
            nodes {
                id
                fullname
                avatarByAvatar {
                    id
                    url
                }
            }
        }
    }
`;


function getExternalMessagesQueryVariables(ownProps) {
    const settings = JSON.parse(localStorage.getItem('external-messages-settings')) || {};
    const admin = JSON.parse(localStorage.getItem('current-admin'));
    const region = parseInt(localStorage.getItem('selected-region')|| '1')
    //console.log(settings);
    return {
        offsetNo: (ownProps.match.params.page ? parseInt(ownProps.match.params.page, 10) - 1 : 0) * PAGE_SIZE,
        limitNo: PAGE_SIZE,
        unreadOnly: (settings && settings.unReadOnly === true) || null,
        direction: (settings && settings.direction) || 'received',
        admin: (settings && settings.isMine && admin && admin.id) || null,
        region
    };
}

const ExternalMessages = (props) => (
    <Query query={externalSmsQuery} variables={getExternalMessagesQueryVariables(props)}
        fetchPolicy='network-only' pollInterval='30000'>
        {({data, loading, error, refetch}) => {
            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true}/>;
            }
            if (data && data.externalSms && data.allAdmins) {
                return <ExternalMessagesContainer allExternalSms={data.externalSms} refetch={refetch} history={props.history} allAdmins={data.allAdmins} match={props.match}/>
            }
            return <Info icon='warning'
                         message={(error && error.message) || 'Sorry, we could not display external sms at the moment!'}
                         title='Error' loading={false}/>;
        }}
    </Query>);

export default ExternalMessages;
