import React, {Component} from 'react';
import gql from "graphql-tag";
import {Container, Header, Icon, Card, Image, Grid, Statistic, Table, Button, List} from 'semantic-ui-react';
import { Query } from "react-apollo";
import moment from 'moment'
import Info from '../components/Info';
import EditSignature from "../components/signature/EditSignature";
import NewSignature from "../components/signature/NewSignature";
import _ from 'lodash';
import {Link} from "react-router-dom";
import UpdateAdmin from "../components/admin/UpdateAdmin";

const adminQuery = gql`
    query AdminQuery($id: Int!) {
        admin: adminById(id: $id) {
            id
            fullname
            createdAt
            description
            avatarByAvatar {
                id
                url
            }
            questionsByModeratedby (orderBy:  [APPROVEDAT_DESC], first: 20) {
                totalCount
                nodes {
                    id
                    articleByArticleid {
                        id
                        title
                        createdat
                    }
                }
            }
            externalContactsByCreatedBy (orderBy:  [LAST_MESSAGE_TIME_DESC, CREATED_AT_DESC], first: 20) {
                totalCount
                nodes {
                    id
                    fullname
                    lastMessageTime
                }
            }
            signaturesByAdmin {
                nodes {
                    id
                    title
                    text
                    group
                }
            }

        }
    }

`;

class AdminContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedSignature: null,
            newSignature: false
        };
        this.renderExternalContacts = this.renderExternalContacts.bind(this);
        this.renderModerations = this.renderModerations.bind(this);
    };

    refetchAndClose(shouldClose) {
        this.props.refetchQuery({id: this.props.admin.id});
        if(shouldClose) {
            this.setState({selectedSignature: undefined, newSignature: false})
        }
    }

    renderExternalContacts() {
        const {admin : {externalContactsByCreatedBy}} = this.props;

        return _.map(externalContactsByCreatedBy.nodes, (ec) => (
            <List.Item key={ec.id} name={ec.id}><List.Content>{ec.fullname} @ {moment(ec.lastMessageTime).format('MMMM Do, h:mm a')}</List.Content></List.Item>
        ));
    }

    renderModerations() {
        const {admin : {questionsByModeratedby}} = this.props;

        return _.map(questionsByModeratedby.nodes, (q) => (
            <List.Item key={q.id} name={q.id}><List.Content><Link to={`/article/${q.articleByArticleid.id}`}>{q.articleByArticleid.title} @ {moment(q.articleByArticleid.createdat).format('MMMM Do, h:mm a')}</Link></List.Content></List.Item>
        ));
    }

    render() {
        const {admin} = this.props;
        const {selectedSignature} = this.state;

        if (admin && admin.id) {
            return (
                <div>
                    <Header as='h1' textAlign='center' color='blue'>
                        {`Admin: ${admin.fullname}`}
                    </Header>
                    <Container style={{marginTop: 20}}>
                        <Grid container stackable celled padded>
                            <Grid.Row width={16}>
                                <Grid.Column width={5}>
                                    <Card raised fluid>
                                        {admin.avatarByAvatar && (
                                            <Image
                                                src={process.env.PUBLIC_URL + admin.avatarByAvatar.url}
                                                wrapped
                                                ui={false}
                                            />
                                        )}
                                        <Card.Content>
                                            <Card.Header>{admin.fullname}</Card.Header>
                                            <Card.Meta>Joined
                                                in {moment(admin.createdAt).format('MMM YYYY')}</Card.Meta>
                                            <Card.Description>
                                                {admin.description}
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Statistic size='mini' text>
                                                <Statistic.Label>Articles</Statistic.Label>
                                                <Statistic.Value>
                                                    <Icon name='adn'/>{admin.questionsByModeratedby.totalCount}
                                                </Statistic.Value>
                                            </Statistic>
                                            <Statistic size='mini' text>
                                                <Statistic.Label>Contacts</Statistic.Label>
                                                <Statistic.Value>
                                                    <Icon
                                                        name='address book outline'/>{admin.externalContactsByCreatedBy.totalCount}
                                                </Statistic.Value>
                                            </Statistic>
                                        </Card.Content>
                                    </Card>
                                    <UpdateAdmin adminId={admin.id}/>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <Header as='h3' color='red'>Message Templates</Header>
                                    <Table striped basic='very' celled collapsing selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Title</Table.HeaderCell>
                                                <Table.HeaderCell>Text</Table.HeaderCell>
                                                <Table.HeaderCell>Group</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {admin.signaturesByAdmin.nodes.map((node) => {
                                                return (
                                                    <Table.Row key={node.id}
                                                               onClick={() => this.setState({selectedSignature: node})}>
                                                        <Table.Cell>{node.title}</Table.Cell>
                                                        <Table.Cell>{node.text}</Table.Cell>
                                                        <Table.Cell>{node.group}</Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                        </Table.Body>
                                    </Table>
                                    <Button onClick={() => this.setState({newSignature: !this.state.newSignature})}>Add new</Button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                {this.state.selectedSignature ?
                                    <EditSignature adminId={admin.id} signature={selectedSignature}
                                                   refetchQuery={this.refetchAndClose.bind(this)}/> : ''}

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    {this.state.newSignature ?
                                        <NewSignature adminId={admin.id} refetchQuery={this.refetchAndClose.bind(this)}/> : ''}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Header as='h3' color='red'>Last 20 External Contacts</Header>
                                    <List divided relaxed='very' style={{textAlign: 'left'}}>
                                        {this.renderExternalContacts()}
                                    </List>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header as='h3' color='red'>Last 20 Moderations</Header>
                                    <List divided relaxed='very' style={{textAlign: 'left'}}>
                                        {this.renderModerations()}
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            );
        } else {
            return <Info icon='warning' message={'Sorry, we could not find the admin you requested!'} title='Error'
                         loading={false}/>;
        }
    }
};

const AdminPage = (props) => ( <Query query={adminQuery} variables={{ id: parseInt(props.match.params.adminId)}}>
    {({ data, loading, error, refetch }) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if(data && data.admin) {
            return <AdminContainer refetchQuery={refetch} admin={data.admin}/>
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, we could not find the admin you requested!'} title='Error' loading={false}/>;
    }}
</Query>);

export default AdminPage;

