import React from 'react'
import gql from 'graphql-tag';
import {Header, Container} from 'semantic-ui-react'
import Info from '.././Info';
import {
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    Resizable,
    BarChart,
    styler,
} from "react-timeseries-charts";
import { Query } from "react-apollo";
import _ from "lodash";
import { TimeSeries} from "pondjs";
const moment = require('moment');

class EventStatsContainer extends React.Component {
    state = {
        selection: null
    };

    render() {
        const style = styler([
            { key: "active", color: "#A5C8E1", selected: "#2CB1CF" },
            { key: "suspended", color: "#FFCC9E", selected: "#2CB1CF" },

        ]);

        const selectedDate = this.state.selection
            ? this.state.selection.event.index().toNiceString()
            : "--";
        const selectedValue = this.state.selection
            ? `${+this.state.selection.event.value('active')} active, ${+this.state.selection.event.value('suspended')} suspended`
            : "--";
        const highlight = this.state.highlight;

        let infoValues = [];

        if (highlight) {
            const eventNumbersText = `${highlight.column +  ' '+highlight.event.get(highlight.column)}`;
            infoValues = [{ label: "Event Count", value: eventNumbersText }];
        }


        const {eventsPerDay} = this.props;
        if (eventsPerDay && eventsPerDay.nodes) {

            const suspendedEvents = _.map(_.filter(eventsPerDay.nodes, ['suspended', true]), (node) => ({
                eventDay: node.eventDay,
                suspendedTotal: parseInt(node.total, 10)
            }));
            const activeEvents = _.map(_.filter(eventsPerDay.nodes, ['suspended', false]), (node) => ({
                eventDay: node.eventDay,
                activeTotal: parseInt(node.total, 10)
            }));

            let maxNumber = 0;
            const data = _.map(_.sortBy(_.unionWith(suspendedEvents, activeEvents, (x, y) => x.eventDay === y.eventDay), 'eventDay'), (node) => {
                const activeCount = node.activeTotal || 0;
                const suspendedCount = node.suspendedTotal || 0;
                maxNumber = maxNumber > (activeCount+suspendedCount) ? maxNumber : (activeCount+suspendedCount);
                return [moment(node.eventDay).format('YYYY-MM-DD'),
                    activeCount,
                    suspendedCount
                ];
            });


            const series = new TimeSeries({
                name: 'Number of Events',
                columns: ['index', 'active', 'suspended'],
                points: data
            });

            if(_.isEmpty(data)) {
                return <Container>
                    <Header as='h2' textAlign='center' color='red'>
                        Upcoming Events Per Day
                    </Header>
                    <Info icon='warning' message={'Sorry, we could not find any future events!'}
                          title='Error' loading={false}/>
                </Container>
            }

            return (
                <Container>
                    <Header as='h2' textAlign='center' color='red'>
                        Upcoming Events Per Day
                    </Header>
                    <p style={{display: this.state.selection ? 'block' : 'none'}}>{selectedValue} events on {selectedDate}</p>
                    <Resizable>
                        <ChartContainer
                            timeRange={series.range()}
                            format="day"
                            onBackgroundClick={() => this.setState({selection: null})}
                        >
                            <ChartRow height="150">
                                <YAxis
                                    id="event-count"
                                    label="Number of Events"
                                    classed="traffic-in"
                                    min={0}
                                    max={maxNumber}
                                    width="70"
                                    type="linear"
                                />
                                <Charts>
                                    <BarChart
                                        axis="event-count"
                                        spacing={3}
                                        columns={["active", "suspended"]}
                                        series={series}
                                        style={style}
                                        info={infoValues}
                                        highlighted={this.state.highlight}
                                        onHighlightChange={highlight =>
                                            this.setState({ highlight })
                                        }
                                        selected={this.state.selection}
                                        onSelectionChange={selection =>
                                            this.setState({ selection })
                                        }
                                    />
                                </Charts>
                            </ChartRow>
                        </ChartContainer>
                    </Resizable>
                </Container>
            );
        } else {
            return <Info icon='warning' message={'Sorry, we could not find any future events!'}
                         title='Error' loading={false}/>;
        }

    }
};

const eventStatsQuery = gql`
    query EventsPerDayQuery {
        eventsPerDay {
            nodes {
                eventDay
                suspended
                total
            }
        }
    }
`;

const EventStats = () => ( <Query query={eventStatsQuery}>
    {({ data, loading, error }) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if(data && data.eventsPerDay) {
            return <EventStatsContainer eventsPerDay={data.eventsPerDay}/>
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, we could not find any future events'} title='Error' loading={false}/>;
    }}
</Query>);

export default EventStats;
