import React from 'react'

import {Icon, Popup} from 'semantic-ui-react'

const MessageStatusIcon = (props) => {

    const {type, displayLabel, failReason, hide} = props;

    if(hide) {
        return '';
    }

    switch (type) {
    case 'received': return <span><Icon name='long arrow alternate left' color='green'/>{displayLabel ? 'Received' : ''}</span>;
    case 'sent': return <span><Icon name='long arrow alternate right' color='green'/>{displayLabel ? 'Sent' : ''}</span> ;
    case 'pending': return <span><Icon name='wait' color='purple'/>{displayLabel ? 'Pending' : ''}</span>;
    case 'failed': return <Popup
        trigger={<span><Icon name='x' color='red'/>{displayLabel ? 'Failed' : ''}</span>}
        content={failReason}
        size='large'/> ;
    default: return '';
    }

};

export default MessageStatusIcon;
