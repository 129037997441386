import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import React from "react";
import {Button, } from "semantic-ui-react";


const ASSIGN_EXTERNAL_CONTACT_MUTATION = gql`
    mutation assignExternalContact($input: AssignExternalContactInput!) {
        assignExternalContact(input: $input) {
            externalContact {
                id
                fullname
            }
        }
    }
`;



const AssignExternalContactContainer = ({assignExternalContact, externalContact, refetchQuery}) => {


    const currentAdmin =  JSON.parse(localStorage.getItem('current-admin'));

    const assign =  () => {
        assignExternalContact({
            variables: {
                input: {
                    contactId: externalContact.id
                }
            }, refetchQueries: [{
                query: refetchQuery,
                variables: {
                    id: externalContact.id,
                    adminId: currentAdmin.id
                }
            }]
        });
    }

    if(!currentAdmin || currentAdmin.id !== externalContact.adminByCreatedBy.id) {
        return (
            <div style={{ 'marginBottom': 10}}><Button compact={true} color='green' onClick={assign}>Assign to me</Button></div>
        );
    }

    return <span/>;

};

const AssignExternalContact = ({externalContact, refetchQuery}) => (
    <Mutation mutation={ASSIGN_EXTERNAL_CONTACT_MUTATION}>
        {(assignExternalContact) => (
            <AssignExternalContactContainer assignExternalContact={assignExternalContact} externalContact={externalContact} refetchQuery={refetchQuery}/>
        )}
    </Mutation>);

export default AssignExternalContact;
