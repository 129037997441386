import React, { useState } from 'react';
import _ from 'lodash';

import { Header, Segment, Button, Dropdown, Icon } from 'semantic-ui-react';

export function parseSignature({ message, contact }) {
    const admin = JSON.parse(localStorage.getItem('current-admin'));
    let result = message;
    // console.log('contact.fullname)', contact.fullname)
    result = _.replace(result, /\$full_name/g, contact.fullname);
    result = _.replace(result, /\$admin_name/g, admin.fullname);
    result = _.replace(result, /\$receiver_name/g, _.get(contact, 'receiverByReceiver.displayName', 'WARNING'));

    const name = contact.fullname || '';
    const lastSpace = name.lastIndexOf(' ');
    const firstSpace = name.indexOf(' ');
    const firstName = name.substring(0, firstSpace > 0 ? firstSpace : name.length);
    const lastName = name.substring(lastSpace > 0 ? lastSpace : 0);
    result = _.replace(result, /\$first_name/g, firstName);
    result = _.replace(result, /\$last_name/g, lastName);

    return result;
}

const emptyFunc = () => {};
export default function(props) {
    const { signatures = [], onChangeSignature = emptyFunc, onClick = emptyFunc, selectedContact } = props;

    const [selectedSignature, setSelectedSignature] = useState();
    const draftMessage = _.get(_.find(signatures, s => s.id === selectedSignature), 'text');

    return (
        <Segment>
            <Header as="h3" textAlign="center" color="blue" style={{ padding: 16 }}>
                Message Templates
            </Header>
            <Dropdown
                style={{ marginTop: 10 }}
                placeholder="Select messsage template"
                fluid
                selection
                onChange={(e, { value }) => {
                    setSelectedSignature(value);
                    onChangeSignature(value);
                }}
                options={_.map(signatures, s => ({
                    key: s.id,
                    text: s.title,
                    value: s.id,
                }))}
            />
            <pre style={{ whiteSpace: 'pre-wrap' }}>
                {selectedContact
                    ? parseSignature({
                          contact: selectedContact,
                          message: draftMessage,
                      })
                    : draftMessage}
            </pre>
            <Button
                disabled={!selectedSignature}
                onClick={() => {
                    if (selectedContact) {
                        onClick(
                            parseSignature({
                                contact: selectedContact,
                                message: draftMessage,
                            }),
                        );
                    } else {
                        onClick(draftMessage);
                    }
                }}
                color="yellow"
            >
                {' '}
                <Icon name="clipboard" /> Copy as new message
            </Button>
        </Segment>
    );
}
