import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Statistic} from 'semantic-ui-react'
import Info from '.././Info';
import StatsTeamsModal from "./StatsTeamsModal";
import StatsContactsModal from "./StatsContactsModal";
import {Query} from "react-apollo";

const LeagueContactStatsContainer = (props) => {
    const [teamOpen, setTeamOpen] = React.useState(false)
    const [activeContactsOpen, setActiveContactsOpen] = React.useState(false)
    const [inprogressContactsOpen, setInprogressContactsOpen] = React.useState(false)
    const [onboardedCountContactsOpen, setOnboardedCountContactsOpen] = React.useState(false)
    const [notOnboardedCountContactsOpen, setNotOnboardedCountContactsOpen] = React.useState(false)
    const {activeCount, inActiveCount, inprogressCount, notOnboardedCount, onboardedCount, league, fromDate, toDate} = props;
    const { teamsByLeagueid : {totalCount}} = league;
    return (
        <Segment inverted raised={true} >
            <Header as={'h3'} color={"black"}>{league.name}</Header>
            <Header as={'h4'} color={"black"}>{`${league.teamclass} - ${league.sportname}`}</Header>
            <Statistic.Group horizontal inverted color={'orange'} size={'small'}>
                <StatsTeamsModal league={league} teamOpen={teamOpen} setTeamOpen={setTeamOpen}/>
                <StatsContactsModal league={league} open={activeContactsOpen} setOpen={setActiveContactsOpen}
                    contactTypeIn={'active'} fromDate={fromDate} toDate={toDate} statLabel={'Active Contacts'}
                    statValue={activeCount + ' - '+ (activeCount/(activeCount+inprogressCount) *100).toFixed(0)+"%"}/>

                <StatsContactsModal league={league} open={inprogressContactsOpen} setOpen={setInprogressContactsOpen}
                    contactTypeIn={'in_progress'} fromDate={fromDate} toDate={toDate} statLabel={'Contacts In Progress'}
                    statValue={inprogressCount + ' - '+ (inprogressCount/(activeCount+inprogressCount) *100).toFixed(0)+"%"}/>

                <StatsContactsModal league={league} open={onboardedCountContactsOpen} setOpen={setOnboardedCountContactsOpen}
                    contactTypeIn={'onboarded'} fromDate={fromDate} toDate={toDate} statLabel={'Onboarded Contacts'}
                    statValue={onboardedCount}/>

                <StatsContactsModal league={league} open={notOnboardedCountContactsOpen} setOpen={setNotOnboardedCountContactsOpen}
                    contactTypeIn={'not_onboarded'} fromDate={fromDate} toDate={toDate} statLabel={'Not Onboarded Contacts'}
                    statValue={notOnboardedCount}/>

                {/*<Statistic>*/}
                {/*    <Statistic.Value>{activeCount} - {(activeCount/(activeCount+inprogressCount) *100).toFixed(0)+"%"}</Statistic.Value>*/}
                {/*    <Statistic.Label>Active Contacts</Statistic.Label>*/}
                {/*</Statistic>*/}

                <Statistic>
                    <Statistic.Value>
                        {inActiveCount}
                    </Statistic.Value>
                    <Statistic.Label>Inactive Contacts</Statistic.Label>
                </Statistic>

                {/*<Statistic>*/}
                {/*    <Statistic.Value>*/}
                {/*        {inprogressCount} - {(inprogressCount/(activeCount+inprogressCount) *100).toFixed(0)+"%"}*/}
                {/*    </Statistic.Value>*/}
                {/*    <Statistic.Label>In progress</Statistic.Label>*/}
                {/*</Statistic>*/}

                {/*<Statistic>*/}
                {/*    <Statistic.Value>*/}
                {/*        {onboardedCount}*/}
                {/*    </Statistic.Value>*/}
                {/*    <Statistic.Label>Onboarded</Statistic.Label>*/}
                {/*</Statistic>*/}
                {/*<Statistic>*/}
                {/*    <Statistic.Value>*/}
                {/*        {notOnboardedCount}*/}
                {/*    </Statistic.Value>*/}
                {/*    <Statistic.Label>Not Onboarded</Statistic.Label>*/}
                {/*</Statistic>*/}
            </Statistic.Group>
            <div style={{marginTop: 25}}><i>All data displayed here are based on contact creation time.</i></div>
        </Segment>
    );



};

const leagueContactStatsQuery = gql`
    query LeagueContacts($leagueId: String!, $date: String, $toDate: String) {
        activeContactCount: contactCount2(leagueIdIn: $leagueId, fromDate: $date, toDate: $toDate, contactTypeIn: "active")
        inactiveContactCount: contactCount2(leagueIdIn: $leagueId, fromDate: $date, toDate: $toDate, contactTypeIn: "inactive")
        inprogressContactCount: contactCount2(leagueIdIn: $leagueId, fromDate: $date, toDate: $toDate, contactTypeIn: "in_progress")
        onboardedContactCount: contactCount2(leagueIdIn: $leagueId, fromDate: $date, toDate: $toDate, contactTypeIn: "onboarded")
        notonboardedContactCount: contactCount2(leagueIdIn: $leagueId, fromDate: $date, toDate: $toDate, contactTypeIn: "not_onboarded")
    }
`;

const LeagueContactStats = ({ league, date, dateTo }) => (<Query query={leagueContactStatsQuery} variables={{leagueId: league.id, date: date, toDate: dateTo}}>
    {({data, loading, error}) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if (data) {
            return <LeagueContactStatsContainer activeCount={data.activeContactCount}
                inActiveCount={data.inactiveContactCount}
                inprogressCount={data.inprogressContactCount}
                onboardedCount={data.onboardedContactCount}
                notOnboardedCount={data.notonboardedContactCount}
                league={league}
                toDate={dateTo}
                fromDate={date}
            >
            </LeagueContactStatsContainer>
        }
        return <Info icon='warning'
            message={(error && error.message) || 'Sorry, we could not find any league contacts'}
            title='Error' loading={false}/>;
    }}
</Query>);

export default LeagueContactStats;
