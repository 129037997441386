import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Feed, Card} from 'semantic-ui-react'
import Info from '.././Info';
import {Query} from "react-apollo";
import _ from "lodash";
import {ExternalContactCardContainer } from "./ExternalContactCard";

const NewExternalContactsContainer = (props) => {
    const {externalContacts} = props;

    if(externalContacts && externalContacts.length > 0) {
        const externalContactCards = _.map(externalContacts, (c) => (<ExternalContactCardContainer contactPerson={c}/>))
        return (
            <Segment compact={true}>
                <Header as='h2' textAlign='center' color='red'>

                </Header>
                <Feed size='small'>
                    <Header as='h4' color={'red'}>New External Contacts</Header>
                    <i>These contacts were received from article receivers and need to be contacted by an admin. Please assign them to yourself and contact them as soon as possible.</i>
                    <Card.Group style={{marginTop: 15}} itemsPerRow={2}>{externalContactCards}</Card.Group>
                </Feed>
            </Segment>
        );
    }
    return (
        <Segment compact={true} style={{maxWidth: 400}}>
            <Header as='h3' textAlign='center' color='olive'>
                No new external contacts received from article receivers.
            </Header>
        </Segment>
    );
};

const externalContactsQuery = gql`
    query NewExternalContacts($region: Int) {
        externalContacts: filterNewExternalContacts(regionIn: $region ){
            nodes{
                id
                fullname
                note
                createdAt
                createdBy
                phone
                teamByTeamId {
                    id
                    name
                    leagueByLeagueid {
                        id
                        name
                    }
                }
                adminByCreatedBy {
                    id
                    fullname
                    avatarByAvatar {
                        id
                        url
                    }
                }
                deleted
                onboarded
                receiverByReceiver {
                    id
                    name
                    displayName
                    key
                }
            }
        }
    }
`;

const NewExternalContacts = () => (<Query query={externalContactsQuery} variables={{region: parseInt(localStorage.getItem('selected-region')|| '1')}}>
    {({data, loading, error}) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading new external contacts..." loading={true}/>;
        }
        if (data && data.externalContacts) {
            return <NewExternalContactsContainer externalContacts={data.externalContacts.nodes}/>
        }
        return <Info icon='warning'
            message={(error && error.message) || 'Sorry, we could not fetch new contacts'}
            title='Error' loading={false}/>;
    }}
</Query>);

export default NewExternalContacts;
