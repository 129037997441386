import React, {Component} from 'react';
import gql from "graphql-tag";
import _ from "lodash";
import {
    Container,
    Header,
    Table,
    Button,
    Segment,
} from 'semantic-ui-react';
import { Query } from "react-apollo";
import Info from '../Info';
import {Link} from "react-router-dom";
import AddAdmin from "./AddAdmin";
import RemoveAdminFromRegion from "./RemoveAdminFromRegion";

const adminQuery = gql`
    query AdminsQuery($region: Int) {
        allRegions {
            nodes{
                id
                name
            }
        }
        admins:filterAdmins(regionIn: $region) {
            nodes {
                id
                fullname
                description
                avatarByAvatar{
                    id
                    url
                }
                phone
                adminRegionsByAdminId{
                    totalCount
                    nodes{
                        regionByRegionId{
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;


class AdministrationContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newAdmin: false,
        };
    };

    render() {
        const {admins, selectedRegion, displayEdit, displayRemove, displayAdd, regions} = this.props;
        const selectedRegionData = _.find(regions?.nodes, (n) => n.id === selectedRegion);

        return (
            <Container fluid attached={"top"}>
                <Header as='h4' color='grey'>Admins in {selectedRegionData?.name} region{selectedRegion > 0 ? '' : 's'}:</Header>
                <Table striped  celled collapsing selectable size={"large"} attached={"top"} columns={"16"} >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Details</Table.HeaderCell>
                            <Table.HeaderCell>Regions</Table.HeaderCell>
                            {displayEdit ? <Table.HeaderCell>Details</Table.HeaderCell> : ''}
                            {displayRemove ? <Table.HeaderCell>Remove</Table.HeaderCell> : ''}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {admins.nodes.map((node) => {
                            return (
                                <Table.Row key={node.id}>
                                    <Table.Cell>{node.id}</Table.Cell>
                                    <Table.Cell>{node.fullname}</Table.Cell>
                                    <Table.Cell>{node.description}</Table.Cell>
                                    <Table.Cell>{node.adminRegionsByAdminId?.totalCount}</Table.Cell>
                                    {displayEdit ? <Table.Cell><Link to={'/admin/'+node.id}>&#62;&#62;</Link></Table.Cell> : ''}
                                    {displayRemove ? <Table.Cell><RemoveAdminFromRegion refetchQuery={adminQuery} adminId={node.id} regionId={selectedRegion}/> </Table.Cell>: ''}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                {
                    displayAdd ?
                        <Segment>
                            <Button color={'green'} icon={'add'} content={'Add a new admin'} fluid onClick={() => this.setState({newAdmin: !this.state.newAdmin})}/>
                            {this.state.newAdmin ?
                                <AddAdmin refetchQuery={adminQuery} region={selectedRegion}/> : ''}
                            {this.state.selectedAdmin ?
                                this.state.selectedAdmin.fullname : ''}
                        </Segment>
                        : ''
                }


            </Container>
        );
    }
};

const AdminList = ({selectedRegion, displayEdit, displayRemove, displayAdd}) => {
    return (<Query query={adminQuery} variables={{region: selectedRegion > 0 ? selectedRegion : null}}>
        {({data, loading, error}) => {
            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true}/>;
            }
            if (data && data.admins) {
                return <AdministrationContainer admins={data.admins} selectedRegion={selectedRegion} regions={data.allRegions} displayEdit={displayEdit} displayRemove={displayRemove} displayAdd={displayAdd}/>
            }
            return <Info icon='warning' message={(error && error.message) || 'Sorry, admins query failed!'}
                         title='Error' loading={false}/>;
        }}
    </Query>);
};

export default AdminList;
