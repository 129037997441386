import {Mutation} from "react-apollo";
import React from "react";
import gql from "graphql-tag";
import {Button} from "semantic-ui-react";

const REMOVE_ADMIN_REGION_MUTATION = gql`
    mutation removeAdminRegion($input: RemoveAdminRegionInput!) {
        removeAdminRegion(input: $input){
            boolean
        }
    }
`;

const RemoveAdminFromRegionContainer = ({removeAdminRegion, adminId, regionId, refetchQuery}) => {
    const remove =  () => {
        removeAdminRegion({
            variables: {
                input: {
                    adminIn: adminId,
                    regionIn: regionId
                }
            }, refetchQueries: [{
                query: refetchQuery,
                variables: {
                    region: regionId
                }
            }]
        });
    }


    return (
        <div><Button icon={'minus'} size={"mini"} color='red' label={'Remove from region'} onClick={remove}/></div>
    );
};

const RemoveAdminFromRegion = ({adminId, regionId, refetchQuery}) => ( <Mutation mutation={REMOVE_ADMIN_REGION_MUTATION}>
    {(removeAdminRegion) => (
        <RemoveAdminFromRegionContainer removeAdminRegion={removeAdminRegion} adminId={adminId} regionId={regionId} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default RemoveAdminFromRegion;
