import React, {createRef} from "react";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import {Ref, Grid, Header, Table} from 'semantic-ui-react'
import Info from '../components/Info';


const CustomerStatsContainer = ({isLoading, error, customerStats}) => {
    const contextRef = createRef();

    if (error) {
        return <Info icon="warning" message={error.message} title="Error" loading={false}/>;
    }

    if (isLoading) {
        return <Info icon="circle notched" message="Loading..." loading={true}/>;
    }

    return (
        <div>
            <Ref innerRef={contextRef}>
                <Grid width={16} stackable centered padded>
                    <Grid.Row stretched>
                        <Header as='h1' textAlign='center' color='blue'>
                            CUSTOMER STATS
                        </Header>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <Table style={{width: '100%'}} celled collapsing striped selectable stackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>#</Table.HeaderCell>
                                        <Table.HeaderCell>ID</Table.HeaderCell>
                                        <Table.HeaderCell>Receiver Key * </Table.HeaderCell>
                                        <Table.HeaderCell>Teams With Active Contacts</Table.HeaderCell>
                                        <Table.HeaderCell>New Contacts (last 6 months)</Table.HeaderCell>
                                        <Table.HeaderCell>Requested Contacts (last 6 months)</Table.HeaderCell>
                                        <Table.HeaderCell>Articles with QnA (last month)</Table.HeaderCell>
                                        <Table.HeaderCell>Articles without QnA(last month)</Table.HeaderCell>
                                        <Table.HeaderCell>QnA Percentage (last month)</Table.HeaderCell>
                                        <Table.HeaderCell>Articles with QnA (last 7 days)</Table.HeaderCell>
                                        <Table.HeaderCell>Articles without QnA (last 7 days)</Table.HeaderCell>
                                        <Table.HeaderCell>QnA percentage(last 7 days)</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {customerStats?.nodes.map((node, index) => {
                                        return (
                                            <Table.Row key={node.id}>
                                                <Table.Cell>{index + 1}</Table.Cell>
                                                <Table.Cell>{node.id}</Table.Cell>
                                                <Table.Cell>{node.key}</Table.Cell>
                                                <Table.Cell>{node.teamActiveContacts}</Table.Cell>
                                                <Table.Cell>{node.newContacts}</Table.Cell>
                                                <Table.Cell>{node.requestedContact}</Table.Cell>
                                                <Table.Cell>{node.approvedArticles}</Table.Cell>
                                                <Table.Cell>{node.notApprovedArticles}</Table.Cell>
                                                <Table.Cell>{node.notApprovedArticles + node.approvedArticles > 0 ?  (node.approvedArticles * 100 / (node.notApprovedArticles + node.approvedArticles)).toFixed(2) : 0 }&#37;</Table.Cell>
                                                <Table.Cell>{node.weekApprovedArticles}</Table.Cell>
                                                <Table.Cell>{node.weekNotApprovedArticles}</Table.Cell>
                                                <Table.Cell>{node.weekApprovedArticles + node.weekNotApprovedArticles > 0 ? (node.weekApprovedArticles * 100 / (node.weekApprovedArticles + node.weekNotApprovedArticles)).toFixed(2) : 0}&#37;</Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Ref>
        </div>
    );
};

const customerStatsQuery = gql`
    query CustomerStatsQuery{
        customerStats : allCustomerStats{
            nodes {
                id
                key
                approvedArticles
                newContacts
                notApprovedArticles
                requestedContact
                teamActiveContacts
                weekApprovedArticles
                weekNotApprovedArticles
            }
        }
    }
`;


const CustomerStats = () => ( <Query query={customerStatsQuery}>
    {({ data, refetch, loading, error }) => {
        return <CustomerStatsContainer isLoading={loading} refetch={refetch} error={error} customerStats={data?.customerStats}/>
    }}
</Query>);


export default CustomerStats;
