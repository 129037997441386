import React from 'react';
import gql from "graphql-tag";
import {
    Container,
    Header,
    Segment, Label, Grid,
    Flag, Table
} from 'semantic-ui-react';
import { useQuery} from "react-apollo";
import Info from '../Info';
import AdminList from "./AdminList";
import AddAdminToRegion from "./AddAdminToRegion";

const regionPageQuery = gql`
    query RegionPageQuery($region: Int!) {
        regionById( id: $region) {
            id
            name
            phonePrefix
            leagueRegionsByRegionId {
                nodes{
                    id
                    leagueByLeagueId{
                        id
                        name
                        sportname
                        teamclass
                    }
                }
            }
        }
        admins:filterAdmins(regionIn: $region) {
            nodes {
                id
                fullname
                description
                avatarByAvatar{
                    id
                    url
                }
                phone
                adminRegionsByAdminId{
                    totalCount
                    nodes{
                        regionByRegionId{
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;



const RegionPage = (props) => {
    const {loading, error, data} = useQuery(regionPageQuery, { variables: {region: parseInt(props.match.params.regionId)}});

    if(loading) {
        return <Info icon="circle notched" message="Loading region data..." loading={true} />;
    }
    if (error) {
        return <Info icon="warning" message={error.message} title="Error loading region data" loading={false} />;
    }

    const { regionById } = data;
    const { leagueRegionsByRegionId} = regionById;
    return (
        <div>
            <Header as='h1' textAlign='center' color='blue'>
                {`Region: ${regionById.name}`}
            </Header>
            <Container style={{marginTop: 20}}>
                <Grid columns={2}>
                    <Grid.Column width={10}>
                        <Segment color='yellow' style={{textAlign: 'left'}}>
                            <Label as='h2' size='big' color='blue' ribbon style={{marginBottom: 15}}>Admins In Region</Label>
                            <AdminList selectedRegion={regionById.id} displayEdit={true} displayRemove={true}/>
                            <AddAdminToRegion refetchQuery={regionPageQuery} region={regionById.id}/>
                        </Segment>
                        <Segment color='yellow' style={{textAlign: 'left'}}>
                            <Label as='h2' size='big' color='blue' ribbon style={{marginBottom: 15}}>Leagues In Region</Label>
                            <Table striped  celled collapsing selectable size={"large"} attached={"top"} columns={"16"} >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>ID</Table.HeaderCell>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Sport</Table.HeaderCell>
                                        <Table.HeaderCell>Class</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {leagueRegionsByRegionId?.nodes.map((node) => {
                                        return (
                                            <Table.Row key={node?.leagueByLeagueId?.id}>
                                                <Table.Cell>{node?.leagueByLeagueId?.id}</Table.Cell>
                                                <Table.Cell>{node?.leagueByLeagueId?.name}</Table.Cell>
                                                <Table.Cell>{node?.leagueByLeagueId?.sportname}</Table.Cell>
                                                <Table.Cell>{node?.leagueByLeagueId?.teamclass}</Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Segment color='blue' textAlign='left'>
                            <Header as='h3' color='red'>Region Info</Header>
                            <div><b>Name:</b> {regionById.name} <Flag name={regionById.name.toLowerCase()}/></div>
                            <div><b>Phone Prefix:</b> {regionById.phonePrefix}</div>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
        </div>
    );

};

export default RegionPage;
