import React from "react";
import CopyTextToClipboard from "../CopyTextToClipboard";

import {Icon, Comment, Divider, Popup} from 'semantic-ui-react';

const _ = require('lodash');
const moment = require('moment');

const ExternalMessages = ( {externalSms, allAdmins }) => {

    const allExternalMessages =  _.map(externalSms, (sms) => {
        let statusIcon;
        if(sms.type === 'pending') {
            statusIcon = <Icon color='purple' name='wait'/>;
        } else if(sms.type === 'sent') {
            statusIcon = <Icon color='green' name='long arrow alternate right'/>
        } else if(sms.type === 'received') {
            statusIcon = <Icon color='green' name='long arrow alternate left'/>
        } else {
            statusIcon = <Popup
                trigger={<Icon color='red' name='x'/>}
                content={sms.failreason}
                size='large'/>
        }

        let avatarUrl = '/img/admin.jpg' ;
        if(sms.type === 'received') {
            avatarUrl = '/img/trainer.png';
        } else {
            if(sms.sender === 'Q&A' || sms.sender === 'Q/A') {
                avatarUrl = '/img/qa.png';
            } else {
                const admin = allAdmins && allAdmins.nodes && _.find(allAdmins.nodes,  (admin) => admin.fullname === sms.sender);

                avatarUrl = (admin && admin.avatarByAvatar) ? admin.avatarByAvatar.url : avatarUrl;
            }
        }

        return (
            <Comment as="div" key={sms.id}>
                <Comment.Avatar
                    src={avatarUrl}/>
                <Comment.Content>
                    <Comment.Author
                        as="i">{sms.sender}</Comment.Author>
                    <Comment.Metadata>
                        {statusIcon}
                        {sms.sentAt &&
                        (<Popup
                            trigger={<div>{moment(sms.sentAt).fromNow()} </div>}
                            content={moment(sms.sentAt).format('YYYY-MM-DD HH:mm')}
                            size='large'/>)
                        }

                    </Comment.Metadata>
                    <Comment.Text>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {sms.text}
                        </pre>
                    </Comment.Text>
                    <CopyTextToClipboard
                        text={sms.text}
                        styles={{ textAlign: 'right' }}
                    />
                </Comment.Content>
            </Comment>

        )}
    );

    return (
        <Comment.Group size='large' style={{textAlign: 'left'}}>
            {allExternalMessages}
            <Divider/>
        </Comment.Group>
    );
};

export default ExternalMessages;
