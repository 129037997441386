import {Query} from "react-apollo";
import gql from "graphql-tag";
import React, { Component } from 'react'
import _ from 'lodash'
import { Icon, Form} from 'semantic-ui-react'

class SeasonPickerContainer extends Component {
    constructor(params) {
        super(params);
        this.state = {value: (params.selectedSeason) || '-1'};
    }

    handleSeasonSelect = (season) => {
        this.props.onSeasonSelected(season > 0 ? season : null);
        this.setState({ value: season });
    }


    render() {
        const { isLoading, seasons } = this.props;

        const seasonOptions = _.map(seasons, r => ({ key:r.id, value: r.id, text: `${r.name}`}));
        seasonOptions.splice(0, 0, { key: '-1', value: '-1', text: 'NONE'});

        if(isLoading){
            return (
                <Icon name={'hourglass start'} />
            )
        }

        return (
            <Form.Select name={'season'} label='Season' placeholder='Select a season' options={seasonOptions} value={this.state.value}
                         onChange={(event,{value}) => this.handleSeasonSelect(value)}/>
        )
    }
}


const SEASONS_QUERY = gql`
    query seasons( $sportId: Int) {
        seasons: allEverysportSeasons(condition: {sportId: $sportId, active: true}, orderBy: [ID_DESC]) {
            nodes {
                id
                name
                regionId
            }
        }
    }
`;

const SeasonPicker = ({selectedSeason, sportId, onSeasonSelected}) => ( <Query query={SEASONS_QUERY} variables={{ sportId: sportId || null}}>
    {({ data, refetch, loading, error }) => {
        return <SeasonPickerContainer isLoading={loading} refetch={refetch} onSeasonSelected={onSeasonSelected} selectedSeason={selectedSeason} sportId={sportId || null} seasons={data?.seasons?.nodes}/>
    }}
</Query>);

export default SeasonPicker;
