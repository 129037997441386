import React, {Component} from 'react'
import gql from 'graphql-tag';
import {Header, Container, Form, Accordion, Segment, Grid, Label, Divider, Item, Button} from 'semantic-ui-react'
import {graphql} from 'react-apollo';
import Info from './Info';
import {Link} from 'react-router-dom';
import ArticleStatusIcon from "./ArticleStatusIcon";
import compose from "lodash.flowright";
import ReceiverTeams from "./receiver/ReceiverTeams";
import ReceiverStats from "./receiver/ReceiverStats";
const _ = require('lodash');
const moment = require('moment');

const TEAM_COUNT = 20;

class ReceiverContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            displayNameChanged: false ,
            noteChanged: false,
            visibleChanged: false,
            teamPage: 0
        };
        this.handleClick = this.handleClick.bind(this);
        this.renderTeams = this.renderTeams.bind(this);
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = this.state.activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    };

    handleUpdateReceiver = async () => {
        const {data: {receiver}} = this.props;

        try {
            await this.props.setReceiverInfo({
                variables: {
                    input: {
                        receiverId: receiver.id,
                        displayNameInput: (this.state.displayNameChanged ? this.state.displayName : receiver.displayName) || '',
                        noteInput: (this.state.noteChanged ? this.state.note : receiver.note) || '',
                        visibleInput: (this.state.visibleChanged ? this.state.visible : receiver.visible)
                    }
                }});
            this.setState({visibleChanged : false, noteChanged: false, displayNameChanged: false});
        } catch (e) {
            console.log('update failed for receiver', e);
        }

    };

    renderTeams = () => {
        const {data: {receiver}} = this.props;
        return _.map(receiver.teamReceiversByReceiverId.nodes, (team, i) =>
            (<div key={team.teamByTeamId.id}>
                <Accordion.Title
                    active={this.state.activeIndex === i}
                    content={<Label size='large' color='blue'>{team.teamByTeamId.name +' (' + team.teamByTeamId.articlesByFocusedteam.totalCount+ ')' }</Label>}
                    index={i}
                    color='olive'
                    onClick={this.handleClick}
                />
                <Accordion.Content active={this.state.activeIndex === i}>
                    <p><b>Team Info: </b>{team.teamByTeamId.leagueByLeagueid.name +' - ' + team.teamByTeamId.leagueByLeagueid.teamclass +' - ' + team.teamByTeamId.leagueByLeagueid.sportname}</p>
                    <Header as='h4'>Latest 10 Articles ( Total number of articles: {team.teamByTeamId.articlesByFocusedteam.totalCount})</Header>
                    <Divider/>
                    {_.map(team.teamByTeamId.articlesByFocusedteam.nodes, (article) => {
                        return <div key={article.id}><Link as='a'  to={'/article/'+article.id}>{article.title}</Link>  <i><ArticleStatusIcon status={article.status} displayLabel={true}/></i> <i>{moment(article.createdat).format('YYYY-MM-DD h:mm a')}</i></div>;
                    })}
                </Accordion.Content>
            </div>));
    };

    renderLatestArticles = () => {
        const {data: {articlesByReceiver}} = this.props;
        return _.map(articlesByReceiver.nodes, (article, i) =>
            (<Item key={article.id}>
                <Item.Image size='mini' src={article.teamByFocusedteam?.logo || '/img/noimage.png'} />

                <Item.Content>
                    <Item.Header as={Link} to={'/article/'+article.id}>{article.title}</Item.Header>
                    <Item.Meta>Created at {moment(article.createdat).format('YYYY-MM-DD HH:mm')}</Item.Meta>
                <Item.Extra><ArticleStatusIcon status={article.status} displayLabel={true}/></Item.Extra>
                </Item.Content>
            </Item>));
    };


    render() {
        const {data: {loading, error, receiver}} = this.props;
        const region = parseInt(localStorage.getItem('selected-region')|| '1')

        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }

        if (receiver && !error) {

            return (
                <div>
                    <Header as='h1' textAlign='center' color='blue'>
                        {`Article Receiver: ${receiver.displayName || receiver.name}`}
                    </Header>
                    <Container style={{marginTop: 20, width: '90%'}}>
                        <Grid streched='true' stackable>
                            <Grid.Row>
                                <Grid.Column width={"8"}>
                                    <Segment color='blue'>
                                        <Header as='h3' color='red'>Information</Header>
                                        <Form>
                                            <Form.Group widths='equal'>
                                                <Form.Input transparent onChange={(e) => e.stopPropagation()} label='ID'
                                                            value={receiver.id}/>
                                                <Form.Input transparent onChange={(e) => e.stopPropagation()}
                                                            label='Key' value={receiver.key}/>
                                                <Form.Input transparent onChange={(e) => e.stopPropagation()}
                                                            label='Name' value={receiver.name}/>
                                            </Form.Group>
                                            <Form.Input label='Display Name'
                                                        value={(this.state.displayNameChanged ? this.state.displayName
                                                            : receiver.displayName) || ''}
                                                        onChange={(event) => this.setState({displayName: event.target.value, displayNameChanged: true})}
                                                        placeholder='Please write user friendly name'/>
                                            <Form.Input label='Note'
                                                        value={(this.state.noteChanged ? this.state.note :receiver.note) || ''}
                                                        onChange={(event) => this.setState({note: event.target.value, noteChanged : true})}
                                                        placeholder='Add a note'/>
                                            <Form.Checkbox label='Visible' toggle={true}
                                                           checked={(this.state.visibleChanged ? this.state.visible : receiver.visible)}
                                                           onChange={(e, data) => {
                                                                this.setState({visible: data.checked, visibleChanged: true});
                                                                console.log(data)
                                                            }}
                                                />
                                            <Form.Button disabled={!this.state.visibleChanged && !this.state.displayNameChanged  && !this.state.noteChanged} onClick={this.handleUpdateReceiver} color='green'>Send</Form.Button>
                                        </Form>
                                    </Segment>
                                    <Segment>
                                        <Header as='h3' color='red'>Latest 50 articles</Header>
                                        <Item.Group style={{textAlign: 'left'}}>
                                            {this.renderLatestArticles()}
                                        </Item.Group>

                                    </Segment>
                                </Grid.Column>
                                <Grid.Column width={"8"}>
                                    <ReceiverStats receiverId={receiver.id} region={region}/>
                                    <ReceiverTeams receiverId={receiver.id} limit={TEAM_COUNT} offset={this.state.teamPage * TEAM_COUNT }/>
                                    <Segment>
                                        <Button disabled={this.state.teamPage < 1} onClick={()=> {this.setState({teamPage: this.state.teamPage-1})}} content='Prev' icon='left arrow' labelPosition='left' />
                                        <Button disabled={(this.state.teamPage+1) * TEAM_COUNT > receiver.teamReceiversByReceiverId?.totalCount} onClick={ () => {  this.setState({teamPage: (this.state.teamPage+1) * TEAM_COUNT < receiver.teamReceiversByReceiverId?.totalCount ? this.state.teamPage+1 : this.state.teamPage})}}  content='Next' icon='right arrow' labelPosition='right' />

                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            );
        } else {
            return <Info icon='warning' message='Sorry, we could not find receiver you requested!' title='Error'
                         loading={false}/>;
        }
    }
};

const receiverQuery = gql`
    query ReceiverQuery($id: Int!, $region: Int) {
        receiver: receiverById(id: $id) {
            id
            displayName
            key
            name
            note
            visible
            teamReceiversByReceiverId {
                totalCount
            }
        }
        articlesByReceiver(regionIn: $region, limitNo: 50, receiverIn: $id, statusFilter: null) {
            nodes{
                id
                teamByFocusedteam {
                    id
                    name
                    logo
                }
                status
                title
                createdat
            }
        }
    }
`;

const SET_RECEIVER_INFO_MUTATION = gql`
    mutation setReceiverInfo($input: SetReceiverInfo2Input!) {
        setReceiverInfo:setReceiverInfo2(input: $input) {
            receiver {
                id
            }
        }
    }
`;

export default
    compose(
        graphql(receiverQuery, {
            options: (ownProps) => ({variables: {id: parseInt(ownProps.match.params.receiverId), region: parseInt(localStorage.getItem('selected-region')|| '1')},
                fetchPolicy: 'network-only'})}
        ),
        graphql(SET_RECEIVER_INFO_MUTATION, {
            name : 'setReceiverInfo',
            options: ownProps => ({
                refetchQueries : [{
                    query: receiverQuery, variables: {id: parseInt(ownProps.match.params.receiverId)}
                }],
            })
        }),
    )(ReceiverContainer);

