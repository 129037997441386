import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Feed} from 'semantic-ui-react'
import Info from '.././Info';
import {Link} from "react-router-dom";
import {Query} from "react-apollo";
import _ from "lodash";

const ReceiverStatsContainer = (props) => {
    const {emptyReceivers} = props;

    if(emptyReceivers && emptyReceivers.length > 0) {
        const feedEvents = _.map(emptyReceivers, (receiver) => (
            <Feed.Event>
                <Feed.Content>
                    <Feed.Summary>
                        <Link as='a' to={'/receivers/'+receiver.id}>{receiver.name}</Link> does not have a display name.
                    </Feed.Summary>
                </Feed.Content>
            </Feed.Event>
        ))
        return (
            <Segment compact={true}>
                <Header as='h2' textAlign='center' color='red'>

                </Header>
                <Feed size='small'>
                    <Header as='h4' color={'red'}>Article receivers that need moderation</Header>
                    {feedEvents}
                </Feed>
            </Segment>
        );
    }
    return (
        <Segment compact={true} style={{maxWidth: 400}}>
            <Header as='h3' textAlign='center' color='olive'>
                All article receivers are set correctly, no need to moderate.
            </Header>
        </Segment>
    );



};

const receiverStatsQuery = gql`
    query EventsPerDayQuery {
        allReceivers(condition: {displayName: null}) {
            nodes {
                id
                key
                name
                teamReceiversByReceiverId {
                    totalCount
                }
            }
        }
    }
`;

const ReceiverStats = () => (<Query query={receiverStatsQuery}>
    {({data, loading, error}) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if (data && data.allReceivers) {
            return <ReceiverStatsContainer emptyReceivers={data.allReceivers.nodes}/>
        }
        return <Info icon='warning'
            message={(error && error.message) || 'Sorry, we could not find any article receivers'}
            title='Error' loading={false}/>;
    }}
</Query>);

export default ReceiverStats;
