import moment from 'moment'

function getArticleLockTime(timestamp) {
    return moment(timestamp)
        // .subtract(8, 'minutes').subtract(55, 'seconds')
}

export function getDuration(timestamp, now) {
    if (!isArticleLocked(timestamp, now)) {
        return false
    }

    const end = getArticleLockTime(timestamp)

    const seconds = moment.duration(end.diff(moment(now))).asSeconds()

    return parseInt(seconds, 10);
}

export default function isArticleLocked(timestamp, now) {
    if (!timestamp) {
        return false
    }

    return getArticleLockTime(timestamp)
        .isSameOrAfter(moment(now));
}
