import React, { Component } from 'react';
import {withApollo, graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {Icon} from 'semantic-ui-react'
import {Link} from "react-router-dom";
import compose from "lodash.flowright";

class NotificationIcon extends Component {
    render() {
        const {loading, warningNotifications, infoNotifications, error, menuClick} = this.props;
        console.log(this.props.error, warningNotifications);


        if (loading || error) {
            return (
                <div>
                    <Link as={'a'} to={'/notification-center'} className={'notification'}>
                        <Icon size='large' color={'black'} name={'envelope'}/>
                    </Link>
                </div>
            );
        }
        return (
            <div>
                <Link as={'a'} to={'/notification-center'} className={'notification'} onClick={() => menuClick(null, { name: 'notification-icon'})}>
                    <Icon size='large' color={'black'} name={'envelope'}/>
                    <span style={{display: warningNotifications.totalCount > 0 ? '' : 'none' }} className={'badge'}>{warningNotifications.totalCount}</span>
                    <span style={{display: infoNotifications.totalCount > 0 ? '' : 'none' }} className={'info'}>{infoNotifications.totalCount}</span>
                </Link>
            </div>

        );
    }
}

const NOTIFICATIONS_QUERY = gql`
    query Notifications {
        warningNotifications: allNotifications (condition: { read : false, level: "WARNING"} ) {
            totalCount
        }
        infoNotifications: allNotifications (condition: { read : false, level: "INFO"} ) {
            totalCount
        }
    }
`;


export default withApollo(
    compose(
        graphql(NOTIFICATIONS_QUERY, {
            options: {fetchPolicy: 'network-only', errorPolicy: 'all',pollInterval: 20000},
            props: ({data: {loading, warningNotifications, infoNotifications, error}}) => ({
                loading, warningNotifications, infoNotifications, error
            })
        })
    )(NotificationIcon)
);
