import React from 'react';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {
    Table,
    Header,
    Icon,
    Menu,
    Popup,
    Pagination,
} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import moment from 'moment'
import _ from 'lodash'

import Info from '../components/Info';
import ArticleStatusIcon from '../components/ArticleStatusIcon';
import LockByArticle from '../components/LockByArticle';
import {
    ARTICLE_STATUS_AUTO_APPROVE, ARTICLE_STATUS_APPROVED, ARTICLE_STATUS_COMMENT_RECEIVED,
    ARTICLE_STATUS_IGNORED, ARTICLE_STATUS_BLOCKED, ARTICLE_STATUS_SNOOZED, ARTICLE_STATUS_DISAPPROVED,
    ARTICLE_STATUS_ARTICLE_RECEIVED, ARTICLE_STATUS_ADMIN_DONE
} from "../utils/QnaConstants";

const PAGE_SIZE = 20;

const getStatus = (statusParam) => {
    if(!statusParam) {
        return 'comment-received'
    } else if(statusParam === 'all') {
        return null;
    } else {
        return statusParam;
    }
};

const ArticlesListContainer = (props) => {
    const {
        history,
        data: {loading, error, filterArticles},
        match: { params: { page, status }}
    } = props;
    const pageNo = page ? parseInt(page, 10) : 1;

    const getRowParams = (status) => {
        if(status === 'approved') {
            return {
                positive: true
            };
        } else if( status === 'disapproved' || status === 'blocked') {
            return {
                negative: true
            };
        } else if (status === 'comment-received') {
            return {
                warning: true
            }
        } else {
            return {};
        }
    };

    const filterMenu = () => {
        return (
            <Menu color='blue' stackable secondary>
                <Menu.Item as={Link} to="/articles/all" name='All' active={props.match.params.status === 'all'}>
                    <Icon name='adn'/> All Articles
                </Menu.Item>
                <Menu.Item as={Link} to="/articles/comment-received" name='approved' active={!props.match.params.status || props.match.params.status === 'comment-received'}>
                    <ArticleStatusIcon status='comment-received' displayLabel={true}/>
                </Menu.Item>
                <Menu.Item as={Link} to={'/articles/'+ ARTICLE_STATUS_AUTO_APPROVE} name={ARTICLE_STATUS_AUTO_APPROVE} active={props.match.params.status === ARTICLE_STATUS_AUTO_APPROVE}>
                    <ArticleStatusIcon status={ARTICLE_STATUS_AUTO_APPROVE} displayLabel={true}/>
                </Menu.Item>
                <Menu.Item as={Link} to="/articles/approved" name={ARTICLE_STATUS_APPROVED} active={props.match.params.status === 'approved'}>
                    <ArticleStatusIcon status={ARTICLE_STATUS_APPROVED} displayLabel={true}/>
                </Menu.Item>
                <Menu.Item as={Link} to="/articles/ignored" name='ignored' active={props.match.params.status === 'ignored'}>
                    <ArticleStatusIcon status={ARTICLE_STATUS_IGNORED} displayLabel={true}/>
                </Menu.Item>
                <Menu.Item as={Link} to="/articles/disapproved" name='disapproved' active={props.match.params.status === 'disapproved'}>
                    <ArticleStatusIcon status={ARTICLE_STATUS_DISAPPROVED} displayLabel={true}/>
                </Menu.Item>
                <Menu.Item as={Link} to={'/articles/'+ ARTICLE_STATUS_SNOOZED} name={ARTICLE_STATUS_SNOOZED} active={props.match.params.status === ARTICLE_STATUS_SNOOZED}>
                    <ArticleStatusIcon status={ARTICLE_STATUS_SNOOZED} displayLabel={true}/>
                </Menu.Item>
                <Menu.Item as={Link} to={'/articles/'+ ARTICLE_STATUS_ADMIN_DONE} name={ARTICLE_STATUS_ADMIN_DONE} active={props.match.params.status === ARTICLE_STATUS_ADMIN_DONE}>
                    <ArticleStatusIcon status={ARTICLE_STATUS_ADMIN_DONE} displayLabel={true}/>
                </Menu.Item>
                <Menu.Item as={Link} to="/articles/blocked" name='blocked' active={props.match.params.status === 'blocked'}>
                    <ArticleStatusIcon status={ARTICLE_STATUS_BLOCKED} displayLabel={true}/>
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item header><i>Only displaying articles with contact person.</i></Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    };

    const dryRunInfo = () => {
        return <Popup
            trigger={<div><Icon name='info circle' /> Dry Run</div>}
            content='This article has a contact person with dry run mode on. Approved answers will not be sent to united robots.'
            size='large'
        />;
    };

    const navigate = url => {
        history.push(`/articles/${(getStatus(status) || 'all')}/${url}`)
    }
    const pagination = (
        <Table.Row>
            <Table.HeaderCell colSpan="9" style={{ textAlign: 'center' }}>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={pageNo}
                    ellipsisItem={null}
                    lastItem={null}
                    siblingRange={1}
                    prevItem={undefined}
                    nextItem={undefined}
                    totalPages={
                        _.get(filterArticles, 'nodes.length', 0) < PAGE_SIZE
                            ? pageNo
                            : pageNo + 1
                    }
                    onPageChange={(e, data) => {
                        navigate(data.activePage)
                    }}
                />
            </Table.HeaderCell>
        </Table.Row>
    )

    if (error) {
        return <Info icon="warning" message={error.message} title="Error" loading={false} />;
    }

    if (loading) {
        return <Info icon="circle notched" message="Loading..." loading={true} />;
    }

    return (
        <div style={{ padding: 10 }}>
            <Header as='h1' textAlign='center' color='blue'>
                ARTICLES
            </Header>

            {filterMenu()}
            <Table celled selectable color='blue'>
                <Table.Header>
                    {pagination}
                    <Table.Row>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Last Message</Table.HeaderCell>
                        <Table.HeaderCell>Article Created At <Icon name='sort descending' /></Table.HeaderCell>
                        <Table.HeaderCell>Home Team</Table.HeaderCell>
                        <Table.HeaderCell>Away Team</Table.HeaderCell>
                        <Table.HeaderCell>Event Start Time</Table.HeaderCell>
                        <Table.HeaderCell>Mode</Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filterArticles && filterArticles.nodes.map((node) => {
                        const rowParams = getRowParams(node.status);
                        const hasActiveNonDryContact = _.some(node.teamByFocusedteam.contactpeopleByTeamid.nodes, (cp) => (cp.active && !cp.drymode));
                        return (
                            <Table.Row warning={rowParams.warning} positive={rowParams.positive} negative={rowParams.negative} key={node.id}>
                                <Table.Cell>{node.title}</Table.Cell>
                                <Table.Cell>
                                    <ArticleStatusIcon status={node.status} displayLabel={true}/>
                                    <LockByArticle article={node} />
                                </Table.Cell>
                                <Table.Cell>{node.messagetime !== node.createdat ? moment(node.messagetime).fromNow() : null}</Table.Cell>
                                <Table.Cell>{moment(node.createdat).fromNow()}</Table.Cell>
                                <Table.Cell>{node.eventByEventid.teamByHometeamid.name}</Table.Cell>
                                <Table.Cell>{node.eventByEventid.teamByAwayteamid.name}</Table.Cell>
                                <Table.Cell>{moment(node.eventByEventid.startdate).format('MMMM Do, h:mm a')}</Table.Cell>
                                <Table.Cell error={!hasActiveNonDryContact}>{hasActiveNonDryContact ? '' : dryRunInfo()}</Table.Cell>
                                <Table.Cell><Link to={`/article/${node.id}`}>See more</Link></Table.Cell>
                            </Table.Row>
                        );
                    })}

                </Table.Body>
                <Table.Footer>
                    {pagination}
                </Table.Footer>
            </Table>
        </div>
    );
};

const articlesListQuery = gql`
    query ArticlesListQuery(
        $limit: Int,
        $offset: Int,
        $status: String,
        $region: Int
    ) {
        filterArticles: filterArticlesByPage2(
            limitNo: $limit,
            offsetNo: $offset,
            statusFilter: $status
            regionIn: $region
        ) {
            nodes {
                id
                title
                status
                createdat
                messagetime
                teamByFocusedteam {
                    id
                    contactpeopleByTeamid {
                        nodes {
                            active
                            id
                            drymode
                        }
                    }
                }
                eventByEventid {
                    id
                    teamByHometeamid {
                        id
                        name
                    }
                    teamByAwayteamid {
                        id
                        name
                    }
                    startdate
                }
                locksByArticle(first: 1, orderBy: [TIMESTAMP_DESC]) {
                    nodes {
                        id
                        timestamp
                        adminByAdmin {
                            fullname
                            id
                        }
                    }
                }
            }
        }
    }
`;


const ArticleList = graphql(articlesListQuery, {
    options: (ownProps) => {
        return {
            variables: {
                offset: (ownProps.match.params.page ? parseInt(ownProps.match.params.page, 10) - 1 : 0) * PAGE_SIZE,
                limit:  PAGE_SIZE,
                status: getStatus(ownProps.match.params.status),
                region: parseInt(localStorage.getItem('selected-region')|| '1')
            },
            fetchPolicy: 'network-only',
            pollInterval: 480000
        };
    }
})(ArticlesListContainer);
export default ArticleList;
