import {Component} from "react";
import {
    Button,
    Icon,
    Segment,
    Header, Checkbox, Popup, Message
} from "semantic-ui-react";

import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

const UPDATE_CONTACT_MUTATION = gql`
    mutation updateContact($input: UpdateContact4Input!) {
        updateContact4(input: $input){
            contactperson{
                id
                fullname
            }
        }
    }
`;

class ContactPersonSettingsContainer extends Component {
    constructor(props) {
        super(props);
        const { contactPerson: { drymode, sendMatchDay, level, active, qnaManaged, sendApproved, remindToAnswer}} = props;
        this.state = {
            dryMode: drymode,
            matchDay: sendMatchDay,
            activate: active,
            qnaOnly: qnaManaged,
            whitelisted: level === 1,
            error: null,
            displaySuccess: false,
            sendApproved,
            remindToAnswer
        };
        this.handleUpdateContact = this.handleUpdateContact.bind(this);
    };

    handleUpdateContact = (event) => {
        console.log('handleUpdateContact', this.props.contactPerson.id);

        this.props.updateContact({
            variables: {
                input: {
                    contactId: this.props.contactPerson.id,
                    drymodeInput: this.state.dryMode,
                    sendMatchDayInput: this.state.matchDay,
                    activate: this.state.activate,
                    qnaOnly: this.state.qnaOnly,
                    levelInput: this.state.whitelisted ? 1 : 0,
                    remindAnswering: this.state.remindToAnswer,
                    sendApprovedSms: this.state.sendApproved
                }
            }, refetchQueries: [{
                query: this.props.refetchQuery, variables: {id: this.props.contactPerson.id}
            }]
        }).then(() => {
            this.setState({displaySuccess: true});
            setTimeout(() => {
                this.setState({displaySuccess: false});
            }, 3000);
        }).catch((err) => {
            console.error(err);
            this.setState({error: err.toString()});
            setTimeout(() => {
                this.setState({error: null});
            }, 5000);
            }
        );

    };

    render() {
        const {contactPerson} = this.props;

        if (contactPerson && contactPerson.id) {
            return (
                <Segment color='blue' textAlign='left'>
                    <Header as='h3' color='red'>Settings</Header>
                    {
                        this.state.displaySuccess ?
                        <Message
                            success
                            header='Settings changed successfully!'
                        />
                        :''
                    }
                    {
                        this.state.error ?
                        <Message
                            warning
                            header='Error changing contact settings'
                            content={this.state.error}
                        /> : ''
                    }
                    <Segment >
                        <Checkbox toggle label='Whitelist' checked={this.state.whitelisted} onChange={() => this.setState({whitelisted: !this.state.whitelisted})}/>
                        <Popup trigger={<Icon style={{float: 'right'}} circular name='info' />} wide='very'>
                            Whitelist the contact person. When whitelisted contacts answer a question, their answers will be auto-approved and sent to receivers/newspapers. Please click "Save changes" to apply changes.
                        </Popup>
                    </Segment>
                    <Segment >
                        <Checkbox toggle label='Dry mode' checked={this.state.dryMode} onChange={() => this.setState({dryMode: !this.state.dryMode})}/>
                        <Popup trigger={<Icon style={{float: 'right'}} circular name='info' />} wide='very'>
                            Set dry mode of the contact person. Contacts with dry mode on will receive questions and able to answer but answers will not be sent to receivers/newspapers. Please click "Save changes" to apply changes.
                        </Popup>
                    </Segment>
                    <Segment >
                        <Checkbox toggle label='Send Matchday Reminder' checked={this.state.matchDay} onChange={() => this.setState({matchDay: !this.state.matchDay})}/>
                        <Popup trigger={<Icon style={{float: 'right'}} circular name='info' />} wide='very'>
                            Set if contact person should receive matchday reminder sms or not. Please click "Save changes" to apply changes.
                        </Popup>
                    </Segment>
                    <Segment>
                        <Checkbox disabled={!contactPerson.qnaManaged} toggle label='Active' checked={this.state.activate} onChange={() => this.setState({activate: !this.state.activate})}/>
                        <Popup trigger={<Icon style={{float: 'right'}} circular name='info' />} wide='very'>
                            Set contact person to active or de-active, this can only be edited if the contact person is managed by QnA system. Please click "Save changes" to apply changes.
                        </Popup>
                    </Segment>
                    <Segment>
                        <Checkbox toggle label='QnA Managed Contact' checked={this.state.qnaOnly} onChange={() => this.setState({qnaOnly: !this.state.qnaOnly})}/>
                        <Popup trigger={<Icon style={{float: 'right'}} circular name='info' />} wide='very'>
                            Set if contact person should only be managed by QnA system. It will not be deleted/updated by Everysport Intra. Please click "Save changes" to apply changes.
                        </Popup>
                    </Segment>
                    <Segment>
                        <Checkbox toggle label='Send Approved SMS' checked={this.state.sendApproved} onChange={() => this.setState({sendApproved: !this.state.sendApproved})}/>
                        <Popup trigger={<Icon style={{float: 'right'}} circular name='info' />} wide='very'>
                            Send whole article to contact person after his/her answer is approved or not. Please click "Save changes" to apply changes.
                        </Popup>
                    </Segment>
                    <Segment>
                        <Checkbox toggle label='Remind to Answer' checked={this.state.remindToAnswer > 0} onChange={() => this.setState({remindToAnswer: this.state.remindToAnswer === 0 ? 2 : 0})}/>
                        <Popup trigger={<Icon style={{float: 'right'}} circular name='info' />} wide='very'>
                            Send a reminder SMS if the contact person does not answer a question or not. Please click "Save changes" to apply changes.
                        </Popup>
                    </Segment>
                    <Button disabled={(contactPerson.level ===1) === this.state.whitelisted && contactPerson.remindToAnswer === this.state.remindToAnswer && contactPerson.sendApproved === this.state.sendApproved && contactPerson.sendMatchDay === this.state.matchDay && contactPerson.drymode === this.state.dryMode && contactPerson.active === this.state.activate} onClick={this.handleUpdateContact} color='green'><Icon name='save'/>Save Changes</Button>
                </Segment>
            );
        }
    }
};


const ContactPersonSettings = ({contactPerson, refetchQuery}) => ( <Mutation mutation={UPDATE_CONTACT_MUTATION}>
    {(updateContact) => (
        <ContactPersonSettingsContainer updateContact={updateContact} contactPerson={contactPerson} refetchQuery={refetchQuery}/>
    )}
</Mutation>);

export default ContactPersonSettings;

