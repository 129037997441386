import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import React, {Component} from "react";
import {Icon, Form} from "semantic-ui-react";

const EDIT_TEAM_NOTE_MUTATION = gql`
    mutation editTeamNote($input: EditTeamNoteInput!) {
        editTeamNote(input: $input) {
            team {
                id
                name
                note
            }
        }
    }
`;

class AddTeamNoteContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            note: props.note
        };
        this.handleEditNote = this.handleEditNote.bind(this);
    };

    handleEditNote = () => {
        this.props.editTeamNote({
            variables: {
                input: {
                    teamIn: this.props.teamId,
                    noteIn: this.state.note
                }
            }, refetchQueries: [{
                query: this.props.refetchQuery, variables: this.props.variables
            }]
        }).then(() => {
            console.log('Team note added', this.state.note)
        }).catch((err) => {
            console.error(err);
        });

    };

    render() {
        return (
            <Form>
                <Form.TextArea label='Note'
                            value={this.state.note}
                            onChange={(event) => this.setState({note: event.target.value})}
                            placeholder='Note'/>

                <Form.Button disabled={this.state.note === this.props.note}  onClick={this.handleEditNote} color='green'><Icon name='edit'/> Edit Note</Form.Button>
            </Form>
        );

    }
};

const AddTeamNote = ({teamId, note, refetchQuery, variables}) => ( <Mutation mutation={EDIT_TEAM_NOTE_MUTATION}>
    {(editTeamNote) => (
        <AddTeamNoteContainer editTeamNote={editTeamNote} teamId={teamId} note={note} refetchQuery={refetchQuery} variables={variables}/>
    )}
</Mutation>);

export default AddTeamNote;
