import React, {createRef} from "react";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import {Ref, Grid, Header, Table, Pagination, Button, Icon} from 'semantic-ui-react'
import Info from '../components/Info';
import {Link, useHistory} from "react-router-dom";
import _ from "lodash";

const PAGE_SIZE = 20;

const ReceiverFocusedStatsContainer = ({isLoading, error, receivers, page, sort}) => {
        const contextRef = createRef();
        const sortBy = sort || 'nameasc';
        const history = useHistory();

        console.log('sortBy', sortBy)

        const pageNo = page ? parseInt(page, 10) : 1;
        const navigate = (sort, page, clickHeader) => {
            let newSort = sort;
            if(clickHeader === 'PERCENT'){
                if(sort === 'percentdesc') {
                    newSort = 'percentasc'
                } else {
                    newSort = 'percentdesc';
                }
            } else if( clickHeader === 'NAME') {
                if(sort === 'nameasc') {
                    newSort = 'namedesc'
                } else {
                    newSort = 'nameasc';
                }
            }
            history.push(`/all-stats/receiver-focused/${newSort}/${page}`);
        };

        const pagination = (
            <Table.Row>
                <Table.HeaderCell colSpan="7" style={{textAlign: 'center'}}>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={pageNo}
                        ellipsisItem={null}
                        lastItem={null}
                        siblingRange={1}
                        prevItem={undefined}
                        nextItem={undefined}
                        totalPages={
                            _.get(receivers, 'nodes.length', 0) < PAGE_SIZE
                                ? pageNo
                                : pageNo + 1
                        }
                        onPageChange={(e, data) => {
                            navigate(sortBy, data.activePage, null)
                        }}
                    />
                </Table.HeaderCell>
            </Table.Row>
        );


        if (error) {
            return <Info icon="warning" message={error.message} title="Error" loading={false}/>;
        }

        if (isLoading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }

        const nameIcon = (sortBy === 'nameasc') ? <Icon name={'angle up'}/> : ((sortBy === 'namedesc') ? <Icon name={'angle down'}/> : '');
        const percentageIcon = (sortBy === 'percentasc') ? <Icon name={'angle up'}/> : ((sortBy === 'percentdesc') ? <Icon name={'angle down'}/> : '');

        return (
            <div>
                <Ref innerRef={contextRef}>
                    <Grid width={16} stackable centered padded>
                        <Grid.Row stretched>
                            <Header as='h1' textAlign='center' color='blue'>
                                RECEIVER FOCUSED TEAMS STATS
                            </Header>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Table style={{width: '100%'}} celled collapsing striped selectable stackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>#</Table.HeaderCell>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell><Button basic={true} onClick={() => navigate(sort, pageNo, 'NAME')}>Name {nameIcon}</Button> </Table.HeaderCell>
                                            <Table.HeaderCell>Teams with contacts</Table.HeaderCell>
                                            <Table.HeaderCell>Teams without contacts</Table.HeaderCell>
                                            <Table.HeaderCell><Button basic={true} onClick={() => navigate(sort, pageNo, 'PERCENT')}>Percentage {percentageIcon}</Button> </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {pagination}
                                        {receivers?.nodes.map((node, index) => {
                                            return (
                                                <Table.Row key={node.id}>
                                                    <Table.Cell>{(PAGE_SIZE * (pageNo - 1)) + index + 1}</Table.Cell>
                                                    <Table.Cell><Link to={'/receivers/'+node.id}>{node.id}</Link></Table.Cell>
                                                    <Table.Cell>{node.name}</Table.Cell>
                                                    <Table.Cell>{node.teamsWithContact}</Table.Cell>
                                                    <Table.Cell>{node.teamsWithoutContact}</Table.Cell>
                                                    <Table.Cell>{Number.parseFloat(node.percentage).toFixed(2)}%</Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Ref>
            </div>
        );
};

const receiverFocusedStatsQuery = gql`
    query ReceiverFocusedStatsQuery($limit: Int, $offset: Int, $orderBy: String){
        receivers : receiverFocusedTeamsFilter( limitNo: $limit, offsetNo: $offset, orderBy: $orderBy){
            nodes {
                id
                name
                percentage
                teamsWithContact
                teamsWithoutContact
            }
        }
    }
`;


const ReceiverFocusedStats = (props) => ( <Query query={receiverFocusedStatsQuery}
                                          variables={
                                              {
                                                  offset: (props.page ? parseInt(props.page, 10) - 1 : 0) * PAGE_SIZE,
                                                  orderBy: (props.sort || 'nameasc'),
                                                  limit: PAGE_SIZE
                                              }}>
    {({ data, refetch, loading, error }) => {
        return <ReceiverFocusedStatsContainer isLoading={loading} refetch={refetch} error={error} page={props.page || '1'} sort={props.sort} receivers={data?.receivers}/>
    }}
</Query>);


export default ReceiverFocusedStats;
