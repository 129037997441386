import React from 'react'
import gql from 'graphql-tag';
import {Header, Segment, Statistic} from 'semantic-ui-react'
import Info from '.././Info';
import {Query} from "react-apollo";

const ContactStatsContainer = (props) => {
    const {contactsCount} = props;
    return (
        <Segment inverted raised={true} >
            <Header as={'h3'} color={"black"}>Contact Stats</Header>
            <Statistic.Group horizontal inverted color={'orange'} size={'small'}>
                <Statistic inverted>
                    <Statistic.Value>{contactsCount}</Statistic.Value>
                    <Statistic.Label>Contacts</Statistic.Label>
                </Statistic>
            </Statistic.Group>

        </Segment>
    );
};

const QUERY_CONTACTS_COUNT = gql`
    query contactStats ($activeNow: Boolean, $sport: Int, $fromDate: String, $toDate: String, $region: Int){
        contactsCount:contactsCount2(activeNow: $activeNow, sport: $sport, fromDate: $fromDate, toDate: $toDate, regionIn: $region)
    }
`;

const ContactStats = ({activeNow, sport, fromDate, toDate}) => (<Query query={QUERY_CONTACTS_COUNT} variables={{activeNow, sport, fromDate, toDate, region: parseInt(localStorage.getItem('selected-region')|| '1')}}>
    {({data, loading, error}) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if (data) {
            return <ContactStatsContainer contactsCount={data.contactsCount}/>
        }
        return <Info icon='warning'
            message={(error && error.message) || 'Sorry, we could not fetch contact stats '}
            title='Error' loading={false}/>;
    }}
</Query>);

export default ContactStats;
