import {
    Card,
    Header, Segment
} from 'semantic-ui-react';

import React from 'react';
import gql from 'graphql-tag';
import { Query} from 'react-apollo';
import Info from "../Info";
import _ from "lodash";
import moment from "moment";



const receiverTeamsQuery = gql`
    query ReceiverQuery($id: Int!, $limit: Int!, $offset: Int!) {
        receiver: receiverById(id: $id) {
            id
            displayName
            name
            teamReceiversByReceiverId (orderBy: [SUBSCRIPTION_TIME_DESC], first: $limit, offset: $offset) {
                totalCount
                nodes {
                    subscriptionTime
                    teamByTeamId {
                        id
                        name
                        logo
                        articlesByFocusedteam {
                            totalCount
                        }
                        leagueByLeagueid {
                            id
                            name
                            sportname
                            teamclass
                        }
                    }
                }
            }
        }
    }
`;

const ReceiverTeamsContainer = ({receiver}) => {
    if (receiver && receiver.id) {
        return (
            <Segment color={'yellow'}>
                <Header as={'h3'} color={'red'} textAlign={'center'}>Teams subscribed by {receiver.displayName || receiver.name} (Total: {receiver.teamReceiversByReceiverId?.totalCount || '0'})</Header>
                <Card.Group itemsPerRow={5}>
                    {
                        _.map(receiver.teamReceiversByReceiverId.nodes, (t) => (
                            <Card href={'/team/'+t.teamByTeamId?.id} key={t.teamByTeamId?.id}
                                  meta={'Subscription date: '+ moment(t.subscriptionTime).format('YYYY-MM-DD')}
                                  description={(t.teamByTeamId?.articlesByFocusedteam?.totalCount || '0') + ' articles for ' + t.teamByTeamId?.name + ' in ' + t.teamByTeamId?.leagueByLeagueid?.name}
                                  raised
                                  image={t.teamByTeamId?.logo || '/img/noimage.png'} />))
                    }
                </Card.Group>
            </Segment>
        )
    } else {
        return null;
    }
}

const ReceiverTeams = ({ receiverId, limit, offset }) => (
    <Query query={receiverTeamsQuery} variables={{ id: receiverId, limit, offset }}>
        {({ loading, error, data}) => {
            if (error) {
                return <Info icon="warning" message={error.message} title="Error" loading={false} />;
            }

            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true} />;
            }
            return (
                <ReceiverTeamsContainer receiver={data.receiver} />
            );
        }}
    </Query>
);

export default ReceiverTeams;
