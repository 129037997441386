import React, {useState} from 'react'
import gql from 'graphql-tag';
import {Header, Container, Segment, Grid, Label, Card, Icon, Image, Item, List, Divider} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import Info from '../components/Info';
import { Query } from "react-apollo";
import SportIcon from "../components/SportIcon";
import ExternalContactCard from "../components/external-contact/ExternalContactCard";
import ContactPersonCard from "../components/contact-person/ContactPersonCard";
import ArticleStatusIcon from "../components/ArticleStatusIcon";
import AddTeamNote from "../components/team/AddTeamNote";
import AddContactPerson from "../components/contact-person/AddContactPerson";

const moment = require('moment');
const _ = require('lodash');

const displayLeagues = (teamLeaguesByTeamId) => {
    return _.map(_.filter(teamLeaguesByTeamId?.nodes, (ln) => ln.leagueByLeagueId?.active), (ln) => {
        return (<List.Item key={ln.leagueByLeagueId.id}>{`${ln.leagueByLeagueId.id} - ${ln.leagueByLeagueId.name}`}</List.Item>);
    })
}

const TeamContainer = ({team, error}) => {
    const region = parseInt(localStorage.getItem('selected-region')|| '1');
    const receivers = _.map(_.get(team, 'teamReceiversByTeamId.nodes'), (r) => (<List.Item key={r.receiverByReceiverId.id} as={Link} to={'/receivers/'+r.receiverByReceiverId.id}>{r.receiverByReceiverId.key + ' - ' + r.receiverByReceiverId.name}</List.Item>))
    const contacts = _.map(_.get(team, 'contactpeopleByTeamid.nodes'), (c) => (<ContactPersonCard contactPersonId={c.id} key={c.id} />))
    const externalContacts = _.map(_.get(team, 'externalContactsByTeamId.nodes'), (c) => (<ExternalContactCard contactPersonId={c.id} key={c.id} />))
    const articles = _.map(team?.latestArticles?.nodes, (article) => {
        return <List.Item key={article.id}><Link as='a'  to={'/article/'+article.id}>{article.title}</Link>  <i><ArticleStatusIcon status={article.status} displayLabel={true}/></i> <i>{moment(article.createdat).format('YYYY-MM-DD h:mm a')}</i></List.Item>;
    })
    const events = _.map(_.sortBy(_.concat(team?.eventsByHometeamid?.nodes, team?.eventsByAwayteamid?.nodes),(o)=> {return -moment(o.startdate).unix()}), (eventObj) => {
        return <List.Item key={eventObj.id}><Link as={'a'} to={'/team/'+eventObj.teamByHometeamid.id}> {eventObj.teamByHometeamid.name}</Link> vs  <Link as='a'  to={'/team/'+eventObj.teamByAwayteamid.id}>{eventObj.teamByAwayteamid.name}</Link> <i>{moment(eventObj.startdate).format('YYYY-MM-DD h:mm a')}</i> <Link as='a'  to={'/event/'+eventObj.id}><Icon color={'black'} name={'external square alternate'} style={{marginLeft:10}}/></Link></List.Item>;
    })
    if (team && team.id ) {
        return (
            <div>
                <Header as='h1' textAlign='center' color='blue'>
                    {`Team: ${team.name}`}
                </Header>
                <Container style={{marginTop: 20}}>
                    <Grid streched='true' stackable>
                        <Grid.Row>
                            <Grid.Column width={"7"}>
                                <Segment color='blue' textAlign={"left"} size={"large"}>
                                    <Item>
                                        {   team.logo ?
                                            <Item.Image src={team.logo} size='small' rounded={true} circular={true} bordered fluid centered />
                                            : ''
                                        }

                                        <Item.Content verticalAlign={"top"} >
                                            <Item.Header><b>Name:</b> {team.name} </Item.Header>
                                            <Item.Meta style={{lineHeight: 1.6}}>
                                                <div><b>Id:</b> {team.id} </div>
                                                <a href={`http://inrapportering.everysport.se/intra-new/contactList.html?teamId=${team.id}&clean`} target="_blank" rel="noopener noreferrer"><Icon color='red' name='linkify' /> <Label>Edit in inrapportering.everysport.se </Label></a>
                                                <div><Icon color='green' name='adn' /> Published Articles: {team.totalApproved.totalCount}</div>
                                                <div><Icon color='orange' name='newspaper outline' /> Receivers: {team.teamReceiversByTeamId.totalCount}</div>
                                                <div><b>Sport:</b> {team.leagueByLeagueid.sportname} <SportIcon sportName={team.leagueByLeagueid.sportname} /></div>
                                                <div><b>Team Class:</b> {team.leagueByLeagueid.teamclass} </div>
                                                <div><b>Leagues:</b><List>{displayLeagues(team.teamLeaguesByTeamId)}</List> </div>
                                                <div><AddTeamNote teamId={team.id} note={team.note} refetchQuery={teamPageQuery} variables={{id: team.id}} /></div>
                                            </Item.Meta>
                                            <Divider style={{marginTop: 30}}/>
                                            {/*<Item.Description><b>League:</b> {team.leagueByLeagueid.name} - {team.leagueByLeagueid.teamclass} <SportIcon sportName={team.leagueByLeagueid.sportname} /></Item.Description>*/}
                                            <Item.Extra>

                                                <div style={{marginTop: 10}}>
                                                    <Header as='h4' textAlign='left' color='red'><Icon color='black' name='address book outline' />Active Contacts: </Header>
                                                    {
                                                        contacts && contacts.length > 0 ?
                                                            <Card.Group itemsPerRow={2}>{contacts}</Card.Group>
                                                            :
                                                            <div>No contact</div>
                                                    }
                                                    {
                                                        region === 2 ?
                                                        <AddContactPerson
                                                            refetchQuery={teamPageQuery}
                                                            teamId={team.id}
                                                        /> : ''
                                                    }

                                                </div>
                                                <Divider />
                                                <div style={{marginTop: 10}}>
                                                    <Header as='h4' textAlign='left' color='red'><Icon color='black' name='address book' />Ongoing External Contacts: </Header>
                                                    {
                                                        externalContacts && externalContacts.length > 0 ?
                                                            <Card.Group itemsPerRow={2}>{externalContacts}</Card.Group>
                                                            :
                                                            <div>No external contact</div>
                                                    }
                                                </div>
                                                <Divider />
                                                <div style={{marginTop: 10}}>
                                                    <Header as='h4' textAlign='left' color='red'><Icon color='black' name='newspaper outline' />List of Receivers:</Header>
                                                    <List bulleted>{receivers}</List>
                                                </div>
                                            </Item.Extra>
                                        </Item.Content>
                                    </Item>

                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Segment textAlign='left' color='blue' >
                                    <Header as='h2' textAlign='center' color='red'>
                                        Latest Related Articles
                                    </Header>
                                    <List bulleted>{articles}</List>
                                </Segment>
                                <Segment textAlign='left' color='blue' >
                                    <Header as='h2' textAlign='center' color='red'>
                                        Related Events
                                    </Header>
                                    <List bulleted>{events}</List>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        );
    } else {
        return <Info icon='warning' message={error || 'Sorry, we could not find the team you requested!'} title='Error'
            loading={false}/>;
    }


};

const teamPageQuery = gql`
    query TeamQuery($id: String!) {
        team: teamById(id: $id) {
            id
            name
            logo
            note
            leagueByLeagueid {
                id
                name
                sportid
                sportname
                teamclass
                startDate
                endDate
            }
            teamLeaguesByTeamId{
                nodes{
                    leagueByLeagueId {
                        id
                        name
                        sportname
                        teamclass
                        active
                    }
                }
            }
            contactpeopleByTeamid(condition: {active: true}) {
                totalCount
                nodes {
                    id
                    fullname
                    title
                }
            }
            totalApproved: articlesByFocusedteam(condition: {status: "approved"}) {
                totalCount
            }
            latestArticles: articlesByFocusedteam(orderBy: [CREATEDAT_DESC, GENERATEDAT_DESC], first: 20) {
                nodes {
                    id
                    focusedteam
                    title
                    createdat
                    status
                }
            }
            eventsByHometeamid(orderBy: [STARTDATE_DESC], first: 5) {
                nodes {
                    id
                    startdate
                    suspended
                    teamByHometeamid{
                        id
                        name
                        logo
                    }
                    teamByAwayteamid {
                        id
                        name
                        logo
                    }
                }
            }
            eventsByAwayteamid(orderBy: [STARTDATE_DESC], first: 5) {
                nodes {
                    id
                    startdate
                    suspended
                    teamByHometeamid{
                        id
                        name
                        logo
                    }
                    teamByAwayteamid {
                        id
                        name
                        logo
                    }
                }
            }
            externalContactsByTeamId(condition: {deleted: false, onboarded: false}){
                nodes {
                    id
                    fullname
                    createdAt
                    adminByCreatedBy{
                        id
                        fullname
                        avatarByAvatar{
                            id
                            url
                        }
                    }

                }
            }
            teamReceiversByTeamId{
                totalCount
                nodes {
                    receiverByReceiverId {
                        id
                        key
                        name
                    }
                }
            }
        }
    }
`;

const Team = (props) => ( <Query query={teamPageQuery} variables={{ id: props.match.params.teamId, eventFilter: moment().subtract(14,"days").format('YYYY-MM-DD')}}>
    {({ data, loading, error }) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if(data && data.team) {
            return <TeamContainer team={data.team}/>
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, we could not find the team you requested!'} title='Error' loading={false}/>;
    }}
</Query>);

export default Team;
