import { Component } from 'react'
import {
    Icon,
    Checkbox,
    Popup,
    Card,
    Image, Message, Label
} from 'semantic-ui-react';

import React from 'react';
import gql from 'graphql-tag';
import {Mutation, Query} from 'react-apollo';
import moment from "moment";
import Info from "../Info";
import {Link} from "react-router-dom";


const UPDATE_CONTACT_MUTATION = gql`
    mutation updateContact($input: UpdateContact2Input!) {
        updateContact2(input: $input){
            contactperson{
                id
                fullname
            }
        }
    }
`;

const contactPersonQuery = gql`
    query ContactPersonQuery($id: Int!) {
        contactPerson: contactpersonById(id: $id) {
            id
            fullname
            title
            phone
            active
            drymode
            level
            registrationdate
            sendMatchDay
            phoneCount
        }
    }
`;

class ContactPersonCardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            displaySuccess: false,
        };
        this.handleWhitelisting = this.handleWhitelisting.bind(this);
    }

    handleWhitelisting = async () => {
        const { updateContact, contactPerson } = this.props;

        updateContact({
            variables: {
                input: {
                    contactId: contactPerson.id,
                    drymodeInput: contactPerson.drymode,
                    sendMatchDayInput: contactPerson.sendMatchDay,
                    levelInput: contactPerson.level === 0 ? 1 : 0
                }
            }, refetchQueries: [{
                query: contactPersonQuery, variables: {id: contactPerson.id}
            }]
        }).then(() => {
            this.setState({displaySuccess: true});
            setTimeout(() => {
                this.setState({displaySuccess: false});
            }, 3000);
        }).catch((err) => {
                console.error(err);
                this.setState({error: err.toString()});
                setTimeout(() => {
                    this.setState({error: null});
                }, 5000);
            }
        );
    };

    render() {
        const {contactPerson, index, articleDate} = this.props;

        if (contactPerson && contactPerson.id) {

            return (
                <Card key={contactPerson.id} fluid={true} >
                    <Card.Content>
                        {
                            index ?
                            <Image
                                floated='right'
                                size='mini'
                                src={'/img/c' + index + '.png'}
                            /> : ''
                        }
                        <Card.Header><Link to={`/contacts/${contactPerson.id}`}>{contactPerson.fullname}</Link></Card.Header>
                        <Card.Meta>{contactPerson.title}</Card.Meta>
                        <Card.Description>
                            Registered: <strong>{moment(contactPerson.registrationdate).format('YYYY-MM-DD')}</strong>
                            {contactPerson && moment(contactPerson.registrationdate).isAfter(moment(articleDate)) ?
                                <Popup
                                    trigger={<Icon color='red' name='info circle'/>}
                                    content='Contact person was registered after the start time of the event so question was not sent'
                                    size='large'
                                /> : <span/>}

                            {contactPerson && contactPerson.phoneCount > 1 ? <div style={{marginTop: 10}}><Label as='a' color={'red'}>Multiple teams are associated with same phone number</Label></div> : ''}
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <div>
                            {
                                this.state.error ?
                                    <Message
                                        warning
                                        header='Error whitelisting contact'
                                        content={this.state.error}
                                    /> : ''
                            }
                            <Checkbox toggle label='Whitelist' checked={contactPerson.level === 1} onChange={this.handleWhitelisting}/>
                            <Popup trigger={<Icon size={"small"} color={'red'} style={{float: 'right'}} circular name='info' />} wide='very'>
                                Whitelist the contact person. When whitelisted contacts answer a question, their answers will be auto-approved and sent to receivers/newspapers.
                            </Popup>
                            {
                                this.state.displaySuccess ?
                                    <div style={{color: 'green'}}>Success</div>
                                    :''
                            }
                        </div>
                    </Card.Content>
                </Card>
            )
        }
    }
}

const ContactPersonCard = ({ contactPersonId, index, articleDate }) => (
<Query query={contactPersonQuery} variables={{ id: contactPersonId }}>
    {({ loading, error, data}) => {
        if (error) {
            return <Info icon="warning" message={error.message} title="Error" loading={false} />;
        }

        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true} />;
        }
        return (
            <Mutation mutation={UPDATE_CONTACT_MUTATION}>
                {(updateContact) => (
                    <ContactPersonCardContainer updateContact={updateContact} index={index} contactPerson={data.contactPerson} articleDate={articleDate} />
                )}
            </Mutation>
        );
    }}
</Query>
);

export default ContactPersonCard;
