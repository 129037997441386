import React from 'react';
import {
    Dropdown,
    Icon,
} from 'semantic-ui-react';
import moment from 'moment'
import _ from 'lodash'

export default function LocksByArticle(props) {
    const { article } = props
    if (!_.get(article, 'locksByArticle.nodes.length')) {
        return null
    }

    const currentAdmin = JSON.parse(localStorage.getItem('current-admin'));

    if (!currentAdmin) {
        return null
    }

    return (
        <div style={{ marginLeft: 5 }}>
            <span>
                <Icon name="pencil alternate" />
            </span>

            <Dropdown text="Edits">
                <Dropdown.Menu>
                    {article.locksByArticle.nodes.map(lock => (
                        <Dropdown.Item
                            key={`lock-${lock.id}`}
                            text={(
                                <div>
                                    by {' '}
                                    <b>{lock.adminByAdmin.id === currentAdmin.id ? 'me' : lock.adminByAdmin.fullname}</b> {' '}
                                    until {moment(lock.timestamp).format('MMMM Do, h:mm a')}
                                </div>
                            )}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
