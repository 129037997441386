import {
    Card,
    Image
} from 'semantic-ui-react';

import React from 'react';
import gql from 'graphql-tag';
import { Query} from 'react-apollo';
import Info from "../Info";
import {Link} from "react-router-dom";
import _ from "lodash";



const contactPersonQuery = gql`
    query ContactPersonQuery($id: Int!) {
        contactPerson: externalContactById(id: $id) {
            id
            fullname
            note
            teamByTeamId {
                id
                name
                logo
                leagueByLeagueid {
                    name
                    id
                }
            }
            adminByCreatedBy {
                avatarByAvatar {
                    id
                    url
                }
                id
                fullname
            }
            phone
            receiverByReceiver {
                id
                displayName
                name
                key
            }
        }
    }
`;

const ExternalContactCardContainer = ({contactPerson}) => {
    if (contactPerson && contactPerson.id) {
        return (
            <Card key={contactPerson.id} fluid={true}>
                <Card.Content>

                    <Card.Header><Link
                        to={`/external-contact/${contactPerson.id}`}>{contactPerson.fullname}</Link></Card.Header>
                    <Card.Meta>{contactPerson.title}</Card.Meta>
                    <Card.Description>
                        {contactPerson.fullname} is an external contact person
                        for <strong>{contactPerson.teamByTeamId && contactPerson.teamByTeamId.name}</strong> team
                        in <strong>{contactPerson.teamByTeamId && contactPerson.teamByTeamId.leagueByLeagueid.name}</strong> competition.
                        <div style={{textAlign: 'left'}}><strong>Phone
                            Number:</strong> {contactPerson.phone}</div>
                        <div style={{textAlign: 'left'}}>
                            <strong>Note:</strong> {contactPerson.note}</div>
                        <div style={{textAlign: 'left'}}>
                            <strong>Receiver:</strong> {contactPerson.receiverByReceiver?.displayName || contactPerson.receiverByReceiver?.name}</div>
                        <div style={{textAlign: 'left'}}><strong>Moderated By: </strong><Image
                            avatar
                            size='mini'
                            inline
                            src={_.get(contactPerson, 'adminByCreatedBy.avatarByAvatar.url')}
                            alt={_.get(contactPerson, 'adminByCreatedBy.fullname')}
                        /> {_.get(contactPerson, 'adminByCreatedBy.fullname')}</div>
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    } else {
        return null;
    }
}

const ExternalContactCard = ({ contactPersonId, index, articleDate }) => (
    <Query query={contactPersonQuery} variables={{ id: contactPersonId }}>
        {({ loading, error, data}) => {
            if (error) {
                return <Info icon="warning" message={error.message} title="Error" loading={false} />;
            }

            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true} />;
            }
            return (
                <ExternalContactCardContainer contactPerson={data.contactPerson} />
            );
        }}
    </Query>
);

export default ExternalContactCard;
export { ExternalContactCardContainer };
