import React from 'react'
import gql from 'graphql-tag';
import {Header, Container} from 'semantic-ui-react'
import Info from '.././Info';
import {
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    Resizable,
    BarChart,
    styler,
} from "react-timeseries-charts";
import { Query } from "react-apollo";
import _ from "lodash";
import { TimeSeries} from "pondjs";
const moment = require('moment');

class DailyArticleStatsContainer extends React.Component {
    state = {
        selection: null
    };

    render() {
        const style = styler([
            { key: "approved", color: "#A5C8E1", selected: "#2CB1CF" },
            { key: "total", color: "#FFCC9E", selected: "#2CB1CF" },

        ]);

        const selectedDate = this.state.selection
            ? this.state.selection.event.index().toNiceString()
            : "--";
        const selectedValue = this.state.selection
            ? `${+this.state.selection.event.value('approved')} approved, ${+this.state.selection.event.value('total')} total`
            : "--";
        const highlight = this.state.highlight;

        let infoValues = [];

        if (highlight) {
            const eventNumbersText = `${highlight.column +  ' '+highlight.event.get(highlight.column)}`;
            infoValues = [{ label: "Article Count", value: eventNumbersText }];
        }


        const {approvedArticles, allArticles} = this.props;
        if (approvedArticles.nodes && allArticles.nodes) {

            const approvedArticlesData = _.map(approvedArticles.nodes, (node) => ({
                day: moment(node.timePeriod).format('YYYY-MM-DD'),
                approvedCount: parseInt(node.numberOfArticles, 10)
            }));


            const totalArticlesData = _.map(allArticles.nodes, (node) => ({
                day: moment(node.timePeriod).format('YYYY-MM-DD'),
                totalCount: parseInt(node.numberOfArticles, 10)
            }));

            let maxNumber = 0;
            const data = _.map(totalArticlesData, (t) => {
                const approvedCount = _.get(_.findLast(approvedArticlesData, (aa) => {return aa.day === t.day}), 'approvedCount') || 0;

                maxNumber = maxNumber > t.totalCount ? maxNumber : t.totalCount;
                return [t.day,
                    approvedCount,
                    t.totalCount
                ];
            })

            console.log('data', data)


            const series = new TimeSeries({
                name: 'Number of Articles Per Day',
                columns: ['index', 'approved', 'total'],
                points: data
            });

            if(_.isEmpty(data)) {
                return <Container>
                    <Header as='h2' textAlign='center' color='red'>
                        Upcoming Events Per Day
                    </Header>
                    <Info icon='warning' message={'Sorry, we could not find any future events!'}
                          title='Error' loading={false}/>
                </Container>
            }

            return (
                <Container>
                    <Header as='h2' textAlign='center' color='red'>
                        Number of Articles Per Day in the last 30 days
                    </Header>
                    <p style={{display: this.state.selection ? 'block' : 'none'}}>{selectedValue} events on {selectedDate}</p>
                    <Resizable>
                        <ChartContainer
                            timeRange={series.range()}
                            format="day"
                            onBackgroundClick={() => this.setState({selection: null})}
                        >
                            <ChartRow height="250">
                                <YAxis
                                    id="article-count"
                                    label="Number of Articles"
                                    classed="traffic-in"
                                    min={0}
                                    max={maxNumber}
                                    width="150"
                                    type="linear"
                                />
                                <Charts>
                                    <BarChart
                                        axis="article-count"
                                        size={10}
                                        offset={5}
                                        columns={["approved"]}
                                        series={series}
                                        style={style}
                                        info={infoValues}
                                        highlighted={this.state.highlight}
                                        onHighlightChange={highlight =>
                                            this.setState({ highlight })
                                        }
                                        selected={this.state.selection}
                                        onSelectionChange={selection =>
                                            this.setState({ selection })
                                        }
                                    />
                                    <BarChart
                                        axis="article-count"
                                        size={10}
                                        offset={-5}
                                        columns={["total"]}
                                        series={series}
                                        style={style}
                                        info={infoValues}
                                        highlighted={this.state.highlight}
                                        onHighlightChange={highlight =>
                                            this.setState({ highlight })
                                        }
                                        selected={this.state.selection}
                                        onSelectionChange={selection =>
                                            this.setState({ selection })
                                        }
                                    />
                                </Charts>
                            </ChartRow>
                        </ChartContainer>
                    </Resizable>
                </Container>
            );
        } else {
            return <Info icon='warning' message={'Sorry, we could not find any future events!'}
                         title='Error' loading={false}/>;
        }

    }
};

const eventStatsQuery = gql`
    query EventsPerDayQuery {
        approvedArticles: dailyArticles(statusIn: "approved") {
            nodes {
                timePeriod
                numberOfArticles
            }
        }
        allArticles: dailyArticles {
            nodes {
                timePeriod
                numberOfArticles
            }
        }
    }
`;

const DailyArticleStats = () => ( <Query query={eventStatsQuery}>
    {({ data, loading, error }) => {
        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }
        if(data && data.approvedArticles && data.allArticles) {
            return <DailyArticleStatsContainer approvedArticles={data.approvedArticles} allArticles={data.allArticles}/>
        }
        return <Info icon='warning' message={(error && error.message) || 'Sorry, you must be super admin to see stats.'} title='Error' loading={false}/>;
    }}
</Query>);

export default DailyArticleStats;
