import {Button, Header, Icon, List, Modal, Card, Statistic} from "semantic-ui-react";
import SportIcon from "../SportIcon";
import React from "react";
import _ from "lodash";
import {Query} from "react-apollo";
import Info from "../Info";
import gql from "graphql-tag";
import ContactPersonCard from "../contact-person/ContactPersonCard";
import ExternalContactCard from "../external-contact/ExternalContactCard";

const contactCards = (contacts, contactType) => {
    //console.log('contacts', contacts);
    return _.map(contacts, (c) => {
        if(contactType === 'active' || contactType === 'inactive' ){
            return <ContactPersonCard contactPersonId={c.contactId} key={c.contactId} />
        } else {
            return <ExternalContactCard contactPersonId={c.contactId} key={c.contactId} />
        }})
}
const StatsContactsModalContainer = ({statValue, contactType, statLabel, open, setOpen, contacts, league, error, loading}) => {
    return(
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
            trigger={<Statistic>
                <Statistic.Value>{statValue}</Statistic.Value>
                <Statistic.Label>{statLabel} </Statistic.Label>
                <Icon name={'external square alternate'} style={{marginLeft:10}}/>
            </Statistic>}
        >
            <Header icon>
                <SportIcon sportName={league.sportname}/>
                {statLabel + ' in ' + league.name + ' ' + league.sportname + ' '+ league.teamclass}
            </Header>
            <Modal.Content scrolling={true}>
                <Card.Group itemsPerRow={2}>
                    {
                        error ? <Info icon='warning' message={(error && error.message) || 'Sorry, we could not fetch contacts!'} title='Error' loading={false}/> : ''
                    }
                    {
                        loading ? <Info icon="circle notched" message="Loading..." loading={true}/> : ''
                    }
                    {
                        contacts ? contactCards(contacts, contactType) : ''
                    }
                </Card.Group>


            </Modal.Content>
            <Modal.Actions>
                <Button color='green' inverted onClick={() => setOpen(false)}>
                    <Icon name='checkmark' /> Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const contactQuery = gql`
    query ContactQuery($contactTypeIn: String!, $fromDate: String, $toDate: String, $leagueIdIn: Int!) {
        contactPerson: contactsCountViewFilter( contactTypeIn: $contactTypeIn, fromDate: $fromDate, toDate: $toDate, leagueIdIn: $leagueIdIn ) {
            nodes {
                contactId
                teamId

            }
        }
    }
`;

const StatsContactsModal = ({league, contactTypeIn, fromDate, toDate, statValue, statLabel, open, setOpen}) => (
    <Query query={contactQuery} variables={{
        contactTypeIn,
        fromDate,
        toDate,
        leagueIdIn: league.id
    }}>
        {({data, loading, error}) => {

            return <StatsContactsModalContainer loading={loading} league={league}
                error={error} contactType={contactTypeIn} contacts={data?.contactPerson?.nodes} statValue={statValue} statLabel={statLabel} open={open} setOpen={setOpen}/>

        }}
    </Query>);

export default StatsContactsModal;


