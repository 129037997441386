const ARTICLE_STATUS_AUTO_APPROVE = 'auto-approve';
const ARTICLE_STATUS_APPROVED = 'approved';
const ARTICLE_STATUS_COMMENT_RECEIVED= 'comment-received';
const ARTICLE_STATUS_SNOOZED = 'snoozed';
const ARTICLE_STATUS_BLOCKED = 'blocked';
const ARTICLE_STATUS_IGNORED = 'ignored';
const ARTICLE_STATUS_DISAPPROVED = 'disapproved';
const ARTICLE_STATUS_ARTICLE_RECEIVED = 'article-received';
const ARTICLE_STATUS_ADMIN_DONE = 'admin-done'

export {
    ARTICLE_STATUS_APPROVED,
    ARTICLE_STATUS_AUTO_APPROVE,
    ARTICLE_STATUS_COMMENT_RECEIVED,
    ARTICLE_STATUS_SNOOZED,
    ARTICLE_STATUS_BLOCKED,
    ARTICLE_STATUS_IGNORED,
    ARTICLE_STATUS_DISAPPROVED,
    ARTICLE_STATUS_ARTICLE_RECEIVED,
    ARTICLE_STATUS_ADMIN_DONE
};
