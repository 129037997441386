const Notify =  require('notifyjs');

const onPermissionGranted = () => {
    console.log('Permission has been granted by the user');
};

const onPermissionDenied = () => {
    console.warn('Permission has been denied by the user');
};

const onNotifyShow = () => {
    console.log('Notification was shown!');
};

const toggleNotifications = () => {
    const result = !areNotificationsEnabled();
    localStorage.setItem('notifications', result ? 'enabled' : 'disabled');
    return result;
};

const areNotificationsEnabled = () => {
    return localStorage.getItem('notifications') !== 'disabled';
};

const requestNotifyPermission = (onPermissionGrantedFunction, onPermissionDeniedFunction) => {
    if(!Notify.needsPermission && areNotificationsEnabled()) {
        doNotification('Subscription request', 'You are already subscribed!');
    } else if (Notify.isSupported()) {
        Notify.requestPermission(onPermissionGrantedFunction || onPermissionGranted, onPermissionDeniedFunction || onPermissionDenied );
    }
};

const doNotification = (title, text, onNotifyShowFunction) => {
    if(!Notify.needsPermission && areNotificationsEnabled()) {
        var myNotification = new Notify(title, {
            body: text,
            notifyShow: onNotifyShowFunction || onNotifyShow
        });

        myNotification.show();
    }
};

module.exports = { doNotification, requestNotifyPermission, needsPermission : Notify.needsPermission, toggleNotifications, areNotificationsEnabled};
