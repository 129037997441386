import React from 'react'
import {Message, Icon} from 'semantic-ui-react'

const Info = ({icon, message, title, loading}) => {
    return (
        <Message icon compact>
            <Icon name={icon} loading={loading} />
            <Message.Content>
                <Message.Header>{title}</Message.Header>
                <p>{message}</p>
            </Message.Content>
        </Message>
    );
};

export default Info;
