import React from 'react'
import gql from 'graphql-tag';
import {Header, Container, Segment, List, Grid, Label, Divider, Image} from 'semantic-ui-react'
import { graphql} from 'react-apollo';
import Info from './Info';
import {Link} from 'react-router-dom';
import ArticleStatusIcon from "./ArticleStatusIcon";
const _ = require('lodash');
const moment = require('moment');

const EventContainer = (props) => {

    const {data: {loading, error, event}} = props;

    if (loading) {
        return <Info icon="circle notched" message="Loading..." loading={true}/>;
    }

    const contacts = (contacts, teamName) => {
        return _.map(contacts, (contact) =>
            (<List.Item key={contact.id}>
                <List.Icon name='address book' size='small'/>
                <List.Content>
                    <List.Header as={Link} to={`/contacts/${contact.id}`}>{`${contact.fullname} - ${contact.title} of ${teamName}`}</List.Header>
                    <List.Description>{contact.active ? 'active' : 'inactive'} - {contact.drymode ? 'dry run on' : 'dry run off'} - Registeration Date: {moment(contact.registrationdate).format('YYYY-MM-DD h:mm a ')}</List.Description>
                </List.Content>
            </List.Item>));
    };

    if (event  && !error) {
        const articles = _.map(event.articlesByEventid.nodes,
            article => (
                <List.Item key={article.id}>
                    <List.Icon name='adn' size='large' />
                    <List.Content>
                        <List.Header as={Link} to={`/article/${article.id}`}>{article.title}</List.Header>
                        <List.Description as='span'>Status: <ArticleStatusIcon status={article.status} displayLabel={true}/></List.Description>
                        <List.List>
                            {contacts(article.teamByFocusedteam.contactpeopleByTeamid.nodes, article.teamByFocusedteam.name)}
                        </List.List>
                    </List.Content>
                </List.Item>));

        return (
            <div>
                <Header as='h1' textAlign='center' color='blue'>
                    {`${event.teamByHometeamid.name} vs ${event.teamByAwayteamid.name}`}
                </Header>
                <Container style={{marginTop: 20}}>
                    <Grid columns={2} streched stackable relaxed={'very'}>

                        <Grid.Column width={5}>
                            <Segment color='yellow' style={{textAlign: 'left'}}>
                                <Label as='h2' size='big' color='blue' ribbon style={{marginBottom: 15}}>Event Info</Label>
                                <div key="event-info">
                                    <div><b>{`${event.teamByHometeamid.name} vs ${event.teamByAwayteamid.name}`}</b></div>
                                    <div>{`${event.leagueByLeagueid && event.leagueByLeagueid.sportname}
                                    - ${event.leagueByLeagueid && event.leagueByLeagueid.teamclass}
                                    - ${event.leagueByLeagueid && event.leagueByLeagueid.name}`}
                                    </div>
                                    <div><b>Start Date:</b> { moment(event.startdate).format('YYYY-MM-DD h:mm a') } </div>
                                    <div><b>Event id: </b>{`${event.id} `}</div>
                                    <div><b>Event status: </b>{`${event.suspended ? 'Suspended/Inactive': 'Active'} `}</div>
                                    <div><b>Last Updated:</b> { moment(event.lastUpdated).format('YYYY-MM-DD h:mm a') } </div>
                                    <div><a href={`http://inrapportering.everysport.se/Intra/Controller?service=se.Everysport.Intra.Services.ReportMatch&action=show&match_id=${event.id}&target=/index.jsp?category=report&content=report_match`} target="_blank" rel="noopener noreferrer">Edit Event in ES intraweb</a></div>
                                </div>

                                <Divider/>

                                <div key="home-team-info">
                                    <Header as='h4'>Home Team</Header>
                                    {
                                        event.teamByHometeamid.logo ? <Image src={event.teamByHometeamid.logo} size='tiny' rounded={true} circular={true} bordered fluid floated={"right"} /> : ''
                                    }
                                    <div><b>Team Id: </b>{event.teamByHometeamid.id}</div>
                                    <div><b>Team Name: </b><Link as={'a'} to={'/team/'+event.teamByHometeamid.id}> {event.teamByHometeamid.name}</Link> </div>
                                    <div><b>Contacts:</b>
                                        {
                                            _.map(event.teamByHometeamid.contactpeopleByTeamid.nodes, node =>
                                            { return <Label as={Link} to={`/contacts/${node.id}`}>{`${node.title} ${node.fullname}`}</Label>})
                                        }
                                    </div>
                                    <div><b>Receivers:</b>
                                        {
                                            _.map(event.teamByHometeamid.teamReceiversByTeamId.nodes, node =>
                                            { return <Label as={Link} to={`/receivers/${node.receiverByReceiverId.id}`}>{`${node.receiverByReceiverId.displayName || node.receiverByReceiverId.name}`}</Label>})
                                        }
                                    </div>
                                </div>
                                <Divider/>
                                <div key="away-team-info">
                                    <Header as='h4'>Away Team</Header>
                                    {
                                        event.teamByAwayteamid.logo ? <Image src={event.teamByAwayteamid.logo} size='tiny' rounded={true} circular={true} bordered fluid floated={"right"} /> : ''
                                    }
                                    <div><b>Team Id: </b>{event.teamByAwayteamid.id}</div>
                                    <div><b>Team Name: </b><Link as={'a'} to={'/team/'+event.teamByAwayteamid.id}> {event.teamByAwayteamid.name}</Link></div>
                                    <div><b>Contacts:</b>
                                        {
                                            _.map(event.teamByAwayteamid.contactpeopleByTeamid.nodes, node =>
                                            { return <Label as={Link} to={`/contacts/${node.id}`}>{`${node.title} ${node.fullname}`}</Label>})
                                        }
                                    </div>
                                    <div><b>Receivers:</b>
                                        {
                                            _.map(event.teamByAwayteamid.teamReceiversByTeamId.nodes, node =>
                                            { return <Label as={Link} to={`/receivers/${node.receiverByReceiverId.id}`}>{`${node.receiverByReceiverId.displayName || node.receiverByReceiverId.name}`}</Label>})
                                        }
                                    </div>
                                </div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Segment color='yellow' style={{textAlign: 'left'}}>
                                <Label as='h2' size='big' color='blue' ribbon style={{marginBottom: 15}}>Articles</Label>
                                <List size='big' divided verticalAlign='middle'>{articles}</List>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Container>
            </div>
        );
    } else {
        return <Info icon='warning' message='Sorry, we could not find event you requested!' title='Error'
                     loading={false}/>;
    }
};

const eventQuery = gql`
    query EventQuery($id: String!) {
        event: eventById(id: $id) {
            id
            suspended
            lastUpdated
            teamByHometeamid {
                id
                name
                logo
                teamReceiversByTeamId {
                    nodes {
                        receiverByReceiverId {
                            id
                            name
                            key
                            note
                            displayName
                        }
                    }
                }
                contactpeopleByTeamid {
                    nodes {
                        id
                        active
                        drymode
                        fullname
                        title
                        phone
                    }
                }
            }
            teamByAwayteamid {
                id
                name
                logo
                teamReceiversByTeamId {
                    nodes {
                        receiverByReceiverId {
                            id
                            name
                            key
                            note
                            displayName
                        }
                    }
                }
                contactpeopleByTeamid {
                    nodes {
                        id
                        active
                        drymode
                        fullname
                        title
                        phone
                    }
                }
            }
            startdate
            leagueByLeagueid {
                id
                name
                sportname
                teamclass
            }
            articlesByEventid {
                nodes {
                    id
                    title
                    status
                    teamByFocusedteam {
                        id
                        name
                        contactpeopleByTeamid {
                            nodes {
                                fullname
                                active
                                drymode
                                id
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Event = graphql(eventQuery, {
    options: (ownProps) => ({variables: {id: ownProps.match.params.eventId},
        fetchPolicy: 'network-only',
        pollInterval: 30000}),
})(EventContainer);

export default Event;
