import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Table, Pagination, Select, Checkbox, Button, Icon, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import SearchContact from '../components/SearchContact';
import Info from '../components/Info';
import SendNewBulkMessages from '../components/contact-person/SendNewBulkMessages';

const ContactListContainer = props => {
    const {
        data: { loading, error, filterContacts: allContactpeople, allSports, allTeamClasses, filterLeagues, allReceivers },
        limitNo,
        orderBy,
        orderByDirection,
        page,
        filter,
        history,
        match,
        selectedContacts,
        setSelectedContacts,
        openSendSmsModal,
        setOpenSendSmsModal,
    } = props;

    if (error) {
        return <Info icon="warning" message={error.message} title="Error" loading={false} />;
    }

    if (loading) {
        return <Info icon="circle notched" message="Loading..." loading={true} />;
    }

    const navigate = url => {
        setSelectedContacts([]);
        setOpenSendSmsModal(false);
        history.push(`/contacts/filter/${url}`);
    };

    const getFilterParam = (newFilter = {}) => {
        console.log(JSON.stringify(newFilter));
        const updatedFilter = {
            ...filter,
            ...newFilter,
        };
        return [
            updatedFilter.sportId,
            updatedFilter.teamClass,
            updatedFilter.teamId,
            updatedFilter.leagueId,
            updatedFilter.onlyActive,
            updatedFilter.levelIn,
            updatedFilter.receiverId
        ].join(',');
    };
    const getOrderParam = (newOrder = {}) => {
        const updatedOrder = {
            orderBy,
            orderByDirection,
            ...newOrder,
        };
        return [updatedOrder.orderBy, updatedOrder.orderByDirection].join(',');
    };

    const sports = [
        {
            key: 'sport',
            value: 0,
            text: 'All sports',
        },
        ...allSports.edges.map(({ node }) => ({
            key: node.sportid,
            value: node.sportid,
            text: node.sportname,
        })),
    ];
    const teamClasses = [{key: 'all', value: 0, text: 'All classes'}, {key: 'herr', value: 'herr', text: 'Men'},{key: 'dam', value: 'dam', text: 'Women'},{key: 'mix', value: 'mix', text: 'Mix'}];

    const leagues = [
        {
            key: 'leagues',
            value: 0,
            text: 'All leagues',
        },
        ...filterLeagues.edges.map(({ node }) => ({
            key: node.id,
            value: node.id,
            text: `${node.name} (${node.sportname} - ${node.teamclass})`,
        })),
    ];

    const receivers = [
        {
            key: 'receivers',
            value: 0,
            text: 'All receivers',
        },
        ...allReceivers.edges.map(({ node }) => ({
            key: node.id,
            value: node.id,
            text: `${node.key} / ${node.name}`,
        })),
    ];

    const getSorted = (field, ui) => {
        const DIRECTION = {
            asc: 'ascending',
            desc: 'descending',
        };
        if (!orderBy) {
            return null;
        }
        if (orderBy !== field) {
            return null;
        }
        if (ui) {
            return DIRECTION[orderByDirection];
        }
        return orderByDirection;
    };

    const handleSort = field => {
        return () => {
            const direction = (() => {
                const currentDirection = getSorted(field);
                if (currentDirection === 'asc') {
                    return 'desc';
                }
                return 'asc';
            })();
            navigate(
                `${match.params.filter}/${getOrderParam({
                    orderBy: field,
                    orderByDirection: direction,
                })}/1`,
            );
        };
    };

    const pagination = (
        <Table.Row>
            <Table.HeaderCell colSpan="14" style={{ textAlign: 'center' }}>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={page}
                    ellipsisItem={null}
                    lastItem={null}
                    siblingRange={1}
                    prevItem={undefined}
                    nextItem={undefined}
                    totalPages={_.get(allContactpeople, 'edges.length', 0) < limitNo ? page : page + 1}
                    onPageChange={(e, data) => {
                        navigate(`${match.params.filter}/${match.params.order}/${data.activePage}`);
                    }}
                />
            </Table.HeaderCell>
        </Table.Row>
    );

    const selectedContactsUIHandler = selectedContacts.length > 0 && (
        <Table.Row>
            <Table.HeaderCell colSpan="14" style={{ textAlign: 'left' }}>
                selected {selectedContacts.length} contacts
                <Modal
                    onClose={() => {
                        setOpenSendSmsModal(false);
                    }}
                    open={openSendSmsModal}
                    closeOnEscape={true}
                    closeOnDimmerClick={false}
                    trigger={
                        <Button
                            onClick={() => {
                                setOpenSendSmsModal(true);
                            }}
                            style={{
                                marginLeft: 10,
                            }}
                            color="green"
                        >
                            <Icon name="send" />
                            Send Message
                        </Button>
                    }
                >
                    <Modal.Header>Send message</Modal.Header>
                    <Modal.Content>
                        {selectedContacts.length && (
                            <SendNewBulkMessages
                                contactPersonIds={selectedContacts}
                                contactPersons={allContactpeople.edges
                                    .filter(c => selectedContacts.includes(c.node.id))
                                    .map(c => c.node)}
                                removeContact={(contactId) => {
                                    setSelectedContacts(selectedContacts.filter(cId => cId !== contactId))
                                }}
                                onSuccess={() => {
                                    setOpenSendSmsModal(false);
                                    setSelectedContacts([]);
                                }}
                                onFail={() => {}}
                            />
                        )}
                    </Modal.Content>
                </Modal>
            </Table.HeaderCell>
        </Table.Row>
    );

    return (
        <div style={{ marginTop: 10, padding: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        flex: '1 1 100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                    }}
                >
                    <Select
                        value={filter.sportId ? parseInt(filter.sportId, 10) : 0}
                        onChange={(e, data) => {
                            const { value } = data;
                            navigate(
                                `${getFilterParam({
                                    ...filter,
                                    sportId: value ? parseInt(value, 10) : null,
                                    leagueId: null,
                                })}/${match.params.order}/1`,
                            );
                        }}
                        style={{ marginRight: 5 }}
                        placeholder="Select sport"
                        options={sports}
                    />
                    <Select
                        value={filter.teamClass || 0}
                        onChange={(e, data) => {
                            const { value } = data;
                            navigate(
                                `${getFilterParam({
                                    ...filter,
                                    teamClass: value ? value : null,
                                    leagueId: null,
                                })}/${match.params.order}/1`,
                            );
                        }}
                        style={{ marginRight: 5 }}
                        placeholder="Select class"
                        options={teamClasses}
                    />
                    <Select
                        value={filter.leagueId ? filter.leagueId : null}
                        onChange={(e, data) => {
                            const { value } = data;
                            navigate(
                                `${getFilterParam({
                                    ...filter,
                                    leagueId: value ? value : null,
                                })}/${match.params.order}/1`,
                            );
                        }}
                        style={{ marginRight: 5 }}
                        placeholder="Select league"
                        options={leagues}
                    />
                    <Select
                        value={filter.receiverId ? parseInt(filter.receiverId, 10) : 0}
                        onChange={(e, data) => {
                            const { value } = data;
                            navigate(
                                `${getFilterParam({
                                    ...filter,
                                    receiverId: value ? parseInt(value, 10) : null,
                                })}/${match.params.order}/1`,
                            );
                        }}
                        style={{ marginRight: 5 }}
                        placeholder="Select receiver"
                        options={receivers}
                    />
                    <Checkbox
                        toggle
                        label="Only Active"
                        checked={Boolean(filter.onlyActive)}
                        onChange={(e, data) => {
                            const { checked } = data;
                            console.log(checked)
                            navigate(
                                `${getFilterParam({
                                    ...filter,
                                    onlyActive: checked ? true : null,
                                })}/${match.params.order}/1`,
                            );
                        }}
                    /><Checkbox
                        toggle
                        label="Only Whitelisted"
                        checked={Boolean(filter.levelIn === 1)}
                        onChange={(e, data) => {
                            const { checked } = data;
                            console.log(checked, 'really');
                            navigate(
                                `${getFilterParam({
                                    ...filter,
                                    levelIn: checked ? 1 : null,
                                })}/${match.params.order}/1`,
                            );
                        }}
                    />
                </div>
                <div
                    style={{
                        flex: '1 1 370px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <SearchContact />
                </div>
            </div>

            <Table sortable celled selectable color="blue">
                <Table.Header>
                    {pagination}
                    {selectedContactsUIHandler}
                    <Table.Row>
                        <Table.HeaderCell>
                            <Checkbox
                                onChange={() => {
                                    if (selectedContacts.length === allContactpeople.edges.length) {
                                        setSelectedContacts([]);
                                    } else {
                                        setSelectedContacts(allContactpeople.edges.map(({ node }) => node.id));
                                    }
                                }}
                                checked={selectedContacts.length === allContactpeople.edges.length}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('active', true)} onClick={handleSort('active')}>
                            Active
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('drymode', true)} onClick={handleSort('drymode')}>
                            Dry Mode
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Whitelisted
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('title', true)} onClick={handleSort('title')}>
                            Title
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('fullname', true)} onClick={handleSort('fullname')}>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('phone', true)} onClick={handleSort('phone')}>
                            Phone
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('team', true)} onClick={handleSort('team')}>
                            Team
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('league', true)} onClick={handleSort('league')}>
                            League
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('sport', true)} onClick={handleSort('sport')}>
                            Sport
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={getSorted('teamclass', true)} onClick={handleSort('teamclass')}>
                            Class
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={getSorted('registrationdate', true)}
                            onClick={handleSort('registrationdate')}
                        >
                            Registration Date
                        </Table.HeaderCell>
                        <Table.HeaderCell>Messaging</Table.HeaderCell>
                        <Table.HeaderCell colSpan="2">Edit Contact</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {allContactpeople.edges.map(({ node }) => {
                        return (
                            <Table.Row warning={!node.active} key={node.id}>
                                <Table.Cell>
                                    <Checkbox
                                        onChange={() => {
                                            if (selectedContacts.includes(node.id)) {
                                                setSelectedContacts(selectedContacts.filter(cId => cId !== node.id));
                                            } else {
                                                setSelectedContacts([...selectedContacts, node.id]);
                                            }
                                        }}
                                        checked={selectedContacts.includes(node.id)}
                                    />
                                </Table.Cell>
                                <Table.Cell>{node.active ? 'active' : 'inactive'}</Table.Cell>
                                <Table.Cell negative={node.drymode}>{node.drymode ? 'yes' : 'no'}</Table.Cell>
                                <Table.Cell positive={node.level === 1}>{node.level === 1 ? 'yes' : 'no'}</Table.Cell>
                                <Table.Cell>{node.title}</Table.Cell>
                                <Table.Cell>{node.fullname}</Table.Cell>
                                <Table.Cell>{node.phone}</Table.Cell>
                                <Table.Cell><Link to={`/team/${node.teamByTeamid.id}`}>{node.teamByTeamid.name}</Link></Table.Cell>
                                <Table.Cell>{node.teamByTeamid.leagueByLeagueid.name}</Table.Cell>
                                <Table.Cell>{node.teamByTeamid.leagueByLeagueid.sportname}</Table.Cell>
                                <Table.Cell>{node.teamByTeamid.leagueByLeagueid.teamclass}</Table.Cell>
                                <Table.Cell>{moment(node.registrationdate).format('YYYY MMMM Do, h:mm a')}</Table.Cell>
                                <Table.Cell>{node.messagesByContactpersonid.totalCount}</Table.Cell>
                                <Table.Cell>
                                    <Link to={`/contacts/${node.id}`}>See last messages</Link>
                                </Table.Cell>
                                <Table.Cell>
                                    <a
                                        href={`http://inrapportering.everysport.se/intra-new/contactList.html?teamId=${node.teamByTeamid.id}&clean`}
                                        target="_blank" rel="noopener noreferrer"
                                    >
                                        Edit
                                    </a>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>

                <Table.Footer>
                    {pagination}
                    {selectedContactsUIHandler}
                </Table.Footer>
            </Table>
        </div>
    );
};

const contactListQuery = gql`
    query ContactListQuery(
        $orderBy: String
        $orderByDirection: String
        $offsetNo: Int
        $limitNo: Int
        $sportId: Int
        $leagueId: String
        $levelIn: Int
        $receiverId: Int
        $region: Int
        $teamClass: String
        $onlyActive: Boolean
    ) {
        filterContacts:filterContacts7(
            orderBy: $orderBy
            orderByDirection: $orderByDirection

            offsetNo: $offsetNo
            limitNo: $limitNo

            sportId: $sportId
            leagueIdIn: $leagueId
            levelIn: $levelIn
            teamClass: $teamClass
            onlyActive: $onlyActive
            receiverIn: $receiverId
            regionIn: $region
        ) {
            edges {
                node {
                    active
                    registrationdate
                    id
                    title
                    fullname
                    phone
                    drymode
                    level
                    teamByTeamid {
                        id
                        name
                        leagueByLeagueid {
                            id
                            name
                            sportname
                            teamclass
                        }
                        teamReceiversByTeamId {
                            nodes {
                                receiverByReceiverId {
                                    id
                                    key
                                    name
                                    displayName
                                }
                            }
                        }
                    }
                    messagesByContactpersonid {
                        totalCount
                    }
                }
            }
        }
        allSports {
            edges {
                node {
                    sportid
                    sportname
                }
            }
        }
        filterLeagues: filterLeagues3(sportId: $sportId, teamClass: $teamClass, regionIn: $region) {
            edges {
                node {
                    id
                    name
                    sportid
                    sportname
                    teamclass
                }
            }
        }
        allReceivers(orderBy: [KEY_ASC, NAME_ASC]) {
            edges {
                node {
                    id
                    key
                    name
                }
            }
        }
    }
`;

const ContactList = graphql(contactListQuery, {
    options: props => {
        const {
            orderBy,
            orderByDirection,
            limitNo,
            offsetNo,
            filter: { sportId = null, leagueId = null, teamClass = null, teamId = null, onlyActive = null, levelIn = null, receiverId = null, region = parseInt(localStorage.getItem('selected-region')|| '1') },
        } = props;

        return {
            variables: {
                limitNo,
                offsetNo,
                orderBy,
                orderByDirection,
                sportId,
                leagueId,
                levelIn,
                teamClass,
                teamId,
                onlyActive,
                receiverId,
                region
            },
            fetchPolicy: 'network-only',
            pollInterval: 480000,
        };
    },
})(ContactListContainer);
export default ContactList;
