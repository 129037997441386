import React, { useState, useEffect } from 'react';
import {
    Icon,
    Segment,
    Button,
    Form,
    Message,
    Modal
} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import {Mutation} from "react-apollo";
import gql from "graphql-tag";

const ADD_CONTACT_PERSON_MUTATION = gql`
    mutation addContactpersonDirectly($input: AddContactpersonDirectlyInput!) {
        addContactpersonDirectly(input: $input) {
            contactperson {
                id
                fullname
            }
        }
    }
`;

const AddContactPersonContainer = (props) => {
    const { teamId, addContactPerson } = props;
    const smsProviderOptions = [{ key:'46elks_nl', value: '46elks_nl', text: `Netherlands`}, { key:'46elks_bel', value: '46elks_bel', text: `Belgium`}];
    const [addContactFailed, setAddContactFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState('46elks_nl');

    const [isOpen, setIsOpen] = useState(false);

    const { register, handleSubmit, errors, setValue, getValues, triggerValidation } = useForm();
    useEffect(() => {
        register({ name: 'fullnameIn' }, { required: true });
        register({ name: 'teamidIn' }, { required: false });
        register(
            { name: 'phoneIn' },
            {
                required: true,
                pattern: {
                    value: /^((([+])(46)(\d{9}))|(([+])(358)(\d{9,10}))|((07)(\d{8}))|(([+])(49)(\d{10}))|(([+])(31)(\d{9}))|(([+])(32)(\d{9}))|(([+])(44)(\d{10}))|(([+])(45)(\d{8})))$/,
                    message: 'Wrong phone number format',
                }
            },
        );
        register({ name: 'titleIn' }, { required: true });
        register({ name: 'smsProviderIn' }, { required: false });
    }, []);

    const handleChange = async (e, { name, value }) => {
        if (name === 'phoneIn'){
            let phoneNumber = value.replace(/[^\d\\+]/g, '');
            phoneNumber = phoneNumber.replace(/^0+(?!$)/, '');
            setValue(name, phoneNumber);
        } else {
            setValue(name, value);
        }
        await triggerValidation({ name });
    };

    return (
        <Modal
            onClose={() => setIsOpen(false)}
            open={isOpen}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            trigger={
                <Button onClick={() => setIsOpen(true)} float="right" color="green">
                    <Icon name="add" />
                    Add contact person for this team
                </Button>
            }
        >
            <Modal.Header>Add a new contact person</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {addContactFailed ? (
                        <Message
                            warning
                            header="Contact person could not be added"
                            content={errorMessage}
                        />
                    ) : null}

                    <Form
                        as='form' autoComplete="off"
                        onSubmit={handleSubmit(async input => {

                            console.log('calling', input)
                            try {
                                input.smsProviderIn = selectedProvider;
                                input.teamidIn = teamId;
                                await addContactPerson({variables : {input}});

                                setValue('fullnameIn', null);
                                setValue('titleIn', null);
                                setValue('phoneIn', null);
                                setIsOpen(false);
                            } catch (err) {
                                setAddContactFailed(true);
                                setErrorMessage(err.message);
                                setTimeout(() => {
                                    setAddContactFailed(false);
                                    setErrorMessage(null);
                                }, 5000);
                            }
                        })}
                    >
                        <Form.Group widths="16">
                            <Form.Field width={8}>
                                <Form.Input
                                    fluid
                                    label="Title"
                                    placeholder="Trainer"
                                    name="titleIn"
                                    defaultValue=""
                                    onChange={handleChange}
                                />
                                {errors['titleIn'] && <Message error visible content={errors['titleIn'].message} />}
                            </Form.Field>
                            <Form.Field width={8}>
                                <Form.Input
                                    fluid
                                    label="Name"
                                    placeholder="Name Surname"
                                    name="fullnameIn"
                                    defaultValue=""
                                    onChange={handleChange}
                                />
                                {errors['fullnameIn'] && <Message error visible content={errors['fullnameIn'].message} />}
                            </Form.Field>

                        </Form.Group>
                        <Form.Group widths="16">
                            <Form.Field width={"8"}>
                                <Form.Select label='SMS Provider' value={selectedProvider} placeholder='ALL' options={smsProviderOptions}
                                             onChange={(e, {value})=> setSelectedProvider(value)}/>
                            </Form.Field>
                            <Form.Field width={8}>
                                <Form.Input
                                    fluid
                                    label="Phone"
                                    placeholder="Format +32708779675, +318708779675"
                                    name="phoneIn"
                                    defaultValue=""
                                    onChange={handleChange}
                                />
                                {errors['phoneIn'] && <Message error visible content={errors['phoneIn'].message} />}
                            </Form.Field>
                        </Form.Group>
                        <Segment basic style={{ textAlign: 'right' }}>
                            <Form.Button color={'green'}>
                                <Icon name="add" /> Add Contact
                            </Form.Button>
                        </Segment>
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}


const AddContactPerson = ({teamId, refetchQuery}) => ( <Mutation mutation={ADD_CONTACT_PERSON_MUTATION} refetchQueries={[{ query: refetchQuery, variables: {id: teamId}}]}>
    {(addContactpersonDirectly) => (
        <AddContactPersonContainer teamId={teamId} addContactPerson={addContactpersonDirectly}/>
    )}
</Mutation>);

export default AddContactPerson;
