import {Mutation} from "react-apollo";
import React from "react";
import gql from "graphql-tag";
import {Button, Icon} from "semantic-ui-react";



const MARK_EXTERNAL_SMS_READ_MUTATION = gql`
    mutation mark($input: MarkExternalSmsReadInput!) {
        markExternalSmsRead(input: $input) {
            externalSm {
                id
                contact
                read
                sender
            }
        }
    }
`;

const markExternalSms= async (markExternalSmsReadFunc, id, isRead) => {
    await markExternalSmsReadFunc({
        variables: {
            input: { inId: id,
                inRead: !isRead }
        }
    })
};

const MarkExternalSmsReadContainer = ({id, isRead, refetchQuery, markExternalSmsRead}) => {
    return (

        <Button color={ isRead ? 'red' :'green'}
                onClick={() => markExternalSms(markExternalSmsRead, id, isRead, refetchQuery)}><Icon
            name='check'/>{isRead ? 'Mark as Unread' : 'Mark as Read'}</Button>

    );
};


const MarkExternalSmsRead = ({id, isRead}) => (
    <Mutation mutation={MARK_EXTERNAL_SMS_READ_MUTATION}>
        {(markExternalSmsRead) => (
            <MarkExternalSmsReadContainer markExternalSmsRead={markExternalSmsRead} isRead={isRead} id={id} />
        )}
    </Mutation>);

export default MarkExternalSmsRead;
