import gql from "graphql-tag";
import { useMutation, useQuery} from "react-apollo";
import React, { useState} from "react";
import {Icon, Form, Message, Segment, Header} from "semantic-ui-react";
import Info from "../Info";

const ADMIN_QUERY = gql`
    query adminDetails($adminId: Int!) {
        adminDetails(adminId: $adminId) {
            id
            fullname
            phone
            smsProvider
        }
    }
`;

const UPDATE_ADMIN = gql`
    mutation UpdateAdmin($admin: Int!, $phone: String!, $provider: String!){
        editAdmin(input: {idIn: $admin, phoneIn: $phone, smsProviderIn: $provider}) {
            admin {
                id
            }
        }
    }
`;
const smsProviderOptions = [
    { key: '46elks_se', value: '46elks_se', text: '46Elks Sweden'},
    { key: '46elks_nl', value: '46elks_nl', text: '46Elks Netherlands'},
    { key: '46elks_bel', value: '46elks_bel', text: '46Elks Belgium'},
    { key: '46elks_de', value: '46elks_de', text: '46Elks Germany'},
    { key: '46elks_fin', value: '46elks_fin', text: '46Elks Finland'}];

const UpdateAdmin = ({adminId}) => {
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedSmsProvider, setSelectedSmsProvider] = useState('46elks_se');
    const [errorUpdate, setErrorUpdate] = useState('');
    const [editAdmin] = useMutation(UPDATE_ADMIN);
    const {loading, error, data} = useQuery(ADMIN_QUERY, {variables: {adminId}, onCompleted:dataC => { setFullName(dataC?.adminDetails?.fullname); setPhone(dataC?.adminDetails?.phone); setSelectedSmsProvider(dataC?.adminDetails?.smsProvider || '46elks_se');}});

    if(loading) {
        return <Info icon="circle notched" message="Loading admin phone info" loading={true} />;
    }
    if (error) {
        return <Info icon="warning" message={error.message} title="Error loading admin phone info" loading={false} />;
    }

    const { adminDetails} = data;
    if(!adminDetails?.id) {
        return <Info icon="warning" message="Cannot edit phone number" />;
    }
    const handleUpdateAdmin = async () => {
        try {
            await editAdmin({
                variables: {
                    admin: adminId,
                    phone: phone,
                    provider: selectedSmsProvider
                }, refetchQueries: [{
                    query: ADMIN_QUERY,
                    variables:{adminId}
                }]
            });

        } catch (err) {
            console.error(err);
            setErrorUpdate(err.toString());
            setTimeout(() => {
                setErrorUpdate('');
            }, 5000);
        }
    };

    return (
        <Segment color={'grey'} inverted textAlign={"center"}>
            <Header as='h3'>Update admin phone</Header>
            <Form>
                {
                    errorUpdate ?
                        <Message
                            warning
                            header='Error updating phone number!'
                            content={errorUpdate}
                        /> : ''
                }

                <Form.Input width={16} label='Phone'
                            value={phone}
                            onChange={(event) => { console.log(event.target.value);setPhone(event.target.value)}}
                            placeholder='Format +46xxx, +358xxx'/>
                <Form.Select width={16} options={smsProviderOptions} label={'SMS Provider'} onChange={(event, data) => {setSelectedSmsProvider(data.value)}} value={selectedSmsProvider}/>

                <Form.Button disabled={(selectedSmsProvider === adminDetails?.smsProvider) && (phone === adminDetails?.phone)}  onClick={handleUpdateAdmin} color='green'><Icon name='add'/>Update Phone Number</Form.Button>
            </Form>
        </Segment>
    );
};

export default UpdateAdmin;
