import React, { useState, useEffect } from 'react';
import {
    Icon,
    Segment,
    Button,
    Form,
    Message,
    Modal, Header
} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import * as _ from 'lodash';
import SearchTeams from "./SearchTeams";
import SeasonPicker from "./SeasonPicker";
import ExternalContactsByPhone from "./ExternalContactsByPhone";

export default function AddExternalContact(props) {
    const region = parseInt(localStorage.getItem('selected-region')|| '1')
    const { open, onClose, onOpen, onSubmit, receivers, sports, signatures } = props;
    const receiverOptions = _.map(_.orderBy(receivers, 'name'), r => ({ key:r.key, value: r.id, text: `${r.name} ( ${r.key} )`}));
    const sportOptions = _.map(_.orderBy(sports, 'name'), r => ({ key:r.sportid, value: r.sportid, text: `${r.sportname}`}));
    const messageOptions = _.map(_.orderBy(signatures, 'title'), r => ({ key:r.id, value: r.id, text: `${r.title}`}));

    receiverOptions.splice(0, 0, { key: '-1', value: '-1', text: 'NONE'});
    sportOptions.splice(0, 0, { key: '-1', value: null, text: 'ALL'});
    messageOptions.splice(0, 0, { key: '-1', value: null, text: 'NONE'});
    const [addExternalContactFailed, setAddExternalContactFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [selectedSport, setSelectedSport] = useState(false);
    const [autoOnboardIn, setAutoOnboardIn] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(-1);
    const [phoneNumber, setPhoneNumber] = useState('');
    const { register, handleSubmit, errors, setValue, getValues, triggerValidation } = useForm();
    useEffect(() => {
        register({ name: 'fullname' }, { required: 'It is required'  });
        register({ name: 'titleIn' }, { required: 'It is required'  });
        register(
            { name: 'phone' },
            {
                // required: 'It is required',
                pattern: {
                    value: /^((([+])(46)(\d{9}))|(([+])(358)(\d{9,10}))|((07)(\d{8}))|(([+])(49)(\d{10}))|(([+])(31)(\d{9}))|(([+])(32)(\d{9}))|(([+])(44)(\d{10}))|(([+])(45)(\d{8})))$/,
                    message: 'Wrong phone number format',
                }
            },
        );
        register({ name: 'note' }, { required: false });
        register({ name: 'receiver' }, { required: false });
        register({ name: 'team' }, { required: true });
        register({ name: 'season' }, { required: false });
        register({ name: 'autoOnboardIn' }, { required: false });
        register({ name: 'messageTemplate' }, { required: false });
    }, []);

    const handleChange = async (e, { name, value }) => {
        if(name === 'team') {
            setValue(name, value);
        } else if(name === 'autoOnboardIn') {
            setValue(name, value === 'true');
        } else if(name === 'receiver' || name === 'season' || name === 'messageTemplate') {
            const rid = parseInt(value, 10);
            if(rid>0) {
                setValue(name, rid);
            } else {
                setValue(name, null);
            }
        } else if (name === 'phone'){
            let phoneNumber = value.replace(/[^\d\\+]/g, '');
            phoneNumber = phoneNumber.replace(/^0+(?!$)/, '');
            setValue(name, phoneNumber);

            if(phoneNumber.length > 7) {
                setPhoneNumber(phoneNumber);
            } else {
                setPhoneNumber('');
            }
        } else {
            setValue(name, value);
        }
        await triggerValidation({ name });
    };

    return (
        <Modal
            onClose={onClose}
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            trigger={
                <Button onClick={onOpen} float="right" color="green">
                    <Icon name="add" />
                    Add a new external contact
                </Button>
            }
        >
            <Modal.Header>Add a new external contact</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {addExternalContactFailed ? (
                        <Message
                            warning
                            header="External contact person could not be added"
                            content={errorMessage}
                        />
                    ) : null}

                    <Form
                        as='form' autoComplete="off"
                        onSubmit={handleSubmit(async variables => {
                            try {
                                variables.regionIn = region;
                                variables.message = selectedMessage > 0 ? selectedMessage : null;
                                variables.autoOnboardIn = autoOnboardIn;
                                await onSubmit(variables);
                                setValue('team', null);
                                setValue('receiver', null);
                                setValue('note', null);
                                onClose();
                            } catch (err) {
                                const errMsg = err.message.indexOf('external_contact_team_id_fk') > 0 ? 'Team Id is not found in the system, please leave empty for now.': err.message;
                                setAddExternalContactFailed(true);
                                setErrorMessage(errMsg);
                                setTimeout(() => {
                                    setAddExternalContactFailed(false);
                                    setErrorMessage(null);
                                }, 5000);
                            }
                        })}
                    >
                        <Form.Group widths="16">
                            <Form.Field width={4}>
                                <Form.Input
                                    fluid
                                    label="Title"
                                    placeholder="Tränare"
                                    name="titleIn"
                                    defaultValue=""
                                    onChange={handleChange}
                                />
                                {errors['titleIn'] && <Message error visible content={errors['titleIn'].message} />}
                            </Form.Field>
                            <Form.Field width={6}>
                                <Form.Input
                                    fluid
                                    label="Name"
                                    placeholder="Name Surname"
                                    name="fullname"
                                    defaultValue=""
                                    onChange={handleChange}
                                />
                                {errors['fullname'] && <Message error visible content={errors['fullname'].message} />}
                            </Form.Field>
                            <Form.Field width={6}>
                                <Form.Input
                                    fluid
                                    label="Phone"
                                    placeholder="Format +46708779675, +358708779675 or 0708779675"
                                    name="phone"
                                    defaultValue=""
                                    onChange={handleChange}
                                />
                                {errors['phone'] && <Message error visible content={errors['phone'].message} />}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="16">
                            <Form.Field width={"6"}>
                                <Form.Select label='Sport' value={selectedSport} placeholder='ALL' options={sportOptions}
                                    onChange={(e, {value})=> setSelectedSport(value)}/>
                            </Form.Field>
                            <Form.Field width={"10"}>
                                <label>Team Search</label>
                                <SearchTeams team={{}} sportId={selectedSport} regionId={region} onTeamSelected={(teamId) => handleChange( null, {name: 'team', value: teamId})}/>
                                {errors['team'] && <Message error visible content={errors['team'].message} />}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Form.Select label='Receiver' placeholder='Select a receiver' name='receiver' options={receiverOptions} onChange={handleChange}/>
                            </Form.Field>
                            <SeasonPicker selectedSeason={getValues('season')} sportId={selectedSport} onSeasonSelected={ (seasonId) => handleChange(null, {name: 'season', value: seasonId})}/>
                        </Form.Group>
                        <Form.Group widths="16">
                            <Form.Field width={"10"}>
                                <label>Auto Onboard</label>
                                <Form.Checkbox toggle={true} onClick={() => setAutoOnboardIn(!autoOnboardIn)} checked={autoOnboardIn} />
                            </Form.Field>
                            <Form.Field width={"6"}>
                                <Form.Select label='Message Template' value={selectedMessage} placeholder='ALL' options={messageOptions}
                                    onChange={(e, {value})=> setSelectedMessage(value)
                                    }/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <Form.TextArea
                                label="Notes"
                                placeholder="Add some notes about external contact"
                                name="note"
                                defaultValue=""
                                onChange={handleChange}
                            />
                        </Form.Field>
                        {
                            phoneNumber ? <Segment raised style={{textAlign: 'left'}}>
                                <Header as={'h3'} color={'grey'} content={'Existing contacts with similar phone number:'}/>
                                <ExternalContactsByPhone phone={phoneNumber} region={region}/>
                            </Segment> : ''
                        }
                        <Segment basic style={{ textAlign: 'right' }}>
                            <Form.Button color={'green'}>
                                <Icon name="add" /> Add Contact
                            </Form.Button>
                        </Segment>
                    </Form>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
