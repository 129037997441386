import React from 'react';
import {graphql, Query} from "react-apollo";
import gql from "graphql-tag";
import {
    Table,
    Header,
    Icon,
    Button,
    Popup,
    Container,
    Checkbox,
    Menu,
    Pagination, Radio, Segment,
} from 'semantic-ui-react'
import {Link} from 'react-router-dom';
import moment from 'moment'
import _ from 'lodash'
import Info from '../components/Info';
import Notification from '../components/Notification';
import MessageStatusIcon from '../components/MessageStatusIcon';
import {TextField} from "material-ui";

const PAGE_SIZE = 20;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
class MessageListContainer extends React.Component {

    constructor(props) {
        super(props);
        const justAbout = localStorage.getItem('just-about-messages') == 'true';
        const aboutText = localStorage.getItem('about-text-messages') || '';
        this.state = {lastMessage: null, notifications: Notification.areNotificationsEnabled(), justAbout, aboutText, refetchingMessages: false};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {allMessages} = this.props;
        if(allMessages) {
            const lastReceivedMessage = _.find(allMessages.nodes, (node) => ( node.type ===  'received' ));
            if(lastReceivedMessage) {
                const lastReceivedMessageTime = moment(lastReceivedMessage.timestamp);
                if(this.state.lastMessage && moment(this.state.lastMessage, dateFormat).isBefore(lastReceivedMessageTime, 'second')) {
                    Notification.doNotification('Message from: ' + lastReceivedMessage.sender, lastReceivedMessage.text);
                    this.setState({lastMessage: lastReceivedMessageTime.format(dateFormat)})
                } else if(!this.state.lastMessage) {
                    this.setState({lastMessage: lastReceivedMessageTime.format(dateFormat)})
                }
            }

        }
    }

    render() {
        const {
            allMessages,
            match: { params: { page, direction }},
            history,
            refetch,
            startPolling,
            stopPolling
        } = this.props;

        const pageNo = page ? parseInt(page, 10) : 1;

        const toggleNotifications = () => {
            this.setState({notifications: Notification.toggleNotifications()});
        };

        const toggleFilterSettings = async (justAbout, aboutText) => {
            this.setState({
                justAbout,
                aboutText,
                refetchingMessages: true
            });
            localStorage.setItem('just-about-messages', ''+justAbout);
            localStorage.setItem('about-text-messages', aboutText);
            stopPolling();
            await refetch(getMessagesQueryVariables(this.props));
            startPolling(35000);
            this.setState({
                refetchingMessages: false
            });
        };

        const filterMenu = () => {
            return (
                <Menu stackable color='blue' secondary>
                    <Menu.Item as={Link} to="/messages" name='all-messages' active={!direction || direction === 'all'}>
                        <Icon name='adn'/> All messages
                    </Menu.Item>
                    <Menu.Item as={Link} to={'/messages/received'} name='received-messages' active={direction === 'received'}>
                        <MessageStatusIcon type='received' displayLabel={true}/>
                    </Menu.Item>
                    <Menu.Item as={Link} to={'/messages/sent'} name='sent messages' active={direction === 'sent'}>
                        <MessageStatusIcon type='sent' displayLabel={true}/>
                    </Menu.Item>
                    <Menu.Item as={Link} to={'/messages/pending'} name='pending-messages' active={direction === 'pending' }>
                        <MessageStatusIcon type='pending' displayLabel={true}/>
                    </Menu.Item>
                    <Menu.Item as={Link} to={'/messages/failed'} name='failed-messages' active={direction === 'failed' }>
                        <MessageStatusIcon type='failed' displayLabel={true}/>
                    </Menu.Item>
                </Menu>
            );
        };

        const navigate = url => {
            history.push(`/messages/${(direction || 'all')}/${url}`)
        }
        const pagination = (
            <Table.Row>
                <Table.HeaderCell colSpan="7" style={{ textAlign: 'center' }}>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={pageNo}
                        ellipsisItem={null}
                        lastItem={null}
                        siblingRange={1}
                        prevItem={undefined}
                        nextItem={undefined}
                        totalPages={
                            _.get(allMessages, 'nodes.length', 0) < PAGE_SIZE
                                ? pageNo
                                : pageNo + 1
                        }
                        onPageChange={(e, data) => {
                            navigate(data.activePage)
                        }}
                    />
                </Table.HeaderCell>
            </Table.Row>
        )

        return (
            <div>
                <Container>
                    <Header as='h1' textAlign='center' color='blue'>
                            MESSAGES
                    </Header>
                    <Button style={{display: Notification.needsPermission ? '' : 'none'}} disabled={!Notification.needsPermission} onClick={Notification.requestNotifyPermission}><Icon name='feed'/>Subscribe notifications</Button>
                    <Checkbox toggle label='Notifications' onChange={toggleNotifications} checked={this.state.notifications}/>
                    <Popup
                        trigger={<span> <Icon name='info circle' /></span>}
                        content='Notifications will only work if you stay on this page. You can open another browser tab or another application.'
                        size='small'
                    />
                </Container>
                <Container fluid={true} textAlign={'left'}>
                    <Segment>
                        <Radio toggle={true} label={'Just display messages not related to articles'} checked={this.state.justAbout} onChange={() => toggleFilterSettings(!this.state.justAbout, '%')}/>
                        <Button disabled={!this.state.justAbout} style={{marginLeft: 10}} color={'blue'} onClick={(t) => toggleFilterSettings(this.state.justAbout, 'event-%')}>Event reminders</Button>
                        <Button disabled={!this.state.justAbout} style={{marginLeft: 10}} color={'blue'} onClick={(t) => toggleFilterSettings(this.state.justAbout, 'unresponding_contact')}>Unresponding contacts</Button>
                        <TextField disable={!this.state.justAbout}  style={{marginLeft: 10}} type={''} label={'About:'} value={this.state.aboutText} onChange={(t) => toggleFilterSettings(this.state.justAbout, t.target.value)}/>
                        {
                            this.state.refetchingMessages ? <Info icon="circle notched" message="Loading..." loading={true}/> : ''
                        }
                    </Segment>
                    {filterMenu()}
                </Container>

                <Table celled selectable color='blue' striped>
                    <Table.Header>
                        {pagination}
                        <Table.Row>
                            <Table.HeaderCell width={1}>Time</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Direction</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Contact Person</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Sender</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Text</Table.HeaderCell>
                            <Table.HeaderCell width={1}>About</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Related Article</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {allMessages.nodes.map((node) => {
                            let articleLink = <div></div>;
                            if (node.questionByQuestionid && node.questionByQuestionid.articleByArticleid) {
                                articleLink = <Link
                                    to={`/article/${node.questionByQuestionid.articleByArticleid.id}`}> {node.questionByQuestionid.articleByArticleid.title}</Link>;
                            }

                            let contactPersonLink = null;

                            if (node.contactpersonByContactpersonid) {
                                contactPersonLink =
                                        <Link to={`/contacts/${node.contactpersonByContactpersonid.id}`}>
                                            {node.contactpersonByContactpersonid.title + ' ' + node.contactpersonByContactpersonid.fullname}
                                        </Link>
                            }
                            return (
                                <Table.Row key={node.id}>
                                    <Table.Cell>{moment(node.timestamp).format('YYYY-MM-DD HH:mm')}</Table.Cell>
                                    <Table.Cell><MessageStatusIcon type={node.type} displayLabel={true} failReason={node.failreason}/></Table.Cell>
                                    <Table.Cell>{contactPersonLink}</Table.Cell>
                                    <Table.Cell>{node.sender}</Table.Cell>
                                    <Table.Cell>{node.text}</Table.Cell>
                                    <Table.Cell>{node.about}</Table.Cell>
                                    <Table.Cell>{articleLink}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer>
                        {pagination}
                    </Table.Footer>
                </Table>
            </div>
        );
    }
};

const messageListQuery = gql`
    query MessageListQuery($limit: Int,$offset: Int, $direction: String, $region: Int, $justAbout: Boolean, $aboutText: String) {
        allMessages: filterMessages3(direction: $direction, limitNo: $limit, offsetNo: $offset, regionIn: $region, justAbout: $justAbout, aboutText: $aboutText) {
            totalCount
            pageInfo {
                endCursor
                startCursor
                hasNextPage
                hasPreviousPage
            }
            nodes {
                id
                type
                timestamp
                about
                text
                sender
                numberoftries
                failreason
                contactpersonByContactpersonid {
                    fullname
                    id
                    title
                }
                questionByQuestionid {
                    id
                    question
                    answer
                    articleByArticleid {
                        id
                        status
                        title
                    }
                }
            }
        }
    }
`;

function getMessagesQueryVariables(props) {
    return {
        offset: (props.match.params.page ? parseInt(props.match.params.page, 10) - 1 : 0) * PAGE_SIZE,
        limit:  PAGE_SIZE,
        direction: props.match.params.direction || 'all',
        region: parseInt(localStorage.getItem('selected-region')|| '1'),
        justAbout: localStorage.getItem('just-about-messages') == 'true',
        aboutText: localStorage.getItem('about-text-messages') || '%'
    };
}

const MessageList = (props) => (
    <Query query={messageListQuery}
        variables={getMessagesQueryVariables(props)}
        fetchPolicy='network-only' pollInterval='35000'>
        {({data, loading, error, refetch, startPolling, stopPolling}) => {
            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true}/>;
            }
            if (data && data.allMessages) {
                return <MessageListContainer allMessages={data.allMessages} refetch={refetch} startPolling={startPolling} stopPolling={stopPolling} history={props.history} match={props.match} />
            }
            return <Info icon='warning'
                message={(error && error.message) || 'Sorry, we could not fetch messages!'}
                title='Error' loading={false}/>;
        }}
    </Query>);

export default MessageList;
