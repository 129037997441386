import React, {Component} from 'react';
import gql from "graphql-tag";
import {
    Container,
    Header,
    Grid,
    Table,
    Segment,
    Select, Label
} from 'semantic-ui-react';
import { Query } from "react-apollo";
import Info from '../components/Info';
import _ from 'lodash';
import {Link} from "react-router-dom";
import AdminList from "../components/admin/AdminList";

const regionQuery = gql`
    query AdminsQuery {
        allRegions {
            nodes{
                id
                name
                phonePrefix
                leagueRegionsByRegionId {
                    totalCount
                }
                adminRegionsByRegionId {
                    totalCount
                }
            }
        }
    }
`;


class AdministrationContainer extends Component {
    constructor(props) {
        super(props)
        const lr = localStorage.getItem('selected-region');
        const selectedRegion = lr ? parseInt(lr, 10) : null
        this.state = {
            selectedAdmin: null,
            newAdmin: false,
            selectedRegion: selectedRegion
        };
    };


    render() {
        const {regions} = this.props;
        const {selectedRegion} = this.state;

        const regionOptions = _.map(_.orderBy(regions.nodes, 'name'), r => ({ key:r.id, value: r.id, text: r.name}));
        regionOptions.splice(0, 0, { key: '-1', value: -1, text: 'ALL'});

        return (
            <div>
                <Header as='h1' textAlign='center' color='blue'>
                    {`Administration`}
                </Header>
                <Container style={{marginTop: 20}}>
                    <Grid columns={2}>
                        <Grid.Column width={8}>
                            <Segment color='yellow' style={{textAlign: 'left'}}>
                                <Header as='h2' textAlign={"center"} color='blue'>Regions</Header>
                                <Table striped  celled collapsing selectable size={"large"} attached={"top"} columns={"16"} >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>ID</Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Phone Prefix</Table.HeaderCell>
                                            <Table.HeaderCell>Leagues</Table.HeaderCell>
                                            <Table.HeaderCell>Admins</Table.HeaderCell>
                                            <Table.HeaderCell>Edit</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {regions.nodes.map((node) => {
                                            return (
                                                <Table.Row key={node.id}>
                                                    <Table.Cell>{node.id}</Table.Cell>
                                                    <Table.Cell>{node.name}</Table.Cell>
                                                    <Table.Cell>{node.phonePrefix}</Table.Cell>
                                                    <Table.Cell>{node.leagueRegionsByRegionId?.totalCount}</Table.Cell>
                                                    <Table.Cell>{node.adminRegionsByRegionId?.totalCount}</Table.Cell>
                                                    <Table.Cell><Link to={'/region/'+node.id}> Edit </Link></Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Segment color='yellow' style={{textAlign: 'left'}}>
                                <Header as='h2' textAlign={"center"} color='blue'>Admins</Header>
                                <Label as='h3' size='large' color='grey' style={{marginBottom: 15}}>Select Region: </Label>
                                <Select label='Select Region' labeled value={selectedRegion} placeholder='ALL' options={regionOptions}
                                        onChange={(e, {value})=> this.setState({selectedRegion: value})}/>
                                <AdminList selectedRegion={selectedRegion} displayEdit={true} displayRemove={false} displayAdd={true}/>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Container>
            </div>
        );
    }
};

const AdministrationPage = () => {

    return (<Query query={regionQuery}>
        {({data, loading, error}) => {
            if (loading) {
                return <Info icon="circle notched" message="Loading..." loading={true}/>;
            }
            if (data && data.allRegions) {
                return <AdministrationContainer regions={data.allRegions} />
            }
            return <Info icon='warning' message={(error && error.message) || 'Sorry, regions query failed!'}
                         title='Error' loading={false}/>;
        }}
    </Query>);
};

export default AdministrationPage;
