import {Card, Icon} from "semantic-ui-react";
import React from "react";
import {Link} from "react-router-dom";

const ContactPersonCardContainer = ({contactPerson}) => {
    if (contactPerson && contactPerson.id) {
        return (
            <Card key={'c'+contactPerson.id} color={"green"} style={{padding:8}} raised centered>
                <Card.Header><Link
                    to={`/contacts/${contactPerson.id}`}>{contactPerson.fullname}</Link></Card.Header>
                <Card.Meta>{contactPerson.title}</Card.Meta>
                <Card.Description>
                    <div>Phone: {contactPerson.phone}</div>
                    <div style={{fontWeight: 700}}>
                        <div>Team: {contactPerson.teamByTeamid.name}</div>
                        <div>League: {contactPerson.teamByTeamid.leagueByLeagueid.name}</div>
                    </div>
                    <div>
                        ACTIVE: { contactPerson.active ? <Icon name='check' color='green'/> : <Icon name='x' color='red'/> }
                        DRYMODE: { contactPerson.drymode ? <Icon name='check' color='green'/> : <Icon name='x' color='red'/> }
                    </div>
                </Card.Description>
            </Card>
        )
    } else {
        return null;
    }
};

export default ContactPersonCardContainer;
