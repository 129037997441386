import React from 'react';
import {graphql} from "react-apollo";
import gql from "graphql-tag";
import {Header, Segment} from 'semantic-ui-react'
import Info from '../components/Info';
import StatsChart from './StatsChart';

class StatsContainer extends React.Component {
    render() {
        const {data: {loading, error, operatorStats}} = this.props;

        if (error) {
            return <Info icon="warning" message={error.message} title="Error" loading={false}/>;
        }

        if (loading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }

        return (
            <div>
                <Header as='h1' textAlign='center' color='blue'>
                    STATS
                </Header>

                <Segment clearing>
                    <Header as={'h3'} textAlign='center' color='red'>EDITING STATISTICS</Header>
                    <StatsChart operatorStatsNodes={operatorStats.nodes} eventType='EDITING' title='USER EDITING STATS'/>
                </Segment>
                <Segment clearing>
                    <Header as={'h3'} textAlign='center' color='red'>PAGE VIEW STATISTICS</Header>
                    <StatsChart operatorStatsNodes={operatorStats.nodes} eventType='PAGE_VIEW' title='PAGE VIEW STATS'/>
                </Segment>
                <Segment clearing>
                    <Header as={'h3'} textAlign='center' color='red'>USER ACTIVE STATISTICS</Header>
                    <StatsChart operatorStatsNodes={operatorStats.nodes} eventType='USER_ACTIVE' title='USER ACTIVE STATS'/>
                </Segment>
            </div>
        );
    }
};

const operatorStatsQuery = gql`
    query OperatorStatsQuery {
        operatorStats {
            nodes {
                day
                avgAnswerLength
                avgDuration
                eventType
                numberOfArticles
            }
        }
    }
`;

const Stats = (graphql(operatorStatsQuery)(StatsContainer));
export default Stats;
