import React, {createRef} from 'react';
import {graphql, Query} from "react-apollo";
import gql from "graphql-tag";
import {Button, Label, Checkbox, Ref, Sticky, Grid, Header, Segment, Select, Table, Input} from 'semantic-ui-react'
import Info from '../components/Info';

import * as _ from "lodash";

import LeagueContactStats from "../components/stats/LeagueContactStats";

class LeagueStatsContainer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedLeague: null,
            selectedSport: null,
            selectedClass: null,
            onlyActiveContact: true,
            onlyActiveNow: false,
            selectedDate: '',
            selectedToDate: '',
        };
        this.contextRef = createRef()
    }

    search() {
        const {refetch} = this.props;
        const {selectedSport, selectedClass, onlyActiveContact, onlyActiveNow} = this.state;
        refetch({
            sport: selectedSport,
            tclass: selectedClass,
            activeContact: onlyActiveContact,
            activeNow: onlyActiveNow
        });

    }


    render() {
        const {isLoading, error, sports, leagues, refetch} = this.props;
        const {selectedLeague, selectedSport, selectedClass, onlyActiveContact, onlyActiveNow, selectedDate, selectedToDate} = this.state;
        const sportOptions = _.map(_.orderBy(sports?.nodes, 'name'), r => ({ key:r.sportid, value: r.sportid, text: `${r.sportname}`}));
        sportOptions.splice(0, 0, { key: '-1', value: null, text: 'ALL'});

        const classOptions = [{key: 'all', value: null, text: 'ALL'}, {key: 'herr', value: 'herr', text: 'Men'},{key: 'dam', value: 'dam', text: 'Women'},{key: 'mix', value: 'mix', text: 'Mix'}]

        if (error) {
            return <Info icon="warning" message={error.message} title="Error" loading={false}/>;
        }

        if (isLoading) {
            return <Info icon="circle notched" message="Loading..." loading={true}/>;
        }

        return (
            <div>
                <Ref innerRef={this.contextRef}>
                <Grid width={16} stackable centered padded>
                    <Grid.Row stretched>
                        <Header as='h1' textAlign='center' color='blue'>
                            LEAGUE STATS
                        </Header>
                        <div style={{width: '90%'}}>
                            <Segment style={{display: 'flex', alignItems: 'center'}} basic floated='left'>
                                <Select label='Sport' value={selectedSport} placeholder='ALL' options={sportOptions}
                                             onChange={(e, {value})=> this.setState({selectedSport: value})}/>

                                <Select label='Class' value={selectedClass } placeholder='ALL' options={classOptions}
                                        onChange={(e, {value})=> this.setState({selectedClass: value})}/>

                                <Checkbox toggle style={{margin: 5}} label='Active Contact'
                                          checked={onlyActiveContact}
                                        color={onlyActiveContact ? 'green' : 'grey'}
                                        onClick={() => {
                                            this.setState({onlyActiveContact: !onlyActiveContact})
                                        }}/>
                                <Checkbox toggle style={{margin: 5}} label='Active League'
                                          checked={onlyActiveNow}
                                        color={onlyActiveNow ? 'green' : 'grey'}
                                        onClick={() => {
                                            this.setState({onlyActiveNow: !onlyActiveNow})
                                        }}/>

                                <Button as='button'
                                        onClick={() => {
                                            refetch({
                                                sport: selectedSport,
                                                tclass: selectedClass,
                                                activeContact: onlyActiveContact,
                                                activeNow: onlyActiveNow
                                            });
                                        }}
                                        content='Search'/>
                            </Segment>
                        </div>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12}>
                        <Table style={{width: '100%'}} celled collapsing striped selectable stackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>#</Table.HeaderCell>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Sport</Table.HeaderCell>
                                    <Table.HeaderCell>Team Class</Table.HeaderCell>
                                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                                    <Table.HeaderCell>End Date</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {leagues?.nodes.map((node, index) => {
                                    return (
                                        <Table.Row key={node.id} onClick={() => this.setState({selectedLeague : node})} positive={selectedLeague?.id == node.id}>
                                            <Table.Cell>{index+1}</Table.Cell>
                                            <Table.Cell>{node.id}</Table.Cell>
                                            <Table.Cell>{node.name}</Table.Cell>
                                            <Table.Cell>{node.sportname}</Table.Cell>
                                            <Table.Cell>{node.teamclass}</Table.Cell>
                                            <Table.Cell>{node.startDate}</Table.Cell>
                                            <Table.Cell>{node.endDate}</Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        </Grid.Column>
                        <Grid.Column width={4}>

                                <Sticky context={this.contextRef}>
                                    <Grid>
                                        <Grid.Row >
                                            <Grid.Column width={4}>
                                                <Label color={"red"} pointing={'right'} size={"large"}>From: </Label>
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                                <Input
                                                    label={{ icon: 'calendar'}}
                                                    labelPosition='left'
                                                    placeholder='YYYY-MM-DD'
                                                    value={selectedDate}
                                                    onChange={(e, {value}) => this.setState({selectedDate:value})}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={4}>
                                                <Label color={"red"} pointing={'right'} size={"large"}>To: </Label>
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                                <Input
                                                    label={{ icon: 'calendar'}}
                                                    labelPosition='left'
                                                    placeholder='YYYY-MM-DD'
                                                    value={selectedToDate}
                                                    onChange={(e, {value}) => this.setState({selectedToDate:value})}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                {
                                    selectedLeague ?
                                        <LeagueContactStats date={selectedDate.length === 10 ? selectedDate : null} dateTo={selectedToDate.length === 10 ? selectedToDate : null} league={selectedLeague}/>
                                        :
                                        <Segment>Please select a league</Segment>
                                }
                                </Sticky>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </Ref>
            </div>
        );
    }
};

const leagueStatsQuery = gql`
    query LeagueStatsQuery($sport: Int, $tclass: String, $activeContact: Boolean, $activeNow: Boolean, $region: Int) {
        allSports{
            nodes {
                id
                sportid
                sportname
            }
        }
        leagues: filterLeagues3(sportId: $sport, teamClass: $tclass, activeContact: $activeContact, activeNow: $activeNow, regionIn: $region) {
            nodes {
                id
                name
                sportid
                sportname
                startDate
                endDate
                teamclass
                teamLeaguesByLeagueId{
                    totalCount
                    nodes{
                        teamByTeamId {
                            id
                            name
                            logo
                        }
                    }
                }
                teamsByLeagueid {
                    totalCount
                    nodes {
                        id
                        name
                        logo
                    }
                }
            }
        }
    }
`;


const LeagueStats = () => ( <Query query={leagueStatsQuery} variables={{activeContact: true, region: parseInt(localStorage.getItem('selected-region')|| '1')}}>
    {({ data, refetch, loading, error }) => {
        return <LeagueStatsContainer isLoading={loading} refetch={refetch} error={error} sports={data?.allSports} leagues={data?.leagues}/>
    }}
</Query>);


export default LeagueStats;
